import React, { Component } from "react";
import { Steps, Button } from 'antd';
import './style.scss';
import BusinessDetails from "./BusinessDetails";
import LedgerAccounts from "./LedgerAccounts";
import EducationType from "./EducationType"
import { FormattedMessage } from "react-intl";
import { showToasterMessage } from "../../utils";





class CompanySetup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
        };
    }
    next() {
        if(this.state.current === 0 && !this.props.selectedIndustry){
            showToasterMessage({
                description: 'Please select industry type',
                messageType: 'warning'
              })
            return;
        }
        if(this.state.current === 0 && !this.props.fyStartDate && !this.props.fyEndDate){
            showToasterMessage({
                description: 'Please select financial year',
                messageType: 'warning'
              })
            return;
        }
        const current = this.state.current + 1;
        this.setState({ current });
        // if(this.props.selectedIndustry && this.props.selectedIndustry.businessIndustryId === 1484){
        //     this.props.updateState({labelConfig : {
        //         preSchool: this.props.preSchool,
        //         school: this.props.school,
        //         college: this.props.college,
        //         institution: this.props.institution,
        //         weekendSchool: this.props.weekendSchool}
        //     })
        // }
        if(this.props.selectedIndustry && this.props.selectedIndustry.industryName === 'Education'){
            this.props.updateState({
                labelConfig : {
                    eduType: this.props.educationType || '',
                    educationLabel : {
                        program : this.props.program || '',
                        subject : this.props.subject || '',
                        term: this.props.term || '',
                        course: this.props.course || '',
                        section: this.props.section || '',
                        class: this.props.class || '' 
                    }
                }
            })
        }
    }
    prev() {
        const current = this.state.current - 1;
        this.setState({ current });
    }
    render() {
        const { current } = this.state;
        const Step = Steps.Step;

        const step1 = [{
            title: <FormattedMessage id='company.companySetup.organizationType' defaultMessage=' ' /> ,
            content: BusinessDetails(this.props),
            key: "1"
        },
        // , {
        //     title: 'Financial Year',
        //     content: SetupFinancial(),
        // }, {
        //     title: 'Accounting Type',
        //     content: AccountingType(),
        // }, 

        {
            title: <FormattedMessage id='sidebar.menuItem.leadgerAccounts' defaultMessage=' ' />,
            content: LedgerAccounts(this.props),
            key: "3"
        }];

        const step2 = [{
            title: <FormattedMessage id='company.companySetup.organizationType' defaultMessage=' ' /> ,
            content: BusinessDetails(this.props),
            key: "1"
        },
        {
            title: 'Education Type',
            content: EducationType(this.props),
            key: "2"
        }, 

        {
            title: <FormattedMessage id='sidebar.menuItem.leadgerAccounts' defaultMessage=' ' />,
            content: LedgerAccounts(this.props),
            key: "3"
        }];

        const steps = this.props.selectedIndustry && this.props.selectedIndustry.industryName === 'Education' ? step2 : step1
        
        return (
            <div className="profile-setup-container">
                <div className="inner-wrapper">
                    <Steps current={current} size='small'>
                        {steps.map(item => <Step key={item.key} title={item.title} />)}
                    </Steps>
                    <div className="steps-content">{steps[this.state.current].content}</div>
                    <div className="steps-action-btns apart">
                        {
                            this.state.current > 0
                            &&
                            <Button onClick={() => this.prev()}>
                               <FormattedMessage id='company.companySetup.button.previous' defaultMessage=' ' /> 
                            </Button>
                        }
                        {
                            this.state.current === 0
                            &&
                            <div></div>
                        }
                        {
                            this.state.current < steps.length - 1
                            &&
                            <Button type="primary" className="ml10" onClick={() => this.next()}>
                                <FormattedMessage id='company.companySetup.button.next' defaultMessage=' ' /> 
                            </Button>
                        }
                        {
                            this.state.current === steps.length - 1
                            &&
                            <Button type="primary" className="ml10" onClick={() => {
                                this.props.completeCompanySetup(this.props);
                            }}>
                                <FormattedMessage id='company.companySetup.button.done' defaultMessage=' ' /> 
                            </Button>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanySetup;