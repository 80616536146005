import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import JournalsComponent from '../../../../components/finance/Journals/JournalCreate';
import { createJournal } from './action';
import { showModal, hideModal } from '../../../../actions/commonActions';
import { fetchAllLedgerAccounts } from '../../LedgerAccounts/action';
import { fetchDataIfNeeded } from '../../../../utils';

class JournalComp extends Component {

    constructor(props){
        super();
        this.state={
            journalDate: new Date()
        }
    }

    componentWillMount() {
        // this.props.updateHeaderState({
        //     collapsed: true
        // });
    }

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId
        };
        fetchDataIfNeeded('fetchAllLedgerAccounts', 'allLedgerAccounts', this.props, payload);
    }



    render() {
        return <JournalsComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }}/>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        isLoading: state.common.isLoading,
        allLedgerAccounts: state.ledger.allLedgerAccounts,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllLedgerAccounts,
    createJournal,
    showModal, 
    hideModal
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(JournalComp);
