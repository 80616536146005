import React from 'react';
import { FormattedMessage } from 'react-intl';
// import { MailOutlined } from '@ant-design/icons';
import { Row, Col, InputNumber } from 'antd';
import { TextBox } from "../../../../general/TextBox";
// import moment from 'moment-timezone';
// import { CONSTANTS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
// const { Option } = Select;
// const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const RecurringInvoiceSetupForForm = (props) => {
   
    const getTotalAmount = () => {
        if (!props.invoiceItem || !props.invoiceItem.length) { return 0.00 }
        let total = 0;
        props.invoiceItem.forEach(function (item) {
            if (item.amount) {
                total += Number(item.amount);
            }
        });
        return total.toFixed(2);
    }

    return (
        <div className="modal-dialog ">
            <div className="modal-content full-width">
                <div className='newheight'>
                <Row style={{borderBottom:'solid 1px #e4e4e4', paddingBottom:'8px', marginBottom:'10px'}}>
                    <Col span={2}><b><FormattedMessage id='serial.no' /></b></Col>
                    <Col span={7}><b><FormattedMessage id='itemDescription.text' /></b></Col>
                    <Col span={3}><b><FormattedMessage id='modal.txDetails.common.qty' /></b></Col>
                    <Col span={4}><b><FormattedMessage id='modal.txDetails.common.rate' /></b></Col>
                    <Col span={4}><b><FormattedMessage id='amount.text' /></b></Col>
                    <Col span={2}>
                        <b><FormattedMessage id='common.listing.header.action' /></b>
                    </Col>
                    <Col span={1}>
                        {
                            !(props.invoiceItem || []).length ?  <i className="fi fi-br-plus pointer" 
                            onClick={()=>{
                                let invoiceItem = props.invoiceItem || [];
                                invoiceItem.push({});
                                props.updateState({ invoiceItem });
                            }}
                            ></i> : ''
                        }
                    </Col>
                </Row>
                    {
                        (props.invoiceItem || []).map((item, i) => {

                            return (
                                <div key={'ques' + i}>
                                    <Row style={{ marginBottom: '10px',borderBottom:'solid 1px #e4e4e4', paddingBottom:'10px' }} >
                                        <Col span={2}><b> {i + 1}</b></Col>
                                        <Col span={7} style={{ paddingRight:'10px'}}>
                                            <TextBox maxLength={500}
                                                style={{ width: '100%'}}
                                                placeholder={props.intl.formatMessage({ id: 'modal.txDetails.common.description' })}
                                                value={item.description}
                                                onChange={(e) => {
                                                    item.description = e.target.value;
                                                    let invoiceItem = props.invoiceItem;
                                                    invoiceItem.splice(i, 1, item)
                                                    props.updateState({ invoiceItem });
                                                }}
                                            />
                                        </Col>
                                        <Col span={3}>
                                            <InputNumber min={0}
                                                style={{ width: '90%'}}
                                                placeholder={props.intl.formatMessage({ id: 'modal.txDetails.common.qty' })}
                                                value={item.qty}
                                                onChange={(qty) => {
                                                    item.qty = qty;
                                                    if(item.qty && item.rate ) { item.amount = (item.qty*item.rate).toFixed(2)}
                                                    else{ item.amount = 0.00 }
                                                    let invoiceItem = props.invoiceItem;
                                                    invoiceItem.splice(i, 1, item)
                                                    props.updateState({ invoiceItem });
                                                }}
                                            />
                                        </Col> 
                                        <Col span={4}>
                                            <InputNumber min={0}
                                                style={{ width: '90%'}}
                                                // placeholder={props.intl.formatMessage({ id: 'modal.txDetails.common.rate' })}
                                                placeholder={'0.00'}
                                                value={item.rate}
                                                onChange={(rate) => {
                                                    item.rate = rate;
                                                    if(item.qty && item.rate ) { item.amount = (item.qty*item.rate).toFixed(2)}
                                                    else{ item.amount = 0.00 }
                                                    let invoiceItem = props.invoiceItem;
                                                    invoiceItem.splice(i, 1, item)
                                                    props.updateState({ invoiceItem });
                                                }}
                                            />
                                        </Col>
                                        <Col span={4}>
                                        <TextBox
                                                style={{ width: '90%'}}
                                                value={item.amount}
                                                placeholder={'0.00'}
                                                disabled
                                            /></Col>
                                        <Col span={2}>
                                            <span style={{ fontSize: '20px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => {
                                                let invoiceItem = props.invoiceItem;
                                                invoiceItem.splice(i, 1)
                                                props.updateState({ invoiceItem });
                                            }
                                            }><i className="fi fi-rr-trash" style={{fontSize: '14px'}}></i> </span>
                                        </Col>
                                        <Col span={1}>
                                            {
                                                i === (props.invoiceItem.length-1) ?  <i className="fi fi-br-plus pointer" style={{ position:'relative', top:'10px' }}
                                                onClick={()=>{
                                                    let invoiceItem = props.invoiceItem || [];
                                                    invoiceItem.push({});
                                                    props.updateState({ invoiceItem });
                                                }}
                                                ></i> : ''
                                            }
                                        </Col>
                                    </Row>



                                </div>
                            )
                        })
                }
                {(props.invoiceItem && props.invoiceItem.length) ? <Row >
                    <Col span={12}></Col>
                    <Col span={5}><b><FormattedMessage id='total.text' /></b></Col>
                    <Col span={5}><b><i className="fa fa-dollar"></i>{getTotalAmount()}</b></Col>
                </Row> : ''}
                {/* <Button className="add-new-record ant-btn ant-btn-default" onClick={
                        () => {
                            let invoiceItem = props.invoiceItem || [];
                            invoiceItem.push({});
                            props.updateState({ invoiceItem });
                        }
                    }><FormattedMessage id='modal.settings.companyInfo.taxInfo.addLine' />  </Button> */}
                    {/* <Row className='pt20'>
                        <Col span={6}>
                            <FormattedMessage id='invoiceTitleOnetime.text' />
                        </Col>
                        <Col span={10}>
                            <TextBox maxLength={40}
                                style={{ width: '100%', padding: '5px' }}
                                placeholder={props.intl.formatMessage({ id: 'invoiceTitleOnetime.text' })}
                                value={props.invoiceTitleOnetime}
                                onChange={(e) => {
                                    props.updateState({ invoiceTitleOnetime: e.target.value });
                                }}
                            />
                        </Col>
                    </Row> */}
                </div>
               
                   
            </div>
            <div>
            <hr className="mt10 "></hr>
                <Row justify='end'>
                    <button type="button" className="ant-btn ant-btn-primary mt20" onClick={() => {
                        let isRateMissing = false;
                        let isQtyMissing = false;
                        let isDescriptionMissing = false;
                        (props.invoiceItem || []).forEach(function (item) {
                            if (!item.description) {
                                isDescriptionMissing = true;
                            }
                            if (!item.rate) {
                                isRateMissing = true;
                            }
                            if (!item.qty) {
                                isQtyMissing = true;
                            }
                        });
                        if (isDescriptionMissing) {
                            return showToasterMessage({
                                messageType: 'error', description: props.intl.formatMessage({ id: 'activity.validation.description' })
                            });
                        }
                        if (isQtyMissing) {
                            return showToasterMessage({
                                messageType: 'error', description: props.intl.formatMessage({ id: 'qty.missing.message' })
                            });
                        }
                        if (isRateMissing) {
                            return showToasterMessage({
                                messageType: 'error', description: props.intl.formatMessage({ id: 'rate.missing.message' })
                            });
                        }
                        props.addInvoiceItemList(props.invoiceItem, props.invoiceTitleOnetime);
                        props.popModalFromStack();

                    }}><FormattedMessage id='forgot.btn.save' defaultMessage='' /></button>
                </Row>
            </div>
        </div>
    );

}

export default RecurringInvoiceSetupForForm;