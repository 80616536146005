import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import {Col,Row, Input} from "antd";
import { Dropdown } from "../../../general/Dropdown";
import "@ant-design/compatible/assets/index.css";

const { TextArea } = Input;
const onChange = (e) => {
  console.log('Change:', e.target.value);
};

const SMSDetails = (props) => {

  const transactionMode = [
    { trnMode: "Option 1", referCode: "OPT1" },
    { trnMode: "Option 2", referCode: "OPT2" },
    { trnMode: "Option 3", referCode: "OPT3" },
  ];

  return (
    <>
      <Row gutter={[16, 8]}>
              <Col span={24} className="i-label">
                <FormattedMessage
                  id="drawer.labelName.communicationTemplate.smsProvider"
                  defaultMessage=""
                />
                <span className="required">*</span>
                
              </Col>
              <Col span={24}>
                <Dropdown
                      items={transactionMode || []}
                      valueKeyName="trnMode"
                      optionKeyName="referCode"
                      value={(props.transactionMode || {}).trnMode}
                      placeholder={"Select sms service provider"}
                      // onSelect={(referCode) => {
                      //   const doc =
                      //     find(transactionMode, { referCode: referCode }) || {};
                      //   props.updateState({
                      //     transactionMode: doc,
                      //   });
                      // }}
                    />
                  {/* <ErrorMsg
                    message={"Transaction Mode is required"}
                  /> */}
              </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={24} className="i-label">
          <FormattedMessage
            id="drawer.labelName.communicationTemplate.smsId"
            defaultMessage=""
          />
          <span className="required">*</span>
          
        </Col>
        <Col span={24}>
          <Dropdown
                items={transactionMode || []}
                valueKeyName="trnMode"
                optionKeyName="referCode"
                value={(props.transactionMode || {}).trnMode}
                placeholder={"APMX90"}
                // onSelect={(referCode) => {
                //   const doc =
                //     find(transactionMode, { referCode: referCode }) || {};
                //   props.updateState({
                //     transactionMode: doc,
                //   });
                // }}
              />
            {/* <ErrorMsg
              message={"Transaction Mode is required"}
            /> */}
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={24} className="i-label">
          <FormattedMessage
            id="drawer.labelName.communicationTemplate.smsMessage"
            defaultMessage=""
          />
          <span className="required">*</span>
          
        </Col>
        <Col span={24}>
          <TextArea
            showCount
            maxLength={100}
            style={{
              height: 120,
            }}
            onChange={onChange}
            placeholder="Type message here"
          />
        </Col>
      </Row>
    </>
  );
};

export default injectIntl(SMSDetails);
