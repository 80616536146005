import React from "react";
import { Tabs } from 'antd';
import PageBreadcrumb from "../../PageBreadcrumb";
import AllCategory from "./AllCategory";
import PublishedCategory from "./publishedCategory";
import UnpublishedCategory from "./unpublishedCategory";
import { FormattedMessage } from "react-intl";
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../utils';
const { TabPane } = Tabs;

function callback(key, props) {
    const payload = { companyInfo: props.companyInfo, relationshipId: props.companyInfo.relationshipId, pageNumber: props.pageNumber, pageSize: props.pageSize, isDetails: true };
    if (key === '1') {
        props.updateState({activeTab: 'All Category', activeTabKey: "1"});
    } else if (key === '2') {
        props.updateState({activeTab: 'Published Category', activeTabKey: "2"});
        fetchDataIfNeeded('getPublishedCategoryCount', 'publishedCategoryCount', props, payload);
        fetchPaginationDataIfNeeded('fetchPublishedCategory', 'publishedCategories', props, payload);
    }else if (key === '3') {
        props.updateState({activeTab: 'Unpublished Category', activeTabKey: "3"});
       fetchDataIfNeeded('getUnpublishedCategoryCount', 'unpublishedCategoryCount', props, payload);
       fetchPaginationDataIfNeeded('fetchUnpublishedCategory', 'unpublishedCategories', props, payload);
    }
}





const PublishedCategoryComp = (props) => {
    const breadCrumbList = [
        // {
        //     name: 'Dashboard',
        //     link: 'dashboard'
        // },
        // {
        //     name: 'Sales',
        // },
        {
            name: <FormattedMessage id='ecommerce.text' defaultMessage='' />,
        },
        {
            name: <FormattedMessage id='publishedCategory.text' defaultMessage='' />,
        }
    ];
    return (
        <div className="customer-details-container">
            <div className="pb10">
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div>
            <div className="customer-details">

                <div>
                    <Tabs activeKey={props.activeTabKey || "1"} onChange={(key) => { callback(key, props); }} type="card">
                    <TabPane tab="All Category" key="1">
                            <AllCategory {...props} />
                        </TabPane>
                        <TabPane tab="Published Category" key="2">
                            <PublishedCategory {...props} />
                        </TabPane>
                        <TabPane tab="Unpublished Category" key="3">
                            <UnpublishedCategory {...props} />
                        </TabPane>
                        
                    </Tabs>
                </div>

            </div>
        </div>
    )
};

export default PublishedCategoryComp;
