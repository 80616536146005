import React from "react";
import { Drawer, Row, Col } from 'antd';
import { CustomButton } from "../../general/CustomButton";
import { TextBox } from '../../general/TextBox';
import { Dropdown } from '../../general/Dropdown';
import { FilterOutlined, UndoOutlined,} from '@ant-design/icons';

const StockTransferFiltersComp = (props) => {
    const { updateState, companyInfo } = props;

    const closeDrawer = () =>{
        props.updateState({
            StockTransferFilterDrawerVisible: false
        })
    }

    const applyFilter = () => {
        const payload ={
            relationshipId: companyInfo.relationshipId,
            gitNumber: props.gitNumber,
            sourceWarehouse: props.selectedDestinationWarehouse,
            destinationWarehouse: props.selectedSourceWarehouse,
            status: props.selectedStatus,
            pageNumber: 1,
            pageSize: props.pageSize,
        }
        props.fetchstockTransfer(payload);
        props.getStockTransferCount(payload);
    }

    const clearFilter = () =>{
        updateState({
            gitNumber: undefined,
            selectedDestinationWarehouse: undefined,
            selectedSourceWarehouse: undefined,
            status: undefined,
        });
        const payload ={
            relationshipId: companyInfo.relationshipId,
            pageNumber: 1,
            pageSize: props.pageSize,
        }
        props.fetchstockTransfer(payload);
        props.getStockTransferCount(payload);
    }

    return (
        <Drawer
            title={'Stock Transfer Filter'}
            width={720}
            zIndex={1}
            maskClosable={false}
            destroyOnClose={true}
            onClose={closeDrawer}
            className="custom-drawer"
            visible={props.StockTransferFilterDrawerVisible}
            footer={<>
                    <CustomButton
                        intlId='confirmation.cancel'
                        defaultMessage=''
                        type='default'
                        key={0}
                        onClick={closeDrawer}
                    />
                    <div>
                        <CustomButton
                            intlId='common.clearFilter.txt'
                            defaultMessage=''
                            type='default'
                            key={0}
                            onClick={clearFilter}
                            btnIcon={<UndoOutlined />}
                            className='mr5'
                        />
                        <CustomButton
                            intlId='common.applyFilter.txt'
                            defaultMessage=''
                            htmlType='submit'
                            onClick={applyFilter}
                            key={1}
                            btnIcon = {<><FilterOutlined /> &nbsp;</>}
                        />
                    </div>
                </>}
        >
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <div className="i-label">Stock #</div>
                    <TextBox 
                        placeholder='Enter Stock #'
                        type='text'
                        maxLength={20}
                        countStyle={{top: '35px'}}
                        value={props.gitNumber}
                        onChange={(e) => {
                            props.updateState({ gitNumber: e.target.value });
                        }}
                    />
                </Col>

                <Col span={12}>
                    <div className="i-label">Source Warehouse</div>
                    <Dropdown
                        items={props.allWarehouses}
                        valueKeyName='warehouseName'
                        optionKeyName='warehouseMasterId'
                        value={props.selectedSourceWarehouse}
                        placeholder='Select Source Warehouse'
                        className={props.selectedSourceWarehouse && props.selectedSourceWarehouse === props.selectedDestinationWarehouse ? 'select-error' : ''}
                        onSelect={(val) => {
                            updateState({ selectedSourceWarehouse: val })
                        }}
                        allowClear={true}
                        onClear={() => {
                            updateState({ selectedSourceWarehouse: undefined })
                        }}
                    />
                </Col>

                <Col span={12}>
                    <div className="i-label">Destination Warehouse</div>
                    <Dropdown
                        placeholder='Select Destination Warehouse'
                        items={props.allWarehouses}
                        valueKeyName='warehouseName'
                        optionKeyName='warehouseMasterId'
                        value={props.selectedDestinationWarehouse}
                        className={props.selectedSourceWarehouse && props.selectedSourceWarehouse === props.selectedDestinationWarehouse ? 'select-error' : ''}
                        onSelect={(val) => {
                            updateState({ selectedDestinationWarehouse: val })
                        }}
                        allowClear={true}
                        onClear={() => {
                            updateState({ selectedDestinationWarehouse: undefined })
                        }}
                    />
                </Col>

                <Col span={12}>
                    <div className="i-label">Status</div>
                    <Dropdown
                        placeholder='Select Status'
                        items={['Active', 'Inactive']}
                        value={props.selectedStatus}
                        onSelect={(val) => {
                            updateState({ selectedStatus: val })
                        }}
                        allowClear={true}
                        onClear={() => {
                            updateState({ selectedStatus: undefined })
                        }}
                    />
                </Col>

                <Col span={24}>
                    {props.selectedSourceWarehouse && props.selectedSourceWarehouse === props.selectedDestinationWarehouse ?
                        <div className="required">
                            Source and Destination warehouse cannot be same
                        </div>
                    :
                    ""    
                }
                </Col>
            </Row>
        </Drawer>
    )
};

export default StockTransferFiltersComp;
