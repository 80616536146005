import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const ProductList = (props) => {
    const { productData } = props;
    return (        
                <div className="table-container" style={{ height: "480px" }}>

                    <table id='customer-table'>
                        <thead>
                            <tr>
                                <th>Product Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(productData.customerSalesComplainItemRef || []).length ? (productData.customerSalesComplainItemRef || []).map((rowData, i) => {
                                return <tr key={i}>
                                    <td>{rowData.inventoryItemName}</td> 
                                </tr>
                            }) : 'No data'}

                        </tbody>
                    </table>
                </div>
    );
};

export default injectIntl(ProductList);
