import React, { Component } from "react";
import config from "../../../../../config/environmentConfig";
import { FormattedMessage } from "react-intl";
import { Input, Button } from "antd";
import { showToasterMessage } from "../../../../../utils";
import { ICONS } from "../../../../../static/constants";
import QRCode from 'qrcode.react';

export default class ShareRegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewUrl: `${config.UI_BASE_URL}register?relationshipId=${props.relationshipId}&formId=${props.formSettingData.registrationFormSettingId}`,

      // previewUrl: `https://${props.subdomainName}/app/register?relationshipId=${props.relationshipId}&formId=${props.formSettingData.registrationFormSettingId}`
    };
  }

  copyCodeToClipboard = () => {
    const el = this.textArea;
    el.select();
    document.execCommand("copy");
    showToasterMessage({
      messageType: "success",
      description: this.props.intl.formatMessage({
        id: "copyToClipboard.text",
        defaultMessage: "",
      }),
    });
  };

  render() {
    return (
      <div>
        <div className="mb10" style={{textAlign:'center', fontSize: '14px', fontWeight: 700}}>
          {/* <FormattedMessage id="shareRegistrationForm.link.description" /> */}
          Scan QR code to open form
        </div>
        <div className="mb10" style={{textAlign:'center'}}>
          <QRCode value={this.state.previewUrl} renderAs="canvas" />
        </div>
        <div className="mb10" style={{textAlign:'center', fontSize: '14px'}}>
          {/* <FormattedMessage id="shareRegistrationForm.link.description" /> */}
          <b>Or open below link</b>
        </div>
        <div className="mb20">
          <Input
            style={{ width: "100%" }}
            addonAfter={
              // <Button
              //   title="Copy Link"
              //   type="default"
              //   // style={{ paddingLeft: "5px", paddingRight: "5px" }}
              //   onClick={() => this.copyCodeToClipboard()}
              // >
              // </Button>
                <i className={ICONS.COPY_P} onClick={() => this.copyCodeToClipboard()}/>
            }
            ref={(textarea) => (this.textArea = textarea)}
            value={this.state.previewUrl}
          />
        </div>

        <div>
          <b>Note </b>: <span className="light-txt"><FormattedMessage id="shareRegistrationForm.link.description" /></span>
        </div>

        <div
          className="align-footer"
          style={{ borderTop: "1px solid #e5e5e5", textAlign: "center" }}
        >
          <Button
            className="mt10"
            type="primary"
            onClick={() => {
              //window.location = this.state.previewUrl;
              // window.open(this.state.previewUrl, '_blank');
              Object.assign(document.createElement("a"), {
                target: "_blank",
                href: this.state.previewUrl,
              }).click();
            }}
          >
            <FormattedMessage id="preview.text" />
          </Button>
        </div>
      </div>
    );
  }
}
