import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DesignationDrawer from '../../../drawer/company/EmployeeMeta/Designation';
import DesignationComp from '../../../../components/company/EmployeeMeta/Designation';
import { createDesignation, getDesignation, getTotalDesignationCount, deleteDesignation,setStatus } from './action';
import { hideModal, showModal } from '../../../../actions/commonActions';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';


class DesignationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
    }
  };


  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};

    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,

    }
    fetchPaginationDataIfNeeded('getDesignation', 'designationList', this.props, payload, true);
    fetchDataIfNeeded('getTotalDesignationCount', 'designationListCount', this.props, payload);


  }



  render() {
    return <>

      <DesignationComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      {this.state.designationDrawer && <DesignationDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
    </>
  }

}

const mapStateToProps = (state) => {
  return {

    companyInfo: state.common.companyInfo,
    isLoading: state.common.isLoading,
    listLoading: state.common.listLoading,
    drawerLoading:state.common.drawerLoading,
    designationList: state.employee.designationList,
    designationListCount: state.employee.designationListCount,
    userInfo: state.common.userInfo,



  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  createDesignation,
  getDesignation,
  getTotalDesignationCount,
  deleteDesignation,
  setStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DesignationContainer);
