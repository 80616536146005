import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { Skeleton, Menu, Pagination, Switch } from "antd";
import {
  capitalizeFirstLetter,
  getMomentLoclDateForUIReadOnly,
  sortTable,
  fetchPaginationDataIfNeeded,
  fixedDecimalAndFormateNumber,
} from "../../../utils";
import { ICONS, CUSTOMER_LISTING_PAGE } from "../../../static/constants";
import CustomerProfile from "../../../containers/modal/modalBody/customer/Profile";
import DropdownAction from "antd/lib/dropdown";
import PageBreadcrumb from "../../PageBreadcrumb";
import Tile from "../../tiles/tilesIndex";
import img1 from "../../../assets/images/Usernewstu.png";
import img2 from "../../../assets/images/Userstu.png";
import img3 from "../../../assets/images/Userdue.png";
import img4 from "../../../assets/images/Userpaid.png";
import OpenOrder from "../../../containers/modal/modalBody/customer/OpenOrders";
//import LedgerList from '../../../containers/modal/modalBody/common/ledger';
import TotalOrders from "../../../containers/modal/modalBody/customer/TotalOrders";
import PaidInvoices from "../../../containers/modal/modalBody/customer/PaidInvoices";
import UnpaidInvoices from "../../../containers/modal/modalBody/customer/UnpaidInvoices";

const CustomerListComp = (props) => {
  const {
    customers,
    customerCount,
    pageNumber,
    dir = 0,
    updateState,
    companyInfo,
    customerOpenOrderCount,
    customerOpenSOCount,
    customerUnpaidBalance,
    customerSalesRevenue,
  } = props;
  customers[pageNumber].sort((a, b) =>
    a.dateCreated < b.dateCreated ? 1 : -1
  );
  const createCustomer = (customerData) => {
    props.updateHeaderState({
      customer: {
        ...props.customer,
        customerDrawerVisible: true,
        isUpdate: Object.values(customerData || {}).length ? true : "",
        customerData: customerData,
      },
    });
  };

  const getAddressInfoFragment = (obj) => {
    return (
      <>
        {/* {
                obj.streetAddress1 ? <span className='billing-address'>{obj.streetAddress1}</span>: ''
            }
            {
                obj.streetAddress2 ? <span className='billing-address'> {obj.streetAddress2}</span>: ''
            } */}
        {obj.cityName ? (
          <div className="billing-address">
            {`${obj.cityName || ""}${
              obj.stateName ? ", " + capitalizeFirstLetter(obj.stateName) : ""
            }`}
          </div>
        ) : (
          ""
        )}
        {obj.countryName ? (
          <div
            className="billing-address"
            style={{ textTransform: "uppercase" }}
          >
            {`${capitalizeFirstLetter(obj.countryName) || ""}`}
          </div>
        ) : (
          ""
        )}
        {/* {
                additionInfo.workPhone
                    ? <div className='billing-address'>
                        <FormattedMessage id='phone' defaultMessage='Ph' /> - {additionInfo.workPhone}
                    </div> :
                    ''
            }
            {
                additionInfo.email
                    ? <div className='billing-address'>
                        <FormattedMessage id='email' defaultMessage='Email' /> - {additionInfo.email}
                    </div> :
                    ''
            } */}
      </>
    );
  };

  const openCustomerProfile = (rowData, props) => {
    const modalData = {
      title: (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Customer profile</div>
        </div>
      ),
      modalBody: <CustomerProfile {...props} customerId={rowData.customerId} />,
      alertCancel: true, keyboard:false,
      onCancel: () => {
        props.popModalFromStack();
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          userId: (props.userInfo || {}).userId,
          pageNumber: 1,
          pageSize: 25,
        };
        props.fetchCustomers(payload);
        props.getCustomerCount(payload);
        return;
      },
      hideFooter: true,
      hideTitle: true,
      width: "100%",
      height: "100%",
      maskClosable: true,
      keyboard: false,
      wrapClassName: "new-transaction-wrapper",
    };
    props.pushModalToStack(modalData);
  };

  const actionMenu = (rowData) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="1" value="edit" onClick={() => createCustomer(rowData)}>
          <i className={ICONS.EDIT} /> Edit
        </Menu.Item>

        <Menu.Item
          key="2"
          onClick={() => {
            const modalBody = (
              <FormattedMessage
                id="commons.delete.popup.confimation.msg"
                defaultMessage=""
              />
            );
            const modalData = {
              modalBody,
              handleSubmit: () => {
                props.deleteCustomers(rowData);
              },
            };
            props.showModal(modalData);
          }}
        >
          <i className={ICONS.DELETE} /> Delete
        </Menu.Item>
      </Menu>
    );
  };

  const sortColumn = (e) => {
    sortTable(
      document.getElementById("customer-table"),
      e.target.cellIndex,
      1 - dir
    );
    updateState({ dir: 1 - dir });
  };

  const breadCrumbList = [
    {
      name: props.isCrmView ? (
        <FormattedMessage id="sidebar.menuItem.crm" defaultMessage="" />
      ) : (
        "Sales"
      ),
    },
    {
      name: (
        <FormattedMessage
          id="sidebar.menuItem.customerProfile"
          defaultMessage=""
        />
      ),
    },
  ];
  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.fetchCustomers(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded(
      "fetchCustomers",
      "customers",
      props,
      payload,
      true
    );
  };

  const getPrimaryContact = (rowData) => {
    let primaryContact =
      (rowData.boContactList || []).find((obj) => obj.isPrimaryContact) || {};
    return (
      <>
        <div>
          <i className={`${ICONS.CALL} mr5`} />{" "}
          {primaryContact.cellPhone
            ? `+${primaryContact.countryCallingCode} ${primaryContact.cellPhone}`
            : "-"}
        </div>
        <div>
          <i className={`${ICONS.MAIL} mr5`} />{" "}
          {primaryContact.emailAddress || "-"}
        </div>
      </>
    );
  };

  const tilesData = [
    {
      footer: (
        <FormattedMessage
          id="customer.listing.tiles.tatalOrders.label"
          defaultMessage=""
        />
      ),
      count: customerOpenOrderCount,
      type: CUSTOMER_LISTING_PAGE.TOTAL_ORDERS,
      // icon: 'fa fa-bullseye',
      antIcon: <img src={img1} alt="" />,
      // antIcon: <BookTwoTone />,
      color: "tile2",
    },
    {
      footer: (
        <FormattedMessage
          id="customer.listing.tiles.openSalesOrder.label"
          defaultMessage=""
        />
      ),
      count: customerOpenSOCount,
      // icon: 'fa fa-file-text-o',
      antIcon: <img src={img2} alt="" />,
      // antIcon: <ContainerTwoTone />,
      type: CUSTOMER_LISTING_PAGE.OPEN_SO,
      color: "tile1",
    },
    {
      footer: (
        <FormattedMessage
          id="customer.listing.tiles.unpaidBalance.label"
          defaultMessage=""
        />
      ),
      count: customerUnpaidBalance,
      // icon: companyInfo.currencyIcon,
      // antIcon: <EuroCircleTwoTone />,
      antIcon: <img src={img3} alt="" />,
      currencyIcon: companyInfo.currencyIcon,
      type: CUSTOMER_LISTING_PAGE.UNPAID_BALANCE,
      color: "tile4",
    },
    {
      footer: (
        <FormattedMessage
          id="customer.listing.tiles.salesRevenue.label"
          defaultMessage=""
        />
      ),
      count: customerSalesRevenue,
      // icon: companyInfo.currencyIcon,
      currencyIcon: companyInfo.currencyIcon,
      antIcon: <img src={img4} alt="" />,
      // antIcon: <DollarCircleTwoTone />,
      type: CUSTOMER_LISTING_PAGE.SALES_REVENUE,
      color: "tile3",
    },
  ];

  const openModalOfSelectedTile = (data) => {
    switch (data.type) {
      case CUSTOMER_LISTING_PAGE.TOTAL_ORDERS:
        props.showModal({
          title: <div className="ant-modal-title">Total Orders</div>,
          modalBody: <TotalOrders {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      case CUSTOMER_LISTING_PAGE.OPEN_SO:
        props.showModal({
          title: <div className="ant-modal-title">Open Sales Orders</div>,
          modalBody: <OpenOrder {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      case CUSTOMER_LISTING_PAGE.UNPAID_BALANCE:
        props.showModal({
          title: <div className="ant-modal-title">Unpaid Balance</div>,
          modalBody: <UnpaidInvoices {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      case CUSTOMER_LISTING_PAGE.SALES_REVENUE:
        props.showModal({
          title: <div className="ant-modal-title">Sales Revenue</div>,
          modalBody: <PaidInvoices {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="sidebar.menuItem.customerProfile"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
          <button
            className="create-action-btn"
            onClick={() => {
              createCustomer();
            }}
          >
            <i className={ICONS.ADD} /> Create
          </button>
        </div>
      </div>
      {!props.isCrmView ? (
        <Tile
          tilesData={tilesData}
          {...props}
          getSelectedTile={openModalOfSelectedTile}
          className="p10"
        />
      ) : (
        ""
      )}

      <div
        className="view-container"
        style={{ height: !props.isCrmView ? "calc(100vh - 240px)" : "" }}
      >
        <Skeleton loading={props.isLoading}>
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">Customers</div>
              {customerCount ? (
                <>
                  <div className="vertical-sep" />
                  <div>{customerCount}</div>
                </>
              ) : (
                ""
              )}
            </div>
            {/* <div className="right-actions">
                            <button style={{backgroundColor: "#1cb961",color:"#ffffff",fontSize:"14px"}}
                                onClick={()=>{
                                    createCustomer();
                                }}
                            >
                                <i className={`${ICONS.ADD} mr5`} /> <FormattedMessage id="add" defaultMessage="" />
                            </button>
                        </div> */}
          </div>
          <div className="table-container">
            <table id="customer-table">
              <thead>
                <tr>
                  <th onClick={sortColumn}>
                    Type <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn}>
                    Customer Name <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn}>
                    Company <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn}>
                    Contact <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn}>
                    Location <i className={ICONS.SORT} />
                  </th>
                  {/* <th onClick={sortColumn}><FormattedMessage id='supplier.listing.header.unpaidInvoices' defaultMessage=''  /><i className={ICONS.SORT} /></th>
                                    <th onClick={sortColumn}><FormattedMessage id='supplier.listing.header.paidInvoices' defaultMessage='' /><i className={ICONS.SORT} /></th> */}

                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.listing.header.openOrder"
                      defaultMessage=""
                    />
                  </th>
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.listing.header.unpaidInvoices"
                      defaultMessage=""
                    />
                  </th>
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.listing.header.paidInvoices"
                      defaultMessage=""
                    />
                  </th>

                  <th onClick={sortColumn}>
                    Source <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn}>
                    Date Created <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn}>
                    Status <i className={ICONS.SORT} />
                  </th>
                  <th width="6%">Action</th>
                </tr>
              </thead>

              <tbody>
                {customers[pageNumber || 1] && customers[pageNumber || 1].length
                  ? customers[pageNumber || 1]
                      .sort((a, b) => (a.dateCreated < b.dateCreated ? 1 : -1))
                      .map((rowData, i) => {
                        return (
                          <tr key={`cus` + i}>
                            <td>
                              {capitalizeFirstLetter(
                                rowData.customerType || ""
                              )}
                            </td>
                            <td>
                              <div
                                className="link"
                                onClick={() => {
                                  openCustomerProfile(rowData, props);
                                }}
                              >
                                {rowData.customerType === "individual" ? (
                                  <>
                                    <div>{rowData.companyName || "-"}</div>
                                    <div className="light-txt">
                                      ID - {rowData.customerId || "-"}
                                    </div>
                                  </>
                                ) : (
                                  <div className="empty-data"></div>
                                )}
                              </div>
                            </td>
                            <td>
                              <div
                                className="link"
                                onClick={() => {
                                  openCustomerProfile(rowData, props);
                                }}
                              >
                                {rowData.customerType === "business" ? (
                                  <>
                                    <div>{rowData.companyName || "-"}</div>
                                    <div className="light-txt">
                                      ID - {rowData.customerId || "-"}
                                    </div>
                                  </>
                                ) : (
                                  "-"
                                  
                                )}
                              </div>
                            </td>
                            <td>{getPrimaryContact(rowData)}</td>
                            <td>
                              {(rowData.boLocationList || []).length ? (
                                <div>
                                  {getAddressInfoFragment(
                                    (rowData.boLocationList || []).find(
                                      (obj) => obj.isDefault
                                    )
                                  )}
                                </div>
                              ) : (
                                <div style={{backgroundColor:"red"}}></div>

                              )}
                            </td>

                            {/* <td>
                                           {rowData.unpaidInvoicesCount}
                                            </td> 
                                        
                                        <td>
                                        {rowData.paidInvoicesTotalAmount}
                                            </td>  */}
                            <td>
                              {
                                <div>
                                  {rowData.openedOrdersCount &&
                                  rowData.openedOrdersCount === 1
                                    ? "Order "
                                    : rowData.openedOrdersCount === 0
                                    ? ""
                                    : "Orders "}
                                  <span
                                    onClick={() => {
                                      props.showModal({
                                        title: (
                                          <Fragment>
                                            <div className="ant-modal-title">
                                              Open Orders
                                            </div>
                                            <div className="ant-modal-subtitle extra-sm">
                                              {rowData.companyName}
                                            </div>
                                          </Fragment>
                                        ),
                                        modalBody: (
                                          <OpenOrder
                                            {...props}
                                            customerData={rowData}
                                          />
                                        ),
                                        width: 1000,
                                        hideFooter: true,
                                        wrapClassName: "tiles-modal",
                                      });
                                    }}
                                    className={
                                      rowData.openedOrdersCount
                                        ? "table-link-text"
                                        : ""
                                    }
                                  >
                                    {rowData.openedOrdersCount}
                                  </span>
                                  {/* <p> {rowData.openedOrdersTotalAmount ? (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ") : ''}{(rowData.openedOrdersTotalAmount || '') && rowData.openedOrdersTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p> */}
                                  <p>
                                    {" "}
                                    {rowData.openedOrdersTotalAmount ? (
                                      props.companyInfo.currencyIcon ? (
                                        <i
                                          className={
                                            props.companyInfo.currencyIcon
                                          }
                                        ></i>
                                      ) : (
                                        props.companyInfo.currencyCode + " "
                                      )
                                    ) : (
                                      ""
                                    )}
                                    {(rowData.openedOrdersTotalAmount || "") &&
                                      fixedDecimalAndFormateNumber(
                                        rowData.openedOrdersTotalAmount
                                      )}
                                  </p>
                                </div>
                              }
                            </td>
                            <td>
                              <div>
                                {rowData.unpaidInvoicesCount &&
                                rowData.unpaidInvoicesCount === 1
                                  ? "Invoice "
                                  : rowData.unpaidInvoicesCount === 0
                                  ? ""
                                  : "Invoices "}
                                <span
                                  onClick={() => {
                                    props.showModal({
                                      title: (
                                        <Fragment>
                                          <div className="ant-modal-title">
                                            Unpaid Invoices
                                          </div>
                                          <div className="ant-modal-subtitle extra-sm">
                                            {rowData.companyName}
                                          </div>
                                        </Fragment>
                                      ),
                                      modalBody: (
                                        <UnpaidInvoices
                                          customerData={rowData}
                                          {...props}
                                        />
                                      ),
                                      width: 1000,
                                      hideFooter: true,
                                      wrapClassName: "tiles-modal",
                                    });
                                  }}
                                  className={
                                    rowData.unpaidInvoicesCount
                                      ? "table-link-text"
                                      : ""
                                  }
                                >
                                  {" "}
                                  {rowData.unpaidInvoicesCount}
                                </span>
                                {/* <p> {rowData.unpaidInvoicesTotalAmount ? (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ") : ''}{(rowData.unpaidInvoicesTotalAmount || '') && rowData.unpaidInvoicesTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p> */}
                                <p>
                                  {" "}
                                  {rowData.unpaidInvoicesTotalAmount ? (
                                    props.companyInfo.currencyIcon ? (
                                      <i
                                        className={
                                          props.companyInfo.currencyIcon
                                        }
                                      ></i>
                                    ) : (
                                      props.companyInfo.currencyCode + " "
                                    )
                                  ) : (
                                    ""
                                  )}
                                  {(rowData.unpaidInvoicesTotalAmount || "") &&
                                    fixedDecimalAndFormateNumber(
                                      rowData.unpaidInvoicesTotalAmount
                                    )}
                                </p>
                              </div>
                            </td>
                            <td>
                              <div>
                                {rowData.paidInvoicesCount &&
                                rowData.paidInvoicesCount === 1
                                  ? "Invoice "
                                  : rowData.paidInvoicesCount === 0
                                  ? ""
                                  : "Invoices "}
                                <span
                                  onClick={() => {
                                    props.showModal({
                                      title: (
                                        <Fragment>
                                          <div className="ant-modal-title">
                                            Paid Invoices
                                          </div>
                                          <div className="ant-modal-subtitle extra-sm">
                                            {rowData.companyName}
                                          </div>
                                        </Fragment>
                                      ),
                                      modalBody: (
                                        <PaidInvoices
                                          customerData={rowData}
                                          {...props}
                                        />
                                      ),
                                      width: 900,
                                      hideFooter: true,
                                      wrapClassName: "tiles-modal paid-invoice",
                                    });
                                  }}
                                  className={
                                    rowData.paidInvoicesCount
                                      ? "table-link-text"
                                      : ""
                                  }
                                >
                                  {" "}
                                  {rowData.paidInvoicesCount}
                                </span>
                                {/* <p> {rowData.paidInvoicesTotalAmount ? (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ") : ''}{(rowData.paidInvoicesTotalAmount || '') && rowData.paidInvoicesTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p> */}
                                <p>
                                  {" "}
                                  {rowData.paidInvoicesTotalAmount ? (
                                    props.companyInfo.currencyIcon ? (
                                      <i
                                        className={
                                          props.companyInfo.currencyIcon
                                        }
                                      ></i>
                                    ) : (
                                      props.companyInfo.currencyCode + " "
                                    )
                                  ) : (
                                    ""
                                  )}
                                  {(rowData.paidInvoicesTotalAmount || "") &&
                                    fixedDecimalAndFormateNumber(
                                      rowData.paidInvoicesTotalAmount
                                    )}
                                </p>
                              </div>
                            </td>
                            <td>
                              {(props.sourcesList || []).find(
                                (obj) =>
                                  obj.crmLeadSourceId === rowData.sourceId
                              )?.leadSourceName || "-"}
                            </td>
                            <td>
                              {rowData.dateCreated
                                ? getMomentLoclDateForUIReadOnly({
                                    date: rowData.dateCreated,
                                  })
                                : "-"}
                            </td>

                            {/* <td>{rowData.status || "-"}</td> */}
                            <td>
                              <div className="status-toggle">
                                <Switch
                                  checked={rowData.isActive === 1 || true}
                                  // onClick={(checked, e) => {

                                  //   props.createLeadSource(
                                  //     { ...rowData, isActive: checked ? 1 : 0 },
                                  //     props
                                  //   );
                                  // }}
                                />
                              </div>
                            </td>
                            <td style={{ paddingLeft: "50px" }}>
                              <DropdownAction
                                overlay={actionMenu(rowData)}
                                trigger={["click"]}
                              >
                                <i className={ICONS.MORE} />
                              </DropdownAction>
                            </td>
                          </tr>
                        );
                      })
                  : ""}
              </tbody>
            </table>
          </div>
        </Skeleton>
        <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={props.customerCount}
              // showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(1, pageSize, props);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerListComp;
