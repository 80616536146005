import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col } from 'antd';

//import './customerDrawer.scss';
import { CustomButton } from '../../../general/CustomButton';
import { TextBox } from '../../../general/TextBox';
// import { Dropdown } from '../../../general/Dropdown';
import { ErrorMsg } from '../../../general/ErrorMessage';
function SchoolDrawer(props) {

  // const { type, fee, frequency, stmtFreq, duration, period, isLifetime } = props;

  const closeDrawer = () => {
    props.updateHeaderState({
      school: {
        addGradeDrawerVisible: false
      }
    })
  }

  const handleSubmit = (props) => {
    if (props.gradeCode && props.gradeName) {
      const payload = {
        gradeName: props.gradeName,
        gradeCode: props.gradeCode,
        relationshipId: (props.companyInfo || {}).relationshipId,
        createdByEmpId: (props.userInfo || {}).userId,
      };

      props.pushModalToStack({
        modalBody: <FormattedMessage id='save.confirmation' defaultMessage='' /> ,
        handleSubmit: () => {
          props.saveGrade(payload, props);
        }
      });

    } else {
      props.updateState({
        submittedOnce: true,
      })
    }
  }

  return (
    <Fragment>
      <Drawer
        title={
          <div className="drawer-title">
            <FormattedMessage id='schoolManagement.school.new' defaultMessage=' ' />
          </div>
        }
        width={720}
        keyboard={false}
        zIndex={1}
        destroyOnClose={true}
        onClose={closeDrawer}
        maskClosable={false}
        className="custom-supplier-drawer"
        visible={props.addGradeDrawerVisible}
        afterVisibleChange={
          (visible) => {
            if (visible) {
              //props.initCalls();
            }
          }
        }
      >
        <Form layout="vertical" hideRequiredMark>


          <Row gutter={16}>
            <Col span={3}>
            </Col>
            <Col className="text-right" span={6}>
              <span className="">
                <FormattedMessage id='schoolManagement.grade.name' defaultMessage='' />
              </span><span className="required">*</span>
            </Col>
            <Col span={12}>
            <TextBox
               // className={(props.submittedOnce && (props.duration <= 0 || !props.duration)) ? 'input-text-error' : ''}
                type='text'
                value={props.gradeName || null}
                onChange={(e) => {
                  props.updateState({
                    gradeName: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'schoolManagement.grade.name',
                    defaultMessage: ''
                  })
                }
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || !!props.gradeName }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
          </Row>

          <br />


          <Row gutter={16}>
            <Col span={3}>
            </Col>
            <Col className="text-right" span={6}>
              <label>
                <FormattedMessage id='schoolManagement.grade.code' defaultMessage='' /></label>
              <span className="required">*</span>
            </Col>
            <Col span={12}>
            <TextBox
               // className={(props.submittedOnce && (props.duration <= 0 || !props.duration)) ? 'input-text-error' : ''}
                type='text'
                value={props.gradeCode || null}
                onChange={(e) => {
                  props.updateState({
                    gradeCode: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'schoolManagement.grade.code',
                    defaultMessage: ''
                  })
                }
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || !!props.gradeCode }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>

          </Row>

          <br />
         
        </Form>

        <div className='form-footer'>
          <CustomButton
            intlId='confirmation.cancel'
            defaultMessage=''
            type='default'
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId='confirmation.submit'
            defaultMessage=''
            htmlType='submit'
            onClick={() => handleSubmit(props)}
            key={1}
          />
        </div>
      </Drawer>
    </Fragment>
  );
}

export default injectIntl(SchoolDrawer);
