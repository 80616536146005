import React, { Fragment } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CONSTANTS } from '../../static/constants';
import { getCurrencySymbol, getMomentDateForUIReadOnly, getUserDefinedName, numberToWordsWithDecimals, showToasterMessage } from '../../utils';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import ReactHtmlParser from 'react-html-parser';
import { AWSFileTable } from '../general/AWSFileTable';
import ProcessPayment from '../../containers/orderPreview/ProcessPayment';
import ProcessCardConnectPayment from '../../containers/orderPreview/ProcessCardConnectPayment';


const fixedDecimalNumber = (number) => {
    return (Number(number || 0)).toFixed(CONSTANTS.DEFAULT_DECIMAL_NUMBER_LENGTH);
}


function OrderPreview(props) {
    const { soDetail, companyInfo = {} } = props;
    const { customerSalesOrderDetailsList } = soDetail;

    const relationshipAddress = find(soDetail.boLocationSOList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
    const billingAddress = find(soDetail.boLocationSOList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {};
    const shippingAddress = find(soDetail.boLocationSOList, { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};
    let isPlaceOfSupplySameAsRelationship = false
    isPlaceOfSupplySameAsRelationship = (soDetail.placeOfSupply && relationshipAddress.stateName === soDetail.placeOfSupply) || false;
    let itemLevelTaxes = [];
    if (customerSalesOrderDetailsList) {
        itemLevelTaxes = calculateTaxRateList(customerSalesOrderDetailsList, isPlaceOfSupplySameAsRelationship);
    }
    let isColumnEmpty = {
        product: true,
        service: true,
        variant: true,
        description: true,
        qty: true,
        uomName: true,
        rate: true,
        hsn: true,
        discount: true,
        tax: true,
        specialDiscount: true,
        documentDetails: true,
        comment: true,
        studentClassName: true,
    };
    if (soDetail) {
        isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, soDetail, props);
    }
    const getAddressInfoFragment = (obj, additionInfo) => {

        const fragmentObj = <Fragment>
            <div className="billing-address first-caps">
                {obj.streetAddress1 || obj.streetAddress2 ? (

                    <div>
                        {obj.streetAddress1} {obj.streetAddress2}{","}
                    </div>

                ) : ""}
                {obj.cityName || obj.stateName || obj.zipCode ? (

                    <div>
                        {obj.cityName} {obj.stateName} {obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
                    </div>

                ) : ""}
                {obj.countryName ? (
                    <div>{obj.countryName}</div>
                ) : ""}
            </div>

            {
                additionInfo.workPhone
                    ? <div className='billing-address'>
                        <FormattedMessage id='phone' defaultMessage='' /> - {additionInfo.workPhone}
                    </div> :
                    ''
            }
            {
                additionInfo.email
                    ? <div className='billing-address'>
                        <FormattedMessage id='email' defaultMessage='' /> - {additionInfo.email}
                    </div> :
                    ''
            }


        </Fragment>

        return fragmentObj;
    }

    const getTaxIdentificationsFragment = (taxIdentifications) => {
        if (!taxIdentifications) return <Fragment />;
        let taxIdentificationsList = []
        if (taxIdentifications) {
            taxIdentificationsList = JSON.parse(taxIdentifications);
        }
        const fragmentObj = <Fragment>
            {taxIdentificationsList.map((obj, i) => {
                return <div className='billing-address' key={i}>
                    {obj.taxIdentificationType || ''} - {obj.taxIdentificationNumber}
                </div>
            })}
        </Fragment>

        return fragmentObj;
    }
    const getContactNameFragment = (additionInfo) => {
        if (!additionInfo) return <Fragment />;
        let contactName = "";
        if (additionInfo.salutationName) contactName += additionInfo.salutationName + ' ';
        if (additionInfo.firstName) contactName += additionInfo.firstName;
        if (additionInfo.middleName) contactName += ' ' + additionInfo.middleName;
        if (additionInfo.lastName) contactName += ' ' + additionInfo.lastName;
        const fragmentObj = <Fragment>
            {
                (contactName && contactName.length)
                    ? <div className='title'>
                        {contactName}
                    </div> :
                    ''
            }
            <div>{additionInfo?.EmailAddress || ''}</div>
            <div>{additionInfo?.workPhone || additionInfo?.cellPhone || ''}</div>
        </Fragment>
        return fragmentObj;
    }

    const proccessPayment = () => {
        if (soDetail && soDetail.additionalInfoList && soDetail.additionalInfoList[0] && soDetail.additionalInfoList[0].paymentGatewayId || 'CardPointe') {
            switch (soDetail.additionalInfoList[0].paymentGatewayName || 'CardPointe') {
                case 'paypal': {
                    props.getPayOnlineUrl(soDetail);
                    break;
                }
                case 'stripe': {
                    break;
                }
                case 'Authorize.net': {
                    props.pushModalToStack({

                        modalBody: <ProcessPayment {...props} />,
                        width: '50%',
                        hideFooter: true,
                        wrapClassName: 'paylist'
                    })
                    break;
                }
                case 'CardPointe': {
                    props.pushModalToStack({
                        hideTitle: true,
                        modalBody: <ProcessCardConnectPayment {...props} />,
                        width: '65%',
                        hideFooter: true,
                        keyboard: false,
                        wrapClassName: 'pop-bgsm',
                        onCancel: () => {
                            props.showModal({
                                modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage="" />,
                                footer: { submit: { intlId: "button.close.label" } },
                                handleSubmit: () => {
                                    props.popModalFromStack();
                                }
                            })
                        }
                    })

                    break;
                }
                default: {
                    showToasterMessage({ description: props.intl.formatMessage({ id: 'onlinePayment.not.available.message' }) });
                    break;
                }
            }
        } else {
            showToasterMessage({ description: props.intl.formatMessage({ id: 'onlinePayment.not.available.message' }) });
        }
    }

    return (
        <div className={"custom-modal show"} style={{ paddingBottom: '100px' }}>
            <div className="modal-dialog">
                <div className="modal-content full-width  vertical-view-data">
                    {!props.status ?
                        <div className="button-group-action header-control pay-btn">
                            <div>
                                <Row>
                                    <Col span={8}>
                                        {soDetail.totalPaymentReceived >= soDetail.salesOrderTotalAmount ?
                                            <Button type="" style={{ color: '#fff', backgroundColor: '#1890ff', borderColor: '#1890ff' }}>
                                                <span> <FormattedMessage id='paid' /></span>
                                            </Button> : ''}
                                        {soDetail.status === 'canceled' ?
                                            <Button type="" style={{ color: '#fff', backgroundColor: '#6a7fb0', borderColor: '#6a7fb0' }}>
                                                <span><FormattedMessage id='tabs.Invoice' /> <FormattedMessage id='void' /> </span>
                                            </Button> : ''}
                                        {(soDetail.totalPaymentReceived < soDetail.salesOrderTotalAmount && soDetail.status !== 'cancelled') ? <Button type="primary" onClick={() => { proccessPayment() }}>
                                            <FormattedMessage id='pay.text' /> <span className='pl5'>- {soDetail.isMultiCurrency ?
                                                <span> {soDetail.foreignCurrencyIcon ? <i className={soDetail.foreignCurrencyIcon}></i> : ""} </span>
                                                : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : ""}</span>
                                            }{fixedDecimalNumber(soDetail.salesOrderTotalAmount-soDetail.totalPaymentReceived )}
                                            </span>
                                        </Button> : ''}
                                    </Col>
                                </Row>
                            </div>
                        </div> : ''}
                        {(props.status === 'success' || props.status === 'hold') ? <b><div className="mt10" style={{ fontSize: '20px', textAlign: 'center' }}>Your Receipt</div></b> : ''}
                        <br/>
                        <br/>
                    {(props.status === 'success' || props.status === 'hold')  && <div>
                        <b><div style={{ fontSize: '16px', textAlign: 'center' }}>{props.txStatusMessage || props.intl.formatMessage({ id: 'payOnline.success' })} </div></b>

                        <div className='mt30' style={{display: 'flex', justifyContent: 'center', }}>
                            <Row gutter={[16, 8]} style={{width: '50%',}}>
                                <Col span={10} offset={6}>
                                            <FormattedMessage id='modal.common.priceList.name' defaultMessage='' />
                                </Col>
                                <Col span={8}>
                                {soDetail.customerName}
                                </Col>

                                <Col span={10} offset={6}>
                                    <FormattedMessage id='modal.txDetails.salesOrder.soNumber' defaultMessage='' />
                                </Col>
                                <Col span={8}>
                                    {soDetail.soNumber}
                                </Col>

                                <Col span={10} offset={6}>
                                    <FormattedMessage id='modal.txDetails.salesOrder.soDate' defaultMessage='' />
                                </Col>
                                <Col span={8}>
                                    { soDetail.salesOrderDate && getMomentDateForUIReadOnly({date: soDetail.salesOrderDate, format: CONSTANTS.DISPLAY_DATE_FORMAT})}
                                </Col>

                                <Col span={10} offset={6}>
                                    <FormattedMessage id='modal.txDetails.payment.soAmount' defaultMessage='' />
                                </Col>
                                <Col span={8}>
                                    {soDetail.isMultiCurrency ?
                                        <span> {soDetail.foreignCurrencyIcon ? <i className={soDetail.foreignCurrencyIcon}></i> : soDetail.foreignCurrency+ " "}</span>
                                        : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</span>
                                    } {fixedDecimalNumber(soDetail.salesOrderTotalAmount -(soDetail.totalPaymentReceived || 0))}
                                </Col>

                                <Col span={10} offset={6}>
                                    <FormattedMessage id='paymentMethod.text' defaultMessage='' />
                                </Col>
                                <Col span={8}>
                                    <FormattedMessage id='creditCard.text' />
                                </Col>

                                <Col span={10} offset={6}>
                                    <FormattedMessage id='modal.txDetails.payment.paymentDate' defaultMessage='' />
                                </Col>
                                <Col span={8}>
                                { getMomentDateForUIReadOnly({date: new Date(), format: CONSTANTS.DISPLAY_DATE_FORMAT})}
                                </Col>

                                <Col span={10} offset={6}>
                                    <FormattedMessage id='expense.Transaction.number' defaultMessage='' />
                                </Col>
                                <Col span={8}>
                                    {props.txnId}
                                </Col>
                            </Row>
                        </div>
                        <b> <div style={{ fontSize: '14px', textAlign: 'center', marginTop: '20px' }}><FormattedMessage id='email.confirmation.sent.message' /> </div></b>
                    </div>
                    }
                    {props.status === 'failed' && <div style={{ fontSize: '16px', textAlign: 'center', color: 'red' }}><b><div>{props.intl.formatMessage({ id: 'payOnline.failed' })}</div><div>{props.txStatusMessage }</div> </b></div>}
                    {props.status === 'cancelled' && <div style={{ fontSize: '16px', textAlign: 'center', color: 'red' }}><b>{props.intl.formatMessage({ id: 'transaciton.canceled.message' })} </b></div>}
                    {!props.status ?
                    
                    <div id="so-readonly" className="inner-content">

                        <div className="doc-title">
                            {soDetail.documentName || 'Sales Order'}
                        </div>
                        <Row>
                            <Col span={16}>
                                <div className="read-only-content pt20">

                                    <div className="title lh-adjust underline-text pb5">
                                        <FormattedMessage id='modal.txDetails.common.billTo' defaultMessage='' />
                                    </div>
                                    <div>

                                        <div className='title'> {soDetail.customerName}</div>


                                        <div>
                                            {getAddressInfoFragment(billingAddress, (soDetail.additionalInfoList ? soDetail.additionalInfoList[0] || {} : {}))}
                                            {getTaxIdentificationsFragment(((soDetail.additionalInfoList || [])[0] || {}).taxIdentifications)}
                                        </div>
                                        <div>
                                            {(soDetail.additionalInfoList ? soDetail.additionalInfoList[0] || {} : {})?.customerPhone}
                                            <br />
                                            {(soDetail.additionalInfoList ? soDetail.additionalInfoList[0] || {} : {})?.customerEmail}
                                        </div>
                                    </div>

                                    <div className="">
                                        {getContactNameFragment((soDetail.additionalInfoList ? soDetail.additionalInfoList[0] || {} : {}))}

                                    </div>

                                </div>
                                {shippingAddress.streetAddress1 ?
                                    <div className="read-only-content pt10">
                                        <div className="title lh-adjust underline-text pb5">
                                            <FormattedMessage id='modal.txDetails.common.shipTo' defaultMessage='' />
                                        </div>
                                        <div className="title">
                                            {soDetail.customerName}
                                        </div>
                                        <div>
                                            {getAddressInfoFragment(shippingAddress, {})}
                                        </div>

                                    </div> : ''}
                            </Col>
                            <Col span={4}>

                                <div className="read-only-content pt20">
                                    <div className="pb5">
                                        <div className="title">
                                            <FormattedMessage id='modal.txDetails.salesOrder.soNumber' defaultMessage='' />
                                        </div>

                                        <div>
                                            {soDetail.soNumber}
                                        </div>
                                    </div>

                                    <div className="pb5">
                                        <div className="title">
                                            <FormattedMessage id='modal.txDetails.salesOrder.soDate' defaultMessage='' />
                                        </div>

                                        <div>
                                            {(soDetail.salesOrderDate && getMomentDateForUIReadOnly({ date: soDetail.salesOrderDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                        </div>
                                    </div>
                                    {!!soDetail.isMultiCurrency ?
                                        <div className="pb5">
                                            <div className="title">
                                                <FormattedMessage id='modal.txDetails.common.foreignCurrency' defaultMessage='' />
                                            </div>

                                            <div>
                                                {soDetail.foreignCurrency}
                                            </div>
                                        </div> : ""}

                                    {!!soDetail.isMultiCurrency ?
                                        <div className="pb5">
                                            <div className="title">
                                                <FormattedMessage id='modal.txDetails.common.exchangeRate' defaultMessage='' />
                                            </div>

                                            <div>
                                                {soDetail.exchangeRate}
                                            </div>
                                        </div> : ""}
                                    {soDetail.projectName ?
                                        <div className="pb5">
                                            <div className="title">
                                                <FormattedMessage id='modal.txDetails.common.project' defaultMessage='' />
                                            </div>
                                            <div>
                                                <abbr>{soDetail.projectName}</abbr>
                                            </div>
                                        </div> : ''}
                                    {soDetail.deliveryDate ?
                                        <div className="pb5">
                                            <div className="title">
                                                <FormattedMessage id='modal.txDetails.salesOrder.deliveryDate' defaultMessage='' />
                                            </div>
                                            <div>
                                                {(soDetail.deliveryDate && getMomentDateForUIReadOnly({ date: soDetail.deliveryDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                            </div>
                                        </div> : ""}
                                    {soDetail.userStatus ?
                                        <div className="pb5">
                                            <div className="title">
                                                <FormattedMessage id='modal.txDetails.salesOrder.userStatus' defaultMessage='' />
                                            </div>
                                            <div>
                                                <abbr>{soDetail.userStatus}</abbr>
                                            </div>
                                        </div> : ""}{soDetail.incotermName ?
                                            <div className="pb5">
                                                <div className="title">
                                                    <FormattedMessage id='package.packageDetail.incoterm' defaultMessage='' />
                                                </div>
                                                <div>
                                                    <abbr>{soDetail.incotermName}</abbr>
                                                </div>
                                            </div> : ""}




                                </div>
                            </Col>
                            <Col span={4}>

                                <div className="d-inline-flex w-100">
                                    <div className="read-only-content pt20 pl10">
                                        <div className="title lh-adjust pb5">
                                            {props.companyInfo.storeName}
                                        </div>
                                        <div>
                                            {getAddressInfoFragment(relationshipAddress, companyInfo)}
                                            <div>
                                                {getTaxIdentificationsFragment(((soDetail.additionalInfoList || [])[0] || {}).relationshipTaxIdentifications)}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="modal-table-container vertical-space">
                            <table>
                                <thead>
                                    <tr>
                                        {props.companyInfo.countryName === 'India' && <th><FormattedMessage id='modal.txDetails.common.placeOfSupply' defaultMessage='' /></th>}
                                        <th><FormattedMessage id='modal.txDetails.salesOrder.soSource' defaultMessage='' /></th>
                                        <th><FormattedMessage id='modal.txDetails.salesOrder.customerPO' defaultMessage='' /></th>
                                        <th><FormattedMessage id='modal.txDetails.common.refNumber' defaultMessage='' /></th>
                                        <th><FormattedMessage id='customerRfqNumber.text' /></th>
                                        <th><FormattedMessage id='customerSalesEnquiryNumber.text' /></th>
                                        <th><FormattedMessage id='common.salesPerson' defaultMessage='' /></th>
                                        <th><FormattedMessage id='modal.txDetails.common.priority' defaultMessage='' /></th>
                                        <th><FormattedMessage id='modal.txDetails.common.paymentTerm' defaultMessage='' /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {props.companyInfo.countryName === 'India' && <td>{soDetail.placeOfSupply || '-'}</td>}
                                        <td>{soDetail.soSourceName || '-'}</td>
                                        <td>{soDetail.customerPONumber || '-'}</td>
                                        <td>{soDetail.referenceNumber || '-'}</td>
                                        <td>{soDetail.customerRFQNumber || ''}</td>
                                        <td>{soDetail.customerInquiryNumber || '-'}</td>
                                        <td>{soDetail.soPersonFullName || ''}</td>
                                        <td>{soDetail.orderPriority || ''}</td>
                                        <td>{soDetail.paymentTermName || ''}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-table-container mb15">
                            <table className="transaction-details-table">
                                <thead>
                                    <tr>
                                        <th className="cell-size-50">#</th>
                                        {(!isColumnEmpty.product || !isColumnEmpty.description) && <th>
                                            {!isColumnEmpty.product && <span> <FormattedMessage id='modal.txDetails.common.product' defaultMessage='' /></span>}
                                            {(!isColumnEmpty.product && !isColumnEmpty.description) && <span> & </span>}
                                            {!isColumnEmpty.description && <span> <FormattedMessage id='modal.txDetails.common.description' defaultMessage='' /></span>}
                                        </th>}
                                        {/* {!isColumnEmpty.variant && <th>Variant</th>} */}
                                        {!isColumnEmpty.qty && <th className="text-center"><FormattedMessage id='modal.txDetails.common.qty' defaultMessage='' /></th>}
                                        {/* {!isColumnEmpty.uomName && <th>UOM</th>} */}
                                        {!isColumnEmpty.specialDiscount && <th className="text-center"><FormattedMessage id='modal.txDetails.common.basePrice' defaultMessage='' /></th>}
                                        {!isColumnEmpty.specialDiscount && <th className="text-right"><FormattedMessage id='modal.txDetails.common.specialDiscount' defaultMessage='' /></th>}
                                        {!isColumnEmpty.rate && <th className="text-center"><FormattedMessage id='modal.txDetails.common.rate' defaultMessage='' /></th>}
                                        {!isColumnEmpty.hsn && <th className="text-center"><FormattedMessage id='modal.txDetails.common.hsn' defaultMessage='' /></th>}
                                        {!isColumnEmpty.discount && <th className="text-right"><FormattedMessage id='modal.txDetails.common.discount' defaultMessage='' /></th>}
                                        {!isColumnEmpty.tax && <th className="text-right"><FormattedMessage id='modal.txDetails.common.tax' defaultMessage='' /></th>}
                                        {/* {!isColumnEmpty.comment && <th>Warehouse</th>} */}
                                        <th className="text-right">Taxable Amount</th>
                                        <th className="text-right"><FormattedMessage id='modal.txDetails.common.netAmount' defaultMessage='' /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(customerSalesOrderDetailsList || []).sort((a, b) => Number(a.sNo) > (b.sNo) ? 1 : -1)?.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className="cell-size-50">{i + 1}</td>
                                                {(!isColumnEmpty.product || !isColumnEmpty.description) && <td>
                                                    <strong>{getVariantFragment(item)}</strong>
                                                    {!isColumnEmpty.description && <div className="description-cell">
                                                        {getDescriptionFragment(soDetail, item, i + 1)}
                                                    </div>}
                                                </td>}
                                                {/* {!isColumnEmpty.variant && <td>{item.itemVariantName || '-'}</td>} */}
                                                {!isColumnEmpty.qty && <td className="text-center">{item.quantityToInvoice + (item.uomName ? ' ' + item.uomName : '')}</td>}
                                                {/* {!isColumnEmpty.uomName && <td>{item.uomName || '-'}</td>} */}
                                                {!isColumnEmpty.specialDiscount && <td className="text-center">{fixedDecimalNumber(item.basePrice || 0) || '-'}</td>}
                                                {!isColumnEmpty.specialDiscount && <td className="text-right">{getSpecialDiscount(item, 'amountPercent')}</td>}
                                                {!isColumnEmpty.rate && <td className="text-center">{fixedDecimalNumber(item.anItemSalePrice || 0) || '-'}</td>}
                                                {!isColumnEmpty.hsn && <td className="text-center">{item.hsnCode || '-'}</td>}
                                                {!isColumnEmpty.discount && <td className="text-right">{getTxTypeData(item, 'discount', 'amount')} {getTxTypeData(item, 'discount', 'amountPercent')}</td>}
                                                {!isColumnEmpty.tax && <td className="text-right">{getTotalTaxAmount(item, isPlaceOfSupplySameAsRelationship)} {getTotalTaxPercent(item, 'amountPercent', isPlaceOfSupplySameAsRelationship)}</td>}
                                                {/* {!isColumnEmpty.comment && <td>
                <div className="description-cell">
                    <pre>{ReactHtmlParser(item.comment || '-')}</pre>
                </div>
            </td>} */}
                                                <td className="text-right">{getNetAmount(item, isPlaceOfSupplySameAsRelationship, true) || "-"}</td>
                                                <td className="text-right">{getNetAmount(item, isPlaceOfSupplySameAsRelationship) || '-'}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <Col span={24} className="">
                                {soDetail.currencyCode ? (
                                    <span>
                                        {" "}
                                        {soDetail.currencyCode ? (
                                            soDetail.currencyCode
                                        ) : (
                                            soDetail.currencyCode
                                        )}{" "}
                                    </span>
                                ) : (
                                    <span>
                                        {" "}
                                        {props.companyInfo.currencyCode ? (
                                            <i className={props.companyInfo.currencyCode}></i>
                                        ) : (
                                            props.companyInfo.currencyCode + " "
                                        )}{" "}
                                    </span>
                                )}
                                {(soDetail.salesOrderTotalAmount &&
                                    numberToWordsWithDecimals(fixedDecimalNumber(soDetail.salesOrderTotalAmount), props.companyInfo.currencyCode === "INR" ? "Paise" : "Cent")) ||
                                    ""}
                            </Col>
                        </div>
                        <Row>
                            <Col span={9} className="read-only-content">
                                {soDetail.docDetailList && soDetail.docDetailList.length ? <Row>
                                    <Col span={24} className="title">
                                        <FormattedMessage id='modal.txDetails.common.attachments' defaultMessage='' />
                                    </Col>
                                    <Col span={12}>
                                        <AWSFileTable {...props} docDetailList={soDetail.docDetailList} fetchTxDetail={(data) => {
                                            props.fetchSODetail({
                                                customerId: soDetail.customerId,
                                                salesOrderMasterId: soDetail.salesOrderMasterId,
                                                soMasterId: soDetail.salesOrderMasterId,
                                                relationshipId: soDetail.relationshipId,
                                            });
                                        }} />

                                    </Col>
                                </Row> : null}


                                {soDetail.remarksInternal &&
                                    <Row>
                                        <Col span={24} className="title">
                                            <FormattedMessage id='modal.txDetails.common.internalRemarks' defaultMessage='' />
                                        </Col>
                                        <Col span={24}>
                                            {ReactHtmlParser(soDetail.remarksInternal)}
                                        </Col>
                                    </Row>}

                                {soDetail.remarksCustomer &&
                                    <Row className="pt5">
                                        <Col span={24} className="title">
                                            <FormattedMessage id='modal.txDetails.common.customerRemarks' defaultMessage='' />
                                        </Col>
                                        <Col span={24}>
                                            {ReactHtmlParser(soDetail.remarksCustomer)}
                                        </Col>
                                    </Row>}
                            </Col>

                            <Col offset={8} span={7}>
                                <div className="bill-summary mt5">
                                    <Row>
                                        <Col span={14}>
                                            <div className="right-txt">
                                                <FormattedMessage id='modal.txDetails.common.subTotal' defaultMessage='' />
                                            </div>
                                        </Col>

                                        <Col span={10} className="text-right ">
                                            {soDetail.isMultiCurrency ?
                                                <span> {soDetail.foreignCurrencyIcon ? <i className={soDetail.foreignCurrencyIcon}></i> : soDetail.foreignCurrency} </span>
                                                : <span> {props.companyInfo.currencyIcon ? getCurrencySymbol(props.companyInfo.currencyCode) : props.companyInfo.currencyCode + " "} </span>}
                                            {getSubTotalOrTaxOrDiscountAmount(soDetail, "SubTotal")}
                                        </Col>
                                    </Row>

                                    <div className="pt10">
                                        <Row>
                                            <Col span={14}>
                                                <div className="right-txt">
                                                    <FormattedMessage id='modal.txDetails.common.discount' defaultMessage='' />
                                                </div>
                                            </Col>

                                            <Col span={10} className="text-right ">
                                                {soDetail.isMultiCurrency ?
                                                    <span> {soDetail.foreignCurrencyIcon ? <i className={soDetail.foreignCurrencyIcon}></i> : soDetail.foreignCurrency} </span>
                                                    : <span> {props.companyInfo.currencyIcon ? getCurrencySymbol(props.companyInfo.currencyCode) : props.companyInfo.currencyCode + " "} </span>}
                                                {getSubTotalOrTaxOrDiscountAmount(soDetail, "Discount")}
                                            </Col>
                                        </Row>
                                    </div>
                                    {getTxTypeData(soDetail, 'expense', 'amount') ?
                                        <div className="pt10">
                                            <Row>
                                                <Col span={14}>
                                                    <div className="right-txt">
                                                        <FormattedMessage id='modal.txDetails.common.expense' defaultMessage='' />
                                                    </div>
                                                </Col>

                                                <Col span={10} className="text-right ">
                                                    {soDetail.isMultiCurrency ?
                                                        <span> {soDetail.foreignCurrencyIcon ? <i className={soDetail.foreignCurrencyIcon}></i> : soDetail.foreignCurrency} </span>
                                                        : <span> {props.companyInfo.currencyIcon ? getCurrencySymbol(props.companyInfo.currencyCode) : props.companyInfo.currencyCode + " "} </span>}
                                                    {getTxTypeData(soDetail, 'expense', 'amount')}
                                                </Col>
                                            </Row>
                                        </div> : ''}
                                    {(itemLevelTaxes && itemLevelTaxes.length !== 0) && itemLevelTaxes.map((taxObj, i) => {

                                        return (
                                            props.companyInfo.countryName === 'India' ? (isPlaceOfSupplySameAsRelationship ? <div key={i}>
                                                <div className="pt10">
                                                    <Row>
                                                        <Col span={14}>
                                                            <div className="right-txt">CGST @ {taxObj.taxPercent}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                        </Col>
                                                        <Col span={10} className="text-right ">
                                                            {soDetail.isMultiCurrency ?
                                                                <span> {soDetail.foreignCurrencyIcon ? <i className={soDetail.foreignCurrencyIcon}></i> : soDetail.foreignCurrency} </span>
                                                                : <span> {props.companyInfo.currencyIcon ? getCurrencySymbol(props.companyInfo.currencyCode) : props.companyInfo.currencyCode + " "} </span>}
                                                            {fixedDecimalNumber(taxObj.taxAmount)}
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className="pt10">
                                                    <Row>
                                                        <Col span={14}>
                                                            <div className="right-txt">SGST @ {taxObj.taxPercent}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                        </Col>
                                                        <Col span={10} className="text-right ">
                                                            {soDetail.isMultiCurrency ?
                                                                <span> {soDetail.foreignCurrencyIcon ? <i className={soDetail.foreignCurrencyIcon}></i> : soDetail.foreignCurrency} </span>
                                                                : <span> {props.companyInfo.currencyIcon ? getCurrencySymbol(props.companyInfo.currencyCode) : props.companyInfo.currencyCode + " "} </span>}
                                                            {fixedDecimalNumber(taxObj.taxAmount)}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div> : <div key={i}>
                                                <div className="pt10">
                                                    <Row>
                                                        <Col span={14}>
                                                            <div className="right-txt">{taxObj.taxName} @ {taxObj.taxPercent}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                        </Col>
                                                        <Col span={10} className="text-right ">
                                                            {soDetail.isMultiCurrency ?
                                                                <span> {soDetail.foreignCurrencyIcon ? <i className={soDetail.foreignCurrencyIcon}></i> : soDetail.foreignCurrency} </span>
                                                                : <span> {props.companyInfo.currencyIcon ? getCurrencySymbol(props.companyInfo.currencyCode) : props.companyInfo.currencyCode + " "} </span>}
                                                            {fixedDecimalNumber(taxObj.taxAmount)}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                            ) :
                                                <div key={i}>
                                                    <div className="pt10">
                                                        <Row>
                                                            <Col span={14}>
                                                                <div className="right-txt">{taxObj.taxName} @ {taxObj.taxPercent}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                            </Col>
                                                            <Col span={10} className="text-right ">
                                                                {soDetail.isMultiCurrency ?
                                                                    <span> {soDetail.foreignCurrencyIcon ? <i className={soDetail.foreignCurrencyIcon}></i> : soDetail.foreignCurrency} </span>
                                                                    : <span> {props.companyInfo.currencyIcon ? getCurrencySymbol(props.companyInfo.currencyCode) : props.companyInfo.currencyCode + " "} </span>}
                                                                {fixedDecimalNumber(taxObj.taxAmount)}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                        );
                                    })}


                                    <div className="pt10">
                                        <Row>
                                            <Col span={14}>
                                                <div className="right-txt bold">
                                                    <FormattedMessage id='modal.txDetails.common.grandTotal' defaultMessage='' />
                                                </div>
                                            </Col>

                                            <Col span={10} className="text-right ">
                                                {soDetail.isMultiCurrency ?
                                                    <span> {soDetail.foreignCurrencyIcon ? <i className={soDetail.foreignCurrencyIcon}></i> : soDetail.foreignCurrency} </span>
                                                    : <span> {props.companyInfo.currencyIcon ? getCurrencySymbol(props.companyInfo.currencyCode) : props.companyInfo.currencyCode + " "} </span>}
                                                {(soDetail.salesOrderTotalAmount && fixedDecimalNumber(soDetail.salesOrderTotalAmount)) || ''}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>


                            </Col>
                        </Row>


                    </div>
                    : ''
}



                </div>
            </div>
        </div>
    );

}
const getSubTotalOrTaxOrDiscountAmount = (data, type) => {
    let total = 0;
    data && data.customerSalesOrderDetailsList && data.customerSalesOrderDetailsList.forEach(function (d) {
        if (type === 'SubTotal') {
            total = total + d.anItemSalePrice * d.quantityToInvoice;
        }
        if (type === 'Discount') {
            let discountObject = d.customerSalesOrderCOATxList.find(tx => (tx.txType === 'discount'));
            if (discountObject) {
                total = total + parseFloat(discountObject.amount);
            }
        }
    });
    if (type === 'Discount' && data && data.customerSalesOrderCOATxList) {
        let discount = data.customerSalesOrderCOATxList.find(tx => (tx.txType === 'discount' && tx.accountingEntry === 'credit'));
        if (discount) {
            total = total + parseFloat(discount.amount);
        }
    }
    return fixedDecimalNumber(total);
}
const getSpecialDiscount = (row, toReturn) => {
    if (!row) return;
    let specialDiscount = row.basePrice - row.anItemSalePrice;
    if (specialDiscount && row.basePrice) {
        if (toReturn === 'amountPercent') {
            let percent = fixedDecimalNumber((specialDiscount * 100) / row.basePrice);
            let amount = fixedDecimalNumber(specialDiscount);
            return amount + " (" + percent + ")%";
        } else if (toReturn === 'amount') {
            return fixedDecimalNumber(specialDiscount);
        }

    } else if (!row && toReturn === 'amount') {
        return 0;
    }
    else {
        return "-";
    }
}
const getTxTypeData = (row, type, toReturn) => {
    if (!row || !row.customerSalesOrderCOATxList) return;
    let data = row.customerSalesOrderCOATxList.find(tx => (tx.txType === type));
    if (data) {
        if (data[toReturn] && toReturn === 'amountPercent') {
            if (type === 'VAT') {
                return "VAT(" + data[toReturn] + "%)";
            }
            return "(" + data[toReturn] + "%)";
        } else if (data[toReturn] && toReturn === 'amount') {
            return (fixedDecimalNumber(data[toReturn]));
        } else {
            return ""
        }

    } else if (!data && toReturn === 'amount') {
        return "";
    }
    else {
        return "-";
    }
}
const getTotalTaxAmount = (row, isPlaceOfSupplySameAsRelationship) => {
    let tax1 = getTxTypeData(row, 'tax', 'amount', true) || 0;
    // let tax2 = getTxTypeData(row, 'sgst', 'amount', true) || 0;
    // let tax3 = getTxTypeData(row, 'igst', 'amount', true) || 0;
    //let tax2 = getTxTypeData(row, 'vat', 'amount', true) || 0;
    let totalTax = Number(tax1);
    if (isPlaceOfSupplySameAsRelationship) {

        return fixedDecimalNumber(totalTax * 2);
    } else {
        return fixedDecimalNumber(totalTax);

    }
}

const getTotalTaxPercent = (row, toReturn, isPlaceOfSupplySameAsRelationship) => {
    let tax1 = row.customerSalesOrderCOATxList.find(tx => (tx.txType === 'tax')) || {};
    // let tax2 = row.customerSalesOrderCOATxList.find(tx => (tx.txType === 'sgst')) || {};
    // let tax3 = row.customerSalesOrderCOATxList.find(tx => (tx.txType === 'igst')) || {};
    // let tax4 = row.customerSalesOrderCOATxList.find(tx => (tx.txType === 'vat')) || {};
    let totalPercent = Number(tax1.amountPercent || 0);
    if (toReturn === 'amountPercent') {
        if (isPlaceOfSupplySameAsRelationship) {

            return fixedDecimalNumber(totalPercent) ? ('(' + totalPercent * 2 + "%)") : '';
        } else {
            return fixedDecimalNumber(totalPercent) ? ('(' + totalPercent + "%)") : '';
        }
    }
    else {
        return isPlaceOfSupplySameAsRelationship ? totalPercent * 2 : totalPercent;
    }
}
const getNetAmount = (row, isPlaceOfSupplySameAsRelationship, withoutTax) => {
    let tax = getTotalTaxAmount(row) || 0;
    let taxValue = isPlaceOfSupplySameAsRelationship ? tax * 2 : tax
    let discount = getTxTypeData(row, "discount", "amount", true) || 0;
    let netAmountWithoutTax = Number(row.quantity * row.anItemSalePrice) - Number(discount);
    let netAmountItem = Number(row.quantity * row.anItemSalePrice) + Number(taxValue) - Number(discount);
    return withoutTax ? fixedDecimalNumber(netAmountWithoutTax) : fixedDecimalNumber(netAmountItem);
};
const calculateTaxRateList = (customerSalesOrderDetailsList, isPlaceOfSupplySameAsRelationship) => {
    let subtotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let itemLevelTaxes = []

    customerSalesOrderDetailsList.forEach(function (rowdata, index) {
        let totalAmountOfColumn = 0;
        if (rowdata.quantityToInvoice && rowdata.anItemSalePrice) {
            let taxObj = {
                subtotal: 0,
                taxPercent: 0,
                taxAmount: 0
            }
            totalAmountOfColumn = rowdata.quantityToInvoice * rowdata.anItemSalePrice;
            let discount = getTxTypeData(rowdata, 'discount', 'amount');
            if (discount) {
                totalAmountOfColumn = totalAmountOfColumn - discount;
                totalDiscount = totalDiscount + discount;
            }

            let tax1 = rowdata.customerSalesOrderCOATxList.find(tx => (tx.txType === 'tax')) || {};
            taxObj.taxPercent = Number(tax1.amountPercent);
            taxObj.taxAmount = Number(fixedDecimalNumber(tax1.amount));
            taxObj.taxName = tax1.taxName;
            totalTax = totalTax + taxObj.taxAmount;
            if (taxObj.taxPercent) {
                taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
                const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
                if (availableTaxObj) {
                    itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                        if (ele.taxPercent === taxObj.taxPercent) {
                            ele.subtotal = ele.subtotal + taxObj.subtotal;
                            ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
                        }
                        return true;
                    });
                } else {
                    taxObj.subtotal = Number(fixedDecimalNumber(taxObj.subtotal || 0));
                    itemLevelTaxes.push(taxObj);
                }
            }
            subtotal = subtotal + totalAmountOfColumn;
        }
    });

    return itemLevelTaxes;
}
const getVariantFragment = (item) => {
    let itemName = null;
    if (item.itemName) {
        itemName = item.itemName;
    }
    if (item.itemVariantName) {
        itemName = itemName + ' (' + item.itemVariantName + ')'
    }
    return itemName ? itemName : '';
}

const getDescriptionFragment = (soDetail, item, index) => {
    if (!item) return <Fragment />;
    const fragmentObj = <Fragment>
        {
            item.materialNumber
                ? <div>
                    <span className="title"> Material # : </span>{item.materialNumber}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            item.stockNumber
                ? <div>
                    <span className="title"> Stock # : </span>{item.stockNumber}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            item.partNumber
                ? <div>
                    <span className="title"> Part # : </span>{item.partNumber}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            item.description
                ? <div>
                    {ReactHtmlParser(item.description || '')}
                </div> :
                ''
        }
        {
            item.origin
                ? <div>
                    <br></br>
                    <span className="title"> Origin : </span>{item.origin}
                </div> :
                ''
        }
        {
            item.hsCode
                ? <div>
                    <span className="title"> HS Code : </span>{item.hsCode}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            item.warehouse
                ? <div>
                    <span className="title"> Warehouse Name : </span> <span style={{ 'display': 'inline-block' }}>{ReactHtmlParser(item.warehouse || '')} </span>
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            (soDetail.customerPONumber)
                ? <div> (
                    {soDetail.customerPONumber ? <span className="title">PO {soDetail.customerPONumber} - </span> : ''}
                    {index ? <span className="title"> Line {index}</span> : ''}
                    )
                </div> :
                ''
        }
    </Fragment>
    return fragmentObj;
}
const updateColumnEmptyCheck = (isColumnEmpty, soDetail, props) => {
    if (!soDetail) return isColumnEmpty;
    for (let i in soDetail.customerSalesOrderDetailsList) {
        let row = soDetail.customerSalesOrderDetailsList[i];
        if (row.itemName) { isColumnEmpty.product = false; };
        if (row.serviceName) { isColumnEmpty.service = false; };
        if (row.itemVariantName) { isColumnEmpty.variant = false; }
        if (row.description) { isColumnEmpty.description = false; }
        if (row.quantityToInvoice) { isColumnEmpty.qty = false; }
        if (row.uomName) { isColumnEmpty.uomName = false; }
        if (row.anItemSalePrice) { isColumnEmpty.rate = false; }
        if (row.specialDiscount) { isColumnEmpty.specialDiscount = false; }
        if (row.hsnCode && props.companyInfo.countryName === 'India') { isColumnEmpty.hsn = false; }
        if (!isTxTypeEmpty(row, 'discount')) { isColumnEmpty.discount = false; }
        if (!isTxTypeEmpty(row, 'tax')) { isColumnEmpty.tax = false; }
        if (row.documentDetails && row.documentDetails.length) { isColumnEmpty.documentDetails = false; }

    }
    return isColumnEmpty;
}
const isTxTypeEmpty = (row, type) => {
    if (!row) return true;
    let data = row.customerSalesOrderCOATxList.find(tx => (tx.txType === type));
    if (data && data.amount) {
        return false;
    }
    else {
        return true;
    }
}
export default injectIntl(OrderPreview);
