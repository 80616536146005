import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { saveFundraisingPledge, getAllFundraisingCampaign } from "./action";
import FundraisingPledgeDrawer from "../../../../components/drawer/fundraising/pledges";
import { pushModalToStack, popModalFromStack } from './../../../../actions/commonActions';
import { getPledge, pledgeCount } from '../../../fundraising/pledges/action';
import { fetchDataIfNeeded } from "../../../../utils";
import { fetchCustomers } from "../../../customer/Listing/action";
import { fetchPaymentModes } from "../../../../actions/commonActions";

class Pledge extends Component {
  constructor(props) {
    super(props);
    let pledgeRowData = props.pledgeRowData || {};
    this.state = {
      ...pledgeRowData,
      period: pledgeRowData.isRecurring ? (pledgeRowData.isWeekly ? 'weekly' : (pledgeRowData.isMonthly ? 'monthly' : 'yearly')) : 'oneTimePayment',
      status: pledgeRowData.status ? pledgeRowData.status : 'Ready',
      startDate: pledgeRowData.startDate || new Date(),
      paymentModeId: Number(pledgeRowData.paymentModeId || 0),
      remarks: pledgeRowData.remarks,
      cellPhone: (pledgeRowData.cellPhone && props.companyInfo.countryCallingCode) ? pledgeRowData.cellPhone.replace('+'+props.companyInfo.countryCallingCode, '') : pledgeRowData.cellPhone,
      totalOccurrences: pledgeRowData.totalOccurrences || 0,
      source: pledgeRowData.source ? pledgeRowData.source : 'Manual',
    }
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 1000
    };
    fetchDataIfNeeded("getAllFundraisingCampaign", "allCampaignList", this.props, payload);
    fetchDataIfNeeded("fetchPaymentModes", "paymentModeList", this.props, payload);
   // fetchPaginationDataIfNeeded('fetchCustomers', 'customers', this.props, { ...payload, isCompact: true });
  }

  render() {
    return (
      <FundraisingPledgeDrawer
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></FundraisingPledgeDrawer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    allCampaignList: state.fundraisingReducer.allCampaignList,
  //  customers: state.customer.customers,
    paymentModeList: state.common.paymentModeList
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveFundraisingPledge,
      getPledge,
      pledgeCount,
      getAllFundraisingCampaign,
      fetchCustomers,
      fetchPaymentModes,
      pushModalToStack,
      popModalFromStack
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Pledge);
