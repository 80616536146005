import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ICONS } from "../../../../../static/constants";
import { Empty, Menu, Pagination, Skeleton, Switch } from 'antd';
import { getCurrencySymbol, getMomentDateForUIReadOnly } from '../../../../../utils';
import DropdownAction from "antd/lib/dropdown";

const CouponListComp = (props) => {
    const { couponList, updateState, companyInfo, dir=0, pageNumber, couponCount, pageSize } = props;

    const sortColumn = () => {
        updateState({ dir: 1 - dir })
    }

    const updateCouponState = (coupon, isActive) => {
        coupon.active = isActive;
        props.updateCoupon(coupon, props);
    }

    const deleteCoupon = (coupon) => {
        props.deleteCoupon(coupon, props);
    }

    const editCoupon = (coupon) => {
        props.updateState({
            createCouponDrawerVisible: true,
            couponData: coupon
        });
    }

    const showTotal = (total) => {
        return (
            props.intl.formatMessage({
                id: "pagination.text.total",
                defaultMessage: "",
            }) +
            ` ${total} ` +
            props.intl.formatMessage({
                id: "pagination.text.items",
                defaultMessage: "",
            })
        );
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = {
            relationshipId: companyInfo.relationshipId,
            pageNumber: pageNumber,
            pageSize: pageSize,
        };
        props.getAllCoupons(payload);
    };

    const handlePageSizeChange = (pageNumber, pageSize) => {
        const payload = {
            relationshipId: companyInfo.relationshipId,
            pageNumber: pageNumber,
            pageSize: pageSize,
        };
        props.getAllCoupons(payload);
        props.getAllCouponsCount(payload);
    };

    const actionMenu = (e) => {
        return (
            <Menu className="row-action-dropdown">
    
                <Menu.Item key="0" value="title">
                    Actions
                </Menu.Item>
        
                <Menu.Divider />
        
                <Menu.Item key="edit" value="edit" onClick={()=>{
                        editCoupon(e);
                    }}
                >
                    <i className={ICONS.EDIT} /> Edit
                </Menu.Item>
        
                <Menu.Item key="delete" onClick={()=>{
                    props.showModal({
                        modalBody: "Are you sure want to delete?",
                        handleSubmit: () => {
                            deleteCoupon(e);
                        },
                    });
                }}
                >
                    <i className={ICONS.DELETE} /> Delete
                </Menu.Item>

                <Menu.Item key="clone" onClick={()=>{
                    editCoupon({...e, couponId: undefined, startDate: undefined, endDate: undefined});
                    }
                }>
                    <i className={ICONS.COPY} /> Clone
                </Menu.Item>
            </Menu >
        )
      };

    return (
        <div className="view-container coupon-container">
            <div className="view-container-actions">
                <Skeleton loading={props.headerLoading} paragraph={false} active>

                    <div className="left-actions">
                        <div className="table-heading">
                            <FormattedMessage id='eStore.coupons.table.label' defaultMessage='' />
                        </div>
                        {
                            couponCount ? <>
                            <div className="vertical-sep" />
                            <div>{couponCount}</div>
                            </>:''
                        }
                    </div>
                    <div className="right-actions">

                    </div>
                </Skeleton>
            </div>
            <Skeleton loading={props.listLoading} paragraph={{ rows: 7 }} active>
                <div className='table-container'>
                    <table id='coupons-table'>
                    <thead>
                        <tr>
                            <th>
                                <FormattedMessage id="serial.no" />
                            </th>
                            <th onClick={sortColumn}>
                                <FormattedMessage id="eStore.coupons.table.couponCode.header" defaultMessage="" /> <i className={ICONS.SORT} />
                            </th>
                            <th onClick={sortColumn}>
                                <FormattedMessage id="eStore.coupons.table.couponType.header" defaultMessage="" /> <i className={ICONS.SORT} />
                            </th>
                            <th>
                                <FormattedMessage id="eStore.coupons.table.discount.header" defaultMessage="" />
                            </th>
                            <th>
                                <FormattedMessage id="eStore.coupons.table.minPurchase.header" defaultMessage="" />
                            </th>
                            <th onClick={sortColumn}>
                                <FormattedMessage id="eStore.coupons.table.startDate.header" defaultMessage="" /> <i className={ICONS.SORT} />
                            </th>
                            <th onClick={sortColumn}>
                                <FormattedMessage id="eStore.coupons.table.endDate.header" defaultMessage="" /> <i className={ICONS.SORT} />
                            </th>
                            <th>
                                <FormattedMessage id="eStore.coupons.table.isActive.header" defaultMessage="" />
                            </th>
                            <th>
                                Action
                            </th>
                        </tr>
                    </thead>
                        <tbody>
                            {couponList && couponList[pageNumber]?.length ? couponList[pageNumber].map((rowData, i)=> (
                                <tr key={"coupon" + i}>
                                    <td width={"5%"}>{(pageNumber - 1) * pageSize + (i + 1)}</td>
                                    <td>{rowData.couponCode}</td>
                                    <td>{rowData.couponType}</td>
                                    <td>{rowData.discountPercentage ? 
                                            `${rowData.discountPercentage}%${rowData.maxDiscount ? 
                                                `(${getCurrencySymbol(rowData.currencyCode)}${rowData.maxDiscount})`
                                            : '' }` 
                                        : <>{getCurrencySymbol(rowData.currencyCode)}{rowData.maxDiscount || <div className="empty-data" />}</> }</td>
                                    <td>{rowData?.minPurchaseAmount ? `${getCurrencySymbol(rowData.currencyCode)}${rowData.minPurchaseAmount}` : <div className="empty-data" />}</td>
                                    <td>{rowData?.startDate ? getMomentDateForUIReadOnly({date: rowData?.startDate}) : <div className="empty-data" />}</td>
                                    <td>{rowData?.endDate ? getMomentDateForUIReadOnly({date: rowData?.endDate}) : <div className="empty-data" />}</td>
                                    <td>
                                        <Switch
                                            loading={props.saveLoading && props.updatingRow === i}
                                            checked={rowData.active}
                                            onClick={(checked, e) => {
                                                updateCouponState(rowData, checked)
                                                props.couponList[i].active = checked;
                                                updateState({ couponList: props.couponList, updatingRow: i });
                                            }}
                                        />
                                    </td>
                                    <td style={{paddingLeft: '50px'}}>
                                        {/* <i 
                                            className={`${ICONS.EDIT_P} mr10`} 
                                            onClick={(e) => {
                                                editCoupon(rowData);
                                            }}
                                        />
                                        <i 
                                            className={ICONS.DELETE_P} 
                                            onClick={(e) => {
                                                props.showModal({
                                                    modalBody: "Are you sure want to delete?",
                                                    handleSubmit: () => {
                                                        deleteCoupon(rowData);
                                                    },
                                                });
                                            }}
                                        /> */}
                                        <DropdownAction overlay={actionMenu(rowData)} trigger={['click']}>
                                            <i className={ICONS.MORE_P} />
                                        </DropdownAction>
                                    </td>
                                </tr>
                            ))
                            :
                            <tr key="empty-data-box">
                                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                    <Empty />
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </Skeleton>
            <div className="footer">
                <Skeleton loading={props.headerLoading} paragraph={false} active>

                    <div className="f-left"></div>
                    <div className="f-right">
                        <Pagination
                            size="small"
                            total={couponCount}
                            showTotal={showTotal}
                            current={props.pageNumber || 1}
                            defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                            onChange={(pageNumber, pageSize) => {
                                loadPageData(pageNumber, pageSize);
                                updateState({ pageSize: pageSize, pageNumber: pageNumber })
                            }}
                            onShowSizeChange={(pageNumber, pageSize) => {
                                handlePageSizeChange(pageNumber || 1, pageSize, props)
                            }}
                        />
                    </div>
                </Skeleton>
            </div>
        </div>
    );
}

export default injectIntl(CouponListComp);
