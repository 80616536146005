import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Tooltip } from 'antd';
import './dashboard.scss';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './index.scss';
import TileIndex from '../../tiles/tilesIndex';
import TotalStudents from '../../../containers/modal/modalBody/customer/TotalStudents';
import TotalParents from '../../../containers/modal/modalBody/customer/TotalParents';
import { STUDENT_REGISTRATION_LISTING_PAGE, CONSTANTS } from '../../../static/constants';
import { fixedDecimalNumber, getMomentDateForUIReadOnly } from '../../../utils';
import TopCustomerSales from "./TopCustomerSales";
// import TopSupplierPurchase from "./TopSupplierPurchase";
import StudentByStatus from './StudentByStatus';
import StudentByGrade from './StudentByGrade';
import FinancialAssisatance from './FinancialAssisatance';
// import StudentStatus from '../../../assets/images/Student-status.png';
// import StudentGrad from '../../../assets/images/student-grad.png';
import img1 from '../../../assets/images/Usernewstu.png';
import img2 from '../../../assets/images/Userstu.png';
import img3 from '../../../assets/images/Userdue.png';
import img4 from '../../../assets/images/Userpaid.png';
import UnpaidInvoices from '../../../containers/modal/modalBody/schoolManagement/UnpaidInvoices';
import UnpaidCustomers from '../../../containers/modal/modalBody/schoolManagement/UnpaidCustomers';



const StudentRegistrationListingComp = (props) => {
    const { companyInfo, paymentReminder = {} } = props;

    // const breadCrumbList = [
    //     {
    //         name: <FormattedMessage id='sidebar.menuItem.schoolManagement' defaultMessage='' />
    //     },
    //     {
    //         name: <FormattedMessage id='sidebar.menuItem.dashboard' defaultMessage='' />,
    //     }
    // ];
    const tilesData = [{
        footer: <FormattedMessage id='totalParents.text' />,
        count: Number(props.totalParentsCount).toFixed(2),
        type: STUDENT_REGISTRATION_LISTING_PAGE.TOTAL_PARENTS,
        antIcon: <img src={img1} alt="" />,
        // currencyIcon: companyInfo.currencyIcon,
        color: 'tile2'
    },
    {
        footer: <FormattedMessage id='totalStudent.text' />,
        count: Number(props.totalStudentCount).toFixed(2),
        type: STUDENT_REGISTRATION_LISTING_PAGE.TOTAL_STUDENT,
        antIcon: <img src={img2} alt="" />,
        // currencyIcon: companyInfo.currencyIcon,
        color: 'tile1'
    },
    {
        footer: <FormattedMessage id='feesDue.text' />,
        // count: Number(props.totalFeesDue).toFixed(2),
        count: (fixedDecimalNumber(props.proformaInvoiceList[1]
            .filter(invoice => invoice.module === "school" && invoice.isInvoiceConverted === 0)
            .reduce((sum, invoice) => sum + (invoice.invoiceTotalAmount || 0),0))),          

        type: STUDENT_REGISTRATION_LISTING_PAGE.FEES_DUE,
        antIcon: <img src={img3} alt="" />,
        currencyIcon: companyInfo.currencyIcon,
        color: 'tile4'
    },
    {
        footer: <FormattedMessage id='feesPaid.text' />,
        count: Number(props.totalFeesPaid).toFixed(2),
        type: STUDENT_REGISTRATION_LISTING_PAGE.FEES_PAID,
        antIcon: <img src={img4} alt="" />,
        currencyIcon: companyInfo.currencyIcon,
        color: 'tile3'
    }];

    const openModalOfSelectedTile = (data) => {
        switch (data.type) {
            case STUDENT_REGISTRATION_LISTING_PAGE.TOTAL_PARENTS:
                if (props.selectedFormId) {
                    props.showModal(
                        {
                            title: <div className='ant-modal-title'><FormattedMessage id='totalParents.text' /></div>,
                            modalBody: <TotalParents {...props} registrationFormSettingId={props.selectedFormId} />,
                            width: 1100,
                            hideFooter: true,
                            wrapClassName: 'tiles-modal'
                        }
                    )
                }
                break;
            case STUDENT_REGISTRATION_LISTING_PAGE.TOTAL_STUDENT:
                // if (props.selectedFormId) {
                //     props.showModal(
                //         {
                //             title: <div className='ant-modal-title'><FormattedMessage id='totalStudent.text' /></div>,
                //             modalBody: <TotalStudents {...props} registrationFormSettingId={props.selectedFormId} />,
                //             width: 600,
                //             hideFooter: true,
                //             wrapClassName: 'tiles-modal'
                //         }
                //     )
                // }
               
                    props.history.push({
                      pathname: "/admin/school-management/student",
                    });
                
                break;
            case STUDENT_REGISTRATION_LISTING_PAGE.FEES_DUE:

                break;
            case STUDENT_REGISTRATION_LISTING_PAGE.FEES_PAID:

                break;
            default:
                break;
        }
    }

    const openUnpaidInvoicesModal = (data) => {
        props.showModal(
            {
                title: <Fragment>
                    <div className='ant-modal-title'><FormattedMessage id='customer.listing.header.unpaidInvoices' /></div>
                </Fragment>,
                modalBody: <UnpaidInvoices {...props} />,
                width: 1100,
                hideFooter: true,
                wrapClassName: 'tiles-modal'
            }
        )
    }

    const openUnpaidCustomersModal = (data) => {
        props.showModal(
            {
                title: <Fragment>
                    <div className='ant-modal-title'>Parents</div>
                </Fragment>,
                modalBody: <UnpaidCustomers {...props} />,
                width: 800,
                hideFooter: true,
                wrapClassName: 'tiles-modal'
            }
        )
    }

    return (
        <div className="school-dash-container">
            {/* <div className="page-heading">
                <FormattedMessage id="heading.schoolManagement.schoolDashboard" defaultMessage="" />
            </div>
            <div className="pb10">
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div> */}
            <TileIndex tilesData={tilesData} {...props} getSelectedTile={openModalOfSelectedTile} className='p15' />
            <div className='view-container dashboard-wrapper'>
                <Row>
                    <Col span={12}>
                        <div className="inner-view tabular-data">
                            <div className="inner-heading-title">
                                Student Status
                            </div>
                            <Row style={{background: '#ffffff'}}>
                                <Col span={6} style={{ paddingTop: '80px' }}>
                                    {(Object.keys(props.totalStudentCountByStatus) || []).map((status, i) => {
                                        return <Row key={i}>
                                            <Col span={4}></Col>
                                            <Col span={12}>{status} -</Col>
                                            <Col span={4}>{props.totalStudentCountByStatus[status]}</Col>
                                        </Row>
                                    })
                                    }
                                </Col>
                                <Col span={16}>
                                    <StudentByStatus {...props} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="inner-view tabular-data">
                            <div className="inner-heading-title">
                                Students By Grade
                            </div>
                            <StudentByGrade {...props} />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="inner-view tabular-data" style={{ height: '535px' }}>
                            <div className='inner-heading-title'>
                                Accounts Receivable
                            </div>
                            {/* ['Total Revenue Forecast', 'Invoice Generated', 'Amount Received', 'Balance Due', 'Expense'],
                            [props.totalInvoiceGenerated, props.totalAmountReceived, props.totalInvoiceDue, props.totalExpenseDue] */}

                            <TopCustomerSales {...props} />
                            <div style={{paddingLeft: '16px', background: 'white'}}>
                                <Row style={{background: 'white'}}>
                                    <Col span={6}>Invoice Generated: </Col>
                                    <Col span={6}>{(props.companyInfo || {}).currencyIcon ? <i className={(props.companyInfo || {}).currencyIcon}></i> : (props.companyInfo || {}).currencyCode + " "}{Number(props.totalInvoiceGenerated).toFixed(2)}</Col>
                                </Row>
                                <Row style={{background: 'white'}}>
                                    <Col span={6}>Amount Received: </Col>
                                    <Col span={6}>{(props.companyInfo || {}).currencyIcon ? <i className={(props.companyInfo || {}).currencyIcon}></i> : (props.companyInfo || {}).currencyCode + " "}{Number(props.totalAmountReceived).toFixed(2)}</Col>
                                </Row>
                                <Row style={{background: 'white'}}>
                                    <Col span={6}>Balance Due: </Col>
                                    <Col span={6}>{(props.companyInfo || {}).currencyIcon ? <i className={(props.companyInfo || {}).currencyIcon}></i> : (props.companyInfo || {}).currencyCode + " "}{Number(props.totalInvoiceDue).toFixed(2)}</Col>
                                </Row>
                                <Row style={{background: 'white'}}>
                                    <Col span={6}>Expense: </Col>
                                    <Col span={6}>{(props.companyInfo || {}).currencyIcon ? <i className={(props.companyInfo || {}).currencyIcon}></i> : (props.companyInfo || {}).currencyCode + " "}{Number(props.totalExpenseDue).toFixed(2)}</Col>
                                </Row>
                            </div>
                            <br />
                        </div>
                    </Col>

                    {/* <Col span={12}>
                        <div className="inner-view tabular-data">
                            <div className="table-caption">
                                Expense

                            </div>
                            <TopSupplierPurchase {...props} />
                        </div>
                    </Col>
                </Row>

                <Row > */}
                    <Col span={12}>
                        <div className="inner-view tabular-data" style={{ height: '535px' }}>
                            <div className="inner-heading-title">
                                Payment Reminder
                            </div>
                            <div className='text-center box-middle-txt' style={{height: '87%'}}>
                                <div className="agGridWrapper">
                                    <div><h2><span className='cursor-pointer' onClick={() => { openUnpaidInvoicesModal(); }} style={{ fontSize: '30px' }}>{(props.companyInfo || {}).currencyIcon ? <i className={(props.companyInfo || {}).currencyIcon}></i> : (props.companyInfo || {}).currencyCode + " "}{Number(paymentReminder.balanceDue || 0).toFixed(2)}</span></h2></div>
                                    <div><b>
                                        Due from <span className='cursor-pointer' onClick={() => { openUnpaidCustomersModal(); }}>{`${paymentReminder.unpaidCustomerCount || 0} ${paymentReminder.unpaidCustomerCount > 1 ? 'Parents' : 'Parent'} `}</span>
                                        from <span className='cursor-pointer' onClick={() => { openUnpaidInvoicesModal() }}>{` ${paymentReminder.unpaidInvoiceCount || 0} ${paymentReminder.unpaidInvoiceCount > 1 ? 'Invoices' : 'Invoice'}`}</span>
                                    </b></div>
                                    <div>School year - {paymentReminder.startDate ? getMomentDateForUIReadOnly({ date: paymentReminder.startDate, format: CONSTANTS.DISPLAY_DATE_FORMAT_SESSION }) : ''} - {paymentReminder.endDate ? getMomentDateForUIReadOnly({ date: paymentReminder.endDate, format: CONSTANTS.DISPLAY_DATE_FORMAT_SESSION }) : ''}</div>
                                    <div>Program - {paymentReminder.programName || ''}</div>
                                    {
                                        paymentReminder.balanceDue ?
                                        <div className="action-dropdown-btn mt20">
                                            <button onClick={() => { 
                                                props.updateState({
                                                    emailDetailDrawerVisible: true,
                                                    isSendBulkInvoice: true,
                                                    isBulkInvoice: true,
                                                    docName: 'paymentReminder',
                                                    unpaidInvoiceCount: paymentReminder.unpaidInvoiceCount
                                                })
                                            }} style={{ padding: '0px 30px' }}>
                                                Send Reminder
                                            </button>
                                        </div>:''
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col span={12}>
                        <div className="inner-view tabular-data">
                            <div className="inner-heading-title">
                                Discounts
                            </div>
                            <div style={{paddingLeft: '16px', paddingBottom: '20px'}}>
                                <Row style={{background: 'white'}}>
                                    <Col span={24}>
                                        <FinancialAssisatance {...props} />
                                    </Col>

                                </Row>
                                <Row style={{background: 'white'}}>
                                    <Col span={6}>Fin. Assistance</Col>
                                    <Col span={8}>{(props.companyInfo || {}).currencyIcon ? <i className={(props.companyInfo || {}).currencyIcon}></i> : (props.companyInfo || {}).currencyCode + " "}{`${Number(props.totalFinancialAssisatance.financialAssistanceAmt || 0).toFixed(2)} (${props.totalFinancialAssisatance.countFinancialAssistance || 0} ${props.totalFinancialAssisatance.countFinancialAssistance > 1 ? 'Parents' : 'Parent'})`}
                                    </Col>
                                </Row>

                                <Row style={{background: 'white'}}>
                                    <Col span={6}>Teacher</Col>
                                    <Col span={8}>{(props.companyInfo || {}).currencyIcon ? <i className={(props.companyInfo || {}).currencyIcon}></i> : (props.companyInfo || {}).currencyCode + " "}{`${Number(props.totalFinancialAssisatance.teacherDiscountAmt || 0).toFixed(2)} (${props.totalFinancialAssisatance.countTeacherDiscount || 0} ${props.totalFinancialAssisatance.countTeacherDiscount > 1 ? 'Parents' : 'Parent'})`}
                                    </Col>
                                </Row>
                                <Row style={{background: 'white'}}>
                                    <Col span={6}>Membership</Col>
                                    <Col span={8}>{(props.companyInfo || {}).currencyIcon ? <i className={(props.companyInfo || {}).currencyIcon}></i> : (props.companyInfo || {}).currencyCode + " "}{`${Number(props.totalFinancialAssisatance.membershipDiscountAmt || 0).toFixed(2)} (${props.totalFinancialAssisatance.countMmembershipDiscount || 0} ${props.totalFinancialAssisatance.countMmembershipDiscount > 1 ? 'Parents' : 'Parent'})`}
                                    </Col>
                                </Row>
                                <Row style={{background: 'white'}}>
                                    <Col span={6}>Volunteer</Col>
                                    <Col span={8}>{(props.companyInfo || {}).currencyIcon ? <i className={(props.companyInfo || {}).currencyIcon}></i> : (props.companyInfo || {}).currencyCode + " "}{`${Number(props.totalFinancialAssisatance.volunteerDiscountAmt || 0).toFixed(2)} (${props.totalFinancialAssisatance.countVolunteerDiscount || 0} ${props.totalFinancialAssisatance.countVolunteerDiscount > 1 ? 'Parents' : 'Parent'})`}</Col>
                                </Row>
                            </div>
                        </div>
                    </Col>


                    <Col span={12}>
                        <div className="inner-view tabular-data" >
                            <div className="inner-heading-title">
                                Recent Payments
                            </div>
                                    <div className='new-table-attend'>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th><FormattedMessage id='serial.no' defaultMessage=''/></th>
                                                    <th>Name</th>
                                                    <th>Payment #</th>
                                                    <th>Date</th>
                                                    <th>Amount</th>
                                                </tr>
                                                {props.recentSchoolPayments ? (props.recentSchoolPayments || []).map((e, i) => {
                                                    return <tr key={'std' + i}>
                                                            <td>{i + 1}</td>
                                                            <td> <div>{e.customerName || ''}</div></td>
                                                            <td> <div>{e.paymentNumber || ''}</div></td>
                                                            <td><Tooltip placement="topLeft" title={(e.dateCreated && getMomentDateForUIReadOnly({ date: e.dateCreated, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                                                                {(e.dateCreated && getMomentDateForUIReadOnly({ date: e.dateCreated, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                                            </Tooltip></td>
                                                            <td style={{ textAlign: 'right' }}>{(props.companyInfo || {}).currencyIcon ? <i className={(props.companyInfo || {}).currencyIcon}></i> : (props.companyInfo || {}).currencyCode + " "}{Number(e.paymentAmount || 0).toFixed(2)}</td>
                                                    </tr>
                                                }): 'No data exits.'
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                            <div className='p15'>
                                <div className="agGridWrapper">
                                </div>
                            </div>
                        </div>
                    </Col>


                    {/* <Col span={12}>
                        <div className="inner-view">
                            <div className="table-caption">
                                Teacher
                            </div>
                            <div className='ml50 pb30'>Total: 0</div>
                        </div>
                    </Col> */}
                    {/* </Row>
                <Row  > */}
                    {/* <Col span={12}>
                        <div className="inner-view">
                            <div className="table-caption">Subject</div>
                            <div className='ml50 pb30'>Total: 0</div>
                        </div>
                    </Col>

                    <Col span={12}>
                        <div className="inner-view">
                            <div className="table-caption">
                                Parents
                            </div>
                            <div className='ml50 pb30'>Total: 0</div>
                        </div>
                    </Col> */}
                </Row>
            </div>

        </div>
    );
};

export default injectIntl(StudentRegistrationListingComp);
