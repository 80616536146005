import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TotalParentsComponent from '../../../../../components/modal/modalBody/customer/TotalParents';
import { getRegisterParentsData, resetRegisterParentsData } from './action';

class TotalParents extends Component {

    constructor(props) {
        super();
        this.state = {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
            pageNumber: 1,
            pageSize: 25,
            isRowHeightSet: false
        }
    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            formId: this.props.registrationFormSettingId,
            pageNumber: 1,
            pageSize: 25
        };
        this.props.resetRegisterParentsData();
        this.props.getRegisterParentsData(payload);
    }

    render() {
        return <div>
            <TotalParentsComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        registerParentsData: state.studentRegistration.registerParentsData,
        totalStudentCount: state.studentRegistration.totalStudentCount,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getRegisterParentsData,
    resetRegisterParentsData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TotalParents);