import React, { Fragment, useState } from "react";
import { TextBox } from "../../general/TextBox";
import "../leads.scss";
import { Col, Collapse, Input, Row, Button, Drawer, Checkbox, Radio, Tabs } from "antd";
import { FormattedMessage } from "react-intl";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { Dropdown } from "../../general/Dropdown";
import { Dropdown as DropdownCustom } from "../../general/Dropdown";
import { HTML5Backend } from "react-dnd-html5-backend";
import { v4 as uuidv4 } from "uuid";
import DropdownIcon from "../../../assets/images/dropdown-icon.png";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import FileAttachment from "./components/FileAttachment";
import PreviewForm from "./PreviewForm/PreviewForm";
import { CRM_COMPONENT_TYPES, ICONS } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
import CompEditDrawer from "./compEditDrawer";

const { Panel } = Collapse;
const { TabPane } = Tabs;

const Formbuilder = (props) => {

  const { updateState, customSetting = {} } = props;
  const [formComponents, setFormComponents] = useState(props.formFieldsSetting || []);
  const [hoveredId, setHoveredId] = useState(null);
  const [updatedLabels, setUpdatedLabels] = useState({});
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [formName, setFormName] = useState(props.formName || "");
  const [project, setProject] = useState(props.projectMasterId);
  const [optionRows, setOptionRows] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(0);


  const ComponentDraggable = ({
    type,
    label,
    options,
    icon,
    placeholder,
    isRequired,
  }) => {
    const [, drag] = useDrag(() => ({
      type,
      item: { type, label, options, icon, placeholder, isRequired },
    }));

    return (
      <div ref={drag} className="dragable-components">
        {icon && <img src={icon} alt="Icon" />}
        <p className="draggableCompoent-lable">{label}</p>
      </div>
    );
  };

  const DraggableComponent = ({ type, label, id, onMove, onRemove, icon }) => {
    const [, drag] = useDrag(() => ({
      type,
      item: { type, label, id },
    }));

    const [, drop] = useDrop(() => ({
      accept: Object.values(CRM_COMPONENT_TYPES),
      hover: (item) => {
        if (!item || item.id === id) return;
        onMove(item.id, id);
        item.id = id;
      },
    }));

    // Add the return statement with a JSX element here
    return (
      <div
        ref={(node) => drag(drop(node))}
        style={{
          cursor: "move",
          border: "1px solid #ccc",
          padding: "5px",
          marginBottom: "5px",
          display: "none",
        }}
      >
        {label}
      </div>
    );
  };

  const DropZone = ({ formComponents, onDrop, onMove, onRemove }) => {
    const [, drop] = useDrop(() => ({
      accept: Object.values(CRM_COMPONENT_TYPES),
      drop: (item) => onDrop(item),
    }));

    const getFieldCompHeads = (component) =>{
      return <div className="input-wrapper">
        <div>
          <label>
            {updatedLabels[component.id] ? updatedLabels[component.id] : component.label}
          </label>
          {component.isRequired ? <span className="required">*</span> : ''}
        </div>
        {hoveredId === component.id ? 
          <div>
            <i 
              className={`${ICONS.ASSIGN_P} mr10`}
              onClick={() =>
                setDrawerOpen(hoveredId)
              }
            />
            <i 
              className={ICONS.DELETE_P} 
              onClick={() => onRemove(component.id)}
            />
          </div>
          :
          ''
        }
      </div>
    }

    const getFieldComp = (component) =>{
      switch (component.type) {
        case CRM_COMPONENT_TYPES.INPUT_TEXT:
          return (
            <Input type="text" placeholder={component.placeholder} />
          );
      
        case CRM_COMPONENT_TYPES.DROPDOWN:
          return (
            <Dropdown 
              placeholder={component.placeholder || 'Placeholder'}
              items={component.options || []}
              optionKeyName='key'
              valueKeyName='value'
            />
          );
      
        case CRM_COMPONENT_TYPES.MULTILINEINPUT:
          return (
            <textarea
              placeholder={component.placeholder || ''}
              style={{
                width: "100%",
                height: "32px",
                borderColor: "#d9d9d9",
                borderRadius : "2px", 
                padding :  '0 10px'
              }}
            />
          );
      
        case CRM_COMPONENT_TYPES.INPUT_NUMBER:
          return (
            <Input type="number" placeholder={component.placeholder || ''} />
          );
      
        case CRM_COMPONENT_TYPES.CHECKBOX:
          return (
            <Checkbox.Group
              options={(component.options || [{value: 'option 1'}]).map(obj=>obj.value)}
            />
          );
      
        case CRM_COMPONENT_TYPES.MULTIPLE_CHOICE:
          return (
            <Radio.Group>
            {(component.options || [{value: 'option 1'}]).map((option, index) => (
              <Radio key={index}>
                {option.value}
              </Radio>
            ))}
          </Radio.Group>
          );
        
        case CRM_COMPONENT_TYPES.FILE_ATTACHMENT:
          return (
            <FileAttachment />
          );
      
        default:
          return null;
      }
    }

    return (
      <div
        ref={drop}
        style={{
          height: "66vh",
        }}
      >
        {formComponents.map((component, index) => (
          <div key={`comp`+index} className="mb10">

            <div
              onMouseEnter={() => setHoveredId(component.id)}
              onMouseLeave={()=> setHoveredId(null)}
              className="input-dotted-wrapper"
            >
              {getFieldCompHeads(component)}

              {getFieldComp(component)}

            </div>

            <DraggableComponent
              type={component.type}
              label={component.label}
              index={index}
              id={component.id}
              onRemove={() => handleRemove(component.id)}
            />
          </div>
        ))}
      </div>
    );
  };

  const handleDrop = (item) => {
    const newItem = { ...item, id: uuidv4() };
    setFormComponents((prevComponents) => [...prevComponents, newItem]);
    if (
      [
        CRM_COMPONENT_TYPES.CHECKBOX,
        CRM_COMPONENT_TYPES.DROPDOWN,
        CRM_COMPONENT_TYPES.MULTIPLE_CHOICE,
      ].indexOf(newItem.type) > -1
    ) {
      editDropdown(newItem.id);
    } else {
      let comp = customSetting[newItem.id] || {};
      comp = { ...comp, ...newItem };
      updateState({
        customSetting: {
          ...customSetting,
          [newItem.id]: comp,
        },
      });
    }
  };

  const handleMove = (fromIndex, toIndex) => {
    if (fromIndex === toIndex) return;

    setFormComponents((prevComponents) => {
      const updatedComponents = [...prevComponents];
      const movedItem = updatedComponents.splice(fromIndex, 1)[0];
      updatedComponents.splice(toIndex, 0, movedItem);
      return updatedComponents;
    });
  };

  const handleRemove = (id) => {
    setFormComponents((prevComponents) => {
      return prevComponents.filter((component) => component.id !== id);
    });
  };

  const editDropdown = (id) => {
    let temp = optionRows[id] || [];
    temp.push({ label: "", value: "" });
    temp.push({ label: "", value: "", showMinus: true });
    temp.push({ label: "", value: "", showMinus: true });
    optionRows[id] = temp;
    setOptionRows(optionRows);
    let comp = customSetting[id] || {};
    let temp1 = comp.optionList || [];
    temp1.push({ label: "", value: "" });
    temp1.push({ label: "", value: "", showMinus: true });
    temp1.push({ label: "", value: "", showMinus: true });
    comp = { ...comp, optionList: temp1 };
    updateState({
      customSetting: {
        ...customSetting,
        [id]: comp,
      },
    });
  };

  const handleInputChange = (fieldName, newValue) => {
    props.updateState((prevState) => ({
      ...prevState,
      customSetting: {
        ...prevState.customSetting,
        [fieldName]: newValue,
      },
    }));
  };
  function onTabChange(key, props) {
    
    switch (key) {
      case "basic":
        props.updateState({ activeTab: "Basic", activeTabKey: "basic" });
        break;
      case "form_builder":
        props.updateState({ activeTab: "Form Builder", activeTabKey: "form_builder" });
        break;
      default:
        break;
    }
  }
  const handleSubmit = () => {
    if (!props.campaignId) {
      return showToasterMessage({
        description: "Campaign is Required",
        messageType: "error",
      });
    }

    if (!formName) {
      return showToasterMessage({
        description: "Form Name is Required",
        messageType: "error",
      });
    }

    const formFieldSetting = [
      {
        isRequired: true,
        label: "Full Name",
        options: undefined,
        placeholder: "Full Name",
        type: CRM_COMPONENT_TYPES.INPUT_TEXT,
      },
      {
        isRequired: true,
        label: "Email",
        options: undefined,
        placeholder: "Email",
        type: CRM_COMPONENT_TYPES.INPUT_TEXT,
      },
      {
        isRequired: true,
        label: "Mobile No.",
        options: undefined,
        placeholder: "Mobile No.",
        type: CRM_COMPONENT_TYPES.INPUT_NUMBER,
      },
      ...formComponents,
    ];

    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      crmLeadFormSettingId: props.crmLeadFormSettingId || 0,
      formName: formName,
      projectMasterId: project,
      campaignId:props.campaignId,
      campaignName:props.campaignName,
      isActive:1,
      formShortDescription:props.formShortDescription,
      formFieldsSetting: JSON.stringify(formFieldSetting),
      version: props.version || 0,
    };

    props.SaveCrmLeadFormSetting(payload, props);
  };
  
  return (
    <Fragment>
      <DndProvider backend={HTML5Backend}>
        <div className="top_header">
          <div className="title">
            <FormattedMessage id="heading.lead.leadForm" />
          </div>
        </div>
        <div className="m_body">
          <div className="form-builder-section">

          <Tabs
                activeKey={props.activeTabKey || "basic"} onChange={(key) => { onTabChange(key, props); }}
                type="line"
              >
                <TabPane tab={"Basic"} key="basic">
              
           
                  {/* <Collapse
                    accordion
                    expandIconPosition="right"
                    defaultActiveKey={["0"]}
                  > */}
                    {/* <Panel header={"Form Name"} key="0"> */}
                      <Row gutter={[50, 20]}>

               <Col className="sub-heading-form" offset={6} span={12}>Form Details</Col>
                        <Col offset={6} span={12}>
                          <div className="i-label">
                            Form Name<span className="required">*</span>
                          </div>
                          <TextBox
                            placeholder="Form Name"
                            type="text"
                            maxLength={50}
                            countStyle={{ right: "35px", bottom: "20px" }}
                            value={formName}
                            onChange={(e) =>
                              // handleInputChange("Form Name", e.target.value)
                              setFormName(e.target.value)
                            }
                          />
                        </Col>

                        <Col offset={6} span={12}>
                          <div className="i-label">
                            Form Short Description
                          </div>
                          <TextBox
                            placeholder="Form Shor tDescription"
                            type="text"
                            maxLength={50}
                            countStyle={{ right: "35px", bottom: "20px" }}
                            value={props.formShortDescription}
                            onChange={(e) =>
                              // handleInputChange("Form Name", e.target.value)
                              props.updateState({
                                formShortDescription:e.target.value,
                              })
                              // setFormName(e.target.value)
                            }
                          />
                        </Col>
                        {/* <Col offset={6} span={12}>
                          <div className="i-label">
                            Project<span className="required">*</span>
                          </div>

                          <DropdownCustom
                            items={props.projectList}
                            valueKeyName="projectName"
                            optionKeyName="projectMasterId"
                            value={project}
                            placeholder="Select Project"
                            onSelect={(optionValue, option) => {
                              setProject(optionValue);
                            }}
                          />
                        </Col> */}

                        <Col offset={6} span={12}>
                        <div className='i-label'>
                         Lead Campaign
                        </div>
                        <Dropdown
                          items={(props.crmCampaignList[props.pageNumber || 1] || [])}
                          valueKeyName='campaignName'
                          value={props.campaignName}
                          optionKeyName='campaignId'
                          onSelect={
                            (campaignId, obj) => {
                              updateState({
                                campaignId,
                                campaignName: obj.name
                              });
                            }}
                          placeholder={<FormattedMessage id='common.select.placeholder' />}
                          allowClear={true}
                          onClear={() => {
                            updateState({ campaignId: undefined, campaignName: undefined })
                          }}
                        />
                      
                        </Col>
                      
                      
                      {/* </Row> */}
                    {/* </Panel> */}
               {props.campaignId 
               && 
               (props.crmCampaignList[props.pageNumber || 1] || []).find((item)=>item.campaignId === props.campaignId)  
               &&
                <Col className="detail-para" offset={6} span={12}>This Campaign is associated with Project -
                {`${(props.crmCampaignList[props.pageNumber || 1] || []).find((item)=>item.campaignId === props.campaignId)
                  ?.crmLeadCampaignProjectList?.map((item)=>item.projectName)?.join(" ,")}`} </Col>}
              
                        <Col className="sub-heading-form" offset={6} span={12}>Mandatory Fields</Col>
                        <Col offset={6} span={12}>
                          <div className="i-label">
                            Full Name<span className="required">*</span>
                          </div>
                          <TextBox
                            placeholder="Full Name"
                            type="text"
                            disabled={true}
                            maxLength={50}
                            value={props.selectedRfqNum}
                            onChange={(e) =>
                              handleInputChange("fullName", e.target.value)
                            }
                          />
                        </Col>
                        <Col offset={6} span={12}>
                          <div className="i-label">
                            Email<span className="required">*</span>
                          </div>
                          <TextBox
                            placeholder="Email"
                            type="text"
                            disabled={true}
                            maxLength={50}
                            value={props.selectedRfqNum}
                            onChange={(e) =>
                              handleInputChange("email", e.target.value)
                            }
                          />
                        </Col>
                        <Col offset={6} span={12}>
                          <div className="i-label">
                            Mobile No.<span className="required">*</span>
                          </div>
                          <TextBox
                            placeholder="Mobile No."
                            type="text"
                            maxLength={50}
                            disabled={true}
                            value={props.selectedRfqNum}
                            onChange={(e) =>
                              handleInputChange("mobile", e.target.value)
                            }
                          />
                        </Col>
                      </Row>
                    {/* </Panel> */}
                    {/* <Panel header={"Lead Additional Details"} key="0"> */}      
                    {/* </Panel> */}
                  {/* </Collapse> */}
                </TabPane>
                <TabPane tab={"Form Builder"} key="form_builder">
                <Row>
                    <Col span={12}>
                            <div className="inner-additional-details">
                              {!(formComponents || []).length ? <div style={{fontSize: '18px', display:'flex', position:'relative', top:'50%', left:'50%' }}>Drop Here</div> :''}
                              <Row gutter={[25, 20]}>
                                <Col span={12}>
                                  <DropZone
                                    formComponents={formComponents.filter(
                                      (_, index) => index % 2 === 0
                                    )}
                                    onDrop={handleDrop}
                                    onMove={handleMove}
                                    onRemove={handleRemove}
                                    updatedLabels={updatedLabels}
                                  />
                                </Col>
                                <Col span={12}>
                                  <DropZone
                                    formComponents={formComponents.filter(
                                      (_, index) => index % 2 !== 0
                                    )}
                                    onDrop={handleDrop}
                                    onMove={handleMove}
                                    onRemove={handleRemove}
                                    updatedLabels={updatedLabels}
                                    style={{ borderLeft: "1px solid red" }}
                                  />
                                </Col>
                              </Row>
                            </div>
                    </Col>
                    <Col span={6}>
                      <div className="drag-section">
                        <div className="drag-header">
                          <h3>Form Components</h3>
                        </div>

                        <div className="drag-input-wraper">
                          <Row gutter={[8, 16]}>
                            <Col span={8}>
                              <ComponentDraggable
                                type={CRM_COMPONENT_TYPES.INPUT_TEXT}
                                label="Input"
                                icon={DropdownIcon}
                                placeholder="Placeholder"
                                isRequired={false}
                              />
                            </Col>
                            <Col span={8}>
                              <ComponentDraggable
                                type={CRM_COMPONENT_TYPES.DROPDOWN}
                                label="Dropdown"
                                icon={DropdownIcon}
                                isRequired={false}
                              />
                            </Col>

                            <Col span={8}>
                              <ComponentDraggable
                                icon={DropdownIcon}
                                type={CRM_COMPONENT_TYPES.MULTILINEINPUT}
                                label="Text Area"
                                isRequired={false}
                              />
                            </Col>

                            <Col span={8}>
                              <ComponentDraggable
                                icon={DropdownIcon}
                                type={CRM_COMPONENT_TYPES.INPUT_NUMBER}
                                label="Number Input"
                                isRequired={false}
                              />
                            </Col>

                            <Col span={8}>
                              <ComponentDraggable
                                icon={DropdownIcon}
                                type={CRM_COMPONENT_TYPES.CHECKBOX}
                                label="Check Boxes"
                                isRequired={false}
                              />
                            </Col>

                            {/* <Col span={8}>
                              <ComponentDraggable
                                icon={DropdownIcon}
                                type={CRM_COMPONENT_TYPES.FILE_ATTACHMENT}
                                label="File Attachment"
                                isRequired={false}
                              />
                            </Col> */}

                            <Col span={8}>
                              <ComponentDraggable
                                icon={DropdownIcon}
                                type={CRM_COMPONENT_TYPES.MULTIPLE_CHOICE}
                                label="Multiple Choice"
                                isRequired={false}
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="pt10 pl20 pr20">
                          *Click on above components and drag to "Lead Additional
                          Details" section for create component in form
                        </div>
                      </div>
                    </Col>
              </Row>
                </TabPane>
              </Tabs>
          


            

       
          </div>
          
        </div>
      </DndProvider>

      {/* Drawer for edit fields */}
      {drawerOpen &&
                
        <CompEditDrawer 
          selectedComp = {formComponents.find(obj=>obj.id === hoveredId)}
          formComponents = {formComponents} 
          setParentFormComp = {setFormComponents}
          setDrawerOpen= {setDrawerOpen}
          drawerOpen= {drawerOpen}
        />
      }

      <PreviewForm
        visible={isPreviewVisible}
        onClose={() => {
          setPreviewVisible(false);
        }}
        formComponents={formComponents}
        // updatedLabels={updatedLabels}
        // optionRows={optionRows}
        formName={formName}
      />

           <div className="mf_footer">
              <Button
                className="grey-button"
                onClick={() => {
                  props.showModal({
                    modalBody: "All changes will be erased. Are you sure want to close?",
                    handleSubmit: () => {
                      props.popModalFromStack();
                    },
                  });
                }}
              >
                <FormattedMessage
                  id="button.cancel.label"
                  defaultMessage=""
                />
              </Button>
              <div>
                <Button
                  onClick={() => {
                    setPreviewVisible(true);
                  }}
                  className="mr15"
                >
                  <FormattedMessage
                    id="lead.PreviewForm.button"
                    defaultMessage=""
                  />
                </Button>
                <Button
                  className="ant-btn-primary"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  <FormattedMessage id="lead.save.button" defaultMessage="" />
                </Button>
              </div>
          </div>  
    </Fragment>
  );
};

export default Formbuilder;