import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DonationWebComponent from "../../../components/B2B/donationWeb";
import { fetchRelationshipDataByRID, fetchRelationshipByDomainName } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import { fetchDonationSetting } from '../../modal/modalBody/settings/DonationSetting/action';
import queryString from 'query-string';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import { CONSTANTS } from '../../../static/constants';
import config from '../../../config/environmentConfig';
import CryptoJS from "crypto-js";

class DonationWeb extends Component {
    constructor(props) {
        super(props);
        const linkProps = queryString.parse(props.location?.search) || {};
        this.state = {
            formSetting: {},
            status: linkProps.status,
            relationshipId: linkProps.relationshipId,
            source: linkProps.source,
            donationSettings: {},
            amount: 0,
            neverExpires: 1,
            duration: 'oneTime',
            paymentStartDate: new Date(),
            amountCursor: 0,
            relationshipBillingAddress: {},
            onlineThemes: {}
        }
    }
    componentDidMount() {
        const linkProps = queryString.parse(this.props.location?.search) || {};
        const bytes = linkProps.token ? CryptoJS.AES.decrypt(linkProps.token, config.ENCRYPT_SECRET) : null;
        const load = bytes ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) : {};
        this.props.fetchDonationSetting(load);
        this.props.fetchRelationshipDataByRID(load);
      //  this.props.fetchRelationshipByDomainName({ domainName: window.location.host, source: linkProps.source || ''}, this.props);
    }

    componentWillReceiveProps(props) {
        if (props.donationSettings && props.donationSettings.settingsDonationId) {
            let donationContent = JSON.parse(props.donationSettings.donationContent);
            this.setState({
                relationshipId: props.donationSettings.relationshipId,
                settingsDonationId: props.donationSettings.settingsDonationId,
                donationSettings: donationContent,
                formHeading: donationContent.formHeading
            })
        }
        if (props.companyInfo && props.companyInfo.relationshipId) {
            const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
            const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
            // this.setState({
            //     relationshipBillingAddress,
            // })
         const onlineThemes = JSON.parse( props.companyInfo.onlineThemes || '{}');
      this.setState({
        onlineThemes,
        relationshipBillingAddress,
        companyInfo: props.companyInfo
      })
        }
    }
    render() {
        window.document.title= this.props?.companyInfo?.storeName || '';
        return <div>
             {Object.keys(this.props.donationSettings).length ? <DonationWebComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} /> : <div></div>} 
        </div>
    }
}
const mapStateToProps = (state) => {
    return {
        common: state.common,
        companyInfo: state.settings.relationshipData,
        relationshipId: state.settings.relationshipData?.relationshipId,
        donationSettings: state.donation.donationSetting,
    }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal, 
    hideModal, 
    pushModalToStack, 
    popModalFromStack,
    fetchRelationshipDataByRID,
    fetchRelationshipByDomainName,
    fetchDonationSetting
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DonationWeb);