import { Empty } from 'antd';
import React from 'react';

function TotalFailedComponent(props){
return <>
 <div className="table-container" style={{ height: "480px" }}>
                <table id='total-mail-table'>
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Date Created</th>
                     
                        </tr>
                    </thead>
                    <tbody>
                        {(props.failedStatusDetails).length ? (props.failedStatusDetails || []).map((rowData, i) => {
                            return <tr key={i}>
                            
                                <td>{rowData.email}</td>
                                <td>{rowData.dateCreated}</td>

                            </tr>
                        }) : <tr key="empty-data-box">
                        <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                            <Empty />
                        </td>
                    </tr>
                
                }

                    </tbody>
                </table>
            </div>
</>
}
export default TotalFailedComponent;