import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SubjectComp from "../../../../components/drawer/schoolManagement/subject";
import { pushModalToStack, popModalFromStack } from './../../../../actions/commonActions';
import {  saveSchool } from '../../../schoolManagement/school/action';

class SchoolDrawer extends Component {
  constructor(props) {
    super(props);
    let subjectData = props.subjectData || {};
    this.state = {
      subjectName: subjectData.name,
      subjectCode: subjectData.subjectCode,
      subjectType: subjectData.subjectType,
      subjectId: subjectData.subjectId,
      subjectCredit: subjectData.subjectCredit,
      version: subjectData.version,
    };
  }

  componentDidMount() {

  }

  render() {
    return (
      <SubjectComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveSchool,
      pushModalToStack,
      popModalFromStack
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SchoolDrawer);
