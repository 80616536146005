import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SupplierDetails from "../../../components/supplier/details/index";
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../../utils";
import {
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  addActivityStatus,
  deleteActivityStatus,
  addActivityPriorities,
  deleteActivityPriorities,
  addActivityTypes,
  deleteActivityTypes,
  fetchAllEmployees,
  fetchSupplierDetailsBySupplierId,
  deleteSupplierTaxIdentifications,
  fetchBusinessTypes,
} from "./../../../actions/commonActions";
import {
  fetchSupplierDebitMemoList,
  fetchSupplierDebitMemoListCount,
  fetchSupplierInvoiceList,
  fetchSupplierInvoiceCount,
  fetchAllContacts,
  fetchSupplierPaymentList,
  fetchSupplierPaymentListCount,
  exportLedgerPdf,
  exportTxPdf,
  fetchSupplierPurchaseOrderTransactions,
  fetchSupplierPurchaseOrderTransactionCount,
  fetchSupplierTransactions,
  fetchSupplierTransactionCount,
  resetTransactionListData,
  resetPurchaseOrderTransactionsListData,
  resetInvoiceTransactionListData,
  resetPaymentTransactionListData,
  resetDebitMemoTransactionListData,
  createUpdateTask,
  fetchSupplierCommunications,
  fetchSupplierActivities,
  deleteSupplierActivities,
  fetchSupplierAddresses,
  fetchSupplierToDoTasks,
  fetchSupplierLedgerTxSummaryList,
  getTotalOrderCountBySupplierId,
} from "./action";
import {
  fetchAllActivityPriorities,
  fetchAllActivityStatus,
  fetchAllActivityTypes,
  createUpdateActivity,
} from "../Listing/LogActivity/action";
import {
  createUpdateCommunication,
  deleteCommunication,
} from "../Listing/LogCommunication/action";
import { generatePDF,printPDF } from "./../../../actions/downloadFileAction";
import CommunicationReviewComp from "../../drawer/communication";
import { deleteContact } from "../Listing/ShowConact/action";
import SupplierTaskDrawer from "../../../containers/drawer/supplier/taskDrawer";
import { fetchSupplierSources } from "./../../../actions/commonActions";
import { fetchOpenOrders } from "../../modal/modalBody/supplier/OpenOrders/action";
import { fetchPaidInvoices } from "../../modal/modalBody/supplier/PaidInvoices/action";
import { fetchUnpaidInvoices } from "../../modal/modalBody/supplier/UnpaidInvoices/action";
import { fetchPurchaseInvoicesByFilter,getPurchaseInvoicesCountByFilter} from "../../modal/modalBody/supplier/AllInvoicesByLedger/action";

class SupplierDetailContainer extends Component {
  constructor(props) {
    super(props);
    // const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      pageSize: 100,
      pageNumber: 1,
      isDataRendered: false,
      supplierId: props.supplierId,
      isLoaded: false,
      
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      supplierId: this.props.supplierId,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 100,
      primaryContact:{},
      primaryAddress:{}
    };


    fetchDataIfNeeded('fetchSupplierSources', 'supplierSources', this.props, payload);

    this.props.fetchSupplierDetailsBySupplierId(payload);
    fetchDataIfNeeded(
      "fetchSupplierActivities",
      "activitiesList",
      this.props,
      payload
    );
    this.props.fetchOpenOrders({ ...payload, isForSupplier: true }, { companyInfo });
    this.props.fetchPaidInvoices({ ...payload, isForSupplier: true }, { companyInfo });
    this.props.fetchUnpaidInvoices(payload);
    this.props.getTotalOrderCountBySupplierId(payload)
    this.props.fetchBusinessTypes(payload);
    this.props.fetchAllContacts(payload);
    this.props.fetchSupplierAddresses(payload)
    
    const payload2= {
        relationshipId: companyInfo.relationshipId,
        supplierId: this.props.supplierId,
        pageNumber: 1,
        pageSize: 100,
        filters: {
            supplierId: this.props.supplierId,
            ledgerAccountId: this.props.ledgerAccountId
        }
    };
   
    fetchPaginationDataIfNeeded('fetchPurchaseInvoicesByFilter', 'purchaseInvoiceList', this.props, payload2, true);
    fetchDataIfNeeded('getPurchaseInvoicesCountByFilter', 'purchaseInvoiceCount', this.props, payload2, true);
   
  }
  componentWillReceiveProps(props) {
    if (props.supplier?.supplierId )
    {
      this.setState({
        supplier: props.supplier,
        primaryContact: (props.contacts || []).find(
          (obj) => obj.isPrimaryContact
        ),
        primaryAddress: (props.addressList || []).find(
          (obj) => obj.isDefault === 1
        ),
      });
    }
   
  }

  render() {
    return (
      <div>
        {this.state.supplierTaskDrawerVisible && (
          <SupplierTaskDrawer
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
          />
        )}

        {this.state.communicationReviewDrawerVisible && (
          <CommunicationReviewComp
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
          />
        )}
        <SupplierDetails
          {...this.props}
          {...this.state}
          updateState={(data) => {
            this.setState(data);
          }}
          payload={{
            supplierId: this.props.supplierId ,
            relationshipId: (this.props.companyInfo || {}).relationshipId,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    isLoading: state.common.isLoading,
    transactionsList: state.supplier.transactionsList,
    purchaseOrderTransactionsList: state.supplier.purchaseOrderTransactionsList,
    invoiceList: state.supplier.invoiceList,
    debitMemoList: state.supplier.debitMemoList,
    paymentList: state.supplier.paymentList,
    supplierSources: state.common.supplierSources,
    paymentListCount: state.supplier.paymentListCount,
    debitMemoListCount: state.supplier.debitMemoListCount,
    invoiceListCount: state.supplier.invoiceListCount,
    purchaseOrderTransactionsCount:
      state.supplier.purchaseOrderTransactionsCount,
    transactionsCount: state.supplier.transactionsCount,
    activitiesList: state.supplier.activitiesList,
    addressList: state.supplier.addressList,
    taskList: state.supplier.taskList,
    commuincationList: state.supplier.commuincationList,
    contacts: state.supplier.contacts,
    activityPriorities: state.customer.activityPriorities,
    activityStatus: state.customer.activityStatus,
    activityTypes: state.customer.activityTypes,
    allEmployee: state.common.employees,
    permissions: state.auth.permissions,
    supplier: state.common.supplierDetail,
    supplierInvoiceLedgerSummaryList:
      state.supplier.supplierInvoiceLedgerSummaryList,
    listLoading: state.common.listLoading,
    openOrderList: state.supplier.openOrderList,
    paidInvoiceList: state.supplier.paidInvoiceList,
    unpaidInvoiceList: state.supplier.unpaidInvoiceList,
    supplierTotalOrderCount:state.supplier.supplierTotalOrderCount,
    businessTypes: state.common.businessTypes,
    purchaseInvoiceList: state.purchaseInvoice.purchaseInvoiceList,
    purchaseInvoiceCount: state.purchaseInvoice.purchaseInvoiceCount,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createUpdateTask,
      exportLedgerPdf,
      exportTxPdf,
      fetchSupplierDebitMemoList,
      fetchSupplierDebitMemoListCount,
      fetchSupplierInvoiceList,
      fetchSupplierInvoiceCount,
      fetchSupplierPaymentList,
      fetchSupplierPaymentListCount,
      fetchSupplierPurchaseOrderTransactions,
      fetchSupplierPurchaseOrderTransactionCount,
      fetchSupplierTransactions,
      fetchSupplierTransactionCount,
      fetchAllContacts,
      fetchSupplierToDoTasks,
      fetchSupplierAddresses,
      fetchSupplierActivities,
      deleteSupplierActivities,
      fetchSupplierCommunications,
      resetTransactionListData,
      resetPurchaseOrderTransactionsListData,
      resetInvoiceTransactionListData,
      resetPaymentTransactionListData,
      resetDebitMemoTransactionListData,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      fetchAllEmployees,
      fetchAllActivityPriorities,
      fetchAllActivityStatus,
      fetchAllActivityTypes,
      createUpdateActivity,
      addActivityStatus,
      deleteActivityStatus,
      addActivityPriorities,
      deleteActivityPriorities,
      addActivityTypes,
      deleteActivityTypes,
      createUpdateCommunication,
      deleteCommunication,
      generatePDF,
      fetchSupplierDetailsBySupplierId,
      deleteSupplierTaxIdentifications,
      fetchSupplierLedgerTxSummaryList,
      deleteContact,
      fetchSupplierSources,
      fetchOpenOrders,
      fetchPaidInvoices,
      fetchUnpaidInvoices,
      getTotalOrderCountBySupplierId,
      fetchBusinessTypes,
      getPurchaseInvoicesCountByFilter,
      fetchPurchaseInvoicesByFilter,
      printPDF,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplierDetailContainer);
