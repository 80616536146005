import React from "react";
import { Radio, Divider, Spin, Row, Col } from 'antd'
//import { FormattedMessage } from "react-intl";
import ReactHtmlParser from 'react-html-parser';

const AccountingType = (props) => {
    // let rates = [
    //     {
    //       "serviceName": "FedEx First Overnight\u00ae",
    //       "serviceCode": "fedex_first_overnight",
    //       "shipmentCost": 87.8,
    //       "otherCost": 2.63
    //     },
    //     {
    //       "serviceName": "FedEx Priority Overnight\u00ae",
    //       "serviceCode": "fedex_priority_overnight",
    //       "shipmentCost": 50.23,
    //       "otherCost": 1.51
    //     },
    //     {
    //       "serviceName": "FedEx Standard Overnight\u00ae",
    //       "serviceCode": "fedex_standard_overnight",
    //       "shipmentCost": 46.96,
    //       "otherCost": 1.41
    //     },
    //     {
    //       "serviceName": "FedEx 2Day\u00ae A.M.",
    //       "serviceCode": "fedex_2day_am",
    //       "shipmentCost": 23.04,
    //       "otherCost": 0.69
    //     },
    //     {
    //       "serviceName": "FedEx 2Day\u00ae",
    //       "serviceCode": "fedex_2day",
    //       "shipmentCost": 20.03,
    //       "otherCost": 0.6
    //     },
    //     {
    //       "serviceName": "FedEx Express Saver\u00ae",
    //       "serviceCode": "fedex_express_saver",
    //       "shipmentCost": 14.28,
    //       "otherCost": 0.43
    //     },
    //     {
    //       "serviceName": "FedEx Ground\u00ae",
    //       "serviceCode": "fedex_ground",
    //       "shipmentCost": 8.25,
    //       "otherCost": 0.33
    //     }
    //   ];
    return (
        <div className="" style={{width: '100%'}}>
            {/* <div className="heading">
                <FormattedMessage id='carriarsPrice.text' />
            </div> */}

            <form className="setup-form-details next-scroll"  >
                
                {(!props.isLoadingCarriarList) ?
                    <div>
                        {
                            (props.selectedCarriarCodes || []).map((carriarCode, i) => {
                                return (
                                    <div key={i + 1} style={{ fontSize: '20px', textAlign: 'left', cursor: 'pointer' }} >
                                        <Row>
                                            {/* <Col span={2}>
                                                <Radio style={{ marginRight: '15px' }} checked={props.selectedPriceCarriarCode === carriarCode} /> 
                                            </Col> */}
                                            <Col span={14}>
                                                {carriarCode}
                                            </Col>
                                            <Col span={8}>
                                                {(props.carrierRates?.[carriarCode] || {}).error || ''}
                                            </Col>
                                        </Row>
                                        {
                                            ((props.carrierRates?.[carriarCode] || {}).rates || []).map((rateObj, i) => {
                                                return (
                                                    <div key={i + 1} style={{ fontSize: '15px', textAlign: 'left', cursor: 'pointer' }} onClick={() => {
                                                        props.updateState({ selectedServiceCode: rateObj.serviceCode, selectedPriceCarriarCode: carriarCode })
                                                    }}>
                                                        <Row>
                                                            <Col span={1}></Col>
                                                            <Col span={1}>
                                                                <Radio style={{ marginRight: '15px' }} checked={props.selectedServiceCode === rateObj.serviceCode} />
                                                            </Col>
                                                            <Col span={7}>
                                                             {ReactHtmlParser(rateObj.serviceName || '')}
                                                            </Col>
                                                            <Col span={7}>
                                                                Shipment Cost - {rateObj.shipmentCost || ''}
                                                            </Col>
                                                            <Col span={8}>
                                                                Other Cost - {rateObj.otherCost || ''}
                                                            </Col>
                                                        </Row>
                                                        <Divider></Divider>
                                                    </div>
                                                )
                                            })
                                        }
                                        <Divider></Divider>
                                    </div>
                                )
                            })
                        }
                    </div> :
                    <Spin></Spin>
                }
            </form>
        </div>
    )
};

export default AccountingType;