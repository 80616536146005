import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PlanPricingComp from "../../../components/B2B/PlanPricing";
import {applyUserPlans, getSubcriptionPlanCost, getSubcriptionAddonCost, subscribedPlan,getSubscribedProduct,resetSubcriptionPlanCost} from "./action";
import {CONSTANTS} from "../../../static/constants";
import * as find from "lodash.find";
import config from '../../../config/environmentConfig';


class PlanPricing extends Component {
  constructor(props) {
    super(props);
    const billingAddress =  find(props.companyInfo.boLocationList || [],  {isDefault :1 , locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS}) || {};
    this.state = {
      loginData: props?.location?.state,
      isDataLoaded: false,
      isUpgrade: true,
      activeTab: 0,
      priceTerm: 'annualPrice',
      addons: {},
      cbErrors: {},
      companyName: props.companyInfo.storeName,
      firstName : props.userInfo.firstName, 
      lastName: props.userInfo.lastName,
      email: props.companyInfo.email,
      mobileNumber: props.companyInfo.phoneNumber,
      streetAddress1: billingAddress.streetAddress1,
      streetAddress2: billingAddress.streetAddress2,
      cityName: billingAddress.cityName,
      stateName: billingAddress.stateName,
      zipCode: billingAddress.zipCode,
      countryName: billingAddress.countryName,
      alpideProductId: this.props.alpideProductId || this.props.companyInfo?.alpideProductId
    }
  };
  componentDidMount() {
    this.props.resetSubcriptionPlanCost();
    const payload={
      countryName: this.props.companyInfo?.countryName,
      alpideProductId: this.props.alpideProductId || this.props.companyInfo?.alpideProductId, //set id dynamic from companyInfo or fetch from product drawer
      alpideProductId:this.state.alpideProductId
    }
    this.props.getSubcriptionPlanCost(payload);
    this.props.getSubcriptionAddonCost(payload);
    window.Chargebee.init({
      site: "alpide-test",
      publishableKey: config.CB_PUBLIC_KEY 
    });

    // get cb Instance
    const cbInstance = window.Chargebee.getInstance();
    this.setState({
      cbInstance: window.Chargebee.getInstance()
    })


  }
  
  componentWillReceiveProps(props) {
    const {subscriptionPlans = []} = props;
    if (!this.state.isDataLoaded && (subscriptionPlans || []).length) {

      const starter = subscriptionPlans.find(
        (plan) => plan.planType?.toLowerCase() === "starter"
      );
      const growth = subscriptionPlans.find(
        (plan) => plan.planType?.toLowerCase() === "growth"
      );
      const advanced = subscriptionPlans.find(
        (plan) => plan.planType?.toLowerCase() === "advanced"
      );
  
      this.setState({

        starter : {...starter, ...JSON.parse(starter?.planDetails || '{}')},
        growth : {...growth, ...JSON.parse(growth?.planDetails || '{}')},
        advanced : {...advanced, ...JSON.parse(advanced?.planDetails || '{}')},
       
        selectedPlan: {...growth, ...JSON.parse(growth?.planDetails || '{}')}, 
        isDataLoaded: true
      });
    }
  }
  
  render() {

    return (
      <PlanPricingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }

}
const mapStateToProps = (state) => {
  return {

    companyInfo: state.common.companyInfo, 
    userInfo: state.common.userInfo,
    plansLoading: state.common.plansLoading,
    subscriptionPlans: state.common.subscriptionPlans,
    subscriptionAddons: state.common.subscriptionAddons,
    listLoading: state.common.listLoading,
    subscribedProduct: state.common.subscribedProduct,
    alpideProducts: state.common.alpideProducts,
    saveLoading: state.common.saveLoading,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  applyUserPlans,
  getSubcriptionPlanCost,
  getSubcriptionAddonCost,
  subscribedPlan,
  getSubscribedProduct,resetSubcriptionPlanCost
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PlanPricing);
