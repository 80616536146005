import React, { Component, Fragment } from "react";
import { AgGridReact } from "ag-grid-react";
import { FormattedMessage, injectIntl } from "react-intl";
import { getMomentDateForUIReadOnly } from "../../../utils";
import { CONSTANTS } from '../../../static/constants';
import PageBreadcrumb from "../../PageBreadcrumb";
import RegistrationFormSetting from '../../../containers/modal/modalBody/membership/RegistrationFormSetting';
import ShareRegistrationFormCompo from '../../../components/modal/modalBody/settings/ShareRegistrationForm';

class MembershipFormListComp extends Component {

  render() {
    const format = CONSTANTS.DISPLAY_DATE_FORMAT;
    const agGridStyle = {
      height: "100%",
      width: "100%",
    };

    const columnDefs = [
      {
        headerComponentFramework: () => {
          return <FormattedMessage id='setting.student.registration.list.formName' defaultMessage='' />;
        },
        field: 'programName'
      },
      {
        headerComponentFramework: () => {
          return <FormattedMessage id='setting.student.registration.list.date' defaultMessage='' />;
        },
        cellRendererFramework: (link) => {
          let d = JSON.parse(link.data.formSetting)
          return <div>{d?.programDate ? getMomentDateForUIReadOnly({ date: d.programDate, format }) : ''}</div>
        },
      },
      {
        headerComponentFramework: () => {
          return <div>
            Action
          </div>;
        },
        field: 'action',
        cellRendererFramework: (params) => {

          return <div>
            <span className="cursor-pointer" title='Edit' onClick={
              () => {
                const modalData = {
                  title: <div className='ant-modal-title'><FormattedMessage id='setting.student.registration.form.label' defaultMessage='' /></div>,
                  modalBody: <RegistrationFormSetting {...this.props} registrationFormSettingId={params.data.registrationFormSettingId} style={{ overflow: 'auto' }} />,
                  width: '100%',
                  hideFooter: true,
                  maskClosable: true,
                  hideCloseIcon: true,
                  hideTitle: true,
                  wrapClassName: "modal-custom-registration"
                };
                this.props.pushModalToStack(modalData);
              }}>
              <i className='fa fa-pencil' />
            </span>
            {!params.data.isAccountHolder ?
              <span style={{ marginLeft: '10px' }} title='Delete' className="cursor-pointer" onClick={
                () => {
                  const modalData = {
                    modalBody: <FormattedMessage
                      id='registration.form.delete.confirmation'
                      defaultMessage=''
                    />,
                    handleSubmit: () => {
                      this.props.deleteRegistrationForm({
                        relationshipId: this.props.relationshipId,
                        registrationFormSettingId: params.data.registrationFormSettingId

                      }, this.props);
                      //deleteContact(this.props, this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data)
                    }
                  };
                  this.props.pushModalToStack(modalData);
                }}>
                <i className='fa fa-trash' />
              </span> : ''
            }

            <span style={{ marginLeft: '10px' }} className="cursor-pointer" title='Share' onClick={
              () => {
                this.props.pushModalToStack({
                  title: <div>
                    <FormattedMessage id='shareMembershipForm.text' defaultMessage='' />
                    <div style={{ 'fontSize': '12px' }}> {params.data.programName || ''}</div>
                  </div>,
                  modalBody: <ShareRegistrationFormCompo {...this.props} formSettingData={params.data} />,
                  width: '50%',
                  hideFooter: true,
                })
              }}>
              <i className='fa fa-share' />
            </span>
          </div >
        },
        width: 52
      }
    ];

    
    const breadCrumbList = [
      {
        name: <FormattedMessage id='sidebar.menuItem.membership' defaultMessage='' />


      }, {
        name: <FormattedMessage id='sidebar.menuItem.membershipForm' defaultMessage='' />,
      }
    ];
    return (
      <Fragment>
        <div className="pb10">
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="view-container sales-order-details mt0">
          <div className='team-and-free'>
            <div className="right-align pb10">
              <div className="action-dropdown-btn">
                <button onClick={() => {
                  const modalData = {
                    title: <div className='ant-modal-title'><FormattedMessage id='setting.student.registration.form.label' defaultMessage='' /></div>,
                    modalBody: <RegistrationFormSetting registrationType='membership' {...this.props} style={{ overflow: 'auto' }} />,
                    width: '100%',
                    hideFooter: true,
                    maskClosable: true,
                    hideCloseIcon: true,
                    hideTitle: true,
                    keyboard: false,
                    wrapClassName: "modal-custom-registration"
                  };
                  this.props.pushModalToStack(modalData);
                }}>
                  <FormattedMessage id="button.create.label" defaultMessage="" />
                </button>
              </div>
            </div>
            <div className="agGridWrapper">
              <div className="ag-theme-balham" style={agGridStyle}>
                <AgGridReact
                  // onGridReady={onGridReady}
                  columnDefs={columnDefs}
                  defaultColDef={{
                    flex: 1,
                    autoHeight: true,
                    wrapText: true,
                  }}
                  rowData={this.props.membershipFormList || []}
                  rowDragManaged={true}
                  domLayout={"autoHeight"}
                  //getRowHeight={getRowHeight}
                  suppressDragLeaveHidesColumns={true}
                  animateRows={true}
                // onModelUpdated={onModelUpdated}
                // onColumnResized={onColumnResized}
                // onColumnVisible={(params) => {
                //   params.api.resetRowHeights();
                // }}
                // onDragStopped={() => {
                //   onColumnMoved({}, true);
                // }}
                //onColumnMoved={onColumnMoved}
                ></AgGridReact>
              </div>
            </div>
            
          </div>
        </div>
      </Fragment>
    );
  };
};

export default injectIntl(MembershipFormListComp);
