import React, { useState } from 'react';
import "./style.scss";
import { Radio, Divider } from "antd";
import { getCurrencySymbol } from "../../../utils";
import Check from "../../../assets/images/check.svg";


function PlanBox(props) {
    const {data={}} = props;
    return (
            <div 
                className="plan_box pointer"
                style={props.style}
                onClick={()=>{
                    props.updateState({selectedPlan: data})
                }}
            >
                {props.isRecommeded ? 
                    <div className="recommended_lable">
                        Recommended
                    </div>
                    :
                    ""  
                }

                <div className="main-head">
                    <div className="heading">
                        {data.planName || '-'}
                    </div>
                    <div>
                        <Radio
                            value={props.selectedPlan?.subcriptionPlanCostId}
                            checked={props.selectedPlan && props.selectedPlan?.subcriptionPlanCostId === data.subcriptionPlanCostId}
                        />
                    </div>
                </div>
                <div className="sub-content">
                    {data.firstSubHeading || ''}
                </div>
                <div>{data.secondSubHeading || ''}</div>
                <div className="starts">{data.preAmountText || ''}</div>
                <div className="princing_labels">
                    {
                        props.priceTerm === 'monthPrice' ? 
                            <>
                                <span className="price_label">{getCurrencySymbol(data.currencyCode)}{data.monthPrice?.toLocaleString()}</span>
                                {data.monthPrice && data.monthMRP ? 
                                    <>
                                        <span className="mrp_label">{getCurrencySymbol(data.currencyCode)}{Math.round(data.monthMRP)?.toLocaleString()}</span>
                                        <span className="discount_label">{100 - Math.round(((data.monthPrice)/data.monthMRP)*100)}% off</span>
                                    </>
                                    :
                                    ''
                                }
                            </>
                            :
                            <>
                                <span className="price_label">{getCurrencySymbol(data.currencyCode)}{Math.round(data.annualPrice/12)?.toLocaleString()}</span>
                                {data.annualPrice && data.annualMRP ? 
                                
                                    <>
                                        <span className="mrp_label">{getCurrencySymbol(data.currencyCode)}{Math.round(data.annualMRP/12)?.toLocaleString()}</span>
                                        {/* <span className="discount_label">{Math.round(((data.annualPrice)/data.annualMRP)*100)}% off</span> */}
                                        <span className="discount_label">{Math.round(((data.annualMRP - data.annualPrice)/data.annualMRP)*100)}% off</span>

                                    </>
                                    :
                                ''}
                            </>
                    }
                </div>
                {data.numberOfUsers ? <div>For {data.numberOfUsers} user</div> : ''}
                <div className="">({props.priceTerm === 'monthPrice' ? 'Billed Monthly' : 'Billed Annually'})</div>
                <Divider />
                <div className="fw-700 pb10 color-grey">
                    {data.planModuleHeading || ''}
                </div>
                <ul>
                    <li className="module_items">
                        <img src={Check} />
                        <div>Team Accounts upto {data.planUsers || ''}</div>
                    </li>
                    {
                        (data.planModules || []).length ? data.planModules.map((pointValue, i)=>{
                            return <li key={i+data.planName} className="module_items">
                                <img src={Check} />
                                <div>{pointValue}</div>
                            </li>

                        }) :''
                    }

                </ul>
            </div>
    )
}
export default PlanBox