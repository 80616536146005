import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as find from 'lodash.find';
import { Row, Col, Checkbox, Tooltip, Drawer } from 'antd';
import { TextBox } from '../../../general/TextBox';
import { CustomButton } from '../../../general/CustomButton';
import { Dropdown } from '../../../general/Dropdown';
import { ErrorMsg } from '../../../general/ErrorMessage';
import { MODAL_TYPE } from '../../../../static/constants';
import LedgerAccountGroupComponent from '../../../../containers/modal/modalBody/finance/LedgerAccount/ledgerAccountGroupTab';
import { showToasterMessage } from '../../../../utils';
// import { PromiseStatus } from 'ag-grid-community';

const LedgerAccountComponent = (props) => {
    const { intl, defaultTaxObj = {}, globleTaxes, allGstRates, invalidDefaultTaxClass } = props;
    const handleNewTaxAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.newTax' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.NEW_TAX,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                showModal(data);
            }
        };
        showModal(data);
    }

    const deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage=''
                values={{ value: payload.text }}
            />;

        let deleteFunc;
        let data = { id: payload.id, relationshipId: companyInfo.relationshipId };
        switch (modalType) {
            case MODAL_TYPE.NEW_TAX: {
                deleteFunc = props.deleteTax;
                if (companyInfo.countryName !== "India") { data.taxType = 'VAT' }
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => { deleteFunc(data) },
        };
        showModal(modalData);
    }
    const rowgutter =[24,16]
    return (
        <Drawer
            title={
            <div className="drawer-title">
                <FormattedMessage id='drawer.finance.ledgerAccounts.title' defaultMessage='' />
            </div>
            }
            className="custom-drawer"
            keyboard={false}
            width={720}
            maskClosable={false}
            zIndex={99999}
            destroyOnClose={true}
            onClose={()=>{
                props.updateDrawerState({
                    ledgerAccountsDrawerVisible: false
                })
            }}
            visible={props.ledgerAccountsDrawerVisible}
            footer = {
                <>
                            <CustomButton
              intlId={'confirmation.cancel'}
              defaultMessage={''}
              type={ 'default'}
              key={1}
              onClick={() => {
                  props.updateDrawerState({
                    ledgerAccountNumber:"",
                    ledgerAccountsDrawerVisible: false
                  });
                  props.updateState({
                    ledgerAccountNumber:""
                  })
              }}
            />
            <CustomButton
              intlId={'button.create.label'}
              defaultMessage={ ''}
              htmlType='submit'
              key={2}
              onClick={() => {

                if(props.ledgerAccountName && props.ledgerAccountGroupId && props.ledgerAccountNumber){
                    const modalData = {
                        modalBody: "Do you want to create ledger?",
                        handleSubmit: () => {
                            // props.popModalFromStack();
                            if(!props.isDuplicateName){
                                props.hideModal();
                                props.createLedgerAccount(props);
                                props.updateDrawerState({
                                    ledgerAccountsDrawerVisible: false
                                  });

                            }else{
                                showToasterMessage({description:"Duplicate ledger account name "})
                            }
                        }
                    };
                    props.pushModalToStack(modalData);
                }else{
                    props.updateState({
                        submittedOnce: true
                    });
                }
              }}
            />
                </>
            }
            // afterVisibleChange={
            //     (visible) => {
            //       if (visible) {
            //         props.initCalls();
            //       }
            //     }
            //   }

        >
            <Row gutter={rowgutter}>
                <Col span={12}>
                    <div className='i-label'>
                        Ledger Account
                        <span className="required">*</span></div>
                    <TextBox
                        type='text'
                        value={props.ledgerAccountName}
                        onChange={(e) => {
                            props.updateState({
                                ledgerAccountName: e.target.value
                            });
                        }}
                        onBlur ={()=>{
                            const callBack = (value)=>{
                                props.updateState({
                                  isDuplicateName:value
                                })
                              }
                              let needToCheck = true
                              if(props.isUpdate){
                                needToCheck = props.preLedgerName == props.ledgerAccountName ? false :true
                              }
                              if(needToCheck){
                                props.isDuplicateLedgerAccountName({ledgerAccountName:props.ledgerAccountName,relationshipId:props.companyInfo.relationshipId},callBack,props)
                              }
                        }}
                        placeholder={'Enter Ledger Name'}
                    />
                     <ErrorMsg
                      validator={() => { return !props.submittedOnce || !!props.ledgerAccountName  }}
                      message={ "Ledger Account is required"}/>
                </Col>
                <Col span={12}>
                    <div className='i-label'>Description</div>
                    <TextBox
                        type='text'
                        value={props.description}
                        onChange={(e) => {
                            props.updateState({
                                description: e.target.value
                            });
                        }}
                        placeholder={'Enter Description'}
                    />
                </Col>

                <Col span={12}>
                    <div className="i-label">Account Group
                        <span className="required">*</span></div>
                    <Dropdown
                        items={ props.allLedgerAccountGroups || []}
                        valueKeyName='ledgerAccountGroupName'
                        optionKeyName='ledgerAccountGroupId'
                        value={props.ledgerAccountGroupId}
                        placeholder={'Select Account Group'}
                        onSelect={
                            (ledgerAccountGroupId) => {
                                const selectedLedger = find(props.allLedgerAccountGroups, {ledgerAccountGroupId});
                                props.updateState({
                                    ledgerAccountGroupId,
                                    coaCategoryGroupId: selectedLedger.coaCategoryGroupId,
                                    coaCategoryId: selectedLedger.coaCategoryId,
                                    ledgerAccountNumber: ''
                                });
                                props.fetchNextAccountNo({relationshipId: (props.companyInfo || {}).relationshipId, chartOfAccountMaster: ledgerAccountGroupId});
                            }

                        }
                        canAddNew={props.hideAddNew?false: true}
                        onAddNewClick={()=>{
                            props.showModal({
                                title: <div className="ant-modal-title">
                                      Create Ledger Account Group
                                    </div>,
                                modalBody: <LedgerAccountGroupComponent {...props} />,
                                width: '60%',
                                hideFooter: true,
                              });
                        }}
                    />
                    <ErrorMsg
                        validator={() => { return !props.submittedOnce || !!props.ledgerAccountGroupId  }}
                        message={ "Ledger Account group is required"}/>
                </Col>
                {/* <Col span={12}>
                    <div className='-label'>
                    Ledger Balance
                    <span className="required">*</span>
                    </div>
                    <Dropdown
                        items={ ['Credit', 'Debit']}
                        value={props.ledgerBalance}
                        placeholder={'Select Ledger Balance'}
                        onSelect={
                            (ledgerBalance) => {
                                props.updateState({
                                    ledgerBalance
                                })
                            }
                        }
                    />
                    <ErrorMsg
                        validator={() => { return !props.submittedOnce || !!props.ledgerBalance  }}
                        message={ "Ledger balance is required"}/>
                </Col> */}
 
                <Col span={12}>
                    <div className="i-label">Ledger Account No.
                    <span className="required">*</span></div>
                    <TextBox
                        type='number'
                        value={props.ledgerAccountNumber}
                        onChange={(e) => {
                            props.updateState({
                                ledgerAccountNumber: e.target.value
                            });
                        }}
                        placeholder={'Enter Ledger Account Number'}
                    />
                    {props.ledgerAccountNumberBySystem ? <span>System generated account number is {props.ledgerAccountNumberBySystem}</span>: null}
                    <ErrorMsg
                      validator={() => { return !props.submittedOnce || !!props.ledgerAccountNumber  }}
                      message={ "Ledger account number is required"}/>
                </Col>
                <Col span={12}>
                    <div className="i-label">
                    Is Ledger Taxable
                    </div>
                    <Checkbox checked={props.isLedgerTaxable} onChange={(e) => {
                        props.updateState({ isLedgerTaxable: e.target.checked ? 1 : 0 });
                    }}>
                    </Checkbox>
                </Col>
                { props.isLedgerTaxable ?
                <Col span={12}>
                    {props.companyInfo.countryName === "India" ?
                        <>

                            <div className="i-label">
                                <FormattedMessage id='drawer.inventory.finance.HSNCode' defaultMessage='' />
                                <span>
                                    <Tooltip placement="top" trigger={'click'} title={intl.formatMessage(
                                        {
                                            id: 'tooltip.searchHSN',
                                            defaultMessage: ''
                                        }
                                    )}><i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                    </Tooltip>
                                </span>
                            </div>
                            <Dropdown
                                className={invalidDefaultTaxClass}
                                dropdownCustomStyle='dropdownCustomStyle'
                                items={props.allGstRates}
                                valueKeyName='hsnCode'
                                valueKeyName2='description'
                                optionKeyName='financeGstGoodsRates'
                                value={props.hsnCodeRate}
                                placeholder={intl.formatMessage({ id: 'drawer.inventory.finance.HSNCode.placeholder', defaultMessage: '' })}
                                onSelect={(optionValue) => {
                                    let obj = find(allGstRates, { financeGstGoodsRates: optionValue }) || {};
                                    props.updateState({
                                        hsnCodeRate: obj.hsnCode,
                                        hsnCodeDescription: obj.description,
                                        defaultTaxObj:
                                        {
                                            ...defaultTaxObj,
                                            hsnCode: obj.hsnCode,
                                            cgstRate: obj.cGstRate,
                                            igstRate: obj.iGstRate,
                                            sgstRate: obj.sGstUtGstRate,
                                            cess: obj.cess,
                                        },
                                        globleTaxRate: obj.iGstRate,
                                        taxSingleRateId: obj.financeGstGoodsRates,
                                        invalidDefaultTaxClass: ''
                                    });
                                }}
                                allowClear={true}
                                onClear={() => {
                                    props.props({
                                        defaultTaxObj:
                                        {
                                            ...defaultTaxObj,
                                            hsnCode: undefined,
                                            cgstRate: undefined,
                                            igstRate: undefined,
                                            sgstRate: undefined,
                                            cess: undefined,
                                        },
                                        invalidDefaultTaxClass: '',
                                        taxNameDisplay: undefined,
                                        taxSingleRateId: undefined
                                    });
                                }}
                            />

                            <div className='pt10'>
                                {defaultTaxObj.hsnCode ?
                                    <Row>
                                        <Col span={10}>
                                            Hsn Code :
                                        </Col>
                                        <Col span={14}>
                                            {defaultTaxObj.hsnCode}
                                        </Col>
                                    </Row> : ''}

                                {defaultTaxObj.cgstRate ? <Row>
                                    <Col span={10}>
                                        CGST :
                                    </Col>
                                    <Col span={14}>
                                        {defaultTaxObj.cgstRate}
                                    </Col>
                                </Row> : ''}

                                {defaultTaxObj.igstRate ? <Row>
                                    <Col span={10}>
                                        IGST :
                                    </Col>
                                    <Col span={14}>
                                        {defaultTaxObj.igstRate}
                                    </Col>
                                </Row> : ''}

                                {defaultTaxObj.sgstRate ? <Row>
                                    <Col span={10}>
                                        SGST/UGST :
                                    </Col>
                                    <Col span={14}>
                                        {defaultTaxObj.sgstRate}
                                    </Col>
                                </Row> : ''}
                            </div>
                        </>
                        :
                        <>
                            <div className='i-label'>
                                <FormattedMessage id="drawer.inventory.finance.tax" defaultMessage='' />
                            </div>
                            <Dropdown
                                className={invalidDefaultTaxClass}
                                items={props.globleTaxes}
                                valueKeyName='taxNameDisplay'
                                optionKeyName='taxSingleRateId'
                                value={defaultTaxObj.taxSingleRateId}
                                canAddNew={true}
                                canDelete={true}
                                deleteClickHandler={(payload) => {
                                    deleteClickHandler(props, MODAL_TYPE.NEW_TAX, payload);
                                }}
                                onAddNewClick={(payload) => {
                                    payload.formData = {
                                        taxName: payload.textEntered,
                                        submittedOnce: false
                                    }
                                    handleNewTaxAddition(props, payload, MODAL_TYPE.NEW_TAX)
                                }}
                                placeholder={intl.formatMessage({ id: 'drawer.inventory.finance.tax.placeholder', defaultMessage: '' })}
                                onSelect={(optionValue, elem) => {
                                    let obj = globleTaxes.find(tax => (tax.taxSingleRateId === optionValue));
                                    props.updateState({ defaultTaxObj: { ...defaultTaxObj, globleTaxRate: obj.taxPercent, taxNameDisplay: obj.taxNameDisplay, taxSingleRateId: obj.taxSingleRateId }, invalidDefaultTaxClass: '' });
                                }}
                                allowClear={true}
                                onClear={() => {
                                    props.updateState({
                                        defaultTaxObj: { ...defaultTaxObj, globleTaxRate: 0, taxNameDisplay: undefined, taxSingleRateId: undefined }, invalidDefaultTaxClass: ''
                                    });
                                }}
                            />
                        </>
                    }
                </Col>
                :
                ''}
            </Row>
        </Drawer>
    );
};

export default injectIntl(LedgerAccountComponent);
