import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PackageInformationComponent from '../../../../../components/modal/modalBody/customer/PackageInformation';
import { getSOPackageList, updatePackageQualityCheck } from './action';
import { fetchSalesOrderShipment } from '../ShipmentInformation/action';
import { fetchDataIfNeeded } from  '../../../../../utils';
import { getQualityChecklistSettings } from '../../../../../actions/commonActions';
import QualityCheckDrawer from '../../../../drawer/qualityCheck';
import { fetchSODetail } from '../SODetail/action';
class PackageInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            customerId: this.props.soData.customerId,
            salesOrderMasterId: this.props.soData.salesOrderMasterId,
            relationshipId: companyInfo.relationshipId
        }
        fetchDataIfNeeded('getSOPackageList', 'soPackageList', this.props, payload, true);
        fetchDataIfNeeded('fetchSalesOrderShipment', '', this.props, payload, true);
        fetchDataIfNeeded('getQualityChecklistSettings', 'qualityChecklistSetting', this.props, payload);
        this.props.fetchSODetail(payload)
    }

    render() {
        return <div>
            {this.state.qualityCheckDrawerVisible && <QualityCheckDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <PackageInformationComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return { 
        soPackageList: state.so.soPackageList,
        soShipmentList: state.so.soShipmentList,
        soDetail: state.so.soDetail,
        qualityChecklistSetting: state.settings.qualityChecklistSetting
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getSOPackageList,
    fetchSalesOrderShipment,
    getQualityChecklistSettings,
    updatePackageQualityCheck,
    fetchSODetail
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PackageInformation);
