import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SectionComp from "../../../../components/drawer/schoolManagement/section";
import { pushModalToStack, popModalFromStack } from './../../../../actions/commonActions';
import { saveSchool } from '../../../schoolManagement/school/action';
import { getClassList } from '../../../schoolManagement/class/action';

class SectionDrawer extends Component {
  constructor(props) {
    super(props);
    let sectionData = props.sectionData || {};
    this.state = {
      relationshipId: sectionData.relationshipId,
        createdByEmpId: sectionData.createdByEmpId,
      sectionName: sectionData.sectionName,
      sectionCode: sectionData.sectionCode,
      class_section_id: sectionData.class_section_id,
      className: sectionData.className,
      classId: sectionData.classId,
      version: sectionData.version,
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });

    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
      pageNumber: 1,
      pageSize: 10000,
    };
    this.props.getClassList(payload);
  }

  render() {
    return (
      <SectionComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></SectionComp>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    classList: state.school.classList,
    listLoading: state.common.listLoading
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveSchool,
      getClassList,
      pushModalToStack,
      popModalFromStack
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SectionDrawer);
