import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import JournalsComponent from '../../../components/finance/Journals';
import { fetchAllJournals } from './action';
import { fetchDataIfNeeded } from '../../../utils';
import { showModal, pushModalToStack, hideModal, popModalFromStack } from '../../../actions/commonActions';

class JournalComp extends Component {

    constructor(props) {
        super();
        this.state = {
            searchedText: ''
        }
    }

    componentWillMount() {
        this.props.updateHeaderState({
            collapsed: false
        });
    }

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId
        };
        fetchDataIfNeeded('fetchAllJournals', 'allJournals', this.props, payload);
    }



    render() {
        return <JournalsComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        isLoading: state.common.isLoading,
        allJournals: state.journal.allJournals,
        permissions: state.auth.permissions
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllJournals,
    showModal,
    pushModalToStack,
    hideModal,
    popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(JournalComp);
