import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EmailDrawer from '../../../../components/drawer/schoolManagement/broadcastMessage/EmailDrawer';
import { createBroadcastMessage } from './action';
import {getActiveBroadcastMessage, getActiveBroadcastMessageCount } from '../../../schoolManagement/BroadcastMessage/action';
import { getAllDG } from '../../../communication/distribution-group/action';
import { CONSTANTS_TRANSACTIONS } from '../../../../static/constants';
import moment from 'moment-timezone';

class BroadcastMessageEmail extends Component {

  constructor(props){
    super(props);
    let data = props.data || {}
    
    // if(props.isFormSetting && props.emailContext === 'recurringInvoice' && props.recurringInvoiceSettings && props.recurringInvoiceSettings.emailDetails){
    //   data = props.emailDetails || {}
    // }else if(props.isFormSetting && props.emailDetails && props.emailContext === 'registrationEmail') {
    //   data = props.emailDetails || {}
    // } else if(!props.emailContext) {
    //   data = props.emailDetails ? JSON.parse(props.emailDetails) : {};
    // }
    this.state = {
      dgName: data.dgName,
      distributionGroupId: data.distributionGroupId,
      body: data.message,
      endDate: data.endDate ? moment(data.endDate) : undefined,
      sender: data.sender,
      sendMeACopy: data.sendMeACopy,
      ccRecievers: data.cc || [],
      bccRecievers: data.bcc || [],
      emailBody: data.message,
      subject: data.subject,
      broadcastMessageId: data.broadcastMessageId,
      receiverItems: [],
      receivers: data.to || [],
      ccRecieversList: [],
      bccRecieversList: [],
      ccVisible: (data.cc && data.cc.length) ? true: false,
      bccVisible: (data.bcc && data.bcc.length) ? true: false,
      isSendInvoicePdf: data.isSendInvoicePdf || 0,
      updateReminder: props.updateReminder,
      isBulkInvoice: props.isBulkInvoice || false,
      docName: props.docName || 'Sales Invoice',
      isSendBulkInvoice: props.isSendBulkInvoice || false,
      isNewCommunication: props.isNewCommunication || false,
      proceedNow: true
    }
  }
  
  componentDidMount() {
    // !this.props.emailDetails && this.props.loadEmailData(this);
    
    // const payload = {
    //   relationshipId: (this.props.companyInfo || {}).relationshipId,
    //   transactionName:  CONSTANTS_TRANSACTIONS.STUDENT,
    // };
    // if(this.state.isNewCommunication){
    // this.props.fetchAllStatus(payload);
    // this.props.getAllDG(payload);
    // }
    const companyInfo = this.props.companyInfo || {};
    
    const payload = {
      companyInfo,
      relationshipId: (this.props.companyInfo || {}).relationshipId,
      pageNumber: 1,
      pageSize: 25,
      transactionName:  CONSTANTS_TRANSACTIONS.STUDENT,
    };
    if(this.state.isNewCommunication){
    this.props.fetchAllStatus(payload);
    
  }
  this.props.getAllDG(payload);
  }

  initCalls() {
    // this.props.fetchEmailAddress({
    //   customerId: (this.props || {}).customerId,
    //   relationshipId: (this.props.companyInfo || {}).relationshipId
    // })
    
  }


  componentWillReceiveProps(props) {
    if(props.emailAddressList && props.emailAddressList.length){
      this.setState({
        receiverItems:  props.emailAddressList,
        ccRecieversList:  props.emailAddressList,
        bccRecieversList:  props.emailAddressList
      })
    }
  }


  render() {
    return <>
      <EmailDrawer initCalls={() => {
        this.initCalls();
      }} {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} 
      />
    </>
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    isEmailSpinner: state.common.isEmailSpinner,
    // emailAddressList: state.common.emailAddressList,
    // userStatus: state.common.userStatus,
    dgList: state.communicationReducer.dgList,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createBroadcastMessage,
  getActiveBroadcastMessage,
  getActiveBroadcastMessageCount,
  getAllDG
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(BroadcastMessageEmail);