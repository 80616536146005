import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UOMListingComp from '../../../../../components/inventory/extras/uom/Listing';
import { fetchDataIfNeeded } from '../../../../../utils';
import { fetchUOM, addUOM, deleteUOM, showModal, pushModalToStack, hideModal, popModalFromStack } from '../../../../../actions/commonActions';
import { updateUOM } from './action';
class UOMListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 100,
      pageNumber: 1,
      isDataRendered: false
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 100
    };
    fetchDataIfNeeded('fetchUOM', 'allUOM', this.props, payload);
  }

  render() {
    return (
      <UOMListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    allUOM: state.common.uomList,
    isLoading: state.common.isLoading,
    permissions: state.auth.permissions
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchUOM,
  addUOM,
  updateUOM,
  deleteUOM,
  showModal,
  popModalFromStack,
  pushModalToStack,
  hideModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UOMListing);
