import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchDataIfNeeded } from '../../../../utils';
import LedgerDrawer from '../../../../components/drawer/ledgers/CreditMemoLedger';
import { fetchAllLedgerAccounts } from './../../../finance/LedgerAccounts/action';

class CreditMemoLedger extends Component {


  initCalls() {
    const companyInfo = this.props.companyInfo || {};
    fetchDataIfNeeded('fetchAllLedgerAccounts', 'allLedgerAccounts', this.props, { relationshipId: companyInfo.relationshipId});
  }


  render() {
    return <div>
      <LedgerDrawer initCalls={() => {
        this.initCalls();
      }} {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
    </div>
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    allLedgerAccounts: state.ledger.allLedgerAccounts
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchAllLedgerAccounts
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(CreditMemoLedger);
