import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


  export const saveOrUpdateRollNo = (requestObj, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/student/saveOrUpdateRollNo?relationshipId=${requestObj.relationshipId}`, requestObj.studentList)
        .then(res => {
          showToasterMessage({ description: (res || {}).message || 'Saved successfully.', messageType: 'success' });
          props.popModalFromStack();
          const payload = {
            relationshipId: props.companyInfo.relationshipId,
          }
          props.updateState({selectedItems : []})
          props.getSchoolStudents(payload);
          props.countSchoolStudents(payload);
        })
        .catch(err => {
          showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        })
    }
  }