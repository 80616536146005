
import axios from "axios";
import config from "../../../config/environmentConfig";
import { showToasterMessage } from "../../../utils";
import { E_STORE } from "../../../static/constants";
import { COMMON_ACTIONS } from "../../../static/constants";
export const postPagePolicyData =  (payload ,props)=>{
    return (dispatch) => {
        let formData =  new FormData();
        if (payload.file) {
            formData.append('file', payload.file,payload.file.name);
          
        }
      
        formData.append('ecomHeaderPage',
        new Blob([JSON.stringify(payload.ecomHeaderPage)], { 
          type: 'application/json'
        }));        

        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return  axios.post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecom_header_page/addPage`, formData, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
          .then(res => {
            if(payload.action==='update'){
              showToasterMessage({messageType: "success", description: "Data Update Succssfully."});

            }
            else{
              showToasterMessage({messageType: "success", description: "Data Upload Succssfully."});

            }
            dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch((err) => {
            dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
      }

  }

  export const getPagePublishedListData = (payload)=>{
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecom_header_page/getAllPublishedPage?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}&isPublish=${payload.isPublish}&customerId=${payload.customerId}`
      )
          .then(response => {
              dispatch({ type: E_STORE.PUBLISHED_PAGE_DATA, data: (response.data || []) });
          })
          .catch(err => {
              ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
  }
  }

  export const getPageAllListData =(payload)=>{
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecom_header_page/getAllPages?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}`
      )
          .then(response => {
              dispatch({ type: E_STORE.ALL_PAGE_LIST, data: (response.data || []) });
              ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch(err => {
              ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
  }
  }


  export const deletePageListItem =(payload)=>{
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios.delete(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecom_header_page/deletePage?id=${payload.id}`
      )
          .then(response => {
            showToasterMessage({messageType: "success", description: "Delete Succssfully."});
              ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch(err => {
              ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
  }
  }