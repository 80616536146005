import React from "react";
import { FormattedMessage } from 'react-intl';
import { CONSTANTS } from '../../../static/constants';
//import { AG_GRID_CLASS_CONSTANTS } from '../../../static/cssClassConstants';
import InboundDeliveryDetail from '../../../containers/modal/modalBody/supplier/InboundDeliveryDetail';
import { AgGridReact } from 'ag-grid-react';
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly } from '../../../utils';
import { Empty, Pagination } from 'antd';

const PurchaseTransactions = (props) => {
    const { companyInfo, updateState, purchaseTransactions, purchaseTransactionsCount, pageNumber, pageSize } = props;

    const agGridStyle = {
        height: '100%',
        width: '100%'
    };

    const onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
    }

    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize, itemId: props.itemId};
        fetchPaginationDataIfNeeded('fetchPurchaseTransactions', 'purchaseTransactions', props, payload);
    }
    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize, itemId: props.itemId};
        props.resetPurchasePaginatedData();
        props.fetchPurchaseTransactions(payload);
        //props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    }

    // const getRowHeight = (params) => {
    //     // let height = 28;
    //     // let calcHeight = 1
    //     // if (params.data.sku) {
    //     //     calcHeight = 2;
    //     // }
    //     return 28;
    // };

 

    const openIDDetails = (data) => {
        const invoicePayload = {
            supplierId: data.supplierId,
            inboundDeliveryMasterId: data.inboundDeliveryMasterId
        };
        props.pushModalToStack({
            modalBody: <InboundDeliveryDetail {...props} poPayload={invoicePayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    const columnDefs = [
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='product.detail.tab.inboundInventory.productName' defaultMessage='' />;
            },
            field: "itemName",
            resizable: true,
            rowDrag: true,
            // cellRendererFramework: (link) => <div>
            //     <div>{link.data.itemName}</div>
            //     {link.data.sku ? ("SKU : " + link.data.sku) : ''}
            // </div>,

        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='product.detail.tab.inboundInventory.variant' defaultMessage='' />;
            },
            resizable: true,
            cellRendererFramework: (link) => <div>
                {link.data.attributeValue1 && link.data.attributeValue1}
                {link.data.attributeValue2 && (link.data.attributeValue1 ? " | " : '') + (link.data.attributeValue2)}
                {link.data.attributeValue3 && (link.data.attributeValue1 || link.data.attributeValue ? " | " : '') + (link.data.attributeValue3)}
            </div>,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='drawer.inventory.sku' defaultMessage='' />;
            },
            field: "sku",
            resizable: true,

        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='product.detail.tab.inboundInventory.supplier' defaultMessage='' />;
            },
            field: "supplierName",
            resizable: true,

        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='modal.txDetails.inboundDelivery.inboundDeliveryNumber' defaultMessage='' />;
            },
            resizable: true,
            cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
                openIDDetails(link.data)
            }}>{link.data.inboundDeliveryNumber}</div>,
        },
        // {
        //     headerName: "Quantity",
        //     headerComponentFramework: () => {
        //         return <FormattedMessage id='product.detail.tab.inboundInventory.quantity' defaultMessage='' />;
        //     },
        //     field: "quantity",
        //     resizable: true,
        //     cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        // },
        // {
        //     headerComponentFramework: () => {
        //         return <div><FormattedMessage id='product.detail.tab.inboundInventory.price' defaultMessage='' />   ({props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "})</div>;
        //     },
        //     resizable: true,
        //     field: "purchasedPrice",
        //     cellRendererFramework: (link) => <div>
        //         {(link.data.itemPurchasedPrice || 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        //     </div>,
        //     cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        // },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='product.detail.tab.inboundInventory.txDate' defaultMessage='' />;
            },
            resizable: true,
            cellRendererFramework: (link) => <div>
                {(link.data.inboundDeliveryDate && getMomentDateForUIReadOnly({date: link.data.inboundDeliveryDate, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
            </div>,
        },
    ]

    return (<>
            {/* <div className='agGridWrapper mr15'>
                <div className="ag-theme-balham" style={agGridStyle} >
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowData={props.purchaseTransactions[props.pageNumber || 1] || []}
                        rowDragManaged={true}
                        domLayout={"autoHeight"}
                        animateRows={true}
                        onGridSizeChanged={onFirstDataRendered}
                        onColumnResized ={(params)=>{
                            params.api.resetRowHeights();
                        }}
                        defaultColDef={{
                            flex:1,
                            autoHeight: true,
                            wrapText: true,
                        }}
                        //getRowHeight={getRowHeight}
                        enableCellTextSelection={true}
                    >
                    </AgGridReact>
                </div>

            </div> */}
            <div className="view-container-actions">
                <div className="left-actions">
                    <div className="table-heading">
                        Inbound Inventory List
                    </div>
                    {purchaseTransactionsCount ? (
                        <>
                            <div className="vertical-sep" />
                            <div>{purchaseTransactionsCount}</div>
                        </>
                    ):''}
                </div>
                <div className="right-actions">
                </div>
            </div>
            <div className="table-container" style={{height: props.accordianOpen ? 'calc(100vh - 448px)' : 'calc(100vh - 321px)'}}>
                <table>
                    <thead>
                        <tr>
                            <th width='6%'><FormattedMessage id='serial.no' defaultMessage='' /></th>
                            <th><FormattedMessage id='product.detail.tab.inboundInventory.productName' defaultMessage='' /></th>
                            <th><FormattedMessage id='product.detail.tab.inboundInventory.variant' defaultMessage='' /></th>
                            <th><FormattedMessage id='drawer.inventory.sku' defaultMessage='' /></th>
                            <th><FormattedMessage id='product.detail.tab.inboundInventory.supplier' defaultMessage='' /></th>
                            <th><FormattedMessage id='modal.txDetails.inboundDelivery.inboundDeliveryNumber' defaultMessage='' /></th>
                            <th><FormattedMessage id='product.detail.tab.inboundInventory.txDate' defaultMessage='' /></th>
                        </tr>
                    </thead>
                    <tbody>
                    {(purchaseTransactions[pageNumber || 1] || []).length ? (purchaseTransactions[pageNumber || 1] || []).map((rowData, i)=>{
                        return <tr key={`st`+i}>
                            <td>{ (pageNumber-1)*pageSize + i+1 }</td>
                            <td>{rowData.itemName || '-'}</td>
                            <td>
                                {rowData.attributeValue1 && rowData.attributeValue1}
                                {rowData.attributeValue2 && (rowData.attributeValue1 ? " | " : '') + (rowData.attributeValue2)}
                                {rowData.attributeValue3 && (rowData.attributeValue1 || rowData.attributeValue ? " | " : '') + (rowData.attributeValue3)}
                            </td>
                            <td>{rowData.sku || '-'}</td>
                            <td>{rowData.supplierName || '-'}</td>
                            <td>
                                <div className="cursor-pointer" onClick={() => {
                                    openIDDetails(rowData)
                                }}>{rowData.inboundDeliveryNumber}</div>
                            </td>
                            <td>
                                {(rowData.inboundDeliveryDate && getMomentDateForUIReadOnly({date: rowData.inboundDeliveryDate, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
                            </td>
                        </tr>
                    })
                    
                            :
                            <tr>
                                <td colSpan={'100%'}>
                                    <div className="text-center"><Empty /></div>
                                </td>
                            </tr>
                    }
                    </tbody>
                </table>
            </div>
            <div className="footer">
                <div className="f-left"></div>
                <div className="f-right">
                    <Pagination
                        size="small"
                        total={props.purchaseTransactionsCount}
                        showTotal={showTotal}
                        defaultPageSize={pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                        // showQuickJumper
                        onChange={(pageNumber, pageSize) => {
                            loadPageData(pageNumber, pageSize);
                            updateState({ pageSize: pageSize, pageNumber: pageNumber })
                        }}
                        onShowSizeChange={(pageNumber, pageSize) => {
                            handlePageSizeChange(1, pageSize, props)
                        }}
                    />
                </div>
            </div>
        </>
    )
};

export default PurchaseTransactions;