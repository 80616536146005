import React, { Fragment, useState } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Row, Col, Checkbox, Tooltip, DatePicker, InputNumber } from "antd";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { ErrorMsg } from "../../general/ErrorMessage";
import { TextBox } from "../../general/TextBox";
import { CustomButton } from "../../general/CustomButton";
import {
  CONSTANTS,
  MODAL_TYPE,
  MODAL_ACTIONS,
  ICONS,
} from "../../../static/constants";
import { Dropdown } from "../../general/Dropdown";
import { getMomentDateForUI } from "../../../utils";

export const AddItemBody = (props) => {
  const {
    modalType,
    formData,
    handleSubmit,
    updateFormData,
    data = {},
    
  } = props;
 
  const labelName = getLabelName(modalType);
  formData.textBoxValue = props.selectedValue
    ? props.selectedValue
    : formData.textBoxValue;
  // const [formDataObj, setFormDataObj] = useState({...formData, textBoxValue :formData.textBoxValue|| ''});
  const [formDataObj, setFormDataObj] = useState({
    ...formData,
    // textBoxValue:,
  });
  const dispatch = useDispatch();

  return (
    <Form
      layout="vertical"
      labelAlign="left"
      className="login-form"
      onSubmit={(e) => {
        e.preventDefault();

        if (formDataObj.textBoxValue && formDataObj.textBoxValue.length) {
          handleSubmit(formDataObj.textBoxValue);
          
        } else {
          setFormDataObj({ submittedOnce: true, textBoxValue: null });
        }
      }}
    >
      <Row>
        <Col span={10}>
          <span>
            {labelName}
            <span className="required">*</span>
          </span>
        </Col>
        {!props.isDropDown ? (
          <Col span={14}>
            <TextBox
              style={{ height: "38px" }}
              className="textbox-pd"
              type="text"
              countStyle={{
                top: "0px",
                right: "5px",
                height: "38px",
                padding: "2px",
                display: "flex",
                alignItems: "center",
              }}
              value={
                (data.formData || {}).textBoxValue || formDataObj.textBoxValue
              }
              maxLength={
                formDataObj.maxLength || (data.formData || {}).maxLength || 25
              }
              placeholder={
                formDataObj.placeholder ||
                (data.formData || {}).placeholder ||
                "Enter"
              }
              onChange={(e) => {
                updateFormData &&
                  updateFormData({
                    ...formDataObj,
                    textBoxValue: e.target.value,
                    submittedOnce: true,
                  });
                setFormDataObj({
                  ...formDataObj,
                  textBoxValue: e.target.value,
                  submittedOnce: true,
                });
                dispatch({
                  type: MODAL_ACTIONS.MODAL_FORM_DATA_UPDATE,
                  data: { formData: { textBoxValue: e.target.value } },
                });
              }}
              // className={
              //   formDataObj.submittedOnce && (!formDataObj.textBoxValue || !formDataObj.textBoxValue.length) ? 'input-text-error' : ''
              // }
            />
            <ErrorMsg
              validator={() => {
                return !formData.submittedOnce || !!formData.textBoxValue;
              }}
              message={
                <FormattedMessage
                  id="addItem.validation"
                  defaultMessage=""
                  values={{ value: labelName }}
                />
              }
            />
          </Col>
        ) : (
          <Col span={12}>
            <Dropdown
              items={props.list || []}
              valueKeyName={props.valueKeyName}
              optionKeyName={props.optionKeyName}
              value={formDataObj.textBoxValue}
              style={{ width: "100%" }}
              placeholder={
                formDataObj.placeholder ||
                (data.formData || {}).placeholder ||
                "Select"
              }
              canAddNew={props.canAddNew}
              canDelete={props.canDelete}
              onAddNewClick={(payload) => {
                setFormDataObj({ ...formDataObj, textBoxValue: "" });
                props.onAddNewClick(payload);
              }}
              deleteClickHandler={props.deleteClickHandler}
              onSelect={(selectedValue, option) => {
                updateFormData &&
                  updateFormData({
                    ...formDataObj,
                    textBoxValue: ((option || {}).props || {}).name,
                  });
                setFormDataObj({
                  ...formDataObj,
                  textBoxValue: ((option || {}).props || {}).name,
                });
                dispatch({
                  type: MODAL_ACTIONS.MODAL_FORM_DATA_UPDATE,
                  data: {
                    formData: {
                      textBoxValue: ((option || {}).props || {}).name,
                      optionId: ((option || {}).props || {}).key,
                    },
                  },
                });
              }}
            />
          </Col>
        )}
      </Row>
      <CustomButton htmlType="submit" style={{ display: "none" }} />
    </Form>
  );
};

export const AddPaymentTermBody = (props) => {
  const { formData, handleSubmit ,intl} = props;
  const [formDataObj, setFormDataObj] = useState(formData);
  const dispatch = useDispatch();

  return (
    <Form
      layout="vertical"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="create-new-control pb10">
        <Row className="pb10">
          <Col span={12} className="text-right">
          <span>
                <FormattedMessage
                  id="drawer.customer.defaultPayment"
                  defaultMessage=""
                />
              </span>
            <Tooltip
              placement="bottom"
              title="When this is default then this term will be populated on all new transaction"
            >
              
              <i className={`${ICONS.HELP} cursor-pointer ml5`} />
             
            </Tooltip>
          </Col>
          <Col span={12}>
            <Checkbox
              checked={formDataObj.defaultPayment}
              onChange={() => {
                setFormDataObj({
                  ...formDataObj,
                  defaultPayment: !formDataObj.defaultPayment,
                });
                dispatch({
                  type: MODAL_ACTIONS.MODAL_FORM_DATA_UPDATE,
                  data: {
                    formData: {
                      ...formDataObj,
                      isDefault: formDataObj.isDefaultPayment ? 0 : 1,
                    },
                  },
                });
              }}
            />
          </Col>
        </Row>

        <Row className="pb10">
          <Col span={12} className="text-right">
            <FormattedMessage id="drawer.customer.termName" defaultMessage="" />
            <span className="required">*</span>
          </Col>
          <Col span={12}>
            <TextBox
              type="text"
              value={formDataObj.termName}
              maxLength={25}
              countStyle={{ top: "10px",right:"4px" }}
              placeholder={intl.formatMessage({
                id: "modal.paymentTerm.term.placeholder",
                defaultMessage: "",
              })}
              onChange={(e) => {
                setFormDataObj({ ...formDataObj, termName: e.target.value });
                dispatch({
                  type: MODAL_ACTIONS.MODAL_FORM_DATA_UPDATE,
                  data: {
                    formData: { ...formDataObj, termName: e.target.value },
                  },
                });
              }}
              className={
                formDataObj.submittedOnce &&
                !(formDataObj.termName || "").length
                  ? "input-text-error"
                  : ""
              }
            />
             <ErrorMsg
                  validator={() => {
                    return !formDataObj.submittedOnce || !!formDataObj.termName;
                  }}
                  message={intl.formatMessage({
                    id: "termName.requiredField.missing.msg",
                    defaultMessage: "",
                  })}
                />
          </Col>
        </Row>

        <Row className="pb10">
          <Col span={12} className="text-right">
            <FormattedMessage id="drawer.customer.days" defaultMessage="" />
            <span className="required">*</span>
          </Col>
          <Col span={12}>
            <InputNumber
              type="number"
              value={formDataObj.days}
              max={999}
              min={0}
              placeholder={intl.formatMessage({
                id: "modal.paymentTerm.days.placeholder",
                defaultMessage: "",
              })}
              style={{ width: "100%" }}
              onChange={(value) => {
                // const { value } = e.target;
                const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
                if (
                  (!isNaN(value) && reg.test(value)) ||
                  value === "" ||
                  value === "-"
                ) {
                  setFormDataObj({ ...formDataObj, days: value });
                  dispatch({
                    type: MODAL_ACTIONS.MODAL_FORM_DATA_UPDATE,
                    data: { formData: { ...formDataObj, days: value } },
                  });
                }
              }}
              className={
                formDataObj.submittedOnce &&
                !formDataObj.days &&
                formDataObj.days !== 0
                  ? "input-text-error"
                  : ""
              }
              
            />
               <ErrorMsg
                  validator={() => {
                    return !formDataObj.submittedOnce || !!formDataObj.days;
                  }}
                  message={intl.formatMessage({
                    id: "days.requiredField.missing.msg",
                    defaultMessage: "",
                  })}
                />
          </Col>
        </Row>

        <Row className="pb10">
          <Col span={12} className="text-right">
            <FormattedMessage
              id="drawer.customer.description"
              defaultMessage=""
            />
          </Col>
          <Col span={12}>
            <TextBox
              type="text"
              maxLength={50}
              countStyle={{ top: "10px",right:"4px" }}
              value={formDataObj.description}
              placeholder={intl.formatMessage({
                id: "modal.paymentTerm.description.placeholder",
                defaultMessage: "",
              })}
              onChange={(e) => {
                setFormDataObj({ ...formDataObj, description: e.target.value });
                dispatch({
                  type: MODAL_ACTIONS.MODAL_FORM_DATA_UPDATE,
                  data: {
                    formData: { ...formDataObj, description: e.target.value },
                  },
                });
              }}
            />
          </Col>
        </Row>
        {/* <CustomButton
            htmlType='submit'
            style={{ display: 'none' }}
          /> */}
      </div>
      <Row className="modal-footer" justify="space-between">
        <CustomButton
          className="back-white"
          intlId={"modal.paymentTerm.button.cancel"}
          defaultMessage={""}
          type='default'
          key={1}
          onClick={() => {
            props.hideModal();
          }}
        />
        <CustomButton
          intlId={"button.save.label"}
          defaultMessage={""}
          htmlType="submit"
          onClick={() => {
            if (formDataObj.termName && formDataObj.days) {
              const modalData = {
                modalBody: "Are you sure you want to create Payment Term ?",
                handleSubmit: () => {
                  handleSubmit(formDataObj);
                  dispatch({ type: MODAL_ACTIONS.HIDE_MODAL });
                },
              };
              props.pushModalToStack(modalData);
            } else {
              setFormDataObj({ submittedOnce: true });
            }
            // props.hideModal();
          }}
          key={2}
        />
      </Row>
    </Form>
  );
};

export const AddDateBody = (props) => {
  const {
    modalType,
    formData,
    handleSubmit,
    updateFormData,
    data = {},
  } = props;
  const format = CONSTANTS.DISPLAY_DATE_FORMAT;
  const labelName = getLabelName(modalType);
  formData.textBoxValue = props.selectedValue
    ? props.selectedValue
    : data.formData.textBoxValue;
  const [formDataObj, setFormDataObj] = useState(formData);
  const dispatch = useDispatch();
  return (
    <Form
      layout="vertical"
      className="login-form"
      onSubmit={(e) => {
        e.preventDefault();
        if (formDataObj.textBoxValue && formDataObj.textBoxValue.length) {
          handleSubmit(formDataObj.textBoxValue);
        } else {
          setFormDataObj({
            submittedOnce: true,
            textBoxValue: formDataObj.textBoxValue,
          });
        }
      }}
    >
      <Row>
        <Col span={10} className="padding-top-7">
          <span className="text-input-label">
            {labelName}
            <span className="required">*</span>
          </span>
        </Col>
        <Col span={12}>
          <DatePicker
            format={format}
            key={`${
              formDataObj.textBoxValue
                ? getMomentDateForUI({ date: formDataObj.textBoxValue, format })
                : getMomentDateForUI({ date: new Date(), format })
            }`}
            defaultValue={
              formDataObj.textBoxValue
                ? getMomentDateForUI({ date: formDataObj.textBoxValue, format })
                : getMomentDateForUI({ date: new Date(), format })
            }
            onChange={(selectedDate) => {
              updateFormData &&
                updateFormData({
                  ...formDataObj,
                  textBoxValue: selectedDate,
                  submittedOnce: true,
                });
              setFormDataObj({
                ...formDataObj,
                textBoxValue: selectedDate,
                submittedOnce: true,
              });
              dispatch({
                type: MODAL_ACTIONS.MODAL_FORM_DATA_UPDATE,
                data: { formData: { textBoxValue: selectedDate } },
              });
            }}
          />
          <ErrorMsg
            validator={() => {
              return !formDataObj.submittedOnce || !!formDataObj.textBoxValue;
            }}
            message={
              <FormattedMessage
                id="addItem.validation"
                defaultMessage=""
                values={{ value: labelName }}
              />
            }
          />
        </Col>
      </Row>
      <CustomButton htmlType="submit" style={{ display: "none" }} />
    </Form>
  );
};

export const AddPhoneNumberBody = (props) => {
  const {
    modalType,
    formData,
    handleSubmit,
    updateFormData,
    data = {},
    companyInfo,
  } = props;
  const labelName = getLabelName(modalType);
  formData.textBoxValue = formData.textBoxValue || data.formData.textBoxValue;
  const [formDataObj, setFormDataObj] = useState(formData);
  const dispatch = useDispatch();
  return (
    <Form
      layout="vertical"
      className="login-form"
      onSubmit={(e) => {
        e.preventDefault();
        if (
          formDataObj.textBoxValue &&
          formDataObj.textBoxValue.length &&
          formDataObj.textBoxValue.length === 10
        ) {
          handleSubmit(formDataObj.textBoxValue);
        } else {
          setFormDataObj({
            submittedOnce: true,
            textBoxValue: formDataObj.textBoxValue,
          });
        }
      }}
    >
      <Row className="newregis">
        <Col span={9} className="padding-top-3">
          <span className="text-input-label">
            {labelName}
            <span className="required">*</span>
            <Tooltip
              placement="top"
              title={props.intl.formatMessage({
                id: "phoneNumber.incorrect.message",
              })}
              trigger="click"
            >
              <i className="fa fa-question-circle-o pl5 cursor-pointer" />
            </Tooltip>
          </span>
        </Col>
        <Col span={12} className="input-currency-control">
          <span className="currency-tag">
            {companyInfo.countryCallingCode
              ? "+" + companyInfo.countryCallingCode
              : " "}
          </span>
          <TextBox
            type="text"
            style={{ marginLeft: "45px" }}
            value={formDataObj.textBoxValue}
            maxLength={
              formDataObj.maxLength || (data.formData || {}).maxLength || 10
            }
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                updateFormData &&
                  updateFormData({
                    ...formDataObj,
                    textBoxValue: e.target.value,
                    submittedOnce: true,
                  });
                setFormDataObj({
                  ...formDataObj,
                  textBoxValue: e.target.value,
                  submittedOnce: true,
                });
                dispatch({
                  type: MODAL_ACTIONS.MODAL_FORM_DATA_UPDATE,
                  data: { formData: { textBoxValue: e.target.value } },
                });
              }
            }}
            placeholder={props.intl.formatMessage({
              id: "signup.phoneNumber.placeholder",
              defaultMessage: "",
            })}
            className={
              formDataObj.submittedOnce &&
              (!formDataObj.textBoxValue ||
                !formDataObj.textBoxValue.length ||
                formDataObj.textBoxValue.length !== 10)
                ? "input-text-error add-new-input-text"
                : "add-new-input-text"
            }
          />
          <ErrorMsg
            validator={() => {
              return (
                !formDataObj.submittedOnce ||
                !!formDataObj.textBoxValue ||
                formDataObj.textBoxValue.length === 10
              );
            }}
            message={
              <FormattedMessage
                id="addItem.validation"
                defaultMessage=""
                values={{ value: labelName }}
              />
            }
          />
        </Col>
      </Row>
      <CustomButton htmlType="submit" style={{ display: "none" }} />
    </Form>
  );
};

export const getLabelName = (modalType) => {
  let labelName = "";
  switch (modalType) {
    case MODAL_TYPE.PAYMENT_TERM: {
      labelName = (
        <FormattedMessage id="addItem.text.paymentTerm" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.UPDATE_STATUS: {
      labelName = (
        <FormattedMessage id="addItem.text.statusName" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.CUSTOMER_TYPE: {
      labelName = (
        <FormattedMessage id="addItem.text.customerType" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.CUSTOMER_SOURCE: {
      labelName = (
        <FormattedMessage id="addItem.text.customerSource" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.BUSINESS_TYPE: {
      labelName = <FormattedMessage id="businessType.text" defaultMessage="" />;
      break;
    }
    case MODAL_TYPE.INDUSTRY: {
      labelName = (
        <FormattedMessage id="addItem.text.industry" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.SUPPLIER_TYPE: {
      labelName = (
        <FormattedMessage id="addItem.text.supplierType" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.SUPPLIER_SOURCE: {
      labelName = (
        <FormattedMessage id="addItem.text.supplierSource" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.SO_SOURCE: {
      labelName = (
        <FormattedMessage id="addItem.text.soSource" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.UOM: {
      labelName = <FormattedMessage id="addItem.text.uom" defaultMessage="" />;
      break;
    }
    case MODAL_TYPE.PRODUCT_CATEGORY: {
      labelName = (
        <FormattedMessage id="addItem.text.productCategory" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.PRODUCT_BRAND: {
      labelName = (
        <FormattedMessage id="addItem.text.brandName" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.PRODUCT_MANUFACTURER: {
      labelName = <FormattedMessage id="Manufacturer Name" defaultMessage="" />;
      break;
    }
    case MODAL_TYPE.DEPARTMENT: {
      labelName = (
        <FormattedMessage id="addItem.text.department" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.DOCUMENT_NAME: {
      labelName = (
        <FormattedMessage id="addItem.text.documentName" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.ATTRIBUTE: {
      labelName = (
        <FormattedMessage id="addItem.text.attribute" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.RFQ_PRICE_CODES: {
      labelName = (
        <FormattedMessage id="addItem.text.rfqPriceCode" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.RFQ_STATUS: {
      labelName = (
        <FormattedMessage id="addItem.text.rfqStatus" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.SALUTATION: {
      labelName = (
        <FormattedMessage id="addItem.text.salutation" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.ACTIVITY_STATUS: {
      labelName = (
        "Task Status"
      );
      break;
    }
    case MODAL_TYPE.ACTIVITY_PRIORITY: {
      labelName = (
        <FormattedMessage
          id="addItem.text.taskPriority"
          defaultMessage=""
        />
      );
      break;
    }
    case MODAL_TYPE.ACTIVITY_TYPE: {
      labelName = (
        "Task Type"
      );
      break;
    }
    case MODAL_TYPE.CARRIER_NAME: {
      labelName = (
        <FormattedMessage id="addItem.text.carrierName" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.FREIGHT_TYPE: {
      labelName = (
        <FormattedMessage id="addItem.text.freightType" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.EXPENSE_TYPE: {
      labelName = (
        <FormattedMessage id="addItem.text.expenseType" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.SHIPPING_INSTRUCTION: {
      labelName = (
        <FormattedMessage
          id="addItem.text.shippingInstruction"
          defaultMessage=""
        />
      );
      break;
    }
    case MODAL_TYPE.JOB_TITLE: {
      labelName = (
        <FormattedMessage id="addItem.text.jobTitle" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.JOB_ROLE: {
      labelName = <FormattedMessage id="jobRole.text" defaultMessage="" />;
      break;
    }
    case MODAL_TYPE.EMPLOYEE_TYPE: {
      labelName = (
        <FormattedMessage id="company.employee.type" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.TAX_IDENTIFICATION: {
      labelName = (
        <FormattedMessage
          id="addItem.text.taxIdentification"
          defaultMessage=""
        />
      );
      break;
    }
    case MODAL_TYPE.QUALITY_CHECK: {
      labelName = (
        <FormattedMessage id="addItem.text.qualityCheck" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.USER_STATUS: {
      labelName = (
        <FormattedMessage id="addItem.text.userStatus" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.ACTUAL_DELIVERY_DATE: {
      labelName = (
        <FormattedMessage
          id="addItem.text.actualDeliveryDate"
          defaultMessage=""
        />
      );
      break;
    }
    case MODAL_TYPE.INCOTERM: {
      labelName = (
        <FormattedMessage id="addItem.text.incoterm" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.SALES_COMPLAINT_TYPE: {
      labelName = (
        <FormattedMessage
          id="addItem.text.salesComplaintType"
          defaultMessage=""
        />
      );
      break;
    }
    case MODAL_TYPE.STATUS_NAME: {
      labelName = (
        <FormattedMessage id="addItem.text.statusName" defaultMessage="" />
      );
      break;
    }
    case MODAL_TYPE.CUSTOM_NAME: {
      labelName = <FormattedMessage id="customName.text" defaultMessage="" />;
      break;
    }
    case MODAL_TYPE.SUBACCOUNT_PHONE_NUMBER: {
      labelName = (
        <FormattedMessage id="communication.account.addSubAccount.model.field.accountPhoneNumber" />
      );
      break;
    }
    case MODAL_TYPE.COURSE_TERM: {
      labelName = <FormattedMessage id="courseTerm.text" />;
      break;
    }
    case MODAL_TYPE.SUBCOURSE: {
      labelName = <FormattedMessage id="subcourse.text" />;
      break;
    }
    case MODAL_TYPE.CLASS_SCHEDULE: {
      labelName = <FormattedMessage id="classSchedule.text" />;
      break;
    }
    case MODAL_TYPE.ASSIGNMENTS_GROUP: {
      labelName = <FormattedMessage id="assignment.group" />;
      break;
    }
    case MODAL_TYPE.CRM_CAMPAIGN_TYPE: {
      labelName = "Campaign Type Name";
      break;
    }
    case MODAL_TYPE.CRM_CAMPAIGN_STATUS: {
      labelName = "Campaign Status Name";
      break;}
    case MODAL_TYPE.RETURN_REASON: {
      labelName = <FormattedMessage id="returnReason.text" defaultMessage="" />;
      break;
    }
    default:
      labelName = <Fragment />;
  }
  return labelName;
};
