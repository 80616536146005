import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {showModal, pushModalToStack, popModalFromStack, hideModal} from '../../../../actions/commonActions';
import MaketingCampaignDrawerComp from '../../../../components/drawer/EmailMarketing/MarketingCampaign';

class MarketingCampaignDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
      }

    componentWillMount() {
        // const companyInfo = this.props.companyInfo || {};

    }


    render() {
        return <MaketingCampaignDrawerComp {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
    }
}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal, 
    pushModalToStack,
    popModalFromStack,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MarketingCampaignDrawer);