import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { AgGridReact } from 'ag-grid-react';
import { Skeleton, Pagination } from 'antd';
import { fetchPaginationDataIfNeeded } from '../../../../utils'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import './index.scss';
import PageBreadcrumb from '../../../PageBreadcrumb';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../static/cssClassConstants';
import ExpenseDetail from '../../../../containers/modal/modalBody/supplier/ExpenseDetail';
import moment from 'moment-timezone';
import { CONSTANTS } from '../../../../static/constants';
const format = CONSTANTS.DISPLAY_DATE_FORMAT;


const SupplierExpenseListingComp = (props) => {
    const { supplierExpenseList, updateState, companyInfo, supplierExpenseCount, pageNumber } = props;

    const showTotal = (total) => {
        return props.intl.formatMessage({id: 'pagination.text.total', defaultMessage: ''}) + ` ${ total } `+ props.intl.formatMessage({id: 'pagination.text.items', defaultMessage: ''});
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        fetchPaginationDataIfNeeded('fetchSupplierExpenses', 'supplierExpenseList', props, payload);
    }
    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetPaginatedData();
        props.fetchSupplierExpenses(payload);
        //props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
    }

    // const getRowHeight = (params) => {
    //     let height = 28;
    //     return height;
    // }

    const openExpenseDetails = (data) => {
        const expensePayload = {
            supplierId: data.supplierId,
            expenseMasterId: data.expenseMasterId
        }
        props.pushModalToStack({
            modalBody: <ExpenseDetail {...props} expensePayload={expensePayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    const columnDefs = [
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='supplier.debitMemo.listing.header.expenseNo' defaultMessage='' />;
            },
            field: "expenseNumber",
            rowDrag: true,
            resizable: true,
            cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
                openExpenseDetails(link.data)
            }}>{link.value}</div>,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='supplier.debitMemo.listing.header.supplier' defaultMessage='' />;
            },
            field: "supplierName",
            resizable: true
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='supplier.debitMemo.listing.header.refNumber' defaultMessage='' />;
            },
            field: "refNumber",
            resizable: true
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='supplier.debitMemo.listing.header.expenseDate' defaultMessage='' />;
            },
            resizable: true,
            cellRendererFramework: (link) => <div>
                {moment(new Date(link.data.expenseDate)).format(format)}
            </div>,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='supplier.debitMemo.listing.header.amount' defaultMessage='' />;
            },
            field: "expenseTotalAmount",
            resizable: true,
            cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='supplier.debitMemo.listing.header.status' defaultMessage='' />;
            },
            field: "status",
            resizable: true
        }
    ];

    const onFirstDataRendered = (params) => {
        //params.api.sizeColumnsToFit();
    }

    const agGridStyle = {
        height: '100%',
        width: '100%'
    };

    const breadCrumbList = [
        {
            name:<FormattedMessage id='breadcrum.dashboard' defaultMessage='' /> ,
            link: 'dashboard'
        },
        {
            name: <FormattedMessage id='supplier' defaultMessage='' />,
            link: 'suppliers'
        }, {
            name: <FormattedMessage id='expense.lable' defaultMessage='' />,
        }, {
            name:<FormattedMessage id='expense.list' defaultMessage='' /> 
        }
    ];

    return (
        <Fragment>
            <div className="invoice-header">
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div>
            <div className='view-container'>
                <Skeleton loading={props.isLoading}
                    active
                    paragraph={{ rows: 12 }}
                >
                    <div className='agGridWrapper'>
                        <div className="ag-theme-balham" style={agGridStyle} >
                            <AgGridReact
                                columnDefs={columnDefs}
                                rowData={supplierExpenseList[pageNumber] || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                animateRows={true}
                                onColumnResized ={(params)=>{
                                    params.api.resetRowHeights();
                                }}
                                defaultColDef={{
                                    flex:1,
                                    autoHeight: true,
                                    wrapText: true,
                                }}
                                //getRowHeight={getRowHeight}
                                onGridSizeChanged={onFirstDataRendered}
                            >
                            </AgGridReact>
                        </div>
                    </div>
                </Skeleton>
                <Pagination
                size="small"
                total={supplierExpenseCount}
                showTotal={showTotal}
                defaultPageSize={100}  showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                showQuickJumper
                onChange={(pageNumber, pageSize) => {
                    loadPageData(pageNumber, pageSize);
                    updateState({pageSize : pageSize, pageNumber: pageNumber})
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                    handlePageSizeChange(pageNumber, pageSize, props)
                }}
            />
            </div>
        </Fragment>
    );
};

export default injectIntl(SupplierExpenseListingComp);