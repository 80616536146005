import React, { Fragment,useEffect,useState} from "react";
import { FormattedMessage } from "react-intl";
import {
  FilePdfOutlined,
  MailOutlined,
  PrinterOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Row, Col, Button, Skeleton } from "antd";
import * as find from "lodash.find";
//import * as filter from 'lodash.filter';
import ReactHtmlParser from "react-html-parser";
// import { Link } from "react-router-dom";
import {
  CONSTANTS,
  TRANSACTION_TYPES,
  BUCKET_NAME,
  MODAL_TYPE,
  CONSTANTS_TRANSACTIONS,
} from "../../../../../static/constants";
// import config from "../../../../../config/environmentConfig";
// import { LogoComponent } from '../../../../general/LogoComponent';
import { getMomentDateForUIReadOnly } from "../../../../../utils";
//import { base64Toblob, getAWSFileURL } from '../../../../../utils';
//import {ImageCropper} from '../../../../../components/general/ImageCropper';
//import {CustomUploader} from "../../../../../components/general/CustomUploader";
import { AWSFileTable } from "../../../../general/AWSFileTable";
import InboundDeliveryCreate from "../../../../../containers/supplier/InboundDelivery";
import MultiCurrencyPurchaseInvoice from "../../../../../containers/supplier/invoice/MultiCurrencyPurchaseInvoice";
import StandardPurchaseInvoice from "../../../../../containers/supplier/invoice/StandardPurchaseInvoice";

const IDDetails = (props) => {
  const { inboundDeliveryData, companyInfo } = props;
  const { inboundDeliveryDetailsList } = inboundDeliveryData;
  const relationshipAddress =
    find(inboundDeliveryData.boLocationInboundDeliveryList, {
      locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
    }) || {};
  const billingAddress =
    find(inboundDeliveryData.boLocationInboundDeliveryList, {
      locationType: CONSTANTS.BILLING_ADDRESS,
    }) || {};
  const relShippingAddress =
    find(inboundDeliveryData.boLocationInboundDeliveryList, {
      locationType: CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS,
    }) || {};
  let isPlaceOfSupplySameAsRelationship = false;
  isPlaceOfSupplySameAsRelationship =
    (inboundDeliveryData.placeOfSupply &&
      relationshipAddress.stateName === inboundDeliveryData.placeOfSupply) ||
    false;
  let itemLevelTaxes = [];
  // if (inboundDeliveryDetailsList) {
  //     itemLevelTaxes = calculateTaxRateList(inboundDeliveryDetailsList, isPlaceOfSupplySameAsRelationship);
  // }
  let isColumnEmpty = {
    product: true,
    service: true,
    variant: true,
    description: true,
    qty: true,
    uomName: true,
    rate: true,
    hsn: true,
    discount: true,
    tax: true,
    specialDiscount: true,
  };
  if (inboundDeliveryData) {
    isColumnEmpty = updateColumnEmptyCheck(
      isColumnEmpty,
      inboundDeliveryData,
      props
    );
  }
  // const customProps = {
  //     type: CONSTANTS.TYPE_IMAGE,
  //     imgUrl: getAWSFileURL((inboundDeliveryData || {}).stampAwsKey, BUCKET_NAME.BO_INBOUND_DELIVERY) || '',
  //     height: 60,
  //     width: 60,
  //     name: 'file',
  //     action: null,
  //     buttonName: 'Upload Customer PO',
  //     onChange: (file) => {
  //         if (file.file.originFileObj) {
  //             var reader = new FileReader();

  //             reader.onload = function (e) {
  //                 props.showModal({
  //                     title: <Fragment>
  //                         <div className='ant-modal-title'>Crop Image</div>
  //                     </Fragment>,
  //                     //modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
  //                     modalBody: <ImageCropper onClick={(e) => {
  //                     }} handleSubmit={(newUrl) => {
  //                         props.uploadStamp({
  //                             supplierId: inboundDeliveryData.supplierId,
  //                             inboundDeliveryMasterId: inboundDeliveryData.inboundDeliveryMasterId,
  //                             rid: inboundDeliveryData.relationshipId,
  //                             objectKey: inboundDeliveryData.stampAwsKey,
  //                             bucketName: BUCKET_NAME.BO_INBOUND_DELIVERY,
  //                             file: base64Toblob(newUrl)
  //                         })
  //                     }}{...props} url={e.target.result}/>,
  //                     width: 800,
  //                     hideFooter: true,
  //                     wrapClassName: 'Upload-profile-modal'
  //                 })
  //             }

  //             reader.readAsDataURL(file.file.originFileObj); // convert to base64 string
  //         }
  //     }
  // }

  const getPayloadForPdf = () => {
    return {
      fileName:
        (inboundDeliveryData.inboundDeliveryNumber || "Inbound Delivery") +
        ".pdf",
      transactionName: "inboundDelivery",
      inboundDeliveryData: inboundDeliveryData,
      relationshipAddress: relationshipAddress,
      relShippingAddress: relShippingAddress,
      billingAddress: billingAddress,
      companyInfo: companyInfo,
      isColumnEmpty: isColumnEmpty,
      isPlaceOfSupplySameAsRelationship: isPlaceOfSupplySameAsRelationship,
      itemLevelTaxes: itemLevelTaxes,
      bucketName: BUCKET_NAME.BO_INBOUND_DELIVERY,
      stampAwsKey: inboundDeliveryData.stampAwsKey,
    };
  };

  const generatePDF = () => {
    let payload = getPayloadForPdf();
    props.generatePDF(payload);
  };

  const printPdf = () => {
    let payload = getPayloadForPdf();
    props.printPDF(payload);
  };

  const openEmailDrawer = (props) => {
    let payload = getPayloadForPdf();
    payload.fileDataReqeired = true;
    payload.supplierId = inboundDeliveryData.supplierId;
    payload.inboundDeliveryMasterId =
      inboundDeliveryData.inboundDeliveryMasterId;
    props.updateState({
      emailDrawerVisible: true,
      txData: payload,
      type: TRANSACTION_TYPES.INBOUND_DELIVERY,
    });
  };

  const addItemConfirmationModal = (props, text, color, txData) => {
    const { showModal, companyInfo } = props;
   
    const modalBody = (
      <span>
        <FormattedMessage
          id="setItem.confirmation"
          defaultMessage={``}
        />
        
      </span>
    );

    let payload = {
      userStatus: text,
      userStatusColor: color,
      relationshipId: companyInfo.relationshipId,
      inboundDeliveryMasterId: txData.inboundDeliveryMasterId,
      supplierId: txData.supplierId,
    };

    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.updateUserStatus(payload, props);
        props.hideModal();
        props.popModalFromStack();
      },
    };
    showModal(modalData);
  };

  const updateUserStatus = (props, txData) => {
    let payload = { formData: {}, txData };
    const formData = payload.formData;
    const { pushModalToStack } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      addItemConfirmationModal(props, formData.textBoxValue, txData);
    } else {
      const data = {
        title: (
          <FormattedMessage id="addItem.text.userStatus" defaultMessage="" />
        ),
        formData,
        hideFooter: true,
        modalData: {
          modalType: MODAL_TYPE.USER_STATUS,
          data: payload,
          transactionName: CONSTANTS_TRANSACTIONS.INBOUND_DELIVERY,
        },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            addItemConfirmationModal(
              props,
              formData.textBoxValue,
              formData.statusColor,
              txData
            );
          } else {
            data.formData.submittedOnce = true;
            pushModalToStack(data);
          }
        },
      };
      pushModalToStack(data);
    }
  };
  const [showComp, setShowComp] = useState(false);
  useEffect(()=>{
    const delay = setTimeout(()=>{
      setShowComp(true);
    }, 400);
    return ()=> clearTimeout(delay);
  }, []);
  return (
    <div className={"custom-modal show"}>
      <i className="close-icon">
        <svg
          width="17"
          height="17"
          onClick={() => {
            props.popModalFromStack();
          }}
        >
          <title>
            <FormattedMessage id="button.close.label" defaultMessage="" />
          </title>
          <path
            fill="#67757d"
            fillRule="evenodd"
            d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z"
          />
        </svg>
      </i>
      <div className="modal-dialog">
        <div className="modal-content full-width">
        {showComp ? 
          <><div className="modal-header align-left">
              {inboundDeliveryData.documentName || "Inbound Delivery"}
            </div><div className="inner-content">
                <Row>
                  <Col span={9}>
                    <div className="d-inline-flex w-100">
                      {/* <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()}/> */}
                      {/* <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3+'/'+ config.BUCKET_NAME.BO_RELATIONSHIP +'/'+(props.companyInfo || {}).logoAwsObjectKey + "?"+Date.now()}/> */}
                      <Skeleton loading={props.detailLoading} active paragraph={{ rows: 5 }}>

                        <div className="read-only-content pl10">
                          <div className="title lh-adjust pb5">
                            {props.companyInfo.storeName}
                          </div>
                          {/* {(relationshipAddress.streetAddress1 && relationshipAddress.streetAddress1+', ' ) || ''}
                    {(relationshipAddress.streetAddress2 && relationshipAddress.streetAddress2 +', ') || ''}
                    {(relationshipAddress.cityName && relationshipAddress.cityName+', ') || ''}
                    {(relationshipAddress.stateName && relationshipAddress.stateName+', ') || ''}
                    {(relationshipAddress.zipCode && relationshipAddress.zipCode+' ') || ''}
                    <div>
                        GSTIN - {companyInfo.gstNumber || ''}
                    </div>
                    <div>PAN - {companyInfo.panNumber || ''}</div> */}
                          <div>
                            {getAddressInfoFragment(relationshipAddress, companyInfo)}
                          </div>
                          {getTaxIdentificationsFragment(
                            (
                              (inboundDeliveryData.deliveryAdditionalInfoList ||
                                [])[0] || {}
                            ).relationshipTaxIdentifications
                          )}
                        </div>
                      </Skeleton>
                    </div>
                  </Col>

                  <Col span={8}>
                    <Skeleton loading={props.detailLoading} active paragraph={{ rows: 5 }}>

                      <div className="read-only-content">
                        <div className="title">
                          {getContactNameFragment(
                            inboundDeliveryData.additionalInfoList
                              ? inboundDeliveryData.additionalInfoList[0] || {}
                              : {}
                          )}
                          {inboundDeliveryData.supplierName}
                        </div>
                        {getAddressInfoFragment(
                          billingAddress,
                          inboundDeliveryData.additionalInfoList
                            ? inboundDeliveryData.additionalInfoList[0] || {}
                            : {}
                        )}
                        {getTaxIdentificationsFragment(
                          (
                            (inboundDeliveryData.deliveryAdditionalInfoList ||
                              [])[0] || {}
                          ).taxIdentifications
                        )}
                        {/* {(billingAddress.streetAddress1 && billingAddress.streetAddress1+', ' ) || ''}
                      {(billingAddress.streetAddress2 && billingAddress.streetAddress2 +', ') || ''}
                      {(billingAddress.cityName && billingAddress.cityName+', ') || ''}
                      {(billingAddress.stateName && billingAddress.stateName+', ') || ''}
                      {(billingAddress.zipCode && billingAddress.zipCode+' ') || ''}
                      {(contact && ( contact.workPhone || contact.cellPhone)) && <div>
                          Ph - {contact.workPhone || ''} {contact.cellPhone || ''}
                      </div>}
                      {( contact && contact.faxNumber) &&
                          <div>
                              Fax - {contact.faxNumber || ''}
                      </div>}
                      {(contact && contact.emailAddress) &&
                          <div>
                              Email - {contact.emailAddress || ''}
                      </div>}
                      {supplierData.webSite &&
                          <div>
                              Website - {supplierData.webSite || ''}
                      </div>} */}
                      </div>
                    </Skeleton>
                  </Col>

                  <Col span={6} offset={1}>
                    <Skeleton loading={props.detailLoading} active paragraph={{ rows: 5 }}>
                      <div className="read-only-content">
                        <Row>
                          <Col span={14} className="text-right">
                            <span className="title">
                              <FormattedMessage
                                id="modal.txDetails.inboundDelivery.inboundDeliveryNumber"
                                defaultMessage="" />
                            </span>
                          </Col>
                          <Col span={10}>
                            {inboundDeliveryData.inboundDeliveryNumber}
                          </Col>
                        </Row>

                        <Row>
                          <Col span={14} className="text-right">
                            <span className="title">
                              <FormattedMessage
                                id="modal.txDetails.inboundDelivery.poNumber"
                                defaultMessage="" />
                            </span>
                          </Col>
                          <Col span={10}>{inboundDeliveryData.poNumber}</Col>
                        </Row>

                        <Row>
                          <Col span={14} className="text-right">
                            <span className="title">
                              <FormattedMessage
                                id="modal.txDetails.inboundDelivery.inboundDeliveryDate"
                                defaultMessage="" />
                            </span>
                          </Col>
                          <Col span={10}>
                            {inboundDeliveryData.inboundDeliveryDate &&
                              getMomentDateForUIReadOnly({
                                date: inboundDeliveryData.inboundDeliveryDate,
                                format: CONSTANTS.DISPLAY_DATE_FORMAT,
                              })}
                          </Col>
                        </Row>

                        {/* {inboundDeliveryData.expectedDeliveryDate ?
                          <Row>
                              <Col span={14} className="text-right">
                                  <span className="title">Expected Delivery</span>
                              </Col>
                              <Col span={10}>
                                  {(inboundDeliveryData.expectedDeliveryDate && moment(inboundDeliveryData.expectedDeliveryDate).format(CONSTANTS.DISPLAY_DATE_FORMAT))}
                              </Col>
                          </Row> : ''
                      }

                      {
                          !!inboundDeliveryData.isMultiCurrency &&
                          <Row>
                              <Col span={14} className="text-right">
                                  <span className="title">Foreign Currency</span>
                              </Col>
                              <Col span={10}>
                                  {inboundDeliveryData.foreignCurrency}
                              </Col>
                          </Row>
                      }
                      {
                          !!inboundDeliveryData.isMultiCurrency &&
                          <Row>
                              <Col span={14} className="text-right">
                                  <span className="title">Exchange Rate</span>
                              </Col>
                              <Col span={10}>
                                  {inboundDeliveryData.exchangeRate}
                              </Col>
                          </Row>
                      }

                      {
                          inboundDeliveryData.isDropShipping ?
                          <Row>
                              <Col span={14} className="text-right">
                                  <span className="title">Dropship Order?</span>
                              </Col>
                              <Col span={10}>
                                  Yes
                              </Col>
                          </Row> : null
                      } */}

                        {inboundDeliveryData.rfqNumber ? (
                          <Row>
                            <Col span={14} className="text-right">
                              <span className="title">
                                <FormattedMessage
                                  id="modal.txDetails.inboundDelivery.rfqNumber"
                                  defaultMessage="" />
                              </span>
                            </Col>
                            <Col span={10}>{inboundDeliveryData.rfqNumber}</Col>
                          </Row>
                        ) : ''}
                        {inboundDeliveryData.customerId ? (
                          <Row>
                            <Col span={14} className="text-right">
                              <span className="title">
                                <FormattedMessage
                                  id="common.customer.text"
                                  defaultMessage="" />
                              </span>
                            </Col>
                            <Col span={10}>
                              {inboundDeliveryData.customerId
                                ? inboundDeliveryData.customerName
                                : "-"}
                            </Col>
                          </Row>
                        ) : ''}

                        {/* {
                          inboundDeliveryData.projectNumber &&
                          <Row>
                              <Col span={14} className="text-right">
                                  <span className="title">Project</span>
                              </Col>
                              <Col span={10}>
                                  {inboundDeliveryData.projectNumber +' ('+inboundDeliveryData.projectName+ ')'}
                              </Col>
                          </Row>
                      } */}
                      </div>
                    </Skeleton>
                  </Col>
                </Row>
                <Skeleton loading={props.detailLoading} active paragraph={{ rows: 5 }}>

                  <div className="modal-table-container vertical-space">
                    <table>
                      <thead>
                        <tr>
                          {inboundDeliveryData.supplierQuoteNumber && (
                            <th>
                              <FormattedMessage
                                id="modal.txDetails.inboundDelivery.supplierQuoteNumber"
                                defaultMessage="" />
                            </th>
                          )}
                          <th>
                            <FormattedMessage
                              id="modal.txDetails.common.refNumber"
                              defaultMessage="" />
                          </th>
                          {props.companyInfo.countryName === "India" && (
                            <th>
                              <FormattedMessage
                                id="modal.txDetails.common.placeOfSupply"
                                defaultMessage="" />
                            </th>
                          )}
                          <th>
                            <FormattedMessage
                              id="modal.txDetails.common.shippingAddress"
                              defaultMessage="" />
                          </th>
                          <th>
                            <FormattedMessage
                              id="modal.txDetails.common.project"
                              defaultMessage="" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {inboundDeliveryData.supplierQuoteNumber && (
                            <th>{inboundDeliveryData.supplierQuoteNumber || "-"}</th>
                          )}
                          <td>{inboundDeliveryData.reference || "-"}</td>
                          {props.companyInfo.countryName === "India" && (
                            <td>{inboundDeliveryData.placeOfSupply || "-"}</td>
                          )}
                          <td>
                            {relShippingAddress
                              ? (relShippingAddress.streetAddress1 || "") +
                              " " +
                              (relShippingAddress.streetAddress2 || "")
                              : "-"}
                          </td>
                          <td>{inboundDeliveryData.projectName || "-"}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="modal-table-container mb15">
                    <table className="transaction-details-table">
                      <thead>
                        <tr>
                          <th className="cell-size-50">#</th>
                          {(!isColumnEmpty.product || !isColumnEmpty.description) && (
                            <th>
                              {!isColumnEmpty.product && (
                                <span>
                                  {" "}
                                  <FormattedMessage
                                    id="modal.txDetails.common.product"
                                    defaultMessage="" />
                                </span>
                              )}
                              {!isColumnEmpty.product &&
                                !isColumnEmpty.description && <span> & </span>}
                              {!isColumnEmpty.description && (
                                <span>
                                  {" "}
                                  <FormattedMessage
                                    id="modal.txDetails.common.description"
                                    defaultMessage="" />
                                </span>
                              )}
                            </th>
                          )}
                          {/* {!isColumnEmpty.variant && <th>Variant</th>} */}
                          {!isColumnEmpty.qty && (
                            <th className="cell-size-100">
                              <FormattedMessage
                                id="modal.txDetails.common.qty"
                                defaultMessage="" />
                            </th>
                          )}
                          {/* {!isColumnEmpty.uomName && <th>UOM</th>} */}
                          {/* {!isColumnEmpty.specialDiscount && <th className="right-text">Base Price</th>}
                        {!isColumnEmpty.specialDiscount && <th className="right-text">Special Discount</th>}
                        {!isColumnEmpty.rate && <th className="right-text">Rate</th>} */}
                          {!isColumnEmpty.hsn && (
                            <th>
                              <FormattedMessage
                                id="modal.txDetails.common.hsn"
                                defaultMessage="" />
                            </th>
                          )}
                          {/* {!isColumnEmpty.discount && <th className="right-text">Discount</th>}
                        {!isColumnEmpty.tax && <th className="right-text">Tax</th>}
                        <th className="right-text">Net Amount</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {(inboundDeliveryDetailsList || []).sort((a, b) => Number(a.sNo) > (b.sNo) ? 1 : -1)?.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td className="cell-size-50">{i + 1}</td>
                              {(!isColumnEmpty.product ||
                                !isColumnEmpty.description) && (
                                  <td>
                                    <strong>{getVariantFragment(item)}</strong>
                                    {!isColumnEmpty.description && (
                                      <div className="description-cell">
                                        <pre>
                                          {ReactHtmlParser(item.description || "-")}
                                        </pre>
                                      </div>
                                    )}
                                  </td>
                                )}
                              {/* {!isColumnEmpty.variant && <td>{item.itemVariantName || '-'}</td>} */}
                              {!isColumnEmpty.qty && (
                                <td className="cell-size-100">
                                  {item.qtyReceived +
                                    (item.uomName ? " " + item.uomName : "")}
                                </td>
                              )}
                              {/* {!isColumnEmpty.uomName && <td>{item.uomName || '-'}</td>} */}
                              {/* {!isColumnEmpty.specialDiscount && <td className="right-text">{item.basePrice.toFixed(2) || '-'}</td>}
                                    {!isColumnEmpty.specialDiscount && <td className="right-text">{getSpecialDiscount(item, 'amountPercent')}</td>}
                                    {!isColumnEmpty.rate && <td className="right-text">{item.anItemPurchasePrice.toFixed(2) || '-'}</td>} */}
                              {!isColumnEmpty.hsn && <td>{item.hsnCode || "-"}</td>}
                              {/* {!isColumnEmpty.discount && <td>{getTxTypeData(item, 'discount', 'amount')} {getTxTypeData(item, 'discount', 'amountPercent')}</td>}
                                    {!isColumnEmpty.tax && <td className="right-text">{getTotalTaxAmount(item)} {getTotalTaxPercent(item, 'amountPercent')}</td>}
                                    <td className="right-text">{getNetAmount(item) || '-'}</td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <Row>
                    <Col span={9}>
                      {/* {inboundDeliveryData.docDetailList && inboundDeliveryData.docDetailList.length ?
                <Row>
                        <Col span={24} className="title">
                        <FormattedMessage id='modal.txDetails.common.attachments' defaultMessage='' />
                        </Col>
                        <Col span={12}>
                            {
                                inboundDeliveryData.docDetailList.map((doc) => {
                                    return <span className={"cursor-pointer"} onClick={()=>{
                                        props.downloadFileFromBucket({
                                            bucketName: doc.bucketName,
                                            awsKeyName: doc.awsKeyName,
                                            fileName: doc.fileName
                                        })
                                    }}>{doc.fileName} &nbsp;</span>
                                })
                            }
                        </Col>
                    </Row> : null} */}
                      {inboundDeliveryData.docDetailList &&
                        inboundDeliveryData.docDetailList.length ? (
                        <Row>
                          <Col span={24} className="title">
                            <FormattedMessage
                              id="modal.txDetails.common.attachments"
                              defaultMessage="" />
                          </Col>
                          <Col span={24}>
                            <AWSFileTable
                              {...props}
                              docDetailList={inboundDeliveryData.docDetailList}
                              fetchTxDetail={(data) => {
                                props.fetchInboundryDetailDetail({
                                  supplierId: inboundDeliveryData.supplierId,
                                  inboundDeliveryMasterId: inboundDeliveryData.inboundDeliveryMasterId,
                                  relationshipId: inboundDeliveryData.relationshipId,
                                  poMasterId: inboundDeliveryData.poMasterId,
                                });
                              } } />
                            {/* <table style={{ width: "100%" }}>
                                      <thead>
                                          <tr>
                                              <th>File Name</th>
                                              <th>Size</th>
                                              <th>Type</th>
                                              <th>Upload By</th>
                                              <th>Date</th>
                                              <th>Action</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {inboundDeliveryData.docDetailList.map((doc, i) => {
                                              return (
                                                  <tr key={i}>
                                                      <td>{doc.fileName}</td>
                                                      <td>{doc.fileSize}</td>
                                                      <td>{doc.fileType}</td>
                                                      <td>{doc.createdByName || ''}</td>
                                                      <td>{doc.dateCreated ? getMomentDateForUIReadOnly({ date: doc.dateCreated, format: CONSTANTS.DISPLAY_DATE_FORMAT }) : ''}</td>
                                                      <td><span className={"cursor-pointer"} onClick={() => {
                                                          props.downloadFileFromBucket({
                                                              bucketName: doc.bucketName,
                                                              awsKeyName: doc.awsKeyName,
                                                              fileName: doc.fileName
                                                          })
                                                      }}>Download</span></td>
                                                  </tr>
                                              );
                                          })}
                                      </tbody>
                                  </table> */}
                          </Col>
                        </Row>
                      ) : null}

                      {/* {inboundDeliveryData.shippingInstructions &&
                        <Row className="read-only-content">
                            <Col span={7} className="title">
                                Shipping Instructions
                            </Col>
                            <Col span={17}>
                                {ReactHtmlParser(inboundDeliveryData.shippingInstructions)}
                            </Col>
                        </Row>
                    } */}

                      {inboundDeliveryData.remarksInternal && (
                        <><Row className="read-only-content">
                          <Col span={7} className="remarks-heading">
                            <FormattedMessage
                              id="modal.txDetails.common.internalRemarks"
                              defaultMessage="" />
                          </Col>
                        </Row>
                          <Row gutter={[16, 16]}>
                            <Col span={17}>
                              {ReactHtmlParser(inboundDeliveryData.remarksInternal)}
                            </Col>
                          </Row></>
                      )}

                      {inboundDeliveryData.remarksSupplier && (
                        <><Row className="read-only-content">
                          <Col span={7} className="remarks-heading">
                            <FormattedMessage
                              id="modal.txDetails.common.supplierRemarks"
                              defaultMessage="" />
                          </Col>
                        </Row><Col span={17}>
                            {ReactHtmlParser(inboundDeliveryData.remarksSupplier)}
                          </Col></>
                      )}
                    </Col>

                    {/* <Col offset={7} span={8}>
                      <div className="bill-summary mt5">
                          <Row>
                              <Col span={14}>
                                  <div className="right-txt">
                                      Sub Total
                                  </div>
                              </Col>

                              <Col span={10}>
                                  {inboundDeliveryData.isMultiCurrency ?
                                      <span> {inboundDeliveryData.foreignCurrencyIcon ? <i className={inboundDeliveryData.foreignCurrencyIcon}></i> : inboundDeliveryData.foreignCurrency} </span>
                                      : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                  }  {getSubTotalOrTaxOrDiscountAmount(inboundDeliveryData, "SubTotal")}
                              </Col>
                          </Row>

                          <div className="pt10">
                              <Row>
                                  <Col span={14}>
                                      <div className="right-txt">
                                          Discount
                                      </div>
                                  </Col>

                                  <Col span={10}>
                                      {inboundDeliveryData.isMultiCurrency ?
                                          <span> {inboundDeliveryData.foreignCurrencyIcon ? <i className={inboundDeliveryData.foreignCurrencyIcon}></i> : inboundDeliveryData.foreignCurrency} </span>
                                          : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                      }    {getSubTotalOrTaxOrDiscountAmount(inboundDeliveryData, "Discount")}
                                  </Col>
                              </Row>
                          </div>
                          { getTxTypeData(inboundDeliveryData, 'expense', 'amount') ?
                              <div className="pt10">
                                  <Row>
                                      <Col span={14}>
                                          <div className="right-txt">
                                              Expense
                                      </div>
                                      </Col>

                                      <Col span={10}>
                                          {inboundDeliveryData.isMultiCurrency ?
                                              <span> {inboundDeliveryData.foreignCurrencyIcon ? <i className={inboundDeliveryData.foreignCurrencyIcon}></i> : inboundDeliveryData.foreignCurrency} </span>
                                              : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                          }
                                          {getTxTypeData(inboundDeliveryData, 'expense', 'amount')}
                                      </Col>
                                  </Row>
                              </div> : ''
                          }
                          {
                              (itemLevelTaxes && itemLevelTaxes.length !== 0) && itemLevelTaxes.map((taxObj, i) => {

                                  return (
                                      props.companyInfo.countryName === 'India' ? (isPlaceOfSupplySameAsRelationship ? <div key={i}>
                                          <div className="pt25">
                                              <Row>
                                                  <Col span={14}>
                                                      <div className="right-txt">CGST @ {taxObj.taxPercent.toFixed(2) / 2}% on {taxObj.subspecialDiscount(total)} </div>
                                                  </Col>
                                                  <Col span={10}>
                                                      {inboundDeliveryData.isMultiCurrency ?
                                                          <span> {inboundDeliveryData.foreignCurrencyIcon ? <i className={inboundDeliveryData.foreignCurrencyIcon}></i> : inboundDeliveryData.foreignCurrency} </span>
                                                          : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                      }
                                                      {(taxObj.taxAmount / 2).toFixed(2)}
                                                  </Col>
                                              </Row>
                                          </div>

                                          <div className="pt25">
                                              <Row>
                                                  <Col span={14}>
                                                      <div className="right-txt">SGST @ {taxObj.taxPercent / 2}% on {taxObj.subtotal} </div>
                                                  </Col>
                                                  <Col span={10}>
                                                      {inboundDeliveryData.isMultiCurrency ?
                                                          <span> {inboundDeliveryData.foreignCurrencyIcon ? <i className={inboundDeliveryData.foreignCurrencyIcon}></i> : inboundDeliveryData.foreignCurrency} </span>
                                                          : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                      }
                                                      {(taxObj.taxAmount / 2).toFixed(2)}
                                                  </Col>
                                              </Row>
                                          </div>
                                      </div> : <div key={i}>
                                              <div className="pt25">
                                                  <Row>
                                                      <Col span={14}>
                                                          <div className="right-txt">{taxObj.taxName} @ {taxObj.taxPercent.toFixed(2)}% on {taxObj.subspecialDiscount(total)} </div>
                                                      </Col>
                                                      <Col span={10}>
                                                          {inboundDeliveryData.isMultiCurrency ?
                                                              <span> {inboundDeliveryData.foreignCurrencyIcon ? <i className={inboundDeliveryData.foreignCurrencyIcon}></i> : inboundDeliveryData.foreignCurrency} </span>
                                                              : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                          }
                                                          {(taxObj.taxAmount).toFixed(2)}
                                                      </Col>
                                                  </Row>
                                              </div>
                                          </div>
                                      ) :
                                          <div key={i}>
                                              <div className="pt25">
                                                  <Row>
                                                      <Col span={14}>
                                                          <div className="right-txt">{taxObj.taxName} @ {taxObj.taxPercent.toFixed(2)}% on {taxObj.subspecialDiscount(total)} </div>
                                                      </Col>
                                                      <Col span={10}>
                                                          {inboundDeliveryData.isMultiCurrency ?
                                                              <span> {inboundDeliveryData.foreignCurrencyIcon ? <i className={inboundDeliveryData.foreignCurrencyIcon}></i> : inboundDeliveryData.foreignCurrency} </span>
                                                              : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                          }
                                                          {(taxObj.taxAmount).toFixed(2)}
                                                      </Col>
                                                  </Row>
                                              </div>
                                          </div>
                                  )
                              })
                          }


                          <div className="pt10">
                              <Row>
                                  <Col span={14}>
                                      <div className="right-txt bold">
                                          Grand Total
                                      </div>
                                  </Col>

                                  <Col span={10}>
                                      {inboundDeliveryData.isMultiCurrency ?
                                          <span> {inboundDeliveryData.foreignCurrencyIcon ? <i className={inboundDeliveryData.foreignCurrencyIcon}></i> : inboundDeliveryData.foreignCurrency} </span>
                                          : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                      }  {(inboundDeliveryData.totalPOAmount && inboundDeliveryData.totalPOAmount.toFixed(2)) || ''}
                                  </Col>
                              </Row>
                          </div>
                      </div>

                      <div className="upload-link">
                          Upload Stamp/Signature
                      </div>
                  </Col> */}
                    {/* <Col offset={7} span={8}>
                  <div className="upload-link">
                          <CustomUploader {...customProps} />
                          {inboundDeliveryData.stampAwsKey && <span onClick={() => {
                              props.deleteStamp({
                                  supplierId: inboundDeliveryData.supplierId,
                                  inboundDeliveryMasterId: inboundDeliveryData.inboundDeliveryMasterId,
                                  relationshipId: inboundDeliveryData.relationshipId,
                                  objectKey: inboundDeliveryData.stampAwsKey
                              }, props)
                          }}> Delete Stamp </span>}
                      </div>
                      </Col> */}
                  </Row>

                  <div className="contact-details">
                    {inboundDeliveryData.footer && (
                      <Fragment>
                        {ReactHtmlParser(inboundDeliveryData.footer)}
                      </Fragment>
                    )}
                  </div>
                </Skeleton>
              </div></>
:""}
          <div className=" button-group-action header-control">
            <div className="small-btn-group">
              <Button
                style={{ height: "38px" }}
                title={props.intl.formatMessage({
                  id: "modal.txDetails.common.edit",
                  defaultMessage: "",
                })}
                icon={<EditOutlined />}
                onClick={() => {
                  props.popModalFromStack();
                  props.pushModalToStack({
                    modalBody: (
                      <InboundDeliveryCreate
                        {...props}
                        inboundDeliveryEditData = {inboundDeliveryData}
                        supplierId={inboundDeliveryData.supplierId}
                        inboundDeliveryMasterId={
                          inboundDeliveryData.inboundDeliveryMasterId
                        }
                        update={true}
                      />
                    ),
                    width: "100%",
                    hideTitle: true,
                    hideFooter: true,
                    wrapClassName: "new-transaction-wrapper",
                  });
                  // props.history.push({
                  //     pathname: '/admin/inbound-delivery-update',
                  //     state: {
                  //         supplierId: inboundDeliveryData.supplierId,
                  //         inboundDeliveryMasterId: inboundDeliveryData.inboundDeliveryMasterId,
                  //         update: true
                  //     }
                  // })
                }}
              ></Button>
              {/* <Button
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.clone', defaultMessage: 'Clone' })}
                                    icon={<CopyOutlined />}
                                    onClick={() => {
                                        props.popModalFromStack();
                                        props.history.push({
                                            pathname: '/admin/inbound-delivery-update',
                                            state: {
                                                supplierId: inboundDeliveryData.supplierId,
                                                inboundDeliveryMasterId: inboundDeliveryData.inboundDeliveryMasterId,
                                                clone: true
                                            }
                                        })
                                    }}
                                >
                                </Button> */}
              <Button
                style={{ height: "38px" }}
                title={props.intl.formatMessage({
                  id: "modal.txDetails.common.delete",
                  defaultMessage: "",
                })}
                icon={<DeleteOutlined />}
                onClick={() => {
                  props.showModal({
                    modalBody: (
                      <FormattedMessage
                        id="modal.txDetails.common.delete.confirmation"
                        defaultMessage=""
                      />
                    ),
                    handleSubmit: () => {
                      props.popModalFromStack();
                      props.deleteInboundDelivery(
                        {
                          supplierId: inboundDeliveryData.supplierId,
                          inboundDeliveryMasterId:
                            inboundDeliveryData.inboundDeliveryMasterId,
                          relationshipId: inboundDeliveryData.relationshipId,
                          poMasterId: inboundDeliveryData.poMasterId,
                        },
                        props
                      );
                    },
                  });
                }}
              ></Button>
            </div>
            <div className="small-btn-group">
              <Button
                style={{ height: "38px" }}
                title={props.intl.formatMessage({
                  id: "modal.txDetails.common.email",
                  defaultMessage: "",
                })}
                icon={<MailOutlined />}
                onClick={() => {
                  openEmailDrawer(props);
                }}
              ></Button>

              <Button
                style={{ height: "38px" }}
                title={props.intl.formatMessage({
                  id: "modal.txDetails.common.downloadPDF",
                  defaultMessage: "",
                })}
                icon={<FilePdfOutlined />}
                onClick={() => {
                  generatePDF();
                }}
              ></Button>
              <Button
                style={{ height: "38px" }}
                title={props.intl.formatMessage({
                  id: "modal.txDetails.common.print",
                  defaultMessage: "",
                })}
                icon={<PrinterOutlined />}
                onClick={() => {
                  printPdf();
                }}
              ></Button>
            </div>
            {/* <div className="small-btn-group">
                                <Button title='Audit Trail'
                                    icon={<HistoryOutlined />}
                                    onClick={() => {
                                        openEmailDrawer(props);
                                    }}
                                >
                                </Button>
                            </div> */}
            {inboundDeliveryData.statusInvoice !== "invoiced" ? (
              <Button
                style={{ height: "38px" }}
                onClick={() => {
                  // props.popModalFromStack();
                  props.hideModal();
                }}
                type="primary"
              >
                <div
                  onClick={() => {
                    props.popModalFromStack();
                    inboundDeliveryData.exchangeRate
                      ? //  '/admin/purchase-invoice-multi-currency'
                        props.pushModalToStack({
                          modalBody: (
                            <MultiCurrencyPurchaseInvoice
                              {...props}
                              supplierId={inboundDeliveryData.supplierId}
                              inboundDeliveryMasterId={
                                inboundDeliveryData.inboundDeliveryMasterId
                              }
                            />
                          ),
                          width: "100%",
                          hideTitle: true,
                          hideFooter: true,
                          wrapClassName: "new-transaction-wrapper",
                        })
                      : //  '/admin/purchase-invoice',
                        props.pushModalToStack({
                          modalBody: (
                            <StandardPurchaseInvoice
                              {...props}
                              supplierId={inboundDeliveryData.supplierId}
                              inboundDeliveryMasterId={
                                inboundDeliveryData.inboundDeliveryMasterId
                              }
                            />
                          ),
                          width: "100%",
                          hideTitle: true,
                          hideFooter: true,
                          wrapClassName: "new-transaction-wrapper",
                        });
                  }}
                  // to={
                  //         {
                  //             pathname: inboundDeliveryData.exchangeRate?
                  //              '/admin/purchase-invoice-multi-currency'
                  //             // props.pushModalToStack({
                  //             //     modalBody: <MultiCurrencyPurchaseInvoice {...props} supplierId={inboundDeliveryData.supplierId}   inboundDeliveryMasterId={inboundDeliveryData.inboundDeliveryMasterId}/>,
                  //             //     width: '100%',
                  //             //     hideTitle: true,
                  //             //     hideFooter: true,
                  //             //     wrapClassName: 'new-transaction-wrapper'
                  //             // })
                  //              :
                  //              '/admin/purchase-invoice',
                  //             // props.pushModalToStack({
                  //             //     modalBody: <StandardPurchaseInvoice {...props} supplierId={inboundDeliveryData.supplierId}   inboundDeliveryMasterId={inboundDeliveryData.inboundDeliveryMasterId} />,
                  //             //     width: '100%',
                  //             //     hideTitle: true,
                  //             //     hideFooter: true,
                  //             //     wrapClassName: 'new-transaction-wrapper'
                  //             // })
                  //             state: {
                  //                 inboundDeliveryMasterId: inboundDeliveryData.inboundDeliveryMasterId,
                  //                 supplierId: inboundDeliveryData.supplierId
                  //             }
                  //         }
                  //     }
                >
                  <FormattedMessage
                    id="modal.txDetails.inboundDelivery.convertToInvoice"
                    defaultMessage=""
                  />
                </div>
              </Button>
            ) : (
              ""
            )}
            <Button
              style={{ height: "38px" }}
              onClick={() => {
                updateUserStatus(props, inboundDeliveryData);
              }}
            >
              <FormattedMessage
                id="modal.txDetails.common.updateStatus"
                defaultMessage=""
              />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

// const getSubTotalOrTaxOrDiscountAmount = (data, type) => {
//     let total = 0;
//     data && data.inboundDeliveryDetailsList && data.inboundDeliveryDetailsList.forEach(function (d) {
//         if (type === 'SubTotal') {
//             total = total + d.anItemPurchasePrice * d.quantity;
//         }
//         if (type === 'Discount') {
//             let discountObject = d.supplierPOCOATxList.find(tx => (tx.txType === 'discount'));
//             if (discountObject) {
//                 total = total + parseFloat(discountObject.amount);
//             }
//         }
//     });
//     return specialDiscount(total);
// }

// const getTxTypeData = (row, type, toReturn) => {
//   if(!row || !row.supplierPOCOATxList) return;
//   let data = row.supplierPOCOATxList.find(tx => (tx.txType === type));
//   if(data){
//     if(data[toReturn] && toReturn === 'amountPercent'){
//       if(type === 'VAT'){
//         return "VAT("+data[toReturn]+"%)";
//       }
//       return "("+data[toReturn]+"%)";
//     }else if(data[toReturn] && toReturn === 'amount'){
//       return (Number(data[toReturn])).toFixed(2);
//     }else{
//       return ""
//     }

//   }else if(!data && toReturn === 'amount'){
//     return "";
//   }
//   else{
//     return "-";
//   }
// }
// const getTotalTaxAmount = (row) => {
//   let tax1 = getTxTypeData(row, 'tax', 'amount', true) || 0;
//  // let tax2 = getTxTypeData(row, 'sgst', 'amount', true) || 0;
//  // let tax3 = getTxTypeData(row, 'igst', 'amount', true) || 0;
//  // let tax4 = getTxTypeData(row, 'vat', 'amount', true) || 0;
//   let totalTax = Number(tax1);
//   return totalTax;

// }

// const getTotalTaxPercent = (row, toReturn) => {
//     let tax1 = row.supplierPOCOATxList.find(tx => (tx.txType === 'tax')) || {};
//     //let tax2 = row.supplierPOCOATxList.find(tx => (tx.txType === 'sgst')) || {};
//     //let tax3 = row.supplierPOCOATxList.find(tx => (tx.txType === 'igst')) || {};
//    // let tax4 = row.supplierPOCOATxList.find(tx => (tx.txType === 'vat')) || {};
//     let totalPercent = Number(tax1.amountPercent || 0);
//     if (toReturn === 'amountPercent') {
//         return totalPercent ? ('(' + totalPercent + "%)") : '';
//     }
//     else {
//         return totalPercent;
//     }
// }

// const getNetAmount = (row) => {
//   let tax = getTotalTaxAmount(row) || 0;
//   let discount = getTxTypeData(row, 'discount', 'amount', true) || 0;
//   let netAmountItem = (Number(row.quantity * row.anItemPurchasePrice)+Number(tax) - Number(discount));
//   return fixedDecimalNumber(netAmountItem);

// }

// const calculateTaxRateList = (inboundDeliveryDetailsList, isPlaceOfSupplySameAsRelationship) =>{
//   let subtotal = 0;
//   let totalDiscount = 0;
//   let totalTax = 0;
//   let itemLevelTaxes = []
//   inboundDeliveryDetailsList.forEach(function (rowdata, index) {
//       let totalAmountOfColumn = 0;
//       if (rowdata.quantity && rowdata.anItemPurchasePrice) {
//           let taxObj = {
//               subtotal: 0,
//               taxPercent: 0,
//               taxAmount: 0
//           }
//           totalAmountOfColumn = rowdata.quantity * rowdata.anItemPurchasePrice;
//           let discount = getTxTypeData(rowdata, 'discount', 'amount');
//           if (discount) {
//               totalAmountOfColumn = totalAmountOfColumn - discount;
//               totalDiscount = totalDiscount + discount;
//           }

//           let tax1 = rowdata.supplierPOCOATxList.find(tx => (tx.txType === 'tax')) || {};
//           taxObj.taxPercent = Number(tax1.amountPercent);
//           taxObj.taxAmount = Number(tax1.amount);
//           totalTax = totalTax + taxObj.taxAmount;
//           taxObj.taxName = tax1.taxName;
//           //let tax2 = rowdata.supplierPOCOATxList.find(tx => (tx.txType === 'sgst'));
//           //let tax3 = rowdata.supplierPOCOATxList.find(tx => (tx.txType === 'igst'));
//          // let tax4 = rowdata.supplierPOCOATxList.find(tx => (tx.txType === 'vat'));
//         //   if (isPlaceOfSupplySameAsRelationship && tax1 && tax2) {
//         //       taxObj.taxPercent = Number(tax1.amountPercent) + Number(tax2.amountPercent);
//         //       taxObj.taxAmount = Number(tax1.amount) + Number(tax2.amount);
//         //       totalTax = totalTax + taxObj.taxAmount;
//         //   }
//         //   else if (!isPlaceOfSupplySameAsRelationship && tax3) {
//         //       taxObj.taxPercent = Number(tax3.amountPercent);
//         //       taxObj.taxAmount = Number(tax3.amount);
//         //       totalTax = totalTax + taxObj.taxAmount;
//         //   }
//         //   else if (tax4) {
//         //     taxObj.taxPercent = Number(tax4.amountPercent);
//         //     taxObj.taxAmount = Number(tax4.amount);
//         //     totalTax = totalTax + taxObj.taxAmount;
//         // }
//           if (taxObj.taxPercent) {
//               taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
//               const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
//               if (availableTaxObj) {
//                   itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
//                       if (ele.taxPercent === taxObj.taxPercent) {
//                           ele.subtotal = ele.subtotal + taxObj.subtotal;
//                           ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
//                       }
//                       return true;
//                   });
//               } else {
//                   itemLevelTaxes.push(taxObj);
//               }
//           }
//           subtotal = subtotal + totalAmountOfColumn;
//       }
//   });
//   return itemLevelTaxes;
// }

const updateColumnEmptyCheck = (isColumnEmpty, inboundDeliveryData, props) => {
  if (!inboundDeliveryData) return isColumnEmpty;
  for (let i in inboundDeliveryData.inboundDeliveryDetailsList) {
    let row = inboundDeliveryData.inboundDeliveryDetailsList[i];
    if (row.itemName) {
      isColumnEmpty.product = false;
    }
    if (row.serviceName) {
      isColumnEmpty.service = false;
    }
    if (row.itemVariantName) {
      isColumnEmpty.variant = false;
    }
    if (row.description) {
      isColumnEmpty.description = false;
    }
    if (row.qtyReceived) {
      isColumnEmpty.qty = false;
    }
    if (row.uomName) {
      isColumnEmpty.uomName = false;
    }
    if (row.anItemPurchasePrice) {
      isColumnEmpty.rate = false;
    }
    if (row.specialDiscount) {
      isColumnEmpty.specialDiscount = false;
    }
    if (row.hsnCode && props.companyInfo.countryName === "India") {
      isColumnEmpty.hsn = false;
    }
    if (!isTxTypeEmpty(row, "discount")) {
      isColumnEmpty.discount = false;
    }
    if (!isTxTypeEmpty(row, "tax")) {
      isColumnEmpty.tax = false;
    }
    //if (!isTxTypeEmpty(row, 'sgst')) { isColumnEmpty.tax = false; }
    //if (!isTxTypeEmpty(row, 'igst')) { isColumnEmpty.tax = false; }
    //if (!isTxTypeEmpty(row, 'vat')) { isColumnEmpty.tax = false; }
    if (row.documentDetails && row.documentDetails.length) {
      isColumnEmpty.documentDetails = false;
    }
  }
  return isColumnEmpty;
};

const isTxTypeEmpty = (row, type) => {
  if (!row || !row.supplierPOCOATxList) return true;
  let data = row.supplierPOCOATxList.find((tx) => tx.txType === type);
  if (data && data.amount) {
    return false;
  } else {
    return true;
  }
};

// const getSpecialDiscount = (row, toReturn) => {
//   if (!row) return;
//   let specialDiscount = row.basePrice - row.anItemPurchasePrice;
//   let percent, amount = 0;
//   if (specialDiscount && row.basePrice) {
//     if (toReturn === 'amountPercent') {
//        percent = ((specialDiscount * 100)/row.basePrice).toFixed(2);
//        amount = (specialDiscount).toFixed(2);;
//       return amount +" ("+ percent+")%";
//     } else if (toReturn === 'amount') {
//       return (specialDiscount).toFixed(2);;
//     }

//   } else if (!row && toReturn === 'amount') {
//     return 0;
//   }
//   else {
//     return "-";
//   }
// };

const getAddressInfoFragment = (obj, additionInfo) => {
  const fragmentObj = (
    <Fragment>
       <div className="billing-address first-caps">
            {obj.streetAddress1 || obj.streetAddress2 ? (

                <div>
                    {obj.streetAddress1} {obj.streetAddress2}{", "}
                </div>

            ) : ""}
            {obj.cityName || obj.stateName || obj.zipCode ? (

                <div>
                    {obj.cityName} {obj.stateName}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
                </div>

            ) : ""}
             {obj.countryName ? (
     <div>{obj.countryName}</div>
    ):""}
        </div>
      {additionInfo.workPhone ? (
        <div className="billing-address">
          <FormattedMessage id="phone" defaultMessage="" /> -{" "}
          {additionInfo.workPhone}
        </div>
      ) : (
        ""
      )}
      {additionInfo.email ? (
        <div className="billing-address">
          <FormattedMessage id="email" defaultMessage="" /> -{" "}
          {additionInfo.email}
        </div>
      ) : (
        ""
      )}
      {/* {
            additionInfo.gstNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='gstIn' defaultMessage='' /> - {additionInfo.gstNumber}
                </div> :
                ''
        }
        {
            additionInfo.panNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='pan' defaultMessage='' /> - {additionInfo.panNumber}
                </div> :
                ''
        } */}
    </Fragment>
  );
  return fragmentObj;
};

const getTaxIdentificationsFragment = (taxIdentifications) => {
  if (!taxIdentifications) return <Fragment />;
  let taxIdentificationsList = [];
  if (taxIdentifications) {
    taxIdentificationsList = JSON.parse(taxIdentifications);
  }
  const fragmentObj = (
    <Fragment>
      {taxIdentificationsList.map((obj, i) => {
        return (
          <div className="billing-address">
            {obj.taxIdentificationType || ""} - {obj.taxIdentificationNumber}
          </div>
        );
      })}
    </Fragment>
  );

  return fragmentObj;
};

const getContactNameFragment = (additionInfo) => {
  if (!additionInfo) return <Fragment />;
  let contactName = "";
  if (additionInfo.salutationName)
    contactName += additionInfo.salutationName + " ";
  if (additionInfo.firstName) contactName += additionInfo.firstName;
  if (additionInfo.middleName) contactName += " " + additionInfo.middleName;
  if (additionInfo.lastName) contactName += " " + additionInfo.lastName;
  const fragmentObj = (
    <Fragment>
      {contactName && contactName.length ? (
        <div className="billing-address">{contactName}</div>
      ) : (
        ""
      )}
    </Fragment>
  );
  return fragmentObj;
};

const getVariantFragment = (item) => {
  let itemName = null;
  if (item.itemName) {
    itemName = item.itemName;
  }
  if (item.itemVariantName) {
    itemName = itemName + " (" + item.itemVariantName + ")";
  }
  return itemName ? itemName : "";
};
export default IDDetails;
