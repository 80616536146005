// import React, { Fragment } from 'react';
import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, MEMBERSHIP_ACTION_LIST } from '../../../static/constants'
// import { showToasterMessage } from '../../../utils';
// const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');




export const fetchNewsLetter = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    dispatch({ type: MEMBERSHIP_ACTION_LIST.NEWS_LETTER_TEMPLATE, data: {} });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/formBuilder/getFormById?relationshipId=${payload.relationshipId}&alpideFormBuilderId=${payload.templateId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: MEMBERSHIP_ACTION_LIST.NEWS_LETTER_TEMPLATE, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = MEMBERSHIP_ACTION_LIST.NEWS_LETTER_TEMPLATE;
        }
      })
  }
}


export const resetNewsLetter = () => {
  return dispatch => {
    dispatch({ type: MEMBERSHIP_ACTION_LIST.NEWS_LETTER_TEMPLATE, data: {} });
  }
}