import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import "./inventoryDrawer.scss";
import { TextBox } from "../../general/TextBox";

import { ErrorMsg } from "../../general/ErrorMessage";
// import ProductDetailsTab from "./ProductDetailsTab";
import { checkCodeRanges } from "../../general/stateCode";


import "@ant-design/compatible/assets/index.css";

import { Col, Row } from "antd";
import { Dropdown } from "../../general/Dropdown";
import find from "lodash.find";
import { MODAL_TYPE } from "../../../static/constants";

const BuyerDetails = (props) => {
  
  return (
    <div>
      <div>
        <div>
          <Row gutter={[16, 8]}>
          <Col span={12} className="drawer-label">
              <FormattedMessage id="Buyer Legal Name" defaultMessage="" />
              <span className="required">*</span>
            </Col>
            <Col span={12} className="drawer-label">
              <FormattedMessage id="Buyer GSTIN" defaultMessage="" />
              <span className="required">*</span>
            </Col>

           
          </Row>

          <Row gutter={[16, 16]}>
         

            <Col span={12}>
              <TextBox
                placeholder={"Buyer Name"}
                type="text"
                maxLength={20}
                value={props.buyerName}
                onChange={(e) => {
                  props.updateState({
                    buyerName: e.target.value,
                  });
                }}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.buyerName;
                }}
                message={"Buyer Name is required"}
              />
            </Col>
            <Col span={12}>
            <Dropdown
                // style={{ width: "210px" }}
                items={props.buyerGstVal || []}
                valueKeyName="taxIdentificationNumber"
                optionKeyName="taxIdentificationNumber"
                value={(props.buyerGSTIN || {}).taxIdentificationNumber}
                placeholder={"GSTIN of User"}
                onSelect={(e) => {
                  const doc = find(props.buyerGstVal, { taxIdentificationNumber: e }) || {};
                  props.updateState({
                    buyerGSTIN: doc,
                  });
                }}
                canAddNew={true}
                onAddNewClick={(payload) => {
                 
                  const formData = {
                    customerId: Number(props?.customerDetails.customerId),
                  };
                  const { showModal, companyInfo } = props;
                  formData.relationshipId = companyInfo.relationshipId;
                  formData.isGstin = true;
                  formData.callback = function (taxIdentification) {
                    props.fetchCustomerDetailsByCustomerId(formData);
                    return;
                  };
                  const data = {
                    title: (
                      <FormattedMessage id="addItem.text.taxIdentification" />
                    ),
                    formData,
                    hideFooter: true,
                    modalData: {
                      modalType: MODAL_TYPE.NEW_TAX_IDENTIFICATION,
                      data: { formData },
                    },
                    handleSubmit: (formData = {}) => {
                      data.formData.submittedOnce = true;
                      props.hideModal(data);
                    },
                  };
                  showModal(data);
                }}
                // allowClear={true}
                // onClear={() => {
                //     this.props.updateState({
                //         supplierObj: undefined,
                //         supplierName: undefined,
                //     });
                // }}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.buyerGSTIN;
                }}
                message={"Buyer GSTIN is required"}
              />
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={[16, 8]}>
            <Col span={12} className="drawer-label">
              <FormattedMessage id="Buyer Address 1" defaultMessage="" />
              <span className="required">*</span>
            </Col>

            <Col span={12} className="drawer-label">
              <FormattedMessage id="Buyer Location" defaultMessage="" />
              <span className="required">*</span>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextBox
                maxLength={20}
                placeholder={"Buyer Address"}
                type="text"
                value={props.buyerAddress}
                onChange={(e) => {
                  props.updateState({
                    buyerAddress: e.target.value,
                  });
                }}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.buyerAddress;
                }}
                message={"Buyer Address is required"}
              />
            </Col>

            <Col span={12}>
              <TextBox
                placeholder={"Buyer Location"}
                type="text"
                maxLength={20}
                value={props.buyerLocation}
                onChange={(e) => {
                  props.updateState({
                    buyerLocation: e.target.value,
                  });
                }}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.buyerLocation;
                }}
                message={"Buyer Location is required"}
              />
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={[16, 8]}>
            <Col span={12} className="drawer-label">
              <FormattedMessage id="Buyer State code" defaultMessage="" />
              <span className="required">*</span>
            </Col>

            <Col span={12} className="drawer-label">
              <FormattedMessage id="Buyer Pincode" defaultMessage="" />
              <span className="required">*</span>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextBox
                maxLength={20}
                placeholder={"Buyer State Code"}
                type="text"
                value={props.buyerStateCode}
                // onChange={(e) => {
                //   props.updateState({
                //     buyerStateCode: e.target.value,
                //   });
                // }}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.buyerStateCode;
                }}
                message={"State Code is required"}
              />
            </Col>

            <Col span={12}>
              <TextBox
                placeholder={"PinCode"}
                type="text"
                maxLength={6}
                minLength={6}
                value={props.buyerPincode}
                onChange={(e) => {
                  props.updateState({
                    buyerPincode: e.target.value,
                  });
                }}
              />
  
               {
                (props.buyerGSTIN?.taxIdentificationNumber && props.buyerPincode && checkCodeRanges(props.buyerGSTIN?.taxIdentificationNumber ,props.buyerPincode ,false)) ? "":   ((props.buyerGSTIN?.taxIdentificationNumber&&props.buyerPincode) ? <span style={{color:"red"}}> Invalid Pincode</span>:"")
                }
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.buyerPincode || checkCodeRanges(props.buyerGSTIN?.taxIdentificationNumber ,props.buyerPincode,false);
                }}
                message={"Pincode is required"}
              />
            </Col>
          </Row>
        </div>
      </div>

      {props.optionalFields ? (
        <div className="mt20">
          <div className="drawer-heading">Optional Details</div>
          <div>
            <Row gutter={[16, 8]}>
              <Col span={12} className="drawer-label">
                <FormattedMessage id="Buyer Trade Name" defaultMessage="" />
              </Col>

              <Col span={12} className="drawer-label">
                <FormattedMessage id="Buyer Address 2" defaultMessage="" />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <TextBox
                  maxLength={20}
                  placeholder={"Buyer Trade Name"}
                  type="text"
                  value={props.buyerTradeName}
                  onChange={(e) => {
                    props.updateState({
                      buyerTradeName: e.target.value,
                    });
                  }}
                />
              </Col>

              <Col span={12}>
                <TextBox
                  placeholder={"Buyer Address 2"}
                  type="text"
                  maxLength={20}
                  value={props.buyerAddress2}
                  onChange={(e) => {
                    props.updateState({
                      buyerAddress2: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
          </div>
          <div>
            <Row gutter={[16, 8]}>
              <Col span={12} className="drawer-label">
                <FormattedMessage id="Buyer District" defaultMessage="" />
              </Col>

              <Col span={12} className="drawer-label">
                <FormattedMessage id="Buyer Phone" defaultMessage="" />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <TextBox
                  placeholder={" District"}
                  type="text"
                  maxLength={20}
                  value={props.buyerDistrict}
                  onChange={(e) => {
                    props.updateState({
                      buyerDistrict: e.target.value,
                    });
                  }}
                />
              </Col>

              <Col span={12}>
                <TextBox
                  placeholder={"Phone No."}
                  type="text"
                  maxLength={20}
                  value={props.buyerPhone}
                  onChange={(e) => {
                    props.updateState({
                      buyerPhone: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
          </div>
          <div>
            <Row gutter={[16, 8]}>
              <Col span={12} className="drawer-label">
                <FormattedMessage id="Buyer Email" defaultMessage="" />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <TextBox
                  maxLength={20}
                  placeholder={" Email"}
                  type="text"
                  value={props.buyerEmail}
                  onChange={(e) => {
                    props.updateState({
                      buyerEmail: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default injectIntl(BuyerDetails);
