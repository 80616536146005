import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AdvanceSearchComponent from '../../../../../../components/modal/modalBody/finance/Banking/Search';
import { fetchCustomers } from '../../../../../customer/Listing/action';
import { fetchPaginationDataIfNeeded, fetchDataIfNeeded } from '../../../../../../utils';
import { fetchProjects, showModal, hideModal, pushModalToStack, popModalFromStack, fetchSupplierSummary } from '../../../../../../actions/commonActions';
import { findMatchingInvoice, createMatchedPayment, resetFindTransactions, createMatchedPaymentForSupplier, getMatchingInvoiceCount } from './action';
// import EmailComponent from '../../../../../../containers/drawer/email';

class AdvanceSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ledgerStartDate: null,
            ledgerEndDate: null,
            activeAccount: props.activeAccount
        }
    }
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            userId: (this.props.userInfo || {}).userId,
            pageNumber: 1,
            pageSize: 500,
            isCompact: true
        };
        if (this.props.transactionData.amount < 0) {
            fetchPaginationDataIfNeeded('fetchSupplierSummary', 'supplierSummary', this.props, payload, true);
        } else {
            fetchPaginationDataIfNeeded('fetchCustomers', 'customers', this.props, payload, true);
        }
        fetchDataIfNeeded('fetchProjects', 'projectList', this.props, payload);
        this.props.resetFindTransactions();
    }

    render() {
        return <div>
            <AdvanceSearchComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        customers: state.customer.customers,
        companyInfo: state.common.companyInfo,
        userInfo: state.common.userInfo,
        customerLedger: state.customer.customerLedger,
        projectList: state.common.projectList,
        findTransactions: state.finance.findTransactions,
        supplierSummary: state.supplier.supplierSummary,
        findTransactionCount: state.finance.findTransactionCount
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCustomers,
    fetchProjects,
    findMatchingInvoice,
    showModal, 
    hideModal, 
    pushModalToStack, 
    popModalFromStack,
    createMatchedPayment,
    resetFindTransactions,
    fetchSupplierSummary,
    createMatchedPaymentForSupplier,
    getMatchingInvoiceCount
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceSearch);