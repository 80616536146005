import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MailOutlined, DeleteOutlined } from '@ant-design/icons';
import { Row, Col, Select, InputNumber, Button, Checkbox, Divider } from 'antd';
//import moment from 'moment-timezone';
//import { CONSTANTS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { Option } = Select;
//const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const ReminderSetup = (props) => {
   
const { updateState } = props;

    return (
        <div className="modal-dialog ">
            <div className="modal-content full-width">
                <Row>
                    <Col span={12}> <FormattedMessage id='invoiceIs.text' />  </Col>
                    <Col span={6}>
                        <Select onChange={(value) => {
                            updateState({
                                remindBeforeOrAfterDueDate: value,
                            })
                        }}
                            style={{ width: '100%' }}
                            value={props.remindBeforeOrAfterDueDate}>
                            <Option value="before"><FormattedMessage id='dueIn.text' /></Option>
                            <Option value="after"><FormattedMessage id='pastDueIn.text' /></Option>
                        </Select>
                    </Col>
                    <Col span={3}>
                        <InputNumber style={{ width: '100%' }} value={props.days} placeholder=""  onChange={(value) => {
                            updateState({days: value })
                        }}/></Col>
                    <Col span={3}>
                        <FormattedMessage id='drawer.customer.days' />
                    </Col>
                </Row>
                <Row>
                    <Col span={12}> <FormattedMessage id='recurringInvoice.setup.emailCustomer' defaultMessage='' /></Col>
                    <Col span={3}>
                        <Checkbox checked={props.notifyCustomer} onChange={(e) => {
                            updateState({
                                notifyCustomer: e.target.checked,
                                feeReminderEmailDrawerVisible: e.target.checked,
                                emailContext: 'feeReminder'
                            })
                        }} />
                    </Col>
                    {props.feeReminderEmailDetails ? <Col span={9} >
                        <Button icon={<MailOutlined />} onClick={() => {
                            updateState({feeReminderEmailDrawerVisible: true, 
                                emailContext: 'feeReminder'})
                        }}>
                        </Button>
                    </Col> 
                    : 
                    <Col span={9}> </Col>
                    }
                </Row>
               
                <Row justify='end'>
                    <Col span={6} >
                        <Button type="primary" onClick={() => {
                            if(props.reminderList && props.reminderList.length > 3){
                                return showToasterMessage({
                                    description: props.intl.formatMessage({ id: 'reminder.validation.maxActive', defaultMessage: '' }),
                                    messageType: 'error'
                                })
                            }
                            if(!props.remindBeforeOrAfterDueDate || !props.days){
                                return showToasterMessage({
                                    description: props.intl.formatMessage({ id: 'reminder.validation.requiredMessage', defaultMessage: '' }),
                                    messageType: 'error'
                                })
                            }
                            if(!props.notifyCustomer || !props.feeReminderEmailDetails || props.feeReminderEmailDetails === ''){
                                return showToasterMessage({
                                    description: props.intl.formatMessage({ id: 'reminder.notifyCustomer.validation.message', defaultMessage: '' }),
                                    messageType: 'error'
                                })
                            }
                            const modalBody = <FormattedMessage id='save.confirmation' defaultMessage='' />;
                            const modalData = {
                                modalBody,
                                handleSubmit: () => {
                                    let payload = {
                                        relationshipId: props.relationshipId,
                                        isBeforeDueDate: props.remindBeforeOrAfterDueDate === 'before' ? 1 : 0,
                                        isAfterDueDate: props.remindBeforeOrAfterDueDate === 'after' ? 1 : 0,
                                        days: props.days,
                                        isActive: 1,
                                        createdByEmpId: (props.userInfo || {}).relationshipEmployeeId,
                                        isSendEmail: props.notifyCustomer ? 1 : 0,
                                        email: props.notifyCustomer ? props.feeReminderEmailDetails || '' : '',
                                    }
                                    // props.addReminderSetting(payload);
                                    // props.popModalFromStack();

                                    //props.popModalFromStack();
                                    let reminderList = props.reminderList || [];
                                    reminderList.push(payload);
                                    props.addReminderSetting(reminderList);
                                    updateState({
                                        reminderList: reminderList,
                                        remindBeforeOrAfterDueDate: '',
                                        days: 0,
                                        notifyCustomer: false,
                                        feeReminderEmailDetails: ''
                                    })
                                },
                            };
                            props.showModal(modalData);

                        
                        }}>
                            <FormattedMessage id='addReminder.text' />
                        </Button>
                    </Col>
                </Row>
                
                <Divider />

                <Row>
                    {(props.reminderList && props.reminderList.length) ?
                        <Col span={24}>
                            <b>Active Reminder{props.reminderList.length > 1 ? 's' : ''}</b>
                            {(props.reminderList || []).map((item, i) => {
                                return (
                                    <div key={i}>
                                    <Row>
                                       <Col span="20"> {item.days || "0"} days {item.isBeforeDueDate ? "before" : "after"} the due date </Col>
                                       {item.email ?
                                                <Col span={2} > <Button icon={<MailOutlined />} onClick={() => {
                                                    updateState({
                                                        feeReminderEmailDrawerVisible: true,
                                                        emailContext: 'feeReminder',
                                                        feeReminderEmailDetails: item.email,
                                                        isFormSetting: true,
                                                        updateReminder: function (newEmailDetails) {
                                                            const modalBody = <FormattedMessage id='save.confirmation' defaultMessage='' />;
                                                            const modalData = {
                                                                modalBody,
                                                                handleSubmit: () => {
                                                                    let payload = { ...item, email: newEmailDetails };
                                                                    let reminderList = props.reminderList || [];
                                                                    reminderList[i] = payload;
                                                                    updateState({ remindBeforeOrAfterDueDate: 'before', days: 0, updateReminder: undefined, feeReminderEmailDrawerVisible: false, reminderList: reminderList, feeReminderEmailDetails: '' })
                                                                    props.addReminderSetting(reminderList);
                                                                },
                                                            };
                                                            props.showModal(modalData);
                                                        }
                                                    })
                                                }}>
                                                </Button></Col> :
                                                <Col span={2} ></Col>
                                            }
                                       <Col span={2} > <Button icon={<DeleteOutlined />} onClick={() => {
                                                const modalBody = <FormattedMessage id='common.delete.confirmation' />;
                                                const modalData = {
                                                    modalBody,
                                                    handleSubmit: () => {
                                                        let reminderList = props.reminderList || [];
                                                        reminderList.splice(i, 1);
                                                        updateState({reminderList: reminderList});
                                                        props.addReminderSetting(reminderList);
                                                    },
                                                };
                                                props.showModal(modalData);
                                            }}>
                                            </Button></Col> 
                                       
                                    </Row>
                                    <hr></hr>
                                    </div>
                                )
                            })
                            }
                        </Col> 
                        :
                        <Col>
                            <b><FormattedMessage id='noActive.reminder' /></b>
                        </Col>
                    }
                </Row>
                
            </div>
        </div>
    );

}

export default ReminderSetup;