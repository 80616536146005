import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

import { TextBox } from '../general/TextBox';
import { CustomButton } from '../general/CustomButton';
import { validateEmail } from '../../utils';
import './forgotpassword.scss';
import SuccessIcon from "../../assets/images/success.svg";
function ForgotPassword(props) {
  const { email, emailClass, emailInvalidClass, updateState, intl, history } = props;
  const [sentDone, setSentDone] = useState(false);
  const handleSubmit = (payload) => {
    if (payload.email && validateEmail(payload.email)) {
      payload.sendResetMail(payload.email, payload.history ,(e)=>setSentDone(e));
    } else {
      if (!validateEmail(payload.email)) {
        payload.updateState({
          emailClass: 'forgot-form-input-error input-error',
          emailInvalidClass: 'forgot-form-input-error input-error'
        });
      }
    }
  }
  return (
    <Row className='forgot-wrapper' type='flex' justify='center' align='middle'>
    {
      !sentDone ?(
       
        <Col md={6} xs={24} sm={24} className="forgot-panel">
          <h4 align="left">
            <FormattedMessage id='forgot.headingText' defaultMessage='' />
          </h4>
          <p className='forget-password-msg'>
            <FormattedMessage
              id='forgot.text'
              defaultMessage=''
            />
          </p>
          <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(props); }}>
            <Row className='pb15'>
              {/* <Col span={3} className='forgot-lock-icon'>
                <span>
                  <i className='fa fa-lock'></i>
                </span>
              </Col> */}
              <Col span={24}>
                <TextBox
                  placeholder={intl.formatMessage(
                    { id: 'forgot.email.placeholder', defaultMessage: '' }
                  )}
                  type='text'
                  value={email}
                  className={emailClass || 'forgot-form-input'}
                  invalidClassName={emailInvalidClass || 'forgot-form-input-error'}
                  validator={(val) => { return validateEmail(val); }}
                  onChange={(e) => {
                    updateState({
                      email: e.target.value,
                      emailClass: 'forgot-form-input'
                    })
                  }}
                />
              </Col>
            </Row>
            <Form.Item>
              <Row>
                <Col span={24} align="end">
                  <CustomButton
                    type='primary'
                    htmlType='submit'
                    className='forgot-send-email'
                    intlId='forgot.btn.submit'
                    defaultMessage=''
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <CustomButton
                    type='default'
                    htmlType='button'
                    className='forgot-goback'
                    intlId='forgot.btn.back'
                    defaultMessage=''
                    onClick={(e) => { e.preventDefault(); history.push('/') }}
                  />
                </Col>
  
              </Row>
             
            </Form.Item>
          </Form>
        </Col>
      
      ):(<div>
         <div className="text-center">
            <img src={SuccessIcon} alt='success' width={100}/>
          </div>
          <div className="text-center"><h2>Email send</h2></div>
          <div className="text-center"><p>Check your registered mail id</p></div>
      </div>)
    }
    </Row>
   
  );
}



export default injectIntl(ForgotPassword);
