import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddressDrawer from '../../../components/drawer/Address';
import { fetchCountries, fetchStates, resetStates, showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import { createAddress } from './action';
import { fetchDataIfNeeded } from '../../../utils';
import { CONSTANTS } from '../../../static/constants';
import CommunicationDrawer from '../../../components/drawer/CommunicationDrawer';


class CommContainer extends Component {
  constructor(props){
    super(props);
    this.state = {
      // countryName: '',
      // countryId: 0
    }
  }

  initCalls() {
  }
  // componentDidMount() {
  //   const companyInfo = this.props.companyInfo || {};
  //   fetchDataIfNeeded('fetchCountries', 'countries', this.props);
  //   if (companyInfo.countryName === 'India') {
  //     fetchDataIfNeeded('fetchStates', 'states', this.props, CONSTANTS.INDIA_COUNTRY_ID);
  //   }else{
  //     this.props.resetStates();
  //   }
  // }
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.countries !== this.state.countries && nextProps.countries && !this.state.countryCallingCode) {
  //     let obj = nextProps.countries.find(country => (country.countryName === (this.props.companyInfo).countryName)) || {};
  //     this.setState({ countryCallingCode: obj.countryCallingCode || '', countryId: obj.countryId});
  //   }
  // }


  render() {
    return <div>
      {/* <CommunicationDrawer updateState={(data) => { this.setState(data) }} {...this.props} {...this.state} /> */}
    {/* <CommunicationDrawer /> */}
    </div>
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    isEmailSpinner: state.common.isEmailSpinner,
    emailAddressList: state.common.emailAddressList,
    payOnlineUrl: state.common.payOnlineUrl
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
 
  pushModalToStack,
  popModalFromStack,
  showModal,
  hideModal,
  fetchCountries}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(CommContainer);
