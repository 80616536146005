import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PaymentPlanComp from '../../../components/B2B/PaymentPlan';
// import queryString from 'query-string';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import { CONSTANTS } from '../../../static/constants';

class PaymentPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    }
  }

  componentDidMount() {
   
  }

  componentWillReceiveProps(props) {
    if (props.companyInfo && props.companyInfo.relationshipId) {
      const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
      const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
      // this.setState({
      //   relationshipBillingAddress,
      //   companyInfo: props.companyInfo
      // })
      const onlineThemes = JSON.parse( props.companyInfo.onlineThemes || '{}');
      this.setState({
        onlineThemes,
        relationshipBillingAddress,
        companyInfo: props.companyInfo
      })
    }
  }

  render() {
    return (
      <PaymentPlanComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    );
  }

}

const mapStateToProps = (state) => {
  return {
    
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
 
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPlan);
