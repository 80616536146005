import React from 'react';
// import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as find from 'lodash.find';
import { Dropdown } from '../../../../general/Dropdown';
import LoadingIcon from '../../../../../assets/images/loading.gif';
import { DetailDropdown } from '../../../../general/DetailDropdown';

const HeaderAction = (props) => {
    const {
        intl,
        supplierSummary,
        pageNumber,
        supplier,
        updateState
    } = props;
    const getFormatedNextTxNumber = (props) => {
        // const { supplier } = props;
        // if (!supplier || !supplier.supplierId) {
        //     return <span>{props.nextTxNumber}</span>;
        // }
        // return <span>{props.nextTxNumber.replace('DM-00', 'DM-' + (supplier.supplierId < 10 ? ('0' + supplier.supplierId) : supplier.supplierId))}</span>
        return <span>{props.nextTxNumber}</span>;
    }
    return (
        <>
         <div className='left-itms'>
      <ul>
        <li className='title'>
          Debit Memo
        </li>
        <li className='sep' />
        <li>
          <FormattedMessage id='supplier.debitMemo.form.debitMemoNumber.label' defaultMessage='' /> &nbsp; - &nbsp;
          {props.nextTxNumber ?
                                                    <span>{getFormatedNextTxNumber(props)}</span>
                                                    :
                                                    <img src={LoadingIcon} alt="Invoice" style={{
                                                        'height': '74px',
                                                        'margin-top': '-25px',
                                                        'margin-bottom': '-25px'
                                                    }} />
                                                }
        </li>
      </ul>
     </div>
            <div className='right-itms'>
                {/* <span className="pr10">
                    <FormattedMessage id='supplier.debitMemo.form.supplier.label' defaultMessage='' />
                </span> */}
                <DetailDropdown
          style={{ width: "200px" }}
          items={props.suppliers[pageNumber] || []}
                    valueKeyName='supplierStoreName'
                    optionKeyName='supplierId'
                    className="detailDropdown"
                    value={(supplier || {}).name}
                    onSearch= {(searchedText)=>{
                        props.fetchSuppliers({
                            searchedText: searchedText,
                            pageNumber: 1,
                            hideSpinner: true,
                            pageSize: 100,
                            companyInfo:props.companyInfo,
                            filterData:true,
                            relationshipId: (props.companyInfo || {}).relationshipId
                        })
                    }}
                    placeholder={intl.formatMessage({
                        id: 'supplier.debitMemo.form.supplier.placeholder', defaultMessage: ''
                    })}
                    canAddNew={true}
                    canDelete={true}
                    deleteClickHandler={(payload) => {
                        props.deleteSuppliers({ groupSupplierIds: [payload.id], relationshipId: (props.companyInfo || {}).relationshipId })
                    }}
                    onAddNewClick={(payload) => {
                        props.updateHeaderState({
                            supplier: {
                                supplierDrawerVisible: true,
                                supplierName: payload.textEntered,
                            }
                        })
                    }}
                    onSelect={
                        (supplierId, optionObj) => {
                            const supplierObj = find(supplierSummary[pageNumber], { supplierId: supplierId }) || {};
                            const supplierPaymentTerm = find(props.paymentTerms, { paymentTermId: Number(supplierObj.paymentTermId) }) || {};
                            updateState({
                                supplierPaymentTerm,
                                stateSupplierId: supplierId
                            });
                            props.fetchSupplierDetailsBySupplierId({
                                relationshipId: props.companyInfo.relationshipId,
                                supplierId: supplierId,
                                pageNumber: props.pageNumber || 1,
                                pageSize: props.pageSize || 100
                              });
                        }
                    }
                    allowClear={true}
                    onClear={() => {
                        updateState({
                            boContactList: undefined,
                            selectedContact: undefined,
                            supplier: undefined,
                            supplierPaymentTerm: undefined,
                            supplierBillingAddress: undefined
                        })
                    }}
                />
                <Button 
          style={{ height: "38px" }}
                onClick={() => {
                    props.updateState({
                        ledgerDrawerVisible: true
                    })
                }} >
                <i className='fa fa-book fa-lg'> </i>
                </Button>
                <Button onClick={() => {
              props.updateDrawerState({
                  settingPurchaseDrawerVisible: true
              })
          }} >
              <i class="fa fa-cog" aria-hidden="true"></i>
          </Button>
                {/* <div className="space-lr">
                    <Button icon={<SwapOutlined />} />
                </div> */}
            </div>
        </>
    );
};

export default injectIntl(HeaderAction);
