import React, { Fragment } from "react";
import { Row, Col, Button, Pagination, Checkbox } from 'antd';
import envConfig from '../../../config/environmentConfig';
// import AmazonIcon from '../../../assets/images/amazon-logo.png';
import { ShopifyLogo } from "../../../assets/images";
import { MARKETPLACE_NAME } from '../../../static/constants';
import PageBreadcrumb from "../../PageBreadcrumb";
import { FormattedMessage } from 'react-intl';
import * as find from 'lodash.find';
import { fetchPaginationDataIfNeeded } from "../../../utils";
import AddInventoryItemLisitng from "../../../containers/modal/modalBody/salesChannel/amazon";
import AmazonList from '../../../containers/modal/modalBody/common/amazon';

const ShopifyListingComp = (props) => {

    const { linkedMarketplaceList, inventoryItemsListing, inventoryItemsCount, pageNumber, companyInfo, updateState,
        amazonPublishedQty, amazonUnPublishedQty, amazonTodayListing, amazonThisWeekListing, amazonThisMonthListing,
        amazonTotalSales, selectedItems } = props

    if (!selectedItems) {
        props.updateState({ selectedItems: [] });
    }

    const showTotal = (total) => {
        return (
            props.intl.formatMessage({
                id: "pagination.text.total",
                defaultMessage: "",
            }) +
            ` ${total || 0} ` +
            props.intl.formatMessage({
                id: "pagination.text.items",
                defaultMessage: "",
            })
        );
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            pageNumber: pageNumber,
            pageSize: pageSize,
        };
        fetchPaginationDataIfNeeded("getInventoryItems", "inventoryItemsListing", props, payload);
    };

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            pageNumber: pageNumber,
            pageSize: pageSize,
        };
        props.resetPaginatedData();
        fetchPaginationDataIfNeeded("getInventoryItems", "inventoryItemsListing", props, payload);
        updateState({ pageSize: pageSize, pageNumber: pageNumber });
    };

    const breadCrumbList = [
        // {
        //     name: 'Dashboard',
        //     link: 'dashboard'
        // },
        // {
        //     name: 'Sales',
        // },
        {
            name: <FormattedMessage id='sidebar.menuItem.salesChannels' defaultMessage='' />

        }, {
            name: <FormattedMessage id='shopify.logo' defaultMessage='' />,
        }
    ];

    // const dataSource = [
    //     {
    //         key: '1',
    //         ProductsDetails: 'Mike',
    //         age: 32,
    //         address: '10 Downing Street',
    //         Variant: 'Variant',
    //         SKU: 'SKU',
    //         Category: 'Category',
    //         Warehouse: 'Warehouse',
    //         BrandName: 'BrandName',
    //     },
    //     {
    //         key: '2',
    //         ProductsDetails: 'John',
    //         age: 42,
    //         address: '10 Downing Street',
    //         Variant: 'Variant',
    //         SKU: 'SKU',
    //         Category: 'Category',
    //         Warehouse: 'Warehouse',
    //         BrandName: 'BrandName',
    //     },
    // ];

    // const columns = [
    //     {
    //         title: 'Products Details',
    //         dataIndex: 'ProductsDetails',
    //         key: 'ProductsDetails',
    //         Variant: 'ProductsDetails',
    //         SKU: 'ProductsDetails',
    //         Category: 'ProductsDetails',
    //         Warehouse: 'ProductsDetails',
    //         BrandName: 'ProductsDetails',
    //     },
    //     {
    //         title: 'Age',
    //         dataIndex: 'age',
    //         key: 'age',
    //         Variant: 'age',
    //         SKU: 'age',
    //         Category: 'age',
    //         Warehouse: 'age',
    //         BrandName: 'age',
    //     },
    //     {
    //         title: 'Address',
    //         dataIndex: 'address',
    //         key: 'address',
    //         Variant: 'address',
    //         SKU: 'address',
    //         Category: 'address',
    //         Warehouse: 'address',
    //         BrandName: 'address',
    //     },

    //     {
    //         title: 'Variant',
    //         dataIndex: 'Variant',
    //         key: 'Variant',
    //         Variant: 'Variant',
    //         SKU: 'Variant',
    //         Category: 'Variant',
    //         Warehouse: 'Variant',
    //         BrandName: 'Variant',
    //     },

    //     {
    //         title: 'SKU',
    //         dataIndex: 'SKU',
    //         key: 'SKU',
    //         Variant: 'SKU',
    //         SKU: 'SKU',
    //         Category: 'SKU',
    //         Warehouse: 'SKU',
    //         BrandName: 'SKU',
    //     },

    //     {
    //         title: 'Category',
    //         dataIndex: 'Category',
    //         key: 'Category',
    //         Variant: 'Category',
    //         SKU: 'Category',
    //         Category: 'Category',
    //         Warehouse: 'Category',
    //         BrandName: 'Category',
    //     },
    //     {
    //         title: 'Warehouse',
    //         dataIndex: 'Warehouse',
    //         key: 'Warehouse',
    //         Variant: 'Warehouse',
    //         SKU: 'Warehouse',
    //         Category: 'Warehouse',
    //         Warehouse: 'Warehouse',
    //         BrandName: 'Warehouse',
    //     },

    //     {
    //         title: 'BrandName',
    //         dataIndex: 'BrandName',
    //         key: 'BrandName',
    //         Variant: 'BrandName',
    //         SKU: 'BrandName',
    //         Category: 'BrandName',
    //         Warehouse: 'BrandName',
    //         BrandName: 'BrandName',
    //     },
    // ];

    const itemSelection = (item) => {
        let selectItem = find(selectedItems, { itemId: Number(item.itemId) });
        let updatedItems = [];
        if (selectItem) {
            updatedItems = selectedItems.filter(obj => obj.itemId !== item.itemId);
        } else {
            updatedItems = JSON.parse(JSON.stringify(selectedItems));
            updatedItems.push(item);
        }
        props.updateState({ selectedItems: updatedItems });
    }

    const selectAll = (context) => {
        if (!context) {
            selectedItems.splice(0, selectedItems.length);
            props.updateState({ selectedItems: selectedItems });
            return;
        }
        for (let i = 0; i < inventoryItemsListing[pageNumber].length; i++) {
            let item = inventoryItemsListing[pageNumber][i];
            let selectItem = find(selectedItems, { itemId: Number(item.itemId) });
            if (!selectItem) {
                selectedItems.push(item);
            }
        }
    }

    const isCheckedAll = () => {
        if (!inventoryItemsListing || !inventoryItemsListing[pageNumber] || inventoryItemsListing[pageNumber].length === 0) {
            return false;
        }
        for (let i = 0; i < inventoryItemsListing[pageNumber].length; i++) {
            let usr = inventoryItemsListing[pageNumber][i];
            let selectItem = find(selectedItems, { itemId: Number(usr.itemId) });
            if (!selectItem) {
                return false;
            }
        }
        return true;
    }

    return (
        <div className="customer-details-container shadesama">

            <div className="pb10">
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div>
            {(!linkedMarketplaceList || linkedMarketplaceList.length === 0 || !find(linkedMarketplaceList, { marketplaceName: 'amazon' })) ?
                <div className="customer-details">
                    <div className="prod-ama">
                        <Row >
                            <Col span={2}></Col>
                            <Col span={15}>
                                <h3>The global commerce platform. Sell to every buyer, everywhere </h3>
                                <div className="line-box"></div>
                                <h4>Build your business with Shopify to sell online, offline, and everywhere in between</h4>
                                <ul>
                                    <li><span><i class="fa fa-square" aria-hidden="true"></i>

                                    </span>  Build an online storefront </li>
                                    <li><span><i class="fa fa-square" aria-hidden="true"></i>

                                    </span>  Bring your vision to life with Shopify draf-and-drop sore creator  </li>
                                    <li><span><i class="fa fa-square" aria-hidden="true"></i>

                                    </span>  Add more features and functionality to your online store with apps  </li>
                                    <li><span><i class="fa fa-square" aria-hidden="true"></i>

                                    </span> Sell online, in person, and around the world with the marketing tools, social integrations, and sales channels  </li>

                                    {/* <Button className="ant-btn cnant-btn ant-btn-primary prod-amab">Start Now</Button> */}

                                </ul>
                                <div className="prod-amab"><a href="https://www.shopify.com/free-trial" target="_blank" rel="noopener noreferrer"> Guide to Selling on Shopify</a></div>
                            </Col>
                            <Col span={6}> <div className="ant-card accounting-market-place text-center integsm ant-card-bordered ">
                                <div className="ant-card-head headr">
                                    <div className="ant-card-head-wrapper">
                                        <div className="ant-card-extra">
                                            <img src={ShopifyLogo} alt="" />
                                        </div>
                                    </div>
                                </div>

                                {props.marketplaceConfig && props.marketplaceConfig[MARKETPLACE_NAME.AMAZON] ? <div class="cr cr-top cr-left cr-green">Integrated</div> : ''}
                                <div className="ant-card-body">
                                    <p> List product to Shopify</p>
                                    <p> Update price</p>
                                    <p> Sync up quantity</p>
                                    <p> Remove quantity</p>

                                    <div className="border-footer mt10"></div>
                                    {props.marketplaceConfig && props.marketplaceConfig[MARKETPLACE_NAME.AMAZON] ? <Button className="cnant-btn grey-button" onClick={
                                        () => {
                                            props.disconnectConfiguration(props.marketplaceConfig[MARKETPLACE_NAME.AMAZON], props);
                                        }
                                    }>Disconnect </Button> : <Button className="ant-btn cnant-btn ant-btn-primary" onClick={() => {
                                      
                                        window.location = `${envConfig.PAYMENT_SERVER_BASE_URL}/alpide_amazon/onboarding?callback_uri=${envConfig.CALLBACK_URI_MARKTPLACE_AMAZON}&relationshipId=${props.companyInfo?.relationshipId}&apiBaseUrl=${envConfig.API_BASE_URL}${envConfig.rootContext}/`;
                                    }}> Start Selling</Button>
                                    }
                                </div>
                            </div></Col>
                            <Col span={1}></Col>
                        </Row>



                    </div>
                    <div className="clearfix"></div>
                </div>
                : ''
            }



            {(linkedMarketplaceList && linkedMarketplaceList.length > 0 && find(linkedMarketplaceList, { marketplaceName: 'amazon' })) ?
                <div style={{ background: '#fff', padding: '15px' }}>
                    <Row >
                        <Col span={20}><h3 style={{ fontSize: '24px', fontWeight: '700' }}>My Amazon Store</h3>
                            <Button className="cnant-btn grey-button"
                                onClick={() => {
                                    props.disconnectConfiguration(props.marketplaceConfig[MARKETPLACE_NAME.AMAZON], props);
                                }}>
                                Disconnect
                            </Button>
                        </Col>
                        <Col span={4} style={{ textAlign: 'right' }} >   <img src={ShopifyLogo} alt="" /></Col>
                    </Row>

                    <div className='amazon-box-border'>
                        <Row>
                            <Col span={8} className='text-center'><h3 className='ama-hed '>Chart</h3> </Col>
                            <Col span={8} className='text-center' style={{ borderLeft: 'solid 1px #d3d3d3' }}>
                                <h3 className='ama-hed' style={{ float: 'left', width: '50%' }}>{amazonPublishedQty} <br />
                                    <span>Published</span>
                                </h3>
                                <h3 className='ama-hed ' style={{ float: 'right', width: '50%' }}>{amazonUnPublishedQty} <br />
                                    <span>Unpublished</span>
                                </h3>
                                <div className="clearfix"></div>
                                <Row style={{ background: '#f7f7f7', margin: '0px 10px', borderRadius: '8px', padding: '10px 0px' }}>
                                    <Col span={8}> Today <br />
                                        {amazonTodayListing} EA</Col>
                                    <Col span={8} style={{ borderLeft: 'solid 1px #d3d3d3' }}>This Week <br />
                                        {amazonThisWeekListing} EA</Col>
                                    <Col span={8} style={{ borderLeft: 'solid 1px #d3d3d3' }}> This Month <br />
                                        {amazonThisMonthListing} EA</Col>
                                </Row>
                            </Col>
                            <Col span={8} className='text-center' style={{ borderLeft: 'solid 1px #d3d3d3' }}>
                                <h3 className='ama-hed1'>$ {amazonTotalSales} <span className='cir-der'><i class="fa fa-angle-up" aria-hidden="true"></i>
                                    15%</span><br />
                                    <span>Total  Sales</span>
                                </h3>
                                <div className="clearfix"></div>
                                <Row style={{ background: '#f7f7f7', margin: '0px 0px 0px 10px', borderRadius: '8px', padding: '10px 0px' }}>
                                    <Col span={8}> Today <br />
                                        $2512</Col>
                                    <Col span={8} style={{ borderLeft: 'solid 1px #d3d3d3' }}>This Week <br />
                                        $89825 </Col>
                                    <Col span={8} style={{ borderLeft: 'solid 1px #d3d3d3' }}> This Month <br />
                                        $234567</Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    {/* <Row>
                    <Col span={24} style={{ textAlign: 'center', padding: '10px 0px' }}>Show this only when item is selected
                        <br />
                        <i class="fa fa-long-arrow-down" aria-hidden="true"></i>

                    </Col>
                </Row> */}

                    <Row>
                        <Col span={24}>
                            {/* <Table dataSource={dataSource} columns={columns} style={{ width: '100%' }} /> */}
                            <div>
                                <button
                                    className="action-btn"
                                    title={props.intl.formatMessage({ id: 'product.listing.amazon.upload', defaultMessage: '' })}
                                    onClick={() => {
                                        const ids = [];
                                        const data = [];
                                        // props.gridApi.forEachNode(obj => {
                                        //     if (obj.data.isChecked) {
                                        //         data.push(obj.data);
                                        //         ids.push(obj.data.itemId);
                                        //     }
                                        // })
                                        selectedItems.forEach(obj => {
                                            data.push(obj);
                                            ids.push(obj.itemId);
                                        })
                                        if (ids.length) {

                                            const modalData = {
                                                title: <FormattedMessage id='product.listing.amazon' defaultMessage='' />,
                                                modalBody: <AmazonList inventoryList={ids} {...props} />,
                                                hideFooter: true,
                                                width: 1400,
                                                wrapClassName: 'validation-failed-modal'
                                            };
                                            props.pushModalToStack(modalData);
                                        } else {
                                            const modalData = {
                                                title:
                                                    <div className="title">
                                                        <i className="fa fa-ban mr15" />
                                                        <FormattedMessage id='product.listing.validation.failed.label' defaultMessage='' />
                                                    </div>,
                                                modalBody:
                                                    <Fragment>
                                                        <div className="warning-message">
                                                            <FormattedMessage id='product.listing.validation.failed.message.amazon' defaultMessage='' />
                                                        </div>
                                                        <div className="button-action">
                                                            <Button className="ant-btn-default"
                                                                onClick={() => props.popModalFromStack()}><FormattedMessage id='ok' defaultMessage='' /></Button>
                                                        </div>
                                                    </Fragment>,
                                                hideFooter: true,
                                                wrapClassName: 'validation-failed-modal'
                                            };
                                            props.pushModalToStack(modalData);
                                        }
                                    }}
                                >
                                    <i className="fa fa-amazon" />
                                </button>
                            </div>
                            <div className="new-tables">
                                <table width={100}>
                                    <thead>
                                        <tr>
                                            <th>
                                                <Checkbox
                                                    onChange={(e) => {
                                                        selectAll(e.target.checked);
                                                    }}
                                                    checked={isCheckedAll()}
                                                />
                                            </th>
                                            <th>Product & Desc</th>
                                            <th>Seller SKU</th>
                                            <th>ASIN #</th>
                                            <th>Tax Code</th>
                                            <th>Item Condition</th>
                                            <th>Category</th>
                                            <th>Manufacturer</th>
                                            <th>Brand</th>
                                            <th>Qty</th>
                                            <th>Amazon Selling Price</th>
                                            <th>Fulfillment Channel</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {inventoryItemsListing && inventoryItemsListing[pageNumber] ?
                                            inventoryItemsListing[pageNumber].map((item, i) => {
                                                return (<tr key={i}>
                                                    <th>
                                                        <Checkbox
                                                            onClick={() => {
                                                                itemSelection(item);
                                                            }}
                                                            checked={find(selectedItems, { itemId: Number(item.itemId) }) ? true : false}
                                                        />
                                                    </th>
                                                    <th>{item.itemName}</th>
                                                    <th>{item.sku}</th>
                                                    <th>ASIN #</th>
                                                    <th>Tax Code</th>
                                                    <th>Item Condition</th>
                                                    <th>Category</th>
                                                    <th>Manufacturer</th>
                                                    <th>Brand</th>
                                                    <th>Qty</th>
                                                    <th>{item.amazonSellingPrice}</th>
                                                    <th>Fulfillment Channel</th>
                                                    {!item.qtyOnAmazon || item.qtyOnAmazon === 0 ?
                                                        <th onClick={() => {
                                                            props.showModal({
                                                                title: <FormattedMessage id='setting.marketplaceConfiguration.taxes.addMarketplaceState.model.name' defaultMessage='' />,
                                                                modalBody: <AddInventoryItemLisitng {...props} />,
                                                                width: 600,
                                                                hideFooter: true,
                                                                // wrapClassName: "manage-user-modal"
                                                            })
                                                        }}>Publish</th>
                                                        :
                                                        <th onClick={() => {
                                                            alert("Product successfully unpublished.")
                                                        }}>Unpublish</th>
                                                    }
                                                </tr>)
                                            })

                                            : 'No Data'}
                                    </tbody>
                                </table>

                                <Pagination
                                    size="small"
                                    total={inventoryItemsCount}
                                    showTotal={showTotal}
                                    defaultPageSize={props.pageSize}
                                    showSizeChanger
                                    pageSizeOptions={[10, 25, 50, 100, 200]}
                                    showQuickJumper
                                    onChange={(pageNumber, pageSize) => {
                                        loadPageData(pageNumber, pageSize);
                                        updateState({ pageSize: pageSize, pageNumber: pageNumber });
                                    }}
                                    onShowSizeChange={(pageNumber, pageSize) => {
                                        handlePageSizeChange(pageNumber || 1, pageSize, props);
                                    }}
                                />
                            </div>


                        </Col>
                    </Row>
                </div> : ''
            }

        </div>
    )
};

export default ShopifyListingComp;