import React, { Component } from 'react';
import config from '../../../../../config/environmentConfig';
import { FormattedMessage } from 'react-intl';
import { Input, Button} from 'antd';
import { showToasterMessage } from '../../../../../utils';
import CryptoJS from "crypto-js";

export default class ShareInvoice extends Component {

  constructor(props){
    super(props);
    let tokenInfo={
      relationshipId: props.companyInfo.relationshipId,
      source: 'kiosk'
    }
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(tokenInfo), config.ENCRYPT_SECRET).toString();
    this.state= {
      previewUrl: config.KIOSK_BASE_URL + '?token=' + encodeURIComponent(encrypted)
    }
}

  copyCodeToClipboard = () => {
    const el = this.textArea;
    el.select();
    document.execCommand("copy");
    showToasterMessage({
      messageType: 'success', description: this.props.intl.formatMessage(
        {
          id: 'copyToClipboard.text',
          defaultMessage: ''
        }
      )
    });
  }

  render() {
    return (
      <div>
        <div> <FormattedMessage id='shareKioskUrl.link.description' /></div>
        <br/>
        <div>
          <Input style={{width: '90%'}}
            ref={(textarea) => this.textArea = textarea}
            value={this.state.previewUrl}
          />
          <Button title='Copy Link' type="default" style={{paddingLeft: '5px', paddingRight: '5px'}} onClick={() => this.copyCodeToClipboard()}>
          <i className="fa fa-clone"></i>
          </Button>
        </div>
        {/* <br/>
        <br/>
          <div className="align-footer" style={{ borderBottom: '1px solid #e5e5e5', textAlign: 'center' }}>
            <Button style={{marginBottom:'10px'}} type="primary" onClick={() => {
              //window.location = this.state.previewUrl;
              // window.open(this.state.previewUrl, '_blank');
              Object.assign(document.createElement('a'), {
                target: '_blank',
                href: this.state.previewUrl,
              }).click();
            }}>
              <FormattedMessage id='preview.text' />
            </Button>
        </div> */}

        <br />
        <br />
      
      </div>
    )
  }
}
