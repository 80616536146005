import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FinancialReportComp from "../../../components/schoolManagement/FinancialReport"
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../../utils";
import { getFinancialReport, getgetFinancialReportCount, resetPaginatedData } from "./action";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import { getSubdomain } from '../../modal/modalBody/settings/CompanyInfo/action';
import { sendResetMail } from '../../Forgot/action';
import EmailComponent from '../../../containers/drawer/recurringInvoiceEmail';
import { FormattedMessage } from 'react-intl';

class FinancialReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 200,
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
    this.props.updateHeaderState({pageHeading: <FormattedMessage id='heading.schoolManagement.financialReports' defaultMessage='' />})
    this.props.updateHeaderState({breadCrumbList : [
      {
        name: <FormattedMessage id='sidebar.menuItem.schoolManagement' defaultMessage='' />
      },
      {
        name: <FormattedMessage id='financialReport.text' defaultMessage='' />,
      }
    ]})
    // let self = this;
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
      pageNumber: 1,
      pageSize: 200,
      formId: this.props.defaultSchoolForm
    };
    fetchPaginationDataIfNeeded("getFinancialReport", "financialReportList", this.props, payload);
    fetchDataIfNeeded("getgetFinancialReportCount", "financialReportCount", this.props, payload);
   // this.props.getSubdomain(payload, function (stat) { self.setState({ isSubDomainLoaded: stat }) });
  }
  componentWillReceiveProps(props) {
    // if (props.subdomain && props.subdomain.alpideSubdomainId && !this.state.subdomainName) {
    //   this.setState({
    //     subdomainName: props.subdomain.subdomainName ? 'https://' + props.subdomain.subdomainName : window.location.origin
    //   });
    // }
  }


  render() {
    return (
      <div>
           {this.state.emailDetailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      <FinancialReportComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></FinancialReportComp>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    financialReportList: state.school.financialReportList,
    financialReportCount: state.school.financialReportCount,
    subdomain: state.common.subdomain,
    defaultSchoolForm: state.common.defaultSchoolForm,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getFinancialReport, getgetFinancialReportCount,
      resetPaginatedData,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getSubdomain,
      sendResetMail,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FinancialReport);
