import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Col, Drawer, Row, Checkbox, Select,Skeleton } from "antd";
import { CustomButton } from "../../../../general/CustomButton";
import { TextBox } from "../../../../general/TextBox";
import { ErrorMsg } from "../../../../general/ErrorMessage";
import { Dropdown } from "../../../../general/Dropdown";
import { formatDateForBackend } from "../../../../../utils";
import find from "lodash.find";
import { Form } from "@ant-design/compatible";
const { Option } = Select;

const HierarchyDrawer = (props) => {
  const { getFieldDecorator } = props;

  const closeDrawer = () => {
    props.updateState({
      hierarchyDrawer: false,
    });
  };
  const handleSubmit = (props) => {
    if (
      props.hierarchyName &&
      (props.hierarchyParentId || props.isRootHierarchy) &&
      props.hierarchyLevel &&
      props.hierarchyCode
    ) {
      const payload = {
        relationshipId: (props.companyInfo || {}).relationshipId,
        isRootHierarchy: props.isRootHierarchy ? 1 : 0,
        hierarchyName: props.hierarchyName,
        hierarchyDescription: props.hierarchyDescription,
        hierarchyLevel: props.hierarchyLevel,
        hierarchyParentId: props.hierarchyParentId,
        hierarchyCode: props.hierarchyCode,
        dateCreated: formatDateForBackend(new Date()),
      };
      if (props.hierarchyData) {
        payload.hierarchyId = props.hierarchyData.hierarchyId;
      }

      props.showModal({
        modalBody: (
          <FormattedMessage
            id="company.companyHierarchy.confirmation.message"
            defaultMessage=""
          />
        ),
        handleSubmit: () => {
          props.createCompanyHierarchy(payload, props);
          closeDrawer();
        },
      });
    } else {
      props.updateDrawerState({
        invalidHeirarchyLevelCodeClass: !props.heirarchyLevelCode
          ? "input-text-error"
          : "",
        invalidHeirarchyLevelNameClass: !props.heirarchyLevelName
          ? "input-text-error"
          : "",
        invalidHierarchyNameClass: !props.hierarchyName
          ? "input-text-error"
          : "",
        invalidParentHierarchyNameClass: !props.hierarchyParentId
          ? "input-text-error"
          : "",
        submittedOnce: true,
      });
    }
  };
  const hierarchyParentName = find(props.companyHierarchyList, {
    hierarchyId: props.hierarchyParentId,
  })?.hierarchyName;
  return (
    <Drawer
      title="Create New"
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.hierarchyDrawer}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {
              handleSubmit(props)
            }


            }
            key={1}
          />
        </>
      }

    >
      {/* <Row gutter={[16,16]}>
        <Col span={12}>
          <div className="common-label">Hierarchy Name
          <span className="required">*</span>
          
          </div>
          <TextBox
            placeholder="Designation"
            type="text"
            maxLength={20}
            value={props.leadSourceName}
            onChange={(e) => {
              props.updateDrawerState({ leadSourceName: e.target.value });
            }}
          />
            <ErrorMsg
                validator={() => { return !props.submitted || props.leadSourceName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>
        <Col span={12}>
          <div className="common-label">Hierarchy Level
          <span className="required">*</span>
          
          </div>
          <Dropdown 
         placeholder="Select "
         />
            <ErrorMsg
                validator={() => { return !props.submitted || props.leadSourceName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>
        <Col span={12}>
          <div className="common-label">Hierarchy Code
          <span className="required">*</span>
          
          </div>
          <TextBox
            placeholder="Code"
            type="text"
            maxLength={20}
            value={props.leadSourceName}
            onChange={(e) => {
              props.updateDrawerState({ leadSourceName: e.target.value });
            }}
          />
            <ErrorMsg
                validator={() => { return !props.submitted || props.leadSourceName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>
        <Col span={12}>
          <div className="common-label">Hierarchy Description</div>
          <TextBox
            placeholder="Hierarchy Description"
            type="text"
            maxLength={20}
            value={props.leadSourceName}
            onChange={(e) => {
              props.updateDrawerState({ leadSourceName: e.target.value });
            }}
          />
            <ErrorMsg
                validator={() => { return !props.submitted || props.leadSourceName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>
        <Col span={12}>
          <div className="common-label">Parent Hierarchy</div>
         <Dropdown 
         placeholder="Select "
         />
            <ErrorMsg
                validator={() => { return !props.submitted || props.leadSourceName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>
        <Col span={12}>
          <div className="common-label">Root Hierarchy</div>
         <Dropdown 
         placeholder="Select "
         />
            <ErrorMsg
                validator={() => { return !props.submitted || props.leadSourceName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>
      </Row> */}
      <Skeleton loading={props.drawerLoading} paragraph={{ rows: 18 }} active>

      <Form layout="vertical" hideRequiredMark>
        <Row gutter={[16, 4]}>
          <Col span={12}>
            <FormattedMessage
              id="company.hierarchy.hierarchyName"
              defaultMessage=""
            />{" "}
            <span className="required">*</span>
          </Col>
          <Col span={12}>
            <FormattedMessage
              id="company.hierarchy.hierarchyLevel"
              defaultMessage=" "
            />
            <span className="required">*</span>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <TextBox
              maxLength={45}
              type="text"
              value={props.hierarchyName || undefined}
              onChange={(e) => {
                props.updateDrawerState({
                  hierarchyName: e.target.value,
                  invalidHierarchyNameClass: "",
                });
              }}
              placeholder={props.intl.formatMessage({
                id: "company.heirarchy.name.placeholder",
                defaultMessage: "",
              })}
              className={props.invalidHierarchyNameClass}
            />
            <ErrorMsg
              validator={() => {
                return !props.submittedOnce || !!props.hierarchyName;
              }}
              message={props.intl.formatMessage({
                id: "company.heirarchy.name.messgae",
                defaultMessage: "",
              })}
            />
          </Col>
          <Col span={12}>
            <Dropdown
              items={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
              // valueKeyName='hierarchyName'
              // optionKeyName='hierarchyId'
              value={props.hierarchyLevel}
              placeholder={props.intl.formatMessage({
                id: "company.hierarchy.hierarchyLevel.placeholder",
                defaultMessage: "",
              })}
              onSelect={(optionValue) => {
                props.updateDrawerState({
                  hierarchyLevel: optionValue,
                  invalidHeirarchyLevelCodeClass: "",
                });
                props.resetParentCompanyHierarchyByLevel();
                props.fetchParentCompanyHierarchiesByLevel({
                  relationshipId: (props.companyInfo || {}).relationshipId,
                  hierarchyLevel: optionValue,
                });
              }}
              className={props.invalidHeirarchyLevelCodeClass}
            />
            {/* <TextBox
                maxLength={45}
                type='text'
                value={props.hierarchyLevel || undefined}
                onChange={(e) => {
                  props.updateDrawerState({
                    hierarchyLevel: e.target.value,
                    invalidHeirarchyLevelCodeClass: ''
                  });
                }}
                placeholder={'Enter hierarchy level code'}
                className={props.invalidHeirarchyLevelCodeClass}
              /> */}
            <ErrorMsg
              validator={() => {
                return !props.submittedOnce || !!props.hierarchyLevel;
              }}
              message={props.intl.formatMessage({
                id: "company.hierarchy.hierarchyLevel.message",
                defaultMessage: "",
              })}
            />
          </Col>
        </Row>
        <Row gutter={[16, 4]}>
          <Col span={12}>
            <FormattedMessage
              id="company.hierarchy.hierarchyCode"
              defaultMessage=" "
            />{" "}
            <span className="required">*</span>
          </Col>
          <Col span={12}>
            <FormattedMessage
              id="company.hierarchy.hierarchDescription"
              defaultMessage=" "
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <TextBox
              maxLength={10}
              type="text"
              value={props.hierarchyCode || undefined}
              onChange={(e) => {
                props.updateDrawerState({
                  hierarchyCode: e.target.value,
                  invalidHeirarchyLevelNameClass: "",
                });
              }}
              placeholder={props.intl.formatMessage({
                id: "company.heirarchy.level.name.placeholder",
                defaultMessage: "",
              })}
              className={props.invalidHeirarchyLevelNameClass}
            />
            <ErrorMsg
              validator={() => {
                return !props.submittedOnce || !!props.hierarchyCode;
              }}
              message={props.intl.formatMessage({
                id: "company.heirarchy.level.name.message",
                defaultMessage: "",
              })}
            />
          </Col>
          <Col span={12}>
            <TextBox
              maxLength={45}
              type="text"
              value={props.hierarchyDescription || undefined}
              onChange={(e) => {
                props.updateDrawerState({
                  hierarchyDescription: e.target.value,
                });
              }}
              placeholder={props.intl.formatMessage({
                id: "company.hierarchy.hierarchDescription.placeholder",
                defaultMessage: "",
              })}
            />
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <FormattedMessage
              id="company.hierarchy.parentHierarchy"
              defaultMessage=""
            />{" "}
            {props.isRootHierarchy ? "" : <span className="required">*</span>}
          </Col>
          <Col span={12}>Root Hierarchy</Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Dropdown
              disabled={!(props.companyHierarchyList || []).length}
              items={props.companyHierarchyList || []}
              valueKeyName="hierarchyName"
              optionKeyName="hierarchyId"
              hierarchyLevelKey="hierarchyLevel"
              hierarchyCodeLineKey="hierarchyCode"
              value={props.hierarchyParentName || hierarchyParentName}
              placeholder={props.intl.formatMessage({
                id: "company.hierarchy.parentHierarchy.placeholder",
                defaultMessage: "",
              })}
              onSelect={(optionValue, optionKey) => {
                props.updateDrawerState({
                  hierarchyParentId: optionValue,
                  hierarchyParentName: optionKey.name,
                  invalidParentHierarchyNameClass: "",
                });
              }}
              className={
                props.isRootHierarchy
                  ? ""
                  : props.invalidParentHierarchyNameClass
              }
            />
            <ErrorMsg
              validator={() => {
                return (
                  !props.submittedOnce ||
                  !!props.parentHierarchyName ||
                  props.isRootHierarchy
                );
              }}
              message={props.intl.formatMessage({
                id: "company.hierarchy.parentHierarchy.message",
                defaultMessage: "",
              })}
            />
          </Col>
          {/* <Col span={1}>
              <Checkbox
                value={props.isRootHierarchy}
                onChange={(e) => {
                  props.updateDrawerState({
                    isRootHierarchy: e.target.checked,
                  });
                }}
              />
            </Col> */}
          {/* <Col span={12}>
            
                      <Select
                        placeholder="Select"
                        value={props.isRootHierarchy ? "Yes" : "No"}
                        onChange={(e) => {                                                
                          props.updateDrawerState({
                            isRootHierarchy: e === "No" ? 0 : 1,
                          });
                        }}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                 
            </Col> */}
          <Col span={12}>
            <Select
              value={props.isRootHierarchy ? 'yes' : 'no'}
              onChange={(value) => {
                props.updateDrawerState({
                  isRootHierarchy: value === 'yes',
                });
              }}
            >
              <Option value="yes">Yes</Option>
              <Option value="no">No</Option>
            </Select>
          </Col>
        </Row>

      </Form>
      </Skeleton>
    </Drawer>
  );
};

export default injectIntl(HierarchyDrawer);
