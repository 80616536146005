import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ApplyPurchaseCreditComponent from '../../../../../components/modal/modalBody/supplier/ApplyPurchaseCredit';
import { fetchAvailableDebitsList, resetAvailableDebitsList, applyDebits } from './action';
import { popModalFromStack, pushModalToStack, showModal, hideModal} from './../../../../../actions/commonActions';
//import { fetchPaginationDataIfNeeded, fetchDataIfNeeded } from  '../../../../../utils';
import { fetchPurchaseInvoices } from '../../../../supplier/invoice/Listing/action';

class ApplyPurchaseCredits extends Component {
    constructor(props) {
        super(props);
        this.state = {
          searchedText: ''
        };
      }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, supplierId: this.props.invoiceData.supplierId, invoiceMasterId: this.props.invoiceData.invoiceMasterId };
        
        this.props.resetAvailableDebitsList();
        this.props.fetchAvailableDebitsList(payload);
        
    }

    render() {
        return <ApplyPurchaseCreditComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        userInfo: state.common.userInfo,
        availableDebitList: state.purchaseInvoice.availableDebitList
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    popModalFromStack,
    pushModalToStack, 
    showModal, 
    hideModal,
    fetchAvailableDebitsList, 
    resetAvailableDebitsList,
    applyDebits,
    fetchPurchaseInvoices
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ApplyPurchaseCredits);