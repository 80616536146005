import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import  AccountingComponent from '../../../components/integrations/Accounting'
import { fetchXeroAuthURL, generateToken, getXeroConfiguration, disconnectConfiguration } from './action'
import queryString from 'query-string';
import { getCompanyInfo } from '../../../utils';
class Accounting extends Component {

   
    
    componentDidMount() {
        const companyInfo = this.props.companyInfo || getCompanyInfo() || {};
        const linkProps = queryString.parse(this.props.location.search) || {}
        const payload = {
          code: this.props.code || linkProps.code,
          relationshipId: companyInfo.relationshipId
        };
        payload.code && this.props.generateToken(payload, this.props);
        this.props.getXeroConfiguration(payload);
      }

    render() {
        return <AccountingComponent {...this.props}/>
    }

}

const mapStateToProps = (state) => {
    return {
        xeroConfigData: state.common.xeroConfigData
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchXeroAuthURL,
    generateToken,
    getXeroConfiguration,
    disconnectConfiguration
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Accounting);
