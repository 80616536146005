import { CUSTOMER_EXPENSE_ACTION_LIST } from '../static/constants';
const initialState = {
  customerExpenseList: {
    1: []
  },
  customerExpenseCount: 0,
  customerExpenseDetail: {}
}

const CustomerExpenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_EXPENSE_ACTION_LIST.CUSTOMER_EXPENSE_LIST:
      return {
        ...state,
        customerExpenseList: {
          ...state.customerExpenseList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case CUSTOMER_EXPENSE_ACTION_LIST.CUSTOMER_EXPENSE_COUNT:
      return { ...state, customerExpenseCount: action.data };
    case CUSTOMER_EXPENSE_ACTION_LIST.CUSTOMER_EXPENSE_LIST_PAGE_RESET:
      return { ...state, customerExpenseList: initialState.customerExpenseList };
    case CUSTOMER_EXPENSE_ACTION_LIST.CUSTOMER_EXPENSE_DETAIL:
      return { ...state, customerExpenseDetail: action.data };
    default:
      return state;
  }
};

export default CustomerExpenseReducer;