import React, { Fragment, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col} from 'antd';
import { CustomButton } from '../../../../general/CustomButton';
import { MODAL_ACTIONS } from '../../../../../static/constants';
import { Dropdown } from '../../../../general/Dropdown';
import * as find from "lodash.find";

const SystemStatusCompo = (props) => {
  const { formData={}, updateFormData, data={} } = props;
  //const labelName = getLabelName(modalType);
  formData.textBoxValue = data.txData ? data.txData.status : formData.textBoxValue;
  const [formDataObj, setFormDataObj] = useState(formData);
  const dispatch = useDispatch();
  let statusList = [{statusId:'paid', statusName:'Paid'},{statusId:'unpaid', statusName:'Unpaid'},{statusId:'partially.paid', statusName:'Partially Paid'}];

  // const addItemConfirmationModal = (props, text, modalType, statusColor) => {
  //   const { showModal, companyInfo } = props;
  //   const labelName = <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />;
  //   const modalBody = <span>
  //     <FormattedMessage
  //       id='addItem.confirmation'
  //       values={{ value: `${text} to ` }}
  //       defaultMessage={``}
  //     />
  //     {labelName} ?
  //   </span>;

  //   let payload = {
  //     statusName: text,
  //     statusColor: statusColor || '',
  //     relationshipId: companyInfo.relationshipId,
  //     transactionName: props.transactionName
  //   }

  //   const modalData = {
  //     modalBody,
  //     hideFooter: false,
  //     handleSubmit: () => {
  //        props.createStatus(payload);
  //        props.hideModal();
  //   },
  //   };
  //   showModal(modalData);
  // }


  // const handleNewItemAddition = (props, payload, modalType) => {
  //   const formData = payload.formData;
  //   const { showModal } = props;

  //   if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
  //     addItemConfirmationModal(props, formData.textBoxValue, modalType);
  //   } else {
  //     const data = {
  //       title: <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />,
  //       formData,
  //       hideFooter: props.transactionName === CONSTANTS_TRANSACTIONS.STUDENT? true: false,
  //       //hideFooter: false,
  //       modalData: { modalType, data: payload },
  //       handleSubmit: (formData = {}) => {
  //         if (formData.textBoxValue && formData.textBoxValue.length) {
  //           addItemConfirmationModal(props, formData.textBoxValue, modalType, formData.statusColor);
  //         } else {
  //           data.formData.submittedOnce = true;
  //           showModal(data);
  //         }
  //       }
  //     };
  //     showModal(data);
  //   }
  // }

  // const deleteClickHandler = (props, payload) => {
  //   const { showModal } = props;
  //   const modalBody =
  //     <FormattedMessage
  //       id='deleteItem.confirmation'
  //       defaultMessage={``}
  //       values={{ value: payload.text }}
  //     />;



  //   const modalData = {
  //     modalBody,
  //     handleSubmit: () => {
  //       const selectedObj = find((props.userStatus || []), { statusName: payload.text}) || {};
  //       //selectedObj.transactionName = props.transactionName;
  //       props.deleteStatus(selectedObj, props)
  //       props.hideModal();
  //     },
  //   };
  //   showModal(modalData);
  // }

  // const getStatusIdKey = (transactionName) => {
  //   let keyId = 'registrationStatusId';
  //   switch (transactionName) {
  //     case CONSTANTS_TRANSACTIONS.INQUIRY: {
  //       keyId = 'inquiryStatusId';
  //       break;
  //     }
  //     case CONSTANTS_TRANSACTIONS.SALES_QUOTE: {
  //       keyId = 'sqStatusId';
  //       break;
  //     }
  //     case CONSTANTS_TRANSACTIONS.SALES_ORDER: {
  //       keyId = 'soStatusId';
  //       break;
  //     }
  //     case CONSTANTS_TRANSACTIONS.SALES_INVOICE: {
  //       keyId = 'salesInvoiceStatusId';
  //       break;
  //     }
  //     case CONSTANTS_TRANSACTIONS.SHIPMENT: {
  //       keyId = 'shipmentStatusId';
  //       break;
  //     }
  //     case CONSTANTS_TRANSACTIONS.PURCHASE_REQUEST: {
  //       keyId = 'prStatusId';
  //       break;
  //     }
  //     case CONSTANTS_TRANSACTIONS.PURCHASE_ORDER: {
  //       keyId = 'poStatusId';
  //       break;
  //     }
  //     case CONSTANTS_TRANSACTIONS.INBOUND_DELIVERY: {
  //       keyId = 'poStatusId';
  //       break;
  //     }
  //     case CONSTANTS_TRANSACTIONS.PURCHASE_INVOICE: {
  //       keyId = 'purchaseInvoiceStatusId';
  //       break;
  //     }
  //     default:
  //       break;
  //   }
  //   return keyId;
  // };
 
  return (
    <Fragment>
    <Form
      layout='vertical'
      className='login-form'
      onSubmit={(e) => {
        e.preventDefault();
        if (formDataObj.textBoxValue && formDataObj.textBoxValue.length) {
          e.preventDefault();
          props.handleSubmit(formDataObj);
        } else {
          setFormDataObj({ submittedOnce: true, textBoxValue: formDataObj.textBoxValue, statusColor: formDataObj.statusColor });
        }
      }}
    >
      <Row>
        <Col span={6} className='padding-top-7'>
          <span className="text-input-label">
          <FormattedMessage id='common.status' defaultMessage='' />
            <span className="required">*</span>
          </span>
        </Col>
       
          <Col span={12}>
            <Dropdown
              items={statusList}
              valueKeyName='statusName'
              optionKeyName='statusId'
              value={formDataObj.textBoxValue}
              style={{ width: '100%' }}
             
              onSelect={(selectedValue, option) => {
                const selectedObj = find((statusList || []), { statusId: option.value }) || {};
                updateFormData && updateFormData({ ...formDataObj, textBoxValue: ((option || {}).props || {}).name, statusColor: selectedObj.statusColor });
                setFormDataObj({ ...formDataObj, textBoxValue: ((option || {}).props || {}).name, statusColor: selectedObj.statusColor });
                dispatch({
                  type: MODAL_ACTIONS.MODAL_FORM_DATA_UPDATE,
                  data: { formData: { textBoxValue: ((option || {}).props || {}).name, optionId: ((option || {}).props || {}).key } }
                })
              }}
            />
          </Col>
          </Row>
          <br></br>
          <Row>
           <Col span={21}>
              <CustomButton
                intlId={'modal.contact.button.cancel'}
                defaultMessage={''}
                type={'default'}
                key={1}
                onClick={() => { dispatch({ type: MODAL_ACTIONS.POP_STACK_MODAL });}}
              /></Col>
               <Col span={3}>
              <CustomButton className="right-pull"
                intlId={'button.save.label'}
                defaultMessage={''}
                htmlType='submit'
                key={2}
              />
            </Col>
          </Row>

    </Form>
    </Fragment>
  )

  
}
export default (injectIntl(SystemStatusCompo));