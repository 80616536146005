import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RecurringInvoiceSetupComponent from '../../../../../components/modal/modalBody/customer/RecurringInvoiceSetup';
import EmailComponent from '../../../../../containers/drawer/recurringInvoiceEmail';
import {saveRecuringInvoice, getRecuringInvoiceSetupByInvoiceMasterId, resetRecurringInvoiceSetup} from './action';

class RecurringInvoiceSetup extends Component {

    constructor(props){
        super();
        this.state= {
            emaildrawerVisible: false,
            subject: '',
            emailBody: '',
            period: 'daily',
            startDate: new Date(),
            endDate: new Date(),
            customerId: props.salesInvoiceDetail.customerId,
            isBulkInvoice: props.isBulkInvoice || false
        }
    }
    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.salesInvoiceDetail.customerId, invoiceMasterId: this.props.salesInvoiceDetail.invoiceMasterId };
        !this.state.isBulkInvoice && this.props.getRecuringInvoiceSetupByInvoiceMasterId(payload);
    }

    componentWillReceiveProps(props) {

        if (props.recurringInvoiceSetup && props.recurringInvoiceSetup.recurringInvoiceId) {
            this.setState({
                recurringInvoiceId: props.recurringInvoiceSetup.recurringInvoiceId,
                startDate: props.recurringInvoiceSetup.startDate,
                endDate: props.recurringInvoiceSetup.endDate,
                period: props.recurringInvoiceSetup.period,
                dateCreated: props.recurringInvoiceSetup.dateCreated,
                isExpire: props.recurringInvoiceSetup.isExpire,
                dayOfWeek: props.recurringInvoiceSetup.dayOfWeek ? props.recurringInvoiceSetup.dayOfWeek+"" : "",
                dayOfMonth: props.recurringInvoiceSetup.dayOfMonth,
                dayOfYear: props.recurringInvoiceSetup.dayOfYear,
                monthOfYear: props.recurringInvoiceSetup.monthOfYear ? props.recurringInvoiceSetup.monthOfYear+"" : "",
                emailDetails: props.recurringInvoiceSetup.emailDetails,
                notifyCustomer : props.recurringInvoiceSetup.isSendEmail ? 1 : 0,
                isCopyReminder: props.recurringInvoiceSetup.isCopyReminder ? 1: 0
            });
            props.resetRecurringInvoiceSetup();
        }
    }
    render() {
        return <div>
            {this.state.emailDetailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <RecurringInvoiceSetupComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        salesInvoiceDetail: state.salesInvoice.salesInvoiceDetail,
        userInfo: state.common.userInfo,
        availableCredits: state.salesInvoice.availableCredits,
        recurringInvoiceSetup: state.salesInvoice.recurringInvoiceSetup
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    saveRecuringInvoice,
    getRecuringInvoiceSetupByInvoiceMasterId,
    resetRecurringInvoiceSetup
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RecurringInvoiceSetup);