import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShippingInvoiceDetailComponent from '../../../../../components/modal/modalBody/customer/ShippingInvoiceDetail';
import { fetchShippingInvoiceDetail, createShippingInvoice } from './action';
import { fetchDataIfNeeded } from '../../../../../utils';
import { fetchCustomerDetailsByCustomerId, fetchUserPreferences, saveUserPrefrences, fetchTaxes, fetchCountries } from '../../../../../actions/commonActions'
import { generatePDF } from '../../../../../actions/downloadFileAction';
import EmailComponent from '../../../../../containers/drawer/email';
import find from 'lodash.find';

class ShippingInvoiceDetail extends Component {

    constructor(props){
        super();
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
            txPreferences: { txType: "shippingInvoice", templateName: "standard" },
            txColumns: [],
            txColumnSetting: {},
            txMandatoryColumns: [
              'itemName',
              'description',
              'rate',
              'uomId',
              'quantity',
              "sno"
            ],
            txAvailableColumn: {
              itemName: true,
              quantity: true,
              description: true,
              uom: true,
              rate: true,
              stockNumber: true,
              partNumber: true,
              warehouseName: true,
              origin: true,
              discount: true,
              specialDiscount: true,
              tax: true,
              hsCode: true,
              materialNumber: true,
              packageNumber: true,
              salesOrderNumber: true
            },
        }
    }

    componentDidMount() {
        this.props.updateHeaderState({
          collapsed: true
        });
        const companyInfo = this.props.companyInfo || {};
        const payload = {
          relationshipId: companyInfo.relationshipId,
          userId: (this.props.userInfo || {}).userId
        };
        fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
        fetchDataIfNeeded('fetchCountries', 'countries', this.props, payload);
      }
    
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.shipmentPayload.customerId, shipmentMasterId: this.props.shipmentPayload.shipmentMasterId };
        fetchDataIfNeeded('fetchShippingInvoiceDetail', 'shippingInvoiceData', this.props, payload, true);
        fetchDataIfNeeded('fetchCustomerDetailsByCustomerId', 'customerData', this.props, payload, true);
        fetchDataIfNeeded('fetchTaxes', 'taxes', this.props, payload);

    }

    componentWillReceiveProps(props){
        if (props.allPreferences && props.allPreferences.length) {
            let txPreferences = find(props.allPreferences, { txType: "shippingInvoice", templateName: "standard" }) || {};
            if (txPreferences) {
              let txColumns = txPreferences.gridPreference ? ((JSON.parse(txPreferences.gridPreference)) || {}).columnList || [] : [];
              txColumns = txColumns.length ? txColumns : (this.state.txMandatoryColumns || []).concat('product', 'description');
              const txColumnSetting = txPreferences.gridPreference ? ((JSON.parse(txPreferences.gridPreference)) || {}).columnSetting || {} : [];
              const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false : true;
              let tablePreference = txPreferences.tablePreference;
              this.setState({
                tablePreference,
                isColumnFitTable,
                preferenceId: txPreferences.preferenceId,
                txColumns,
                txColumnSetting
              });
            } else {
              this.setState({
                txColumns: (this.state.txMandatoryColumns || []).concat('itemName', 'description', 'rate')
              })
            }
          }
          if(props.shippingInvoiceData){
          }
    }

    render() {
        return <div>
             {this.state.emailDrawerVisible && <EmailComponent {...this.state} updateState={(data) => { this.setState(data) }} />}
            <ShippingInvoiceDetailComponent {...this.props} {...this.state} updateShippingState={(data) => {
               this.setState(data) 
               }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        shippingInvoiceData: state.so.soShippingInvoiceDetail,
        customerData: state.common.customerDetail,
        allPreferences: state.common.allPreferences,
        taxes: state.common.taxes,
        countries: state.common.countries,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchShippingInvoiceDetail,
    fetchCustomerDetailsByCustomerId,
    fetchUserPreferences, 
    fetchTaxes,
    saveUserPrefrences,
    createShippingInvoice,
    fetchCountries,
    generatePDF
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShippingInvoiceDetail);