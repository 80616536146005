import { CUSTOMER_PAYMENT_ACTION_LIST } from '../static/constants';
const initialState = {
  customerPaymentList: {
    1: []
  },
  customerPaymentCount: 0,
  customerPaymentDetail: {},
  customerSubscriptionList: {
    1: []
  },
  customerSubscriptionCount: 0,
  customerCashflow: 0,
  recent10Payments: [],
  allCustomerPayments: [],
}

const CustomerPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_PAYMENT_LIST:
      return {
        ...state,
        customerPaymentList: {
          ...state.customerPaymentList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_PAYMENT_COUNT:
      return { ...state, customerPaymentCount: action.data };
    case CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_PAYMENT_LIST_PAGE_RESET:
      return { ...state, customerPaymentList: initialState.customerPaymentList };
    case CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_PAYMENT_DETAIL:
      return { ...state, customerPaymentDetail: action.data }
    case CUSTOMER_PAYMENT_ACTION_LIST.NEXT_PAYMENT_NUMBER:
      return { ...state, nextTxNumber: action.data };
    case CUSTOMER_PAYMENT_ACTION_LIST.NEXT_PAYMENT_NUMBER_RESET:
      return { ...state, nextTxNumber: '' };
    case CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_SUBSCRIPTION_LIST:
      return {
        ...state,
        customerSubscriptionList: {
          ...state.customerSubscriptionList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_SUBSCRIPTION_COUNT:
      return { ...state, customerSubscriptionCount: action.data };
    case CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_SUBSCRIPTION_LIST_PAGE_RESET:
      return { ...state, customerSubscriptionList: initialState.customerSubscriptionList };
    case CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_PAYMENT_CASHFLOW:
      return { ...state, customerCashflow: action.data };
    case CUSTOMER_PAYMENT_ACTION_LIST.RECENT_10_PAYMENTS:
      return { ...state, recent10Payments: action.data };
    case CUSTOMER_PAYMENT_ACTION_LIST.ALL_CUSTOMER_PAYMENTS:
      return { ...state, allCustomerPayments: action.data };
    default:
      return state;
  }
};

export default CustomerPaymentReducer;