import { AUTH, LOCAL_STORAGE_KEYS } from '../static/constants';
import { primaryPermissionConversion, fetchFromLocalStorage } from '../utils';
// const initialState = {
//   permissions: {
//     primary: ['A-1', 'G-1', 'K-3', 'AD-1', 'B-3', 'L-3', 'M-3', 'F-3', 'E-1', 'D-3', 'C-3',
//       'N-9', 'S-3', 'T-3', 'O-5', 'P-5', 'Q-5', 'R-5', 'BC-1', 'BD-5', 'BE-3', 'X-1', 'AK-3',
//       'AL-3', 'AR-3', 'Y-1', 'Z-3', 'AA-3', 'AB-1', 'AC-1', 'AD-3', 'AE-1', 'AF-1', 'AG-9',
//       'AH-3', 'AI-3', 'AJ-3', 'H-1', 'I-1', 'J-3', 'AZ-8', 'BQ-1', 'BR-1', 'AK-1', 'AL-1',
//       'BP-2', 'BQ-2', 'BR-2', 'BS-2', 'BT-2'],
//     secondary: {
//       productPrice: {
//         listing: {
//           retailPrice: true,
//           wholesalePrice: false,
//           purchasedPrice: false
//         }
//       },
//       agedStock: {
//         listing: {
//           lastPurchasePrice: false
//         }
//       }
//     }
//   }
// }

const initialState = {
  permissions: {
    primary: [],
    secondary: {}
  },
  showResendActivationLink: false,
  sessionType: 'valid'
}

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH.LOGOUT:
      return { state: {} }
    case AUTH.SET_PERMISSIONS: {
      const permData = action.data;
      if (permData && permData.primary) {
        permData.primary = primaryPermissionConversion(permData.primary);
      }
      return { ...state, permissions: permData };
    }
    case AUTH.SHOW_RESEND_ACTIVATION:
      return { showResendActivationLink: true }
    case AUTH.HIDE_RESEND_ACTIVATION:
      return { hideResendActivationLink: false }
    case AUTH.SESSION_TYPE:
      return { sessionType: action.data }
    default: {
      const savedPermissionData = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.PERMISSION_DATA);
      if (savedPermissionData !== undefined) {
        state.permissions = JSON.parse(savedPermissionData);
      }
      if (state.permissions && state.permissions.primary) {
        state.permissions.primary = primaryPermissionConversion(state.permissions.primary);
      }
      return state;
    }
  }
};

export default AuthReducer;