import React, { Fragment } from "react";
import { Skeleton,Empty } from "antd";
import {
  fixedDecimalAndFormateNumber,
} from "../../../../utils";
import { injectIntl, FormattedMessage } from "react-intl";
import InvoicesListing from "../../../../containers/modal/modalBody/supplier/AllInvoicesByLedger";

const PurchaseOrder = (props) => {
  return (
    <Fragment>
      {/* <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id="ledgerSummary.text" />
          </div>

        </div>
        <div className="top-view-right"></div>
      </div> */}
      <div className="view-container">
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">Ledger Summary</div>
              {/* {
                                suppliers[pageNumber].length ? <>
                                    <div className="vertical-sep" />
                                    <div>{suppliers[pageNumber].length}</div>
                                </> : ''
                            } */}
            </div>
            <div className="right-actions">
              {/* <div> Filter : </div> */}
              {/* <RangePicker
                className="drop-fileds2"
                allowEmpty={true}
                format={format}
                placeholder={["Start date", "End date"]}
                value={[
                  props.pLStartDate
                    ? getMomentDateForUI({
                        date: new Date(props.pLStartDate),
                        format,
                      })
                    : null,
                  props.pLEndDate
                    ? getMomentDateForUI({
                        date: new Date(props.pLEndDate),
                        format,
                      })
                    : null,
                ]}
                onChange={(dates) => {
                  if (dates) {
                    props.updateState({
                      pLStartDate: dates[0],
                      pLEndDate: dates[1],
                    });
                  } else {
                    props.updateState({ pLStartDate: null, pLEndDate: null });
                  }
                }}
              /> */} 
              {/* <button
                className="ant-btn ant-btn-default vab ml5"
                onClick={() => {
                  if (!props.pLStartDate && !props.pLEndDate) {
                    return showToasterMessage({
                      messageType: "error",
                      description: "Please select date range",
                    });
                  }
                  const payload = {
                    supplierId: props.supplierId,
                    relationshipId: companyInfo.relationshipId,
                    pageNumber: 0,
                    pageSize: props.pageSize,
                    filters: {
                      startDate: props.pLStartDate
                        ? getCurrentDateForBackend(props.pLStartDate)
                        : null,
                      endDate: props.pLEndDate
                        ? getCurrentDateForBackend(props.pLEndDate)
                        : null,
                    },
                  };
                  props.fetchSupplierLedgerTxSummaryList(payload);
                }}
              >
                {<FilterOutlined />} Apply Filter
              </button>
              <button
                className="ant-btn ant-btn-default vab ml5"
                onClick={() => {
                  props.updateState({
                    pLStartDate: null,
                    pLEndDate: null,
                  });
                  const payload = {
                    supplierId: props.supplierId,
                    relationshipId: companyInfo.relationshipId,
                    pageNumber: 0,
                    pageSize: props.pageSize,
                    filters: {},
                  };
                  props.fetchSupplierLedgerTxSummaryList(payload);
                }}
              >
                {<UndoOutlined />} Clear Filter
              </button> */}

              
            </div>
          </div>

          <Skeleton loading={props.listLoading} active paragraph={{ rows: 6 }}>

            <div className="table-container">
              <table className="">
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="serial.no" defaultMessage="" />
                    </th>
                    <th>Ledger</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  { props.supplierInvoiceLedgerSummaryList.length?  props.supplierInvoiceLedgerSummaryList.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          <span
                            className="cursor-pointer"
                            onClick={() => {
                              const invoicePayload = {
                                supplierId: e.supplierId,
                                ledgerAccountId: e.ledgerAccountId,
                              };
                              props.pushModalToStack({
                                title: (
                                  // <Fragment>
                                  //   <div className="ant-modal-title">
                                  //     {" "}
                                  //     <FormattedMessage
                                  //       id="sidebar.menuItem.invoice"
                                  //       defaultMessage=""
                                  //     />
                                  //   </div>
                                  // </Fragment>
                                  <Fragment>
                                     <div className="ant-modal-title dis-col b-text">
                                      <div>Account Statement</div>
                                      <div>

                                      {props.supplier.supplierStoreName}                            
                                      </div>
                                      <div>

                                      Ledger Type - {e.ledgerAccountName}
                                      </div>
                    </div>
                                </Fragment>
                                ),
                                modalBody: (
                                  <InvoicesListing
                                    {...props}
                                    {...invoicePayload}
                                    ledgerAccountName = {e.ledgerAccountName}
                                    invoicePayload={invoicePayload}
                                  />
                                ),
                                width: 1000,
                                hideFooter: true,
                                wrapClassName: "modal-custom-detail",
                              });
                            }}
                          >
                            {e.ledgerAccountName || <div className="empty-data-box"></div>}
                          </span>
                        </td>
                        <td style={{ width: "15%" }}>
                          <span>
                            {fixedDecimalAndFormateNumber(e.totalAmount)}
                          </span>
                        </td>
                      </tr>
                    );
                  }): <tr key="empty-data">
                  <td colSpan={"100%"} style={{borderBottom:"none"}}>
                    <Empty />
                  </td>
                </tr>}
                </tbody>
              </table>
            </div>
        
        </Skeleton>
      </div>
      <div className="footer">
        <div className="f-left"></div>
        <div className="f-right"></div>
      </div>
    </Fragment>
  );
};

export default injectIntl(PurchaseOrder);
