import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from 'react-intl';
import PageBreadcrumb from "../../PageBreadcrumb";
import { CONSTANTS, ICONS } from '../../../static/constants';
import { Skeleton, Checkbox, Pagination, Menu, Tooltip, Empty } from "antd";
import DropdownAction from "antd/lib/dropdown";
import NewsLetter from "../../../containers/NewsLetter";
import { getMomentDateForUIReadOnly } from "../../../utils";
import ReactHtmlParser from 'react-html-parser';
import ImagePreview from "../../../assets/images/img-placeholder-alpide.jpg";

const EmailTemplateComp = (props) => {
    const breadCrumbList = [
        {
            name: <FormattedMessage id='sidebar.menuItem.emailMarketing' defaultMessage='' />
        },
        {
            name: <FormattedMessage id='sidebar.menuHeading.email' defaultMessage='' />
        },
        {
            name: <FormattedMessage id='sidebar.subMenuItem.emailTemplate' defaultMessage='' />,
        }
    ];

    const actionMenu = (item) => {
        return (
            <Menu className="row-action-dropdown">
                <Menu.Item key="0" value="title">
                    Action
                </Menu.Item>
                <Menu.Divider />



                <Menu.Item key="1" value="edit" onClick={() => newFormBuilder(item)}>
                    <i className={ICONS.EDIT} /> Edit
                </Menu.Item>


                <Menu.Item onClick={() => replicateTemplate(item)} key="2">
                    <i className={ICONS.COPY} /> Replicate
                </Menu.Item>

                <Menu.Item onClick={() => {
                    props.showModal({
                        modalBody: "Are you sure want to delete?",
                        handleSubmit: () => {
                            props.deleteFormBuilder(item, props)
                        },
                    });
                }} key="3">
                    <i className={ICONS.COPY} /> Delete
                </Menu.Item>

            </Menu>
        );
    };

    const replicateTemplate = (data) => {

        props.pushModalToStack({
            modalBody: <NewsLetter {...props} templateType='marketingTemplate' alpideFormBuilderId={data?.alpideFormBuilderId} isReplicate={true}
            
            
            />,
            width: '100%',
            hideTitle: true,
            hideFooter: true,
            wrapClassName: "emailerTemplate_Modal",
            keyboard: false,
            onCancel: () => {
                props.showModal({
                    modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage="" />,
                    footer: { submit: { intlId: "button.close.label" } },
                    handleSubmit: () => {
                        props.popModalFromStack();
                    }
                })
            }
        })
    };
    const newFormBuilder = (data) => {

        props.pushModalToStack({
            modalBody: <NewsLetter {...props} templateType='marketingTemplate' alpideFormBuilderId={data?.alpideFormBuilderId} />,
            width: '100%',
            hideTitle: true,
            hideFooter: true,
            wrapClassName: "emailerTemplate_Modal",
            keyboard: false,
            onCancel: () => {
                props.showModal({
                    modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage="" />,
                    footer: { submit: { intlId: "button.close.label" } },
                    handleSubmit: () => {
                        props.popModalFromStack();
                    }
                })
            }
        })
    };
    return (
        <>
            <div className="emailTemplate">
                <div className="view-top-bar">
                    <div className="top-view-left">
                        <div className="page-heading">
                            <FormattedMessage id='heading.communications.emailMarketing' defaultMessage='' />
                        </div>
                        <div className="vertical-sep" />
                        <PageBreadcrumb breadCrumbList={breadCrumbList} />
                    </div>
                    <div className="top-view-right">
                        <button className="create-action-btn" onClick={() => { newFormBuilder(); }}>
                            <i className={ICONS.ADD} /> <FormattedMessage id="button.create.label" defaultMessage="" />
                        </button>
                    </div>
                </div>
                <div className="view-container">
                    <div className="view-container-actions">
                        <div className="left-actions">
                            <div className="table-heading">
                                <FormattedMessage id='emailMarketing.emailTemplate.table.heading' defaultMessage='' />
                            </div>

                        </div>

                    </div>
                    <div className="table-container">
                        <Skeleton loading={props.listLoading} paragraph={{ rows: 15 }} active>

                            <table>
                                <thead>
                                    <tr>

                                        <th>S.No.</th>
                                        <th>Template Name</th>
                                        <th>Date Created</th>
                                        <th>Created By</th>
                                        <th className="text-center">Status</th>

                                        <th className="text-center"><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (props.newsletterTemplateList || []).length ? props.newsletterTemplateList.map((rowData, i) => {
                                            return <tr key={i}>

                                                {/* <td>
                                                    <div
                                                        onClick={() => {
                                                            props.pushModalToStack({
                                                                
                                                                modalBody: (
                                                                    ReactHtmlParser(rowData?.templateContent)
                                                                ),
                                                                width: "100%",
                                                                height: "100%",
                                                                hideFooter: true,
                                                                hideTitle:true,
                                                                wrapClassName: "image-preview",
                                                            });
                                                        }}
                                                    >
                                                        {<Tooltip placement="topLeft" title="Preview Template">
                                                            <img
                                                                style={{ height: "40px", margin: "5px" }}
                                                                src={ImagePreview}
                                                                alt="image"
                                                                className="image cursor-pointer"
                                                            />
                                                        </Tooltip>}
                                                    </div>
                                                </td> */}
                                                <td>{i + 1}</td>
                                                <td >
                                                    <span className="link"
                                                        //  onClick={() => {
                                                        //     newFormBuilder(rowData)
                                                        // }}
                                                        onClick={() => {
                                                            props.showModal({

                                                                modalBody: (
                                                                    ReactHtmlParser(rowData?.templateContent)
                                                                ),
                                                                width: "100%",
                                                                height: "100%",
                                                                hideFooter: true,
                                                                hideTitle: true,
                                                                wrapClassName: "image-preview",
                                                            });
                                                        }}

                                                    >
                                                        {rowData.templateName}
                                                    </span>
                                                </td>

                                                <td>{<Tooltip placement="topLeft" title={(rowData.dateCreated && getMomentDateForUIReadOnly({ date: rowData.dateCreated, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                                                    {(rowData.dateCreated && getMomentDateForUIReadOnly({ date: rowData.dateCreated, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                                </Tooltip>}
                                                </td>
                                                <td>{props.userInfo?.firstName}</td>
                                                <td className="text-center">
                                                    {rowData.alpideFormBuilderId ?
                                                        <span className={`status-code ready`}>Ready</span>
                                                        :
                                                        <span className={`status-code draft`}>Draft</span>}
                                                </td>
                                                {/* <td className="text-center cursor-pointer" onClick={() => {
                                                    props.showModal({
                                                        modalBody: "Are you sure want to delete?",
                                                        handleSubmit: () => {
                                                            props.deleteFormBuilder(rowData, props)
                                                        },
                                                    });
                                                }}>
                                                    <i className={ICONS.DELETE} />
                                                </td> */}
                                                <td className="text-center">
                                                    <DropdownAction
                                                        overlay={actionMenu(rowData)}
                                                        trigger={["click"]}
                                                    >
                                                        <i className={ICONS.MORE_P} />
                                                    </DropdownAction>
                                                </td>
                                            </tr>
                                        })
                                            :
                                            <tr key="empty-data-box">
                                                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                                    <Empty />
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </Skeleton>
                    </div>
                </div>
            </div>
        </>
    )
};

export default injectIntl(EmailTemplateComp);
