import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import { AgGridReact } from 'ag-grid-react';
import { Skeleton, Pagination, Popover, Tooltip, Empty } from "antd";
import {
  fetchPaginationDataIfNeeded,
  getMomentDateForUIReadOnly,
} from "../../../../utils";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "./index.scss";
import PRDetail from "../../../../containers/modal/modalBody/supplier/PRDetail";
import { CONSTANTS } from "../../../../static/constants";
import { AddAndRemoveSupplierListingColumn } from "../../../general/AddAndRemoveSupplierListingColumn";
import { FilterOutlined, InsertRowRightOutlined } from "@ant-design/icons";

const PurchaseRequestListingComp = (props) => {
  const {
    purchaseRequestList,
    updateState,
    companyInfo,
    purchaseRequestCount,
    pageNumber,
    pageSize,
  } = props;
  // const [collapseActiveKey, setCollapseActiveKey] = useState('');

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded(
      "fetchPurchaseRequests",
      "purchaseRequestList",
      props,
      payload
    );
  };
  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.fetchPurchaseRequests(payload);
    //props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  // const getRowHeight = (params) => {
  //     let height = 28;
  //     return height;
  // }

  const openPRDetails = (data) => {
    
    const prPayload = {
      supplierId: data.supplierId,
      prMasterId: data.purchaseRequestMasterId,
    };
    props.pushModalToStack({
      modalBody: <PRDetail {...props} prPayload={prPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };
  // const saveColumnIndex = (columnState) => {
  //     let data = props.txPreferences || {};
  //     let tablePreference = {};
  //     data.relationshipId = (props.companyInfo || {}).relationshipId;
  //     data.clientUserAccountId = (props.userInfo || {}).userId;
  //     if (data.tablePreference) {
  //         tablePreference = JSON.parse(data.tablePreference);
  //     }
  //     tablePreference.columnSetting = columnState;
  //     tablePreference.columnList = props.txColumns;
  //     data.tablePreference = JSON.stringify(tablePreference);
  //     data.preferenceId = props.preferenceId;
  //     data.gridPreference = props.gridPreference;
  //     props.saveUserPrefrences(data);
  // }

  // const saveColumnWidth = (columnState, width) => {
  //     let data = props.txPreferences || {};
  //     let tablePreference = {};
  //     data.relationshipId = (props.companyInfo || {}).relationshipId;
  //     data.clientUserAccountId = (props.userInfo || {}).userId;
  //     if (data.tablePreference) {
  //         tablePreference = JSON.parse(data.tablePreference);
  //     }
  //     tablePreference.columnSetting = columnState;
  //     tablePreference.columnList = props.txColumns;
  //     data.tablePreference = JSON.stringify(tablePreference);
  //     data.preferenceId = props.preferenceId;
  //     data.gridPreference = props.gridPreference;
  //     props.saveUserPrefrences(data, props);
  // }

  // let moveEvent = {};
  // const onColumnMoved = (event, isDragStopped) => {
  //     if (event.column) {
  //         moveEvent = event;
  //     }
  //     if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
  //         saveColumnIndex(moveEvent.columnApi.getColumnState());
  //     }
  // }

  // const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
  //     const gridColumnApi = tableGridColumnApi || props.gridColumnApi;
  //     event.api.resetRowHeights();
  //     if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
  //         //event.api.resetRowHeights();
  //         if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
  //             props.gridApi.sizeColumnsToFit();
  //             setTimeout(() => {
  //                 const columnArray = event.columnApi.getColumnState();
  //                 saveColumnWidth(columnArray);
  //             }, 1000);
  //         } else {
  //             saveColumnWidth(event.columnApi.getColumnState());
  //         }
  //     }
  // }

  // const getQuantityOnRFQ = (data) => {
  //     return <div className="cursor-pointer circles-ling">
  //         {(data.statusRFQ === 'Converted' || data.statusRFQ === 'converted') ? <i className="fa fa-circle"></i> : (data.statusRFQ === 'converted.partially' ? <i className="fa fa-adjust"></i> : <i className="fa fa-circle-o" ></i>)}
  //     </div>;
  //     // <div onClick={() => {
  //     // }}><Progress className={data.totalQuantityOnRFQ >= data.totalQuantity ? "complete" : "incomplete"} format={() => {
  //     //     return `${data.totalQuantityOnRFQ}/${data.totalQuantity}`
  //     // }} percent={(data.totalQuantityOnRFQ / data.totalQuantity) * 100} /></div>;
  // }

  const getQuantityOnPO = (data) => {
    return (
      <div className="cursor-pointer circles-ling">
        {data.statusPO === "Converted" || data.statusPO === "converted" ? (
          <i className="fa fa-circle"></i>
        ) : data.statusPO === "converted.partially" ? (
          <i className="fa fa-adjust"></i>
        ) : (
          <i className="fa fa-circle-o"></i>
        )}
      </div>
    );
    // <div onClick={() => {
    // }}><Progress className={data.totalQuantityOnPO >= data.totalQuantity ? "complete" : "incomplete"} format={() => {
    //     return `${data.totalQuantityOnPO}/${data.totalQuantity}`
    // }} percent={(data.totalQuantityOnPO / data.totalQuantity) * 100} /></div>;
  };

  const _isColumnToHide = (columnName) => {
    return props.txColumns.indexOf(columnName) < 0 &&
      props.txMandatoryColumns.indexOf(columnName) < 0
      ? true
      : false;
  };

  // const openAuditDrawer = (props, data) => {
  //     props.updateState({
  //         title: <div className="drawer-title">
  //             Audit Trail
  //         </div>,
  //         auditDrawerVisible: true,
  //         deletApiUrl: 'deleteAuditTrailPurchaseRequest',
  //         masterKey: 'purchaseRequestMasterId',
  //         masterKeyValue: data.purchaseRequestMasterId,
  //         apiUrl: 'saveAuditTrailPurchaseRequest',
  //         supplierId: data.supplierId,
  //         trailList: data.auditTrailPRList || [],
  //         updateTrailListInTransaction: (list) => {
  //             data.auditTrailPRList = list;
  //         }
  //     });
  // }

  // const addItemConfirmationModal = (props, text, prData) => {
  //     const { showModal, companyInfo } = props;
  //     const labelName = <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />;
  //     const modalBody = <span>
  //         <FormattedMessage
  //             id='setItem.confirmation'
  //             defaultMessage={``}
  //         />
  //         {labelName} ?
  //   </span>;

  //     let payload = {
  //         userStatus: text,
  //         relationshipId: companyInfo.relationshipId,
  //         purchaseRequestMasterId: prData.purchaseRequestMasterId,
  //         dateCreated: getCurrentDateForBackend(new Date()),
  //     };

  //     const modalData = {
  //         modalBody,
  //         hideFooter: false,
  //         handleSubmit: () => {
  //             props.updateUserStatus(payload, props);
  //             props.hideModal();
  //             props.popModalFromStack();
  //         },
  //     };
  //     showModal(modalData);
  // }

  // const updateUserStatus = (props, prData) => {
  //     let payload = { formData: {} };
  //     const formData = payload.formData;
  //     const { pushModalToStack } = props;

  //     if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
  //         addItemConfirmationModal(props, formData.textBoxValue, prData);
  //     } else {
  //         const data = {
  //             title: <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />,
  //             formData,
  //             hideFooter: true,
  //             modalData: { modalType: MODAL_TYPE.USER_STATUS, data: payload, transactionName: CONSTANTS_TRANSACTIONS.PURCHASE_REQUEST },
  //             handleSubmit: (formData = {}) => {
  //                 if (formData.textBoxValue && formData.textBoxValue.length) {
  //                     addItemConfirmationModal(props, formData.textBoxValue, prData);
  //                 } else {
  //                     data.formData.submittedOnce = true;
  //                     pushModalToStack(data);
  //                 }
  //             }
  //         };
  //         pushModalToStack(data);
  //     }
  // }

  // const actionMenu = (param) => (
  //     <Menu className="cell-action-dropdown">
  //         {/* <Menu.Item key="0" onClick={() => {
  //             openAuditDrawer(props, param.data);
  //         }}>
  //             <i className="fa fa-history" />
  //                  View Audit Trail
  //         </Menu.Item> */}

  //        {checkACLPermission(primaryPerm, MODULE_CODED_VALUES.PURCHASE_REQUEST, PERMISSION_VALUES.UPDATE) &&  <Menu.Item key="1">
  //             <i className="fa fa-pencil" />
  //             <Link
  //                 to={
  //                     {
  //                         pathname: '/admin/pr-update',
  //                         state: {
  //                             prMasterId: param.data.purchaseRequestMasterId,
  //                             update: true
  //                         }
  //                     }}
  //             >Update Purchase Request</Link>
  //         </Menu.Item>}
  //        {checkACLPermission(primaryPerm, MODULE_CODED_VALUES.PURCHASE_REQUEST, PERMISSION_VALUES.CREATE) && <Menu.Item key="2">
  //             <i className="fa fa-clone" />
  //             <Link
  //                 to={
  //                     {
  //                         pathname: '/admin/pr-create',
  //                         state: {
  //                             prMasterId: param.data.purchaseRequestMasterId,
  //                             clone: true
  //                         }
  //                     }}
  //             >Clone Purchase Request</Link>
  //         </Menu.Item>}

  //        {checkACLPermission(primaryPerm, MODULE_CODED_VALUES.PURCHASE_REQUEST, PERMISSION_VALUES.DELETE) && <Menu.Item key="3">
  //             <i className="fa fa-trash" />
  //             <Link onClick={() => {
  //                 props.showModal({
  //                     modalBody: "Are you sure you want to delete this transaction?",
  //                     handleSubmit: () => {
  //                         props.deletePurchaseRequest({
  //                             supplierId: param.data.supplierId,
  //                             prMasterId: param.data.purchaseRequestMasterId,
  //                             purchaseRequestMasterId: param.data.purchaseRequestMasterId,
  //                             relationshipId: param.data.relationshipId
  //                         }, props)
  //                     }
  //                 })

  //             }}>Delete Purchase Request</Link>
  //         </Menu.Item>}
  //         <Menu.Item key="4" onClick={() => {
  //             updateUserStatus(props, param.data);
  //         }}>
  //             <i className="fa fa-pencil" />
  //                  Update User Status
  //                 </Menu.Item>
  //     </Menu>
  // );

  // const columnDefs = [
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseRequest.listing.header.purchaseRequestNo' defaultMessage='' />;
  //         },
  //         field: "purchaseRequestNumber",
  //         colId: "purchaseRequestNumber_1",
  //         hide: _isColumnToHide('purchaseRequestNumber'),
  //         suppressMovable: true,
  //         minWidth: 140,
  //         maxWidth: 400,
  //         rowDrag: true,
  //         resizable: true,
  //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //             openPRDetails(link.data)
  //         }}>{link.value}</div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseRequest.listing.header.requester' defaultMessage='' />;
  //         },
  //         field: "prRequestedBy",
  //         colId: "prRequestedBy_1",
  //         hide: _isColumnToHide('prRequestedBy'),
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseRequest.listing.header.orderPriority' defaultMessage='' />;
  //         },
  //         field: "orderPriority",
  //         colId: "orderPriority_1",
  //         hide: _isColumnToHide('orderPriority'),
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         resizable: true
  //     },
  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <FormattedMessage id='supplier.purchaseRequest.listing.header.department' defaultMessage='' />;
  //     //     },
  //     //     field: "departmentName",
  //     //     colId: "departmentName_1",
  //     //     hide: _isColumnToHide('departmentName'),
  //     //     minWidth: 70,
  //     //     maxWidth: 300,
  //     //     suppressMovable: true,
  //     //     resizable: true
  //     // },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseRequest.listing.header.date' defaultMessage='' />;
  //         },
  //         field: "purchaseRequestDate",
  //         colId: "purchaseRequestDate_1",
  //         hide: _isColumnToHide('purchaseRequestDate'),
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         resizable: true,
  //         cellRendererFramework: (link) => <div>
  //              <Tooltip placement="topLeft" title={(link.data.purchaseRequestDate && getMomentDateForUIReadOnly({date: link.data.purchaseRequestDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
  //             {getMomentDateForUIReadOnly({date: link.data.purchaseRequestDate, format})}
  //             </Tooltip>
  //         </div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseRequest.listing.header.expirationDate' defaultMessage='' />;
  //         },
  //         field: "expirationDate",
  //         colId: "expirationDate_1",
  //         hide: _isColumnToHide('expirationDate'),
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         resizable: true,
  //         cellRendererFramework: (link) => <div>
  //             <Tooltip placement="topLeft" title={(link.data.prExpirationDate && getMomentDateForUIReadOnly({date: link.data.prExpirationDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
  //             {getMomentDateForUIReadOnly({date: link.data.prExpirationDate, format})}
  //             </Tooltip>
  //         </div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseRequest.listing.header.reason' defaultMessage='' />;
  //         },
  //         field: "reason",
  //         colId: "reason_1",
  //         hide: _isColumnToHide('reason'),
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         resizable: true
  //     },

  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseRequest.listing.header.owner' defaultMessage='' />;
  //         },
  //         field: "prOwnerName",
  //         colId: "prOwnerName_1",
  //         hide: _isColumnToHide('prOwnerName'),
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='common.status' defaultMessage='' />;
  //         },
  //         field: "userStatus",
  //         hide: _isColumnToHide('userStatus'),
  //         colId: "userStatus_1",
  //         minWidth: 70,
  //         maxWidth: 200,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 {link.value? <span className="status-code" style={{background: link.data.userStatusColor}}>{link.value || ''}</span> : ''}
  //             </div>
  //         },
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseRequest.listing.header.systemStatus' defaultMessage='' />;
  //         },
  //         field: "status",
  //         colId: "status_1",
  //         hide: true,//_isColumnToHide('status'),
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         cellRendererFramework: (params) => {
  //             return (params.value === 'Rejected' && params.data.rejectionReason) ?
  //                 <div>
  //                     <Tooltip placement="topLeft" title={params.data.rejectionReason}>
  //                         <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage={params.value} /></span>
  //                     </Tooltip>
  //                 </div> :
  //                 <div> <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage={params.value} /></span> </div>
  //         },
  //         resizable: true,
  //         cellClass: 'status-cell'
  //     },

  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseRequest.listing.header.qtyOnRFQ' defaultMessage='' />;
  //         },
  //         field: "totalQuantityOnRFQ",
  //         colId: "totalQuantityOnRFQ_1",
  //         hide: _isColumnToHide('totalQuantityOnRFQ'),
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         cellRendererFramework: function (param) {
  //             return getQuantityOnRFQ(param.data);
  //         },
  //         resizable: true,
  //         cellClass: 'sales-order-progressbar'
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseRequest.listing.header.qtyOnPO' defaultMessage='' />;
  //         },
  //         field: "totalQuantityOnPO",
  //         colId: "totalQuantityOnPO_1",
  //         hide: _isColumnToHide('totalQuantityOnPO'),
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         cellRendererFramework: function (param) {
  //             return getQuantityOnPO(param.data);
  //         },
  //         resizable: true,
  //         cellClass: 'sales-order-progressbar'
  //     }
  //     ,
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customerSalesEnquiryNumber.text' defaultMessage='' />;
  //         },
  //         field: 'customerInquiryNumber',
  //         hide: _isColumnToHide('customerInquiryNumber'),
  //         colId: "customerInquiryNumber_1",
  //         resizable: true,
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div>
  //             {(link.data.customerInquiryNumber && link.data.customerInquiryNumber !== '0')? link.data.customerInquiryNumber : '-'}
  //         </div>,
  //     },

  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customerRfqNumber.text' defaultMessage='' />;
  //         },
  //         field: 'customerRFQNumber',
  //         hide: _isColumnToHide('customerRFQNumber'),
  //         colId: "customerRFQNumber_1",
  //         resizable: true,
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div>
  //             {(link.data.customerRFQNumber  && link.data.customerRFQNumber !== '0') ? link.data.customerRFQNumber : '-'}
  //         </div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customerPONumber.text' defaultMessage='' />;
  //         },
  //         field: 'customerPONumber',
  //         hide: _isColumnToHide('customerPONumber'),
  //         colId: "customerPONumber_1",
  //         resizable: true,
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div>
  //             {(link.data.customerPONumber && link.data.customerPONumber !== '0') ? link.data.customerPONumber : '-'}
  //         </div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='common.project' defaultMessage='' />;
  //         },
  //         field: "project",
  //         hide: _isColumnToHide('project'),
  //         colId: "project_1",
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 {link.data.projectMasterId ? <span>{link.data.projectMasterId + '-' +link.data.projectName }</span> : ''}
  //             </div>
  //         },
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseOrder.listing.header.systemStatus' defaultMessage='' />;
  //         },
  //         cellRendererFramework: (params) => {
  //             return (params.value === 'Rejected' && params.data.rejectionReason) ?
  //                 <div>
  //                     <Tooltip placement="topLeft" title={params.data.rejectionReason}>
  //                         <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage={params.value} /></span>
  //                     </Tooltip>
  //                 </div> :
  //                 <div> <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage={params.value} /></span> </div>
  //         },
  //         field: "status",
  //         colId: "status_1",
  //         hide: _isColumnToHide('status'),
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         resizable: true,
  //         cellClass: 'status-cell'
  //     },

  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseOrder.listing.header.refNumber' defaultMessage='' />;
  //         },
  //         field: "reference",
  //         colId: "reference_1",
  //         hide: _isColumnToHide('reference'),
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         resizable: true,
  //     },

  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <div className="cursor-pointer">
  //     //             <Popover
  //     //                 content={<AddAndRemoveSupplierListingColumn {...props} />}
  //     //                 title={<div className="text-center">Add/Remove </div>}
  //     //                 placement="left"
  //     //                 trigger="click"
  //     //             >
  //     //                 <i className='fa fa-bars' />
  //     //             </Popover>
  //     //         </div>;
  //     //     },
  //     //     cellRendererFramework: (param) => {
  //     //         return <Dropdown overlay={actionMenu(param)} trigger={['click']}>
  //     //             <Button className="action-btn small">
  //     //                 <i className="fa fa-ellipsis-v" />
  //     //             </Button>
  //     //         </Dropdown>
  //     //     },
  //     //     field: "action",
  //     //     colId: "action_1",
  //     //     resizable: true,
  //     //     pinned: 'right',
  //     //     minWidth: 52,
  //     //     lockPosition: true,
  //     //     maxWidth: 52,
  //     //     suppressMovable: true,
  //     // }
  // ];

  // const breadCrumbList = [
  //     {
  //         name: 'Dashboard',
  //         link: 'dashboard'
  //     },
  //     {
  //         name: 'Supplier',
  //         link: 'suppliers'
  //     }, {
  //         name: 'Purchase Request',
  //     }, {
  //         name: 'List'
  //     }
  // ];

  // const onGridReady = (params) => {
  //     props.updateState({
  //         gridApi: params.api,
  //         gridColumnApi: params.columnApi
  //     });
  //     onDataRendered(params);
  // }

  // const onModelUpdated = (params) => {
  //     if ((purchaseRequestList[pageNumber] || []).length && !props.isRowHeightSet) {
  //         setTimeout(() => {
  //             params.api.resetRowHeights();
  //         }, 1000)
  //         props.updateState({
  //             isRowHeightSet: true
  //         })
  //     }
  //     if (props.tableDataReRendered) {
  //         //params.api.resetRowHeights();
  //         props.updateState({
  //             tableDataReRendered: false
  //         })
  //         let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
  //         const columnToBeSet = [];
  //         const indexObjArr = TRANSACTION_COLUMN_INDEX.PURCHASE_REQUEST_LISTING

  //         indexObjArr.forEach((e) => {
  //             let colObj = find(txColumnSetting, { colId: e + '_1' });
  //             if (colObj) {
  //                 columnToBeSet.push(colObj);
  //             }
  //         });

  //         txColumnSetting = txColumnSetting.filter((ele) => {
  //             let columnName = ele.colId.split("_")[0];
  //             return indexObjArr.indexOf(columnName) === -1;
  //         })
  //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
  //         txColumnSetting.forEach((e) => {
  //             let columnName = e.colId.split("_")[0];
  //             if (columnName !== 'action') {
  //                 if (columnName === 'isChecked') {
  //                     e.hide = false;
  //                 } else {
  //                     e.hide = _isColumnToHide(columnName);
  //                 }
  //             } if (columnName === 'action') {
  //                 e.hide = false;
  //                 e.pinned = 'right';
  //             }
  //         });
  //         params.columnApi.setColumnState(txColumnSetting);
  //         setTimeout(() => {
  //             if (props.gridColumnApi && props.gridColumnApi.columnController.bodyWidth < props.gridColumnApi.columnController.scrollWidth) {
  //                 params.api.sizeColumnsToFit();
  //             }
  //         }, 500);
  //     }
  // }

  // const onDataRendered = (params) => {
  //     let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
  //     if (Object.keys(txColumnSetting).length) {
  //         const columnToBeSet = [];
  //         const indexObjArr = TRANSACTION_COLUMN_INDEX.PURCHASE_REQUEST_LISTING

  //         indexObjArr.forEach((e) => {
  //             let colObj = find(txColumnSetting, { colId: e + '_1' });
  //             if (colObj) {
  //                 columnToBeSet.push(colObj);
  //             }
  //         });

  //         txColumnSetting = txColumnSetting.filter((ele) => {
  //             let columnName = ele.colId.split("_")[0];
  //             return indexObjArr.indexOf(columnName) === -1;
  //         })
  //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
  //         txColumnSetting.forEach((e) => {
  //             let columnName = e.colId.split("_")[0];
  //             if (columnName !== 'action') {
  //                 if (columnName === 'isChecked') {
  //                     e.hide = false;
  //                 } else {
  //                     e.hide = _isColumnToHide(columnName);
  //                 }
  //             } if (columnName === 'action') {
  //                 e.hide = false;
  //                 e.width = 52;
  //                 e.pinned = 'right';
  //             }
  //         });
  //         params.columnApi.setColumnState(txColumnSetting);
  //         setTimeout(() => {
  //             if (params.columnApi && params.columnApi.columnController.bodyWidth < params.columnApi.columnController.scrollWidth) {
  //                 params.api.sizeColumnsToFit();
  //             }
  //         }, 500);
  //     } else {
  //         params.api.sizeColumnsToFit();
  //     }
  // }

  return (
    <Fragment>
        <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} active paragraph={false}>
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="purchase.purchaseRequest.table.heading"
                defaultMessage=""
              />
            </div>   
                <div className="vertical-sep" />
                <div>{props.purchaseRequestCount}</div>        
          </div>
          <div className="right-actions">
            <button
              className="action-btn"
              onClick={() => {
                props.updateState({
                  purchaseFilterDrawerVisible: true,
                });
              }}
            >
              <FilterOutlined onClick={() => {}} /> &nbsp;{" "}
              <FormattedMessage id="filter.text" defaultMessage="" />
            </button>
            <Popover
              content={<AddAndRemoveSupplierListingColumn {...props} />}
              title={
                <div className="text-center">
                  <FormattedMessage id="common.addRemove" defaultMessage="" />{" "}
                </div>
              }
              placement="leftTop"
              trigger="click"
              arrow="right"
              visible={props.showColPop}
            >
              <button
                onClick={() => {
                  props.updateState({ showColPop: !props.showColPop });
                }}
              >
                <InsertRowRightOutlined className="mr5" /> Columns
              </button>
            </Popover>
          </div>
          </Skeleton>
        </div>

        {/* <div className='agGridWrapper'>
                {props.txColumns.length ? <div className="ag-theme-balham" style={agGridStyle} >
                    <div className="cursor-pointer">
                            <span className="top-button">
                                <Popover
                                    content={<AddAndRemoveSupplierListingColumn {...props} />}
                                    title={<div className="text-center"><FormattedMessage id='common.addRemove' defaultMessage='' />  </div>}
                                    placement="left"
                                    trigger="click"
                                >
                                    <i className='fa fa-bars' />
                                </Popover>
                            </span>
                            <div className="clearfix"></div>
                        </div>
                            <AgGridReact
                            onGridReady={onGridReady}
                            columnDefs={columnDefs}
                            rowData={purchaseRequestList[pageNumber] || {}}
                            rowDragManaged={true}
                            domLayout={"autoHeight"}
                            onModelUpdated={onModelUpdated}
                            animateRows={true}
                            defaultColDef={{
                                flex:1,
                                autoHeight: true,
                                wrapText: true,
                            }}
                            //getRowHeight={getRowHeight}
                            onColumnResized={onColumnResized}
                            onDragStopped={() => {
                                onColumnMoved({}, true);
                            }}
                            //onColumnMoved={onColumnMoved}
                        >
                        </AgGridReact>
                    </div> : null}
                </div> */}
        <Skeleton loading={props.listLoading} active paragraph={{ rows: 13 }}>

        <div className="table-container">
          <table id="purchase-request-table">
            <thead>
              <tr>
                <th>S.No.</th>
                {!_isColumnToHide("purchaseRequestNumber") ? (
                  <th>
                    <FormattedMessage
                      id="supplier.purchaseRequest.listing.header.purchaseRequest"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("prRequestedBy") ? (
                  <th>
                    <FormattedMessage
                      id="supplier.purchaseRequest.listing.header.requester"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("orderPriority") ? (
                  <th>
                    <FormattedMessage
                      id="supplier.purchaseRequest.listing.header.orderPriority"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("purchaseRequestDate") ? (
                  <th>
                    <FormattedMessage
                      id="supplier.purchaseRequest.listing.header.date"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("expirationDate") ? (
                  <th>
                    <FormattedMessage
                      id="supplier.purchaseRequest.listing.header.expirationDate"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("reason") ? (
                  <th>
                    <FormattedMessage
                      id="supplier.purchaseRequest.listing.header.reason"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("prOwnerName") ? (
                  <th>
                    <FormattedMessage
                      id="supplier.purchaseRequest.listing.header.owner"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("userStatus") ? (
                  <th>
                    <FormattedMessage id="common.status" defaultMessage="" />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("totalQuantityOnRFQ") ? (
                  <th>
                    <FormattedMessage
                      id="supplier.purchaseRequest.listing.header.qtyOnRFQ"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("totalQuantityOnPO") ? (
                  <th>
                    <FormattedMessage
                      id="supplier.purchaseRequest.listing.header.qtyOnPO"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("customerInquiryNumber") ? (
                  <th>
                    <FormattedMessage
                      id="customerSalesEnquiryNumber.text"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("customerRFQNumber") ? (
                  <th>
                    <FormattedMessage
                      id="customerRfqNumber.text"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("customerPONumber") ? (
                  <th>
                    <FormattedMessage
                      id="customerPONumber.text"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("project") ? (
                  <th>
                    <FormattedMessage id="common.project" defaultMessage="" />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("status") ? (
                  <th>
                    <FormattedMessage
                      id="supplier.purchaseOrder.listing.header.systemStatus"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("reference") ? (
                  <th>
                    <FormattedMessage
                      id="supplier.purchaseOrder.listing.header.refNumber"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {purchaseRequestList && (purchaseRequestList[pageNumber] || []).length
                ? purchaseRequestList[pageNumber].map((rowData, index) => (
                    <tr key={index}>
                      <td>{(pageNumber - 1) * pageSize + (index + 1)}</td>
                      {!_isColumnToHide("purchaseRequestNumber") ? (
                        <td>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              openPRDetails(rowData);
                            }}
                          >
                            {rowData.purchaseRequestNumber}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("prRequestedBy") ? (
                        <td className="line-view">{rowData.prRequestedBy || ""}</td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("orderPriority") ? (
                        <td>{rowData.orderPriority || <div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("purchaseRequestDate") ? (
                        <td className="one-line-view">
                          <div>
                            <Tooltip
                              placement="topLeft"
                              title={
                                rowData.purchaseRequestDate &&
                                getMomentDateForUIReadOnly({
                                  date: rowData.purchaseRequestDate,
                                  format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                })
                              }
                            >
                              {getMomentDateForUIReadOnly({
                                date: rowData.purchaseRequestDate,
                                format: CONSTANTS.TABLE_DATE_FORMAT,
                              })}
                            </Tooltip>
                          </div>
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("expirationDate") ? (
                        <td className="one-line-view">
                          <div>
                            <Tooltip
                              placement="topLeft"
                              title={
                                rowData.prExpirationDate &&
                                getMomentDateForUIReadOnly({
                                  date: rowData.prExpirationDate,
                                  format: CONSTANTS.TABLE_DATE_FORMAT,
                                })
                              }
                            >
                              {getMomentDateForUIReadOnly({
                                date: rowData.prExpirationDate,
                                format: CONSTANTS.TABLE_DATE_FORMAT,
                              })}
                            </Tooltip>
                          </div>
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("reason") ? (
                        <td>{rowData.reason || <div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("prOwnerName") ? (
                        <td className="line-view">{rowData.prOwnerName || <div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("userStatus") ? (
                        <td>
                          {rowData.userStatus ? (
                            <span
                              className="status-code"
                              style={{ background: rowData.userStatusColor }}
                            >
                              {rowData.userStatus}
                            </span>
                          ) : (
                             <div className="empty-data"></div>
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                      {/* {!_isColumnToHide('status') ? <td>
                                            {
                                                (rowData.status === 'Rejected' && rowData.rejectionReason) ?
                                                    <div>
                                                        <Tooltip placement="topLeft" title={rowData.rejectionReason}>
                                                            <span className={["status-code", rowData.status.toLowerCase()].join(' ')}><FormattedMessage id={rowData.status} defaultMessage={rowData.status} /></span>
                                                        </Tooltip>
                                                    </div> :
                                                    <div> <span className={["status-code", rowData.status.toLowerCase()].join(' ')}><FormattedMessage id={rowData.status} defaultMessage={rowData.status} /></span> </div>
                                            }
                                        </td>: ''} */}
                      {!_isColumnToHide("totalQuantityOnRFQ") ? (
                        <td>{rowData.totalQuantityOnRFQ || <div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("totalQuantityOnPO") ? (
                        <td>{getQuantityOnPO(rowData)}</td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("customerInquiryNumber") ? (
                        <td>
                          {rowData.customerInquiryNumber &&
                          rowData.customerInquiryNumber !== "0"
                            ? rowData.customerInquiryNumber
                            : <div className="empty-data"></div>}
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("customerRFQNumber") ? (
                        <td>
                          {rowData.customerRFQNumber &&
                          rowData.customerRFQNumber !== "0"
                            ? rowData.customerRFQNumber
                            : <div className="empty-data"></div>}
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("customerPONumber") ? (
                        <td>
                          {rowData.customerPONumber &&
                          rowData.customerPONumber !== "0"
                            ? rowData.customerPONumber
                            : <div className="empty-data"></div>}
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("project") ? (
                        <td>
                          {rowData.projectName ? (
                            <span>{rowData.projectName}</span>
                          ) : (
                             <div className="empty-data"></div>
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("status") ? (
                        <td>
                          {rowData.status === "Rejected" &&
                          rowData.rejectionReason ? (
                            <div>
                              <Tooltip
                                placement="topLeft"
                                title={rowData.rejectionReason}
                              >
                                <span
                                  className={[
                                    "status-code",
                                    rowData.status.toLowerCase(),
                                  ].join(" ")}
                                >
                                  <FormattedMessage
                                    id={rowData.status }
                                    defaultMessage={rowData.status}
                                  /> 
                                </span>
                              </Tooltip>
                            </div>
                          ) : (
                            <div>
                              {" "}
                              <span
                                className={[
                                  "status-code",
                                  rowData.status.toLowerCase(),
                                ].join(" ")}
                              >
                                <FormattedMessage
                                  id={rowData.status }
                                  defaultMessage={rowData.status}
                                /> 
                              </span>{" "}
                            </div>
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("reference") ? (
                        <td>{rowData.reference || <div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}
                    </tr>
                  ))
                :             
                <tr key="empty-data-box">
                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                </td>
            </tr>
                }
            </tbody>
          </table>
        </div>
      </Skeleton>
      <div className="footer">
      <Skeleton loading={props.headerLoading} active paragraph={false}>
        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            size="small"
            total={purchaseRequestCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            // showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
          />
        </div>
        </Skeleton>
      </div>
    </Fragment>
  );
};

export default injectIntl(PurchaseRequestListingComp);
