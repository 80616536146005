import firebase from "../firebase";
const db = firebase.collection('campaign-streaming');
class firebaseDataService {
  getAll() {
    // db.onSnapshot((snapshot) => {
    //   snapshot.docs.map((doc) => {
    //   })
    // });
    return db;
  }
  getByDocId(key) {
    // db.doc('22_27').onSnapshot((snapshot) => {
    // });
    // db.doc('22_27').get().then(snapshot =>  )
    return db.doc(key);
  }
}
export default new firebaseDataService();