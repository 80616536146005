import React, { Fragment,useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Slider } from 'antd';
// import ColorPicker from 'material-ui-rc-color-picker';
import 'material-ui-rc-color-picker/assets/index.css';
// import DividePic from '../../../assets/images/divider/divider-1.jpg';
// import DividePic1 from '../../../assets/images/divider/divider-2.jpg';
// import DividePic2 from '../../../assets/images/divider/divider-3.jpg';
// import DividePic3 from '../../../assets/images/divider/divider-4.jpg';
// import DividePic4 from '../../../assets/images/divider/divider-5.jpg';
// import DividePic5 from '../../../assets/images/divider/divider-6.jpg';
// import DividePic6 from '../../../assets/images/divider/divider-7.jpg';
// import DividePic7 from '../../../assets/images/divider/divider-8.jpg';
// import DividePic8 from '../../../assets/images/divider/divider-9.jpg';
// import DividePic9 from '../../../assets/images/divider/divider-10.jpg';
//import SolidLine from  '../../../assets/images/divider/solid-line.jpg';
const DividePic = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/divider-1.jpg';
const DividePic1 = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/divider-2.jpg';
const DividePic2 = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/divider-3.jpg';
const DividePic3 = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/divider-4.jpg';
const DividePic4 = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/divider-5.jpg';
const DividePic5 = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/divider-6.jpg';
const DividePic6 = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/divider-7.jpg';
const DividePic7 = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/divider-8.jpg';
const DividePic8 = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/divider-9.jpg';
const DividePic9 = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/divider-10.jpg';
const SolidLine = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/solid-line.jpg';

// const { Panel } = Collapse;

function DividerProps(props) {
    let divRef = document.getElementById('dummy_'+props.childId) || {};
    const [imgWidth, updateWidth] = useState(divRef.offsetWidth);
    const [imgHeigth, updateHeigth] = useState(divRef.offsetHeight);
    // function callback(key) {
    // }

    return (
        <Fragment>
<div className='divider-pic' onClick={() => {
    divRef.src = DividePic;
}}>
    <img src={DividePic} alt=""/>
</div>
<div className='divider-pic' onClick={() => {
    divRef.src = DividePic1;
}}>
    <img src={DividePic1} alt=""/>
</div>
<div className='divider-pic' onClick={() => {
    divRef.src = DividePic2;
}}>
    <img src={DividePic2} alt=""/>
</div>

<div className='divider-pic' onClick={() => {
    divRef.src = DividePic3;
}}>
    <img src={DividePic3} alt=""/>
</div>

<div className='divider-pic' onClick={() => {
    divRef.src = DividePic4;
}}>
    <img src={DividePic4} alt=""/>
</div>

<div className='divider-pic' onClick={() => {
    divRef.src = DividePic5;
}}>
    <img src={DividePic5} alt=""/>
</div>

<div className='divider-pic' onClick={() => {
    divRef.src = DividePic6;
}}>
    <img src={DividePic6} alt=""/>
</div>

<div className='divider-pic' onClick={() => {
    divRef.src = DividePic7;
}}>
    <img src={DividePic7} alt=""/>
</div>

<div className='divider-pic' onClick={() => {
    divRef.src = DividePic8;
}}>
    <img src={DividePic8} alt=""/>
</div>

<div className='divider-pic' onClick={() => {
    divRef.src = DividePic9;
}}>
    <img src={DividePic9} alt=""/>
</div>

<div className='divider-pic' onClick={() => {
    divRef.src = SolidLine;
}}>
    <img src={SolidLine} alt=""/>
</div>

            <Row className="but-buttons">
                <Col span={5}> <FormattedMessage id='package.packageDetail.width' /></Col>
                <Col span={19}>
                    {/* <Slider defaultValue={15} tooltipVisible /> */}
                    <Slider min={1} value={imgWidth} max={680} onChange={(value) => {
                        updateWidth(value);
                        divRef.style.width = value + "px"
                    }} />
                </Col>
            </Row>


            <Row className="but-buttons">
                <Col span={5}><FormattedMessage id='buttonHeight.height' /></Col>
                <Col span={19}>

                    {/* <Slider defaultValue={15} tooltipVisible /> */}
                    <Slider value={imgHeigth} min={10} max={500} onChange={(value) => {
                        divRef.style.lineHeight = value + "px"
                        divRef.style.height = value + "px"
                        updateHeigth(value);
                    }} />
                </Col>


            </Row>


           
        </Fragment>
    );
}


export default injectIntl(DividerProps);
