import React from "react";
import { FormattedMessage } from "react-intl";
import { Col, Skeleton, Drawer, Row, Input } from "antd";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { ErrorMsg } from "../../../general/ErrorMessage";
import { Dropdown } from "../../../general/Dropdown"
import { getCurrencySymbol } from "../../../../utils";
import { EMPLOYEE_META_CONSTANTS } from "../../../../static/constants";

const DeductionDrawer = (props) => {
  const closeDrawer = () => {
    props.updateState({
      deductionDrawer: false,
    });
  };

  return (
    <Drawer
      title={<FormattedMessage id="drawer.leaveMangement.titleName.deduction" />}
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.deductionDrawer}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {
              props.updateDrawerState({ submitted: true })

              const payload = {
                relationshipId: props.companyInfo.relationshipId,
                allowanceId: props.allowanceId,
                deductionName: props.deductionName,
                deductionType: props.deductionType,
                deductionRate: props.deductionRate,
                limitAmount: props.limitAmount,
                createdByUserId: props.createdByUserId,
                updatedByUserId: props.updatedByUserId,
                version: 0

              }

              props.showModal({
                modalBody: "Are you sure want to Submit?",
                handleSubmit: () => {
                  props.createDeduction(payload, props)
                  closeDrawer();
                },
              });

            }

            }
            key={1}
          />
        </>
      }

    >
      <Skeleton loading={props.drawerLoading} paragraph={{ rows: 18 }} active>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div className="i-label">Name
              <span className="required">*</span>

            </div>
            <TextBox
              placeholder="Allowance Name"
              type="text"
              maxLength={30}
              value={props.deductionName}
              onChange={(e) => {
                props.updateDrawerState({ deductionName: e.target.value });
              }}
            />
            <ErrorMsg
              validator={() => { return !props.submitted || props.deductionName }}
              message={<FormattedMessage id='requiredField.missing.msg' />}
            />
          </Col>
          <Col span={12}>
            <div className="i-label"><FormattedMessage id="company.payrollManagement.deductionType" />
              <span className="required">*</span>

            </div>
            <Dropdown
              items={EMPLOYEE_META_CONSTANTS.ALLOWANCE_TYPES}
              placeholder="Select"
              value={props.deductionType}
              onSelect={(option) => {
                props.updateDrawerState({
                  deductionType: option,
                });
              }}
            />
            <ErrorMsg
              validator={() => { return !props.submitted || props.deductionType }}
              message={<FormattedMessage id='requiredField.missing.msg' />}
            />
          </Col>
          <Col span={12}>
            <div className="i-label">Deduction Rate
              <span className="required">*</span>
            </div>


            <Input
              addonBefore={props.deductionType === "Percentage" ? '%' : getCurrencySymbol(props.companyInfo.currencyCode)}
              style={{ height: '38px' }}
              placeholder={props.deductionType === "Percentage" ? 'Enter Percentage' : 'Enter Price'}
              type="text"
              maxLength={20}
              value={props.deductionRate}
              onChange={(e) => {
                props.updateDrawerState({ deductionRate: e.target.value });
              }}
            />
            <ErrorMsg
              validator={() => { return !props.submitted || props.deductionRate }}
              message={<FormattedMessage id='requiredField.missing.msg' />}
            />
          </Col>
          <Col span={12}>
            <div className="i-label"><FormattedMessage id="company.payrollManagement.limit" />
              <span className="required">*</span>

            </div>
            <TextBox
              placeholder={props.intl.formatMessage(
                { id: 'company.payrollManagement.limit', defaultMessage: '' }
              )}
              type="number"
              disabled={props.deductionType === 'Fixed'}
              maxLength={30}
              value={props.limitAmount}
              onChange={(e) => {
                props.updateDrawerState({ limitAmount: e.target.value });
              }}
            />

          </Col>

        </Row>
      </Skeleton>
    </Drawer>
  );
};

export default DeductionDrawer;
