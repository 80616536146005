import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BalanceSheetComponent from '../../../components/finance/BalanceSheet';
import { fetchAllLedgerAccounts, fetchAllPrevFYLedgerAccounts } from '../LedgerAccounts/action';
import { fetchDataIfNeeded } from '../../../utils';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';

class BalanceSheetComp extends Component {

    componentWillMount(){
        this.props.updateHeaderState({
            collapsed: false
          });
    }

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId
        };
        fetchDataIfNeeded('fetchAllLedgerAccounts', 'allLedgerAccounts', this.props, payload);
        const payload2 = {
            companyInfo,
            relationshipId: companyInfo.relationshipId
        };
        fetchDataIfNeeded('fetchAllPrevFYLedgerAccounts', 'allPrevFYLedgerAccounts', this.props, payload2);
    }

    render() {
        return <BalanceSheetComponent {...this.props}/>
    }

}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        isLoading: state.common.isLoading,
        allLedgerAccounts: state.ledger.allLedgerAccounts,
        allPrevFYLedgerAccounts: state.ledger.allPrevFYLedgerAccounts,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllLedgerAccounts,
    fetchAllPrevFYLedgerAccounts,
    showModal, 
    hideModal, 
    pushModalToStack, 
    popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BalanceSheetComp);
