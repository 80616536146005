import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ClassComp from "../../../components/schoolManagement/class";
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../../utils";
import { getClassList, getClassListCount, deleteClass, resetPaginatedData, getClasslistCountByFilter, fetchClassListByFilter } from "./action";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../../actions/commonActions';
import ClassListFilterComp from "../../../components/schoolManagement/class/ClassListFilter";
// import { getSubject } from "../subject/action";

class School extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });

    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
      pageNumber: 1,
      pageSize: 25,
      formId: this.props.defaultSchoolForm
    };
    fetchPaginationDataIfNeeded("getClassList", "classList", this.props, payload);
    fetchDataIfNeeded("getClassListCount", "classCount", this.props, payload);
    // this.props.getSubject(payload);
  }


  render() {
    return (
      <>    
        <ClassComp
          {...this.props}
          {...this.state}
          updateState={(data) => {
            this.setState(data);
          }}
        />
        {this.state.classListFilterDrawerVisible && <ClassListFilterComp {...this.state} {...this.props} updateState={(data) => { this.setState(data) }}  />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    classList: state.school.classList,
    classCount: state.school.classCount,
    defaultSchoolForm: state.common.defaultSchoolForm,
    // subjectList: state.school.subjectList,
    listLoading:state.common.listLoading,
    headerLoading: state.common.headerLoading
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getClassList,
      getClassListCount,
      deleteClass,
      resetPaginatedData,
      showModal,
      hideModal, 
      pushModalToStack, 
      popModalFromStack,
      // getSubject,
      getClasslistCountByFilter,
      fetchClassListByFilter
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(School);
