import React, { Fragment, useEffect } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import { AgGridReact } from "ag-grid-react";
import { Tooltip, Skeleton, Pagination, Checkbox, Popover, Menu, Empty, Button,Spin } from "antd";

import {
  fetchPaginationDataIfNeeded,
  showToasterMessage,
  getMomentDateForUIReadOnly,
  exportExcel,
  fixedDecimalAndFormateNumber,
  sortTable,
  getMomentDateForUI,
  fixedDecimalNumber,
} from "../../../utils";

import DropdownAction from "antd/lib/dropdown";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {
  FilterOutlined,
 
} from "@ant-design/icons";


import "./index.scss";

import { CONSTANTS ,ICONS } from "../../../static/constants";


import find from "lodash.find";
import filter from "lodash.filter";







const NotfiledInvoiceListingComp = (props) => {
    const { eInvoiceListing, dir = 0 ,showTaxPeriod} = props;
    const {customerInvoiceMasterList } = eInvoiceListing 
    // const {pageable ,totalPages} = customerInvoiceMasterList;
   const invoiceList = customerInvoiceMasterList?.content;
   const totalElements = customerInvoiceMasterList?.totalElements;
 
   
  
  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
     
      relationshipId: props.companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
      
    };
    fetchPaginationDataIfNeeded(
      "fetchGSTFilingDeatils",
      "eInvoiceListing",
      props,
      payload
    );
  };
  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };
  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
     
      relationshipId: props.companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    
    };

    
    // props.resetPaginatedData();
    props.fetchGSTFilingDeatils(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };
  const checkSameMonthAndYear = (invoices) => {
    if (invoices.length === 0) return false;
  
   const firstDate = invoices[0].invoiceDate;
   
    // const month = firstDate ? getMomentDateForUIReadOnly({date: firstDate ,format: "MM"}) : null;
    const year = firstDate ?  getMomentDateForUIReadOnly({date: firstDate ,format: "YYYY"}) : null;
    // financialPeriod =`${month}${year}`
    
  
    for (let i = 1; i < invoices.length; i++) {
      const invoiceDate = invoices[i].invoiceDate;
      // const month1 = invoiceDate ? getMomentDateForUIReadOnly({date: invoiceDate ,format: "MM"}) : null;
      const year1 = invoiceDate ? getMomentDateForUIReadOnly({date: invoiceDate ,format: "YYYY"}) : null;
      if ( year1 !== year) {
        return false;
      }
    }
  
    return true;
  };
  const _isColumnToHide = (columnName) => {
    return props.txColumns.indexOf(columnName) < 0 &&
      props.txMandatoryColumns.indexOf(columnName) < 0
      ? true
      : false;
  };

  const gstrFiling =()=>{
    const modalData = {
      title: <FormattedMessage
      id="confirmation.submit"
      defaultMessage=""
    />,
    hideFooter: false,
      modalBody: (
       <div>
Are you sure you want to file GSTR-1?
       </div>
      ),
      width: 500,
      handleSubmit: ()=>{
        
        
        
       
          props.uploadGSTR1FilingDetails(props)
        
       
      }
    };


    if((invoiceList||[])?.length){
      const checkMonthandYearSame = checkSameMonthAndYear(invoiceList);
      if(!checkMonthandYearSame){
        return ( showToasterMessage({
          messageType: "error",
          description: `Please ensure the invoice date is within the year before submitting in GSTR-1.`,
        }))
    }
    else{
      props.showModal(modalData); 
    }
     
    }
    else{
      return ( showToasterMessage({
        messageType: "error",
        description: `There are no invoices to upload. Please check and try again.`,
      }))
    }
   
  

   

   
     
    
      
   
  }
  const sortColumn = (e) => {
    //sortTable(document.getElementById('invoiceTable'), e.target.parentElement.cellIndex, 1 - dir);
    sortTable(
      document.getElementById("invoiceTable"),
      e.target.cellIndex,
      1 - dir
    );
    // updateState({ dir: 1 - dir });
  };

  return(
    <Fragment>
      {  props.saveLoading ?
        <div className ="loading">
    
       <Spin size="large" /> 
   
   
  </div>:""
      }
         
 <div className="view-container-actions">
          <Skeleton loading={props.headerLoading} paragraph={false} active>
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage id="Invoice Listing" defaultMessage="" />
            </div>

            <div className="vertical-sep" />
            <div>{totalElements || 0}</div>

            <div className="ml10">
           
              <span>
               
                {
                

                  showTaxPeriod ? showTaxPeriod : <span><b>Tax Period : </b>{getMomentDateForUIReadOnly({date: props.selectedDate , format: "MMMM-YYYY"})}</span>

                }
              </span>
            </div>

        
          </div>

          <div className="right-actions">
         
          <button
                className="upload-button"
                
                onClick={() => gstrFiling()}
               style={{width:"100px"}}
              >
                <i className={ICONS.UPLOAD} />{" "}
                <FormattedMessage
                  id="File GSTR-1"
                  defaultMessage=""
                />
              </button>
            <button
              onClick={() => {
                props.updateState({
                  salesEInvoiceFilterDrawerVisible: true,
                });
              }}
            >
              <FilterOutlined  /> &nbsp;{" "}
              <FormattedMessage id="filter.text" defaultMessage="" />
            </button>

          
          </div>
          </Skeleton>
        </div>
      <Skeleton loading={props.listLoading} active paragraph={{ rows: 12 }}>

        <div className="table-container">
          <table id="invoiceTable">
            <thead>
              <tr>
                {/* <th>
                  <div className="check-cell">
                    <Checkbox
                      onChange={(e) => {
                        selectAll(e.target.checked);
                      }}
                      checked={isCheckedAll()}
                    />
                    <DropdownAction overlay={checkMenu()} trigger={["click"]}>
                      <DownOutlined />
                    </DropdownAction>
                  </div>
                </th> */}
               

                {!_isColumnToHide("invoiceNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.invoiceNo"
                      defaultMessage=""
                    />{" "}
                    
                  </th>
                ) : null}
                <th onClick={sortColumn}>
                    <FormattedMessage id="Invoice Type" defaultMessage="" />{" "}
                    
                  </th>
                {!_isColumnToHide("irnNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage id="IRN #" defaultMessage="" />{" "}
                    
                  </th>
                ) : null}
              
                {!_isColumnToHide("eWayBillNo") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage id="e-Way Bill #" defaultMessage="" />{" "}
                    
                  </th>
                ) : null}

                {!_isColumnToHide("customerName") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.customer"
                      defaultMessage=""
                    />{" "}
                    
                  </th>
                ) : null}

{!_isColumnToHide("invoiceDueAmount") ? (
                  <th onClick={sortColumn} className="text-end">
                    <FormattedMessage
                      id="Taxable Amount"
                      defaultMessage=""
                    />{" "}
                    
                  </th>
                ) : null}
                <th className="text-end">
                <FormattedMessage
                      id="IGST"
                      defaultMessage=""
                    />{" "}
                </th>
                <th className="text-end">
                <FormattedMessage
                      id="CGST"
                      defaultMessage=""
                    />{" "}
                </th>
                <th className="text-end">
                <FormattedMessage
                      id="SGST"
                      defaultMessage=""
                    />{" "}
                </th>
               

                {!_isColumnToHide("invoiceTotalAmount") ? (
                  <th onClick={sortColumn} className="text-end">
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.invoiceAmount"
                      defaultMessage=""
                    />{" "}
                    
                  </th>
                ) : null}

                {!_isColumnToHide("invoiceDate") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.invoiceDate"
                      defaultMessage=""
                    />{" "}
                    
                  </th>
                ) : null}

               
              </tr>
            </thead>
            <tbody>
            
                {(invoiceList||[]).length ? (
                  (invoiceList || []).map((item, i) => {

                   
                    return (
                      <tr key={i}>
                      
                       

                        {!_isColumnToHide("invoiceNumber") ? (
                          <td>
                            <div
                              className="one-line-view"
                              onClick={() => {
                                // openSalesInvoiceDetails(item);
                              }}
                            >
                              {item.invoiceNumber}
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
<td>
                            <div className="one-line-view">{item.eInvoiceType||<div className="empty-data-box"/>}</div>
                          </td>
                        {!_isColumnToHide("irnNumber") ? (
                          <td>
                            {
                              item?.irn ?  <div> <Tooltip   color={'#F3F3F3'}
                              title={item?.irn}
                              overlayInnerStyle={{ backgroundColor: '#F3F3F3', color: 'black', width: '200px' }}>
                              <span>{(item.irn||"").slice(0,10)}...</span>
                            </Tooltip></div> :<div className="empty-data-box"/>
                            }
                          
                          </td>
                        ) : (
                          ""
                        )}
 
                        {!_isColumnToHide("eWayBillNo") ? (
                          <td>
                            <div>{item.ewayBillNo||<div className="empty-data-box"/>}</div>
                          </td>
                        ) : (
                          ""
                        )}
                       {!_isColumnToHide("customerName") ? (
                          <td>
                            <div>{item.customerName||<div className="empty-data-box"/>}</div>
                            {
                              item.buyerGstin ? <div className="one-line-view">GST# - {item.buyerGstin}</div> : <div className="empty-data-box"/>
                            }
                           
                          </td>
                        ) : (
                          ""
                        )}
                       {!_isColumnToHide("invoiceDueAmount") ? (
                          <td className="text-end">
                            <span className="one-line-view">
                                {" "}
                                {item.foreignCurrencyIcon ? (
                                  <i className={item.foreignCurrencyIcon}></i>
                                ) : item.foreignCurrency ? (
                                  <span>{item.foreignCurrency}</span>
                                ) : props.companyInfo.currencyIcon ? (
                                  <i
                                    className={props.companyInfo.currencyIcon}
                                  ></i>
                                ) : (
                                  props.companyInfo.currencyCode + " "
                                )}
                                {
                                  getSubTotalOrTaxOrDiscountAmount(item, "SubTotal")
                                }
                                
                              </span>
                           
                          </td>
                        ) : (
                          ""
                        )}
                        <td className="text-end">
                        <span className="one-line-view">
                                {" "}
                                {item.foreignCurrencyIcon ? (
                                  <i className={item.foreignCurrencyIcon}></i>
                                ) : item.foreignCurrency ? (
                                  <span>{item.foreignCurrency}</span>
                                ) : props.companyInfo.currencyIcon ? (
                                  <i
                                    className={props.companyInfo.currencyIcon}
                                  ></i>
                                ) : (
                                  props.companyInfo.currencyCode + " "
                                )}
                                
                                   {
                                    getSubTotalOrTaxOrDiscountAmount(item, "igst")
                                  }
                                
                               {/* {
                                  ((itemLevelTaxes||[])?.length && itemLevelTaxes[0]?.taxName === "IGST") ? fixedDecimalAndFormateNumber( itemLevelTaxes[0]?.taxAmount) :0
                                } */}
                              </span>
                         
                        </td>
                        <td className="text-end">
                        <span className="one-line-view">
                                {" "}
                                {item.foreignCurrencyIcon ? (
                                  <i className={item.foreignCurrencyIcon}></i>
                                ) : item.foreignCurrency ? (
                                  <span>{item.foreignCurrency}</span>
                                ) : props.companyInfo.currencyIcon ? (
                                  <i
                                    className={props.companyInfo.currencyIcon}
                                  ></i>
                                ) : (
                                  props.companyInfo.currencyCode + " "
                                )}
                                {/* {
                                  ((itemLevelTaxes||[])?.length && itemLevelTaxes[0]?.taxName === "CGST") ?fixedDecimalAndFormateNumber(itemLevelTaxes[0]?.taxAmount) :0
                                } */}
                              {
                                 
                                  getSubTotalOrTaxOrDiscountAmount(item, "cgst")
                                
                              }
                              </span>
                        </td>
                        <td className="text-end">
                        <span className="one-line-view">
                                {" "}
                                {item.foreignCurrencyIcon ? (
                                  <i className={item.foreignCurrencyIcon}></i>
                                ) : item.foreignCurrency ? (
                                  <span>{item.foreignCurrency}</span>
                                ) : props.companyInfo.currencyIcon ? (
                                  <i
                                    className={props.companyInfo.currencyIcon}
                                  ></i>
                                ) : (
                                  props.companyInfo.currencyCode + " "
                                )}
                                 {
                                  getSubTotalOrTaxOrDiscountAmount(item, "cgst")
                                }
                               {/* {
                                  ((itemLevelTaxes||[])?.length && itemLevelTaxes[0]?.taxName === "CGST") ? fixedDecimalAndFormateNumber(itemLevelTaxes[0]?.taxAmount) :0
                                } */}
                              </span>
                        </td>
                        {!_isColumnToHide("invoiceTotalAmount") ? (
                          <td className="text-end">
                              <span className="one-line-view">
                                {" "}
                                {item.foreignCurrencyIcon ? (
                                  <i className={item.foreignCurrencyIcon}></i>
                                ) : item.foreignCurrency ? (
                                  <span>{item.foreignCurrency}</span>
                                ) : props.companyInfo.currencyIcon ? (
                                  <i
                                    className={props.companyInfo.currencyIcon}
                                  ></i>
                                ) : (
                                  props.companyInfo.currencyCode + " "
                                )}
                                {fixedDecimalAndFormateNumber(
                                  item.invoiceTotalAmount
                                )}{" "}
                              </span>
                           
                          </td>
                        ) : (
                          ""
                        )}
                       
                        {/* {!_isColumnToHide("paymentTermName") ? (
                          <td>
                            <div>{item.paymentTermName||<div className="empty-data-box"/>}</div>
                          </td>
                        ) : (
                          ""
                        )} */}
                        {!_isColumnToHide("invoiceDate") ? (
                          <td>
                            <div>{item.invoiceDate? (  getMomentDateForUIReadOnly({
                                    date: item.invoiceDate,
                                    format: CONSTANTS.TABLE_DATE_FORMAT,
                                  })):""}</div>
                          </td>
                        ) : (
                          ""
                        )}
                        {/* {!_isColumnToHide("invoiceDueDate") ? (
                          <td>
                            <div>{item.invoiceDueDate? (  getMomentDateForUIReadOnly({
                                    date: item.invoiceDueDate,
                                    format: CONSTANTS.TABLE_DATE_FORMAT,
                                  })):""}</div>
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("status") ? (
                          <td>
                            <div>{item.status||<div className="empty-data-box"/>}</div>
                          </td>
                        ) : (
                          ""
                        )} */}
                      </tr>
                    );
                  })
                ) : (<tr key="empty">
                  <td colSpan={"100%"}>
                    <Empty />
                  </td>
                </tr>)}
            
            </tbody>
          </table>
        </div>
      </Skeleton>
      <div className="footer-gst-btn">
      <Skeleton loading={props.headerLoading} paragraph={false} active>

        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            size="small"
            total={totalElements}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            // showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
          />
        </div>
        </Skeleton>
      </div>
    </Fragment>
  )
};

const getSubTotalOrTaxOrDiscountAmount = (data, type) => {
  let total = 0;

  data && data.customerInvoiceDetailsList && data.customerInvoiceDetailsList.forEach(function (d) {
      if (type === 'SubTotal') {
          total = total + d.anItemSalePrice * d.quantityToInvoice;
      }
     
      if (type === "Discount") {
      
          let discountObj = d.discountAmount
          if (discountObj) {
            total = total + parseFloat(discountObj);
          }
        }
       
        if(type === "igst"){
          d && d.customerInvoiceCOATxList && d.customerInvoiceCOATxList.forEach(function (discountObject) {
                    
                  if (discountObject.taxName === 'IGST' && discountObject.accountingEntry === 'debit') {
                      total = total + parseFloat(discountObject.amount);
                  }
              });
        }
        if(type === "cgst"){
          d && d.customerInvoiceCOATxList && d.customerInvoiceCOATxList.forEach(function (discountObject) {
                    
                  if (discountObject.taxName === 'CGST' && discountObject.accountingEntry === 'debit') {
                      total = total + parseFloat(discountObject.amount);
                  }
              });
        }
        if(type === "sgst"){
          d && d.customerInvoiceCOATxList && d.customerInvoiceCOATxList.forEach(function (discountObject) {
                    
                  if (discountObject.taxName === 'CGST' && discountObject.accountingEntry === 'debit') {
                      total = total + parseFloat(discountObject.amount);
                  }
              });
        }
  });

  return fixedDecimalNumber(total);
}




export default injectIntl(NotfiledInvoiceListingComp);
