import { MARKETPLACE_SHIPPING_ACTION_LIST } from "../static/constants";

const initialState = {
    shippingCountries: [],
    shippingChoices: [],
    countryShippingRule: {},
    shippingServices: []

};

const MarketplcaeShippingReducer = (state = initialState, action) => {
    switch (action.type) {
        case MARKETPLACE_SHIPPING_ACTION_LIST.SHIPPING_COUNTRIES_LIST:
            return {
                ...state,
                shippingCountries: action.data,
            };
        case MARKETPLACE_SHIPPING_ACTION_LIST.SHIPPING_CHOICES:
            return {
                ...state,
                shippingChoices: action.data,
            };
        case MARKETPLACE_SHIPPING_ACTION_LIST.SHIPPING_SERVICES:
            return {
                ...state,
                shippingServices: action.data,
            };
        case MARKETPLACE_SHIPPING_ACTION_LIST.COUNTRY_SHIPPING_RULE:
            return {
                ...state,
                countryShippingRule: {
                    ...state.countryShippingRule,
                    [action.data.marketplaceShippingCountryId]: action.data.shippingData,
                },
            };
        default:
            return state;
    }
};

export default MarketplcaeShippingReducer;
