import React from 'react';
import { FormattedMessage } from 'react-intl';
// import { FileExcelOutlined } from '@ant-design/icons';
// import { Row, Col} from 'antd';
// import { exportExcel, getMomentDateForUIReadOnly } from '../../../../../utils';
// import { CONSTANTS } from '../../../../../static/constants'
//import ReactHtmlParser from 'react-html-parser';

const StudentDetails =  (props) =>{
    const { studentDetailsList } = props;

    // const downloadFile = () => {
    //     const studentList = [];
    //     studentDetailsList.forEach(student => {
    //         studentList.push({
    //             'Student Name': student.firstName + " " + (student.lastName || ''),
    //             'Father Name': registrationData.fatherFirstName + " " + (registrationData.fatherLastName || ''),
    //             'Mother Name': registrationData.motherFirstName + " " + (registrationData.motherLastName || ''),
    //             'Grade': student.grade || '',
    //             'DOB': student.dob || '',
    //             'Gender': student.gender || '',
    //             'Allergies': student.allergies || '',
    //             'Enrollment Date': getMomentDateForUIReadOnly({date: registrationData.dateCreated, format: CONSTANTS.DISPLAY_DATE_FORMAT}),
    //             'Father Mobile': registrationData.fatherMobile || '',
    //             'Mother Mobile': registrationData.motherMobile || '',
    //             'Emergency Contact Name': registrationData.emergencyContactName || '',
    //             'Emergency Phone Number': registrationData.emergencyPhone || '',
    //         });
    //     });
    //     if (studentList.length) {
    //         exportExcel(studentList, 'Student');
    //     }
    // }

    return (
        <div className={""} style={{ minHeight: '300px'}}>
        
        {/* <i className="close-icon" onClick={() =>{props.popModalFromStack();}}>
                <svg width="17" height="17">
                    <title><FormattedMessage id='button.close.label' defaultMessage='' /></title>
                    <path fill="#67757d" fillRule="evenodd"
                          d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z"/>
                </svg>
            </i> */}
            <div className="modal-dialog">
                <div className="modal-content full-width">
                    {/* <div className="modal-header">
                    <FormattedMessage id='studentDetail.text' defaultMessage='' />
                    </div> */}
                    <div className="inner-content">
                        {/* <Row>
                    <Col span={4} style={{ paddingBottom: '10px'}}>
                            {(studentDetailsList && studentDetailsList.length) ?
                                <div className="small-btn-group">
                                    <button title="Export to Excel" className="ant-btn ant-btn-default" onClick={downloadFile}>{<FileExcelOutlined />}</button >
                                </div> : ''
                            }
                             </Col>
                       
                    </Row> */}

                        <div className="modal-table-container">
                            <table>
                                <thead>
                                <tr>
                                    <th><FormattedMessage id='signup.firstname.placeholder' /></th>
                                    <th><FormattedMessage id='signup.lastname.placeholder' /></th>
                                    <th><FormattedMessage id='grade.text' /></th>
                                    <th><FormattedMessage id='dob.text' /></th>
                                    <th><FormattedMessage id='allergies.header.text' /></th>
                                    <th><FormattedMessage id='addItem.text.userStatus' /></th>
                                    <th><FormattedMessage id='gender.text' /></th>
                                    <th><FormattedMessage id='customer.listing.header.action' /></th>
                                </tr>
                                </thead>
                                <tbody>
                                    {(studentDetailsList|| []).map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item.firstName || '-'}</td>
                                                <td>{item.lastName || '-'}</td>
                                                <td>{item.grade || '-'}</td>
                                                <td>{item.dob || '-'}</td>
                                                 <td dangerouslySetInnerHTML={{
                                                    __html: item.allergies || '-'
                                                }}></td>
                                                <td>{item.userStatus || '-'}</td>
                                                <td>{item.gender || '-'}</td>
                                                <td> <span onClick={() => {
                                                    const modalData = {
                                                        modalBody: 'Are you sure you want to delete?',
                                                        handleSubmit: () => {
                                                            props.deleteStudent(item, props);
                                                            props.popModalFromStack();
                                                        }
                                                    };
                                                    props.pushModalToStack(modalData);
                                                }} className="cursor-pointer">  <i className="fa fa-trash-o" /></span></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </div>
            </div>
            </div>
    );

}

export default StudentDetails;
