import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SupplierPaymentListingComponent from "../../../../components/supplier/payment/Listing";
import {
  fetchDataIfNeeded,
  fetchPaginationDataIfNeeded,
} from "../../../../utils";
import {
  fetchSupplierPayments,
  getSupplierPaymentsCount,
  resetPaginatedData,
  postPaymentsToXero,
  deletePayment,
  fetchSupplierPaymentsByFilter,
  getSupplierPaymentsCountByFilter,
} from "./action";
import {
  showModal,
  pushModalToStack,
  hideModal,
  popModalFromStack,
  fetchUserPreferences,
  saveUserPrefrences,
  fetchProjects,
} from "../../../../actions/commonActions";
import { fetchSuppliers } from "../../Listing/action";
import * as find from "lodash.find";
// import { RowPositionUtils } from "ag-grid-community";
import PaymentListFilterComp from "../../../../components/supplier/payment/PaymentListFilter";

class SupplierPaymentListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      isDataRendered: false,
      txPreferences: { txType: "supplierPayment", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: ["paymentNumber", "paymentAmount", "paymentDate"],
      txAvailableColumn: {
        isXeroUploaded: true,
        paymentNumber: true,
        txNumber: true,
        customerPONumber: true,
        customerRFQNumber: true,
        customerInquiryNumber: true,
        poNumber: true,
        supplierName: true,
        paymentAmount: true,
        invoiceAmount: true,
        paymentDate: true,
        //status: true,
        rfqNumber: true,
        project: true,
        description: true,
        reference: true,
      },
      selectedAmountTerm: "=",
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 25,
    };
    fetchPaginationDataIfNeeded(
      "fetchSupplierPayments",
      "supplierPaymentList",
      this.props,
      payload
    );
    fetchDataIfNeeded(
      "getSupplierPaymentsCount",
      "supplierPaymentCount",
      this.props,
      payload
    );
    fetchDataIfNeeded(
      "fetchUserPreferences",
      "allPreferences",
      this.props,
      payload
    );
    this.props.fetchSuppliers(payload);
    this.props.fetchProjects(payload);
  }

  componentWillReceiveProps(props) {
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences =
        find(props.allPreferences, {
          txType: "supplierPayment",
          templateName: "standard",
        }) || this.state.txPreferences;
      if (txPreferences) {
        let txColumns = txPreferences.tablePreference
          ? (JSON.parse(txPreferences.tablePreference) || {}).columnList || []
          : [];
        txColumns = txColumns.length
          ? txColumns
          : (this.state.txMandatoryColumns || []).concat(
              "product",
              "description"
            );
        const txColumnSetting = txPreferences.tablePreference
          ? (JSON.parse(txPreferences.tablePreference) || {}).columnSetting ||
            {}
          : {};
        const isColumnFitTable =
          Object.keys(txColumnSetting).length ||
          this.state.isColumnFitTable === false
            ? false
            : true;
        let gridPreference = txPreferences.gridPreference;
        // const customerName = props.customerName;
        this.setState({
          gridPreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences,
          // customerId: props.poInvoiceData.customerId,
          // customerName: props.poInvoiceData.customerName,
          // customer: { customerId: props.poInvoiceData.customerId, customerName },
        });
      } else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat(
            "product",
            "description"
          ),
        });
      }
    }
  }

  render() {
    return (
      <>
        <SupplierPaymentListingComponent
          {...this.props}
          {...this.state}
          updateState={(data) => {
            this.setState(data);
          }}
        />
        {this.state.paymentListFilterDrawerVisible && (
          <PaymentListFilterComp
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    userInfo: state.common.userInfo,
    supplierPaymentList: state.supplierPayment.supplierPaymentList,
    supplierPaymentCount: state.supplierPayment.supplierPaymentCount,
    isLoading: state.common.isLoading,
    allPreferences: state.common.allPreferences,
    permissions: state.auth.permissions,
    suppliers: state.supplier.suppliers,
    projectList: state.common.projectList,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading,

  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSupplierPayments,
      getSupplierPaymentsCount,
      resetPaginatedData,
      deletePayment,
      showModal,
      pushModalToStack,
      postPaymentsToXero,
      hideModal,
      popModalFromStack,
      fetchUserPreferences,
      saveUserPrefrences,
      fetchSuppliers,
      fetchProjects,
      fetchSupplierPaymentsByFilter,
      getSupplierPaymentsCountByFilter,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplierPaymentListing);
