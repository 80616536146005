import React, { Fragment, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Input, Button, Select, Slider } from 'antd';
import ColorPicker from 'material-ui-rc-color-picker';
import 'material-ui-rc-color-picker/assets/index.css';
const { Option } = Select;

function ButtonProps(props) {
    let buttonRef = document.getElementById("button_"+props.childId) || {style:{}};
    let buttonContainer = document.getElementById(props.childId) || {style:{}};
    const [buttonWidth, updateWidth] = useState(buttonRef.offsetWidth);
    const [buttonHref, updateHref] = useState(buttonRef.href);
    const [buttonMargin, updateMargin] = useState(buttonRef.offsetMargin);
    const [buttonPadding, updatePadding] = useState(buttonRef.offsetPadding);
    const [buttonPosition, updatePosition] = useState(buttonRef.offsetPosition);
    const [buttonOpacity, updateOpacity] = useState(buttonRef.offsetOpacity);


    return (
        <Fragment>
                    <div>
                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='buttonName.text' />
                        </Col>
                        <Col span={24}>
                            <Input value={buttonRef.innerHTML} onChange={(e) => {
                                buttonRef.innerHTML = e.target.value;
                                props.updateState({buttonName : e.target.value})
                            }}></Input>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='buttonAction.text' />
                        </Col>
                        <Col span={24}>
                            <Input value={buttonHref || buttonRef.href} disabled={props.templateType === 'event' || props.templateType === 'fundraising' } onChange={(e) => {
                                //document.getElementById("button_"+props.childId).onclick = function() { window.location=e.target.value;};
                                let val = e.target.value;
                               // document.getElementById("button_"+props.childId).onclick = function(){ window.location= val} ;
                                document.getElementById("button_"+props.childId).href = val;
                                updateHref(val);
                            }}></Input>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={18}>
                            <FormattedMessage id='buttonSize.text' />
                        </Col>
                        <Col span={24}>
                            <Button title='Small' onClick={() => {

                                buttonRef.style.width = "120px";
                                buttonRef.style.height = "40px";
                                buttonRef.style.lineHeight = "40px";
                               
                                //    buttonRef.style.background = 'teal'; // setting the background color to teal
                                //    buttonRef.style.color = 'white'; // setting the color to white
                                buttonRef.style.fontSize = '20px'; // setting the font size to 20px
                                updateWidth(120);
                            }}style={{borderRadius:'4px 0px 0px 4px'}}>
                                <span className='cirty'>
                                S </span></Button>
                            <Button title='Medium' onClick={() => {
                                buttonRef.style.width = "150px";
                                buttonRef.style.height = "50px";
                                buttonRef.style.lineHeight = "50px";
                                updateWidth(150);
                            }} style={{borderRadius:'0px 0px 0px 0px '}}>
                                <span className='cirty'>M </span></Button>
                            <Button title='Large' onClick={() => {
                                buttonRef.style.width = "170px";
                                buttonRef.style.height = "60px";
                                buttonRef.style.lineHeight = "60px";
                                updateWidth(170);
                            }} style={{borderRadius:'0px 4px 4px 0px '}}><span className='cirty'> L</span></Button>
                        </Col>
                    </Row>
                    <Row className="but-buttons">
                    <Col span={5}><FormattedMessage id='package.packageDetail.width' /></Col>
                        <Col span={19}>
                        {/* <Slider defaultValue={15} tooltipVisible /> */}
                        <Slider min={10} max={680} onChange={(value) => {
                                buttonRef.style.float = 'left';
                                buttonRef.style.marginLeft =  "0px";
                                buttonRef.style.width = value+"px";
                                updateWidth(value);
                            }} />
                        </Col>
                        </Row>


                        <Row className="but-buttons">
                        <Col span={5}><FormattedMessage id='buttonHeight.height' /></Col>
                        <Col span={19}>
                        
                        {/* <Slider defaultValue={15} tooltipVisible /> */}
                        <Slider  min={10} max={100} onChange={(value) => {
                                buttonRef.style.lineHeight = value+"px"
                                buttonRef.style.height = value+"px"
                            }}  />
                        </Col>

                        
                        </Row>
                        <Row className="but-buttons">
                        <Col span={5}><FormattedMessage id='imageMargin.margin' /></Col>
                        <Col span={19}>
                        
                        <Slider value={buttonMargin} min={-500} defaultValue={0} max={1000} onChange={(value) => {
                                 buttonContainer.style.marginTop = value+"px"
                                 buttonContainer.style.display = "table-caption"
                                 updateMargin(value);
                            }}  />
                        </Col>

                        
                        </Row>
                        <Row className="but-buttons">
                        <Col span={5}><FormattedMessage id='imagePadding.padding' /></Col>
                        <Col span={19}>
                        
                        <Slider value={buttonPadding} min={-500} max={1000} defaultValue={0} onChange={(value) => {
                                 buttonRef.style.padding = value+"px"
                                 updatePadding(value);
                            }}  />
                        </Col>

                        
                        </Row>
                        <Row className="but-buttons">
                        <Col span={5}><FormattedMessage id='imagePosition.position' /></Col>
                        <Col span={19}>
                        
                        <Slider value={buttonPosition} min={-100} max={10000} defaultValue={9999} onChange={(value) => {
                                 buttonRef.style.zIndex = value
                                 updatePosition(value);
                            }}  />
                        </Col>

                        
                        </Row>
                        <Row className="but-buttons">
                        <Col span={5}><FormattedMessage id='imageTransparent.transparant' /></Col>
                        <Col span={19}>
                        
                        <Slider value={buttonOpacity} min={-100} max={1000} defaultValue={1} onChange={(value) => {
                                 buttonRef.style.opacity = value
                                 buttonRef.style.background = "none"
                                 buttonRef.style.border = "none"

                                 updateOpacity(value);
                            }}  />
                        </Col>

                        
                        </Row>
                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='buttonCorner.text' />
                        </Col>
                        <Col span={24} >
                            <Button title='Square' onClick={() => {
                                buttonRef.style.borderRadius = "0px"
                            }} style={{borderRadius:'4px 0px 0px 4px', position:'relative'}}> 
                            <div className="left-roud"></div>
                            
                            </Button>
                            <Button title='Round' onClick={() => {
                                buttonRef.style.borderRadius = "4px"
                            }} style={{borderRadius:'0px', position:'relative'}}>   
                            <div className="mr-roud"></div>   
                             
                            </Button>
                            <Button title='Extra Round' onClick={() => {
                                buttonRef.style.borderRadius = "20px"
                            }} style={{borderRadius:'0px 4px 4px 0px', position:'relative'}}>      
                             <div className="right-roud"></div>
                            </Button>
                        </Col>
                    </Row>
                    <Row className="but-buttons">
                    <Col span={5}><FormattedMessage id='buttonCorners.text' /></Col>
                        <Col span={19}>
                        <Slider defaultValue={10} min={0} max={50} onChange={(value) => {
                                buttonRef.style.borderRadius = value+"%"
                            }}  />
                        </Col>
                        </Row>

                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='buttonPosition.text' />
                        </Col>
                        <Col span={24} >
                            <Button title='Left' onClick={() => {
                                buttonRef.style.marginLeft = "10px";
                                buttonRef.style.float = 'left';
                                buttonRef.style.margin = "15px auto 0px";
                            }} style={{borderRadius:'4px 0px 0px 4px', position:'relative'}}>      
                            
                            <div className="leftd-roud1"></div>    
                            </Button>
                            <Button title='Middle' onClick={() => {
                                buttonRef.style.float = 'left';
                                buttonRef.style.marginLeft =  ((680-buttonWidth)/2)+"px";
                            }} style={{borderRadius:' 0px ', position:'relative'}} >      
                             <div className="mrd-roud1"></div>
                            </Button>
                            <Button title='Right' onClick={() => {
                                buttonRef.style.marginLeft = "10px";
                                buttonRef.style.float = 'right';
                            }} style={{borderRadius:'0px 4px 4px 0px', position:'relative'}} >      
                             <div className="rigt-roud1"></div>
                            </Button>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                    <Col span={5}><FormattedMessage id='buttonPosition.text' /></Col>
                        <Col span={19}>
                            <Slider max={680} onChange={(value) => {
                                buttonRef.style.float = 'left';
                                buttonRef.style.marginLeft = (buttonWidth+value) > 680 ? (680-buttonWidth)+"px" : value+"px";
                            }} />
                        </Col>
                        </Row>
                    <Row className="but-buttons">
                        <Col span={6} style={{paddingTop:'4px'}}> 
                            <FormattedMessage id='buttonTextStyle.text' />
                        </Col>
                        <Col span={18} >
                            <Select style={{width: '100%'}} onChange={(val) => {
                                buttonRef.style.fontFamily = val;
                            }}>
                                <Option value="arial">Arial</Option>
                                <Option value="verdana">Verdana</Option>
                                <Option value="verdana">Century Gothic</Option>
                                <Option value="impact">Helvetica</Option>
                                <Option value="gorgeous">gorgeous</Option>
                                <Option value="Myriad Pro">Myriad Pro</Option>
                                <Option value="New York">New York</Option>
                                <Option value="Optima">Optima</Option>
                                <Option value="impact">Impact</Option>
                                <Option value="ms comic sans">MS Comic Sans</Option>
                                <Option value="Times New Roman">Times New Roman</Option>
                                <Option value="Baskerville">Baskerville</Option>
                                <Option value="American Typewriter">American Typewriter</Option>
                                <Option value="Edwardian Script ITC">Edwardian Script ITC</Option>
                                <Option value="arial">Montserrat</Option>
                                 <Option value="ms comic sans">Phosphate</Option>

                            </Select>
                        </Col>
                    </Row>
                    <Row className="but-buttons">
                        <Col span={6}>
                        <FormattedMessage id='buttonTextSize.text' />
                        </Col>
                        <Col span={18}>
                        <Slider min={1} max={50} onChange={(val) => {
                                buttonRef.style.fontSize = val+'px';
                            }}/>
                        </Col>
                        </Row>
                    <Row className="but-buttons">
                    <Col span={7} style={{ paddingTop:'2px', paddingRight:'4px'}}>
                            <FormattedMessage id='buttonTextColor.text' />
                        </Col>
                        <Col span={17} >
                            <ColorPicker
                             placement='bottomLeft'
                                enableAlpha={false}
                                color={buttonRef.style.color || '#fff'}
                                onChange={(obj) => {
                                    props.updateState({ textColor: obj.color });
                                    buttonRef.style.color = obj.color;
                                }}
                                mode="RGB"
                            />
                        </Col>
                        
                    </Row>
                    <Row className="but-buttons">
                    <Col span={7} style={{ paddingTop:'2px', paddingRight:'4px'}}>
                            <FormattedMessage id='buttonBackgraound.text' />
                        </Col>
                    <Col span={17} >
                            <ColorPicker
                                enableAlpha={false}
                                color={buttonRef.style.background || '#525156'}
                                onChange={(obj) => {
                                    props.updateState({ bgColor: obj.color });
                                    buttonRef.style.background = obj.color;
                                }}
                                mode="RGB"
                            />
                        </Col>
                        
                        
                    </Row>

                    <Row className="but-buttons">
                    <Col span={7} style={{ paddingTop:'2px', paddingRight:'4px'}}>
                            <FormattedMessage id='bordercolor.text' />
                        </Col>
                    <Col span={17} >
                            <ColorPicker
                                enableAlpha={false}
                                color={buttonRef.style.borderColor || '#525156'}
                                onChange={(obj) => {
                                    props.updateState({ borderColor: obj.color });
                                    buttonRef.style.borderColor = obj.color;
                                }}
                                mode="RGB"
                            />
                        </Col>
                        
                        
                    </Row>


                </div>
        </Fragment>
    );
}


export default injectIntl(ButtonProps);
