import React from 'react';
import { injectIntl } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Row, Col,  } from 'antd';
// import ColorPicker from 'material-ui-rc-color-picker';
import 'material-ui-rc-color-picker/assets/index.css';
import SectionIcon2 from '../../../assets/images/two-icon.png'
import SectionIcon3 from '../../../assets/images/three-icon.png'
import SectionIcon2s from '../../../assets/images/twos-icon.png'
import SectionIcon2l from '../../../assets/images/twol-icon.png'
import SectionIcon3r from '../../../assets/images/threes-icon.png'
import SectionIcon3l from '../../../assets/images/three2-icon.png'
// const { Panel } = Collapse;

function SectionProps(props) {
    return (
       <>
       <Row>
           <Col span={12}> 
           <div className='diver-box'>
               <img src={SectionIcon2} alt=''/>
               </div>  
           </Col>
           <Col span={12}>
           <div className='diver-box'>
               <img src={SectionIcon3} alt=''/>
               </div>
           </Col>
           <Col span={12}> 
           <div className='diver-box'>
               <img src={SectionIcon2s} alt=''/>
               </div>  
           </Col>
           <Col span={12}>
           <div className='diver-box'>
               <img src={SectionIcon2l} alt=''/>
               </div>
           </Col>

           <Col span={12}> 
           <div className='diver-box'>
               <img src={SectionIcon3r} alt=''/>
               </div>  
           </Col>
           <Col span={12}>
           <div className='diver-box'>
               <img src={SectionIcon3l} alt=''/>
               </div>
           </Col>
       </Row>
       </>
    );
}


export default injectIntl(SectionProps);
