import React from 'react';
import { Tooltip, Button, Row, Col } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as find from 'lodash.find';
import { CONSTANTS } from '../../../../../static/constants';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../../static/cssClassConstants';
import { fixedDecimalNumber, getCurrentDateForBackend, showToasterMessage, getMomentDateForUIReadOnly } from '../../../../../utils';
import { CustomAgGrid } from '../../../../general/CustomAgGrid';

class ApplyDebitsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalAmountToDebit: 0,
        }
    }
    render() {
        const agGridStyle = {
            height: '100%',
            width: '100%'
        };

        // const getRowHeight = () => {
        //     return 32;
        // }

        const onFirstDataRendered = (params) => {
            params.api.sizeColumnsToFit();
        }

        const calculateSubtotal = (obj) => {
            let total = 0;
            let currentNodeAmt = 0;
            let requiredCredit = 0;
            let self = this;
            this.gridApi && this.gridApi.forEachNode(function (rowNode, index) {
                if (rowNode.data.amountToDebit) {
                    total += Number(rowNode.data.amountToDebit);
                    if (index === obj.node.rowIndex) {
                        currentNodeAmt = rowNode.data.amountToDebit;
                    }
                }
            });
            if (total > self.props.invoiceData.invoiceDueAmount) {
                requiredCredit = self.props.invoiceData.invoiceDueAmount - (total - currentNodeAmt);
                this.gridApi && this.gridApi.forEachNode(function (rowNode, index) {
                    if (index === obj.node.rowIndex) {
                        rowNode.data.amountToDebit = requiredCredit;
                    }
                    
                });
                total = self.props.invoiceData.invoiceDueAmount;
            }
            this.setState({
                totalAmountToDebit: total
            });
        }

        const handleApplyCredit = () => {
            const self = this;
            if (!this.state.totalAmountToDebit || this.state.totalAmountToDebit === 0) {
                return showToasterMessage({ description: 'Amount to debit required' });
             }
            const modalData = {
                modalBody: 'Are you sure you want to apply debits',
                handleSubmit: () => {
                    // const payload = {
                    //     invoiceMasterId: this.props.invoiceData.invoiceMasterId,
                    //     customerId: this.props.invoiceData.customerId,
                    //     relationshipId: this.props.invoiceData.relationshipId,
                    //     amountToDebit: this.state.totalAmountToDebit,
                    //     applyCreditList: []
                    // }
                    let applyDebitList = [];

                    this.gridApi && this.gridApi.forEachNode(function (rowNode, index) {
                        let creditObj = { ...rowNode.data };
                        creditObj.invoiceMasterId = self.props.invoiceData.invoiceMasterId;
                        if (creditObj.amountToDebit) {
                            creditObj.amountApplied = creditObj.amountToDebit;
                            creditObj.createdByEmpId = (self.props.userInfo || {}).relationshipEmployeeId;
                            creditObj.dateCreated = getCurrentDateForBackend(new Date());

                            const creditLedger = find(creditObj.supplierCOATxDebitMemoList, (ele) => {
                                return ele.accountingEntry === 'credit';
                            });
                            const debitLedger = find(creditObj.supplierCOATxDebitMemoList, (ele) => {
                                return ele.accountingEntry === 'debit';
                            });
                            creditObj.supplierCOATxDebitMemoList = [];
                            if (creditLedger) {
                                delete creditLedger.creditMemoCoaTxId;
                                creditLedger.accountingEntry = 'debit';
                                creditLedger.amount = Number(creditObj.amountToDebit);
                                creditLedger.dateCreated = getCurrentDateForBackend(new Date());
                                creditObj.supplierCOATxDebitMemoList.push(creditLedger);
                            }
                            if (debitLedger) {
                                delete debitLedger.creditMemoCoaTxId;
                                debitLedger.accountingEntry = 'credit';
                                debitLedger.amount = Number(debitLedger.amountToDebit);
                                debitLedger.dateCreated = getCurrentDateForBackend(new Date());
                                creditObj.supplierCOATxDebitMemoList.push(debitLedger);
                            }
                            applyDebitList.push(creditObj);
                        }
                    });
                    self.props.applyDebits(applyDebitList, self.props);
                    self.props.popModalFromStack();
                    self.props.hideModal();
                }
            };
            self.props.pushModalToStack(modalData);
        }

        const columnDefs = [
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.supplier.applyPurchaseCredit.debitMemoNumber' defaultMessage='' />;
                },
                field: "debitMemoNumber",
                cellRendererFramework: (link) => <div>{link.value}</div>,
                resizable: true,
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.supplier.applyPurchaseCredit.debitMemoDate' defaultMessage='' />;
                },
                field: "debitMemoDate",
                cellRendererFramework: (link) => {
                    return <div>
                        <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}>
                            {(link.value ? getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}) : '-')}
                        </Tooltip>
                    </div>
                },
                resizable: true,
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.supplier.applyPurchaseCredit.debitAmount' defaultMessage='' />;
                },
                field: "debitMemoTotalAmount",
                cellRendererFramework: (params) => {
                    return <span>{this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " "} {fixedDecimalNumber(params.value)}</span>;
                },
                valueFormatter: (data) => {
                    data.value = data.value ? (fixedDecimalNumber(data.value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
                    return data.value;
                },
                resizable: true,
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.supplier.applyPurchaseCredit.debitsAvailable' defaultMessage='' />;
                },
                field: "debitsAvailable",
                cellRendererFramework: (params) => {
                    return <span>{this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " "} {fixedDecimalNumber(params.node.data.debitMemoTotalAmount - (params.node.data.debitUsed || 0))}</span>;
                },
                valueFormatter: (data) => {
                    data.value = data.value ? (fixedDecimalNumber(data.value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
                    return data.value;
                },
                resizable: true,
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.supplier.applyPurchaseCredit.amountToDebit' defaultMessage='' />;
                },
                field: "amountToDebit",
                resizable: true,
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
                editable: true,
                suppressMovable: true,
                cellEditor: 'customNumberEditor',
                cellEditorParams: (obj) => {
                    return {
                        maxValue : (obj.node.data.debitMemoTotalAmount - (obj.node.data.debitUsed || 0)),
                        onChange: (value) => {
                            obj.node.data.amountToDebit = value;
                        }
                    }
                },
                valueGetter: (obj) =>{
                    if(obj.node.data.amountToDebit > (obj.node.data.debitMemoTotalAmount - obj.node.data.debitUsed)){
                        obj.node.data.amountToDebit = obj.node.data.debitMemoTotalAmount - obj.node.data.debitUsed
                    }
                    calculateSubtotal(obj);
                    return obj.node.data.amountToDebit
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.supplier.applyPurchaseCredit.remarks' defaultMessage='' />;
                },
                field: "remarks",
                resizable: true,
                editable: true,
                suppressMovable: true,
                cellEditor: 'agLargeTextCellEditor',
                cellEditorParams: (params) => {
                    setTimeout(() => {
                        if (params.node.data.scrollHeight) {
                            document.querySelector("textarea.ag-text-area-input").style.height = 'inherit';
                            document.querySelector("textarea.ag-text-area-input").style.height = params.node.data.scrollHeight;
                        }
                    }, 100);
                    return {
                        maxLength: '1000',
                        cols: '20',
                        rows: 2
                    }
                },
                cellRendererFramework: (params) => {
                    return <pre> {params.data.remarks}</pre>
                },
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.event.target.value && params.editing && keyCode === KEY_ENTER;
                    params.event.target.style.height = 'inherit';
                    params.event.target.style.height = `${params.event.target.scrollHeight}px`;
                    params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
                    if (params.event.target.scrollHeight > 54) {
                        params.node.setRowHeight(params.event.target.scrollHeight - (params.event.target.scrollHeight * .1));
                    } else {
                        //params.node.setRowHeight(params.event.target.scrollHeight - 26);
                    }
                    this.gridApi && this.gridApi.onRowHeightChanged();
                    return gridShouldDoNothing;
                }
            },
            // {
            //     headerComponentFramework: () => {
            //         return 'Action';
            //     },
            //     field: "action",
            //     cellRendererFramework: (params) => {
            //         return <Button type="primary">Apply Credits</Button>
            //     },
            //     resizable: true,
            // },
        ];
        const onGridReady = (params) => {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
        }

        return (
            <div>
            <div className='agGridWrappers'>
                <div className="modal-table-container vertical-space" >
                            <table>
                                <thead>
                                    <tr style={{background:"#e8e8e8"}}>
                                        <th><FormattedMessage id='modal.supplier.applyPurchaseCredit.invoiceNumber' defaultMessage='' /></th>
                                        <th><FormattedMessage id='modal.supplier.applyPurchaseCredit.invoiceDate' defaultMessage='' /></th>
                                        <th><FormattedMessage id='modal.supplier.applyPurchaseCredit.dueDate' defaultMessage='' /></th>
                                        <th><FormattedMessage id='modal.supplier.applyPurchaseCredit.invoiceAmount' defaultMessage='' /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{this.props.invoiceData.invoiceNumber || '-'}</td>
                                        <td>{ this.props.invoiceData.invoiceDate && getMomentDateForUIReadOnly({date: this.props.invoiceData.invoiceDate, format: CONSTANTS.DISPLAY_DATE_FORMAT})}</td>
                                        <td>{ this.props.invoiceData.invoiceDueDate && getMomentDateForUIReadOnly({date: this.props.invoiceData.invoiceDueDate, format: CONSTANTS.DISPLAY_DATE_FORMAT})}</td>
                                        <td>{ fixedDecimalNumber(this.props.invoiceData.invoiceTotalAmount) }</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                <div className="ag-theme-balham customer-details-table" style={agGridStyle} >
                    {this.props.availableDebitList ? <CustomAgGrid
                        columnDefs={columnDefs}
                        rowData={this.props.availableDebitList}
                        rowDragManaged={true}
                        domLayout={"autoHeight"}
                        animateRows={true}
                        onGridSizeChanged={onFirstDataRendered}
                        onGridReady={onGridReady}
                    >
                    </CustomAgGrid> : ''
                    }
                    <Row type="flex" justify="end">
                        <Col span={7} offset={16}>
                            <div className="amount-summary">
                                <br/>
                                {/* <Row>
                                    <Col span={15}>
                                        <span className="title">Invoice Amount</span>
                                    </Col>
                                    <Col span={9}>
                                        {this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " "}
                                        {this.props.invoiceData.invoiceTotalAmount}
                                    </Col>
                                </Row> */}

                                <Row>
                                    <Col span={15}>
                                        <span className="title"><FormattedMessage id='modal.supplier.applyPurchaseCredit.totalAmountToDebit' defaultMessage='' /></span>
                                    </Col>
                                    <Col span={9}  className="pr10 text-agright">
                                        {this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " "}
                                        {fixedDecimalNumber(this.state.totalAmountToDebit || 0)}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={15} className="pt10 pb10">
                                        <span className="title"><FormattedMessage id='modal.supplier.applyPurchaseCredit.invoiceDue' defaultMessage='' /></span>
                                    </Col>
                                    <Col span={9}  className="pr10 text-agright pt10 pb10">
                                        {this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " "}
                                        {fixedDecimalNumber(this.props.invoiceData.invoiceDueAmount - this.state.totalAmountToDebit)}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
                </div>
                <div className="ant-modal-footer">
                        <Button type="primary" onClick={() => {
                            handleApplyCredit();
                        }}><FormattedMessage id='modal.supplier.applyPurchaseCredit.applyCredits' defaultMessage='' /></Button>
                        </div>
            </div>
        );
    }
}

export default injectIntl(ApplyDebitsComponent);
