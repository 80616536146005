import React from 'react';
// import { AgGridReact } from 'ag-grid-react';
// import { Tooltip, Pagination } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CONSTANTS } from '../../../../../static/constants';
// import { AG_GRID_CLASS_CONSTANTS } from '../../../../../static/cssClassConstants';
import { capitalizeFirstLetter, getMomentDateForUIReadOnly } from '../../../../../utils';
// import SalesInvoiceDetail from '../../../../../containers/modal/modalBody/customer/SalesInvoiceDetail';
import FormDetails from '../../../../../containers/modal/modalBody/schoolManagement/FormDetails/index';


const CustomerRegistrationComponent = (props) => {
    const {customerRegistration,pageNumber,pageSize } = props;

   
   const getStudentNameList = (reg) => {
    return (reg.studentList || []).map((classObj, i) => <div key={i}>{`${classObj.firstName} ${classObj.lastName || ''}`}</div>);
    }

    return (
        <>
            <div className='table-container'style={{height: '550px'}}>
                <table>
                    <thead>
                        <tr>
                            <th><FormattedMessage id='serial.no' /></th>
                            <th>Form Name</th>
                            <th>Student Name</th>
                            <th>Father Name</th>
                            <th>Mother Name</th>
                            <th>Payment Plan</th>
                            <th>Enrolled Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(customerRegistration || []).map((e, i) => {
                            return <tr key={'std' + i}>
                               <td>{(pageNumber-1)*pageSize+(i+1)}</td>                      
                                         <td>
                                    <div className='cursor-pointer' onClick={()=>{
                                        props.pushModalToStack(
                                            {
                                                title: <div className='ant-modal-title'>Form Details</div>,
                                                modalBody: <FormDetails {...props} formDetailsData={e}/>,
                                                width: '100%',
                                                hideFooter: true,
                                                wrapClassName: 'modal-custom-registration'
                                            }
                                        )
                                    }}>
                                        <b>{e.formName || ''}</b>
                                    </div>
                                    <div>{e.startDateSchoolSession ? <span> {(e.startDateSchoolSession && getMomentDateForUIReadOnly({ date: e.startDateSchoolSession, format: CONSTANTS.TABLE_DATE_FORMAT }))} - {(e.endDateSchoolSession && getMomentDateForUIReadOnly({ date: e.endDateSchoolSession, format: CONSTANTS.TABLE_DATE_FORMAT }))} </span> : ''}</div>
                                </td>
                                <td>{getStudentNameList(e)}</td>
                                <td>
                                    {e.studentList && e.studentList.length ? <div>{e.studentList[0].fatherFirstName || ''} {e.studentList[0].fatherLastName || ''}</div> : '-'}
                                    {/* <div>{e.studentList[0].fatherMobile}</div>
                                    <div>{e.studentList[0].fatherEmail}</div> */}
                                </td>
                                <td>
                                    {e.studentList && e.studentList.length ? <div>{e.studentList[0].motherFirstName || ''} {e.studentList[0].motherLastName || ''}</div> : '-'}
                                    {/* <div>{e.studentList[0].motherMobile}</div>
                                    <div>{e.studentList[0].motherEmail}</div> */}
                                </td>
                                <td>
                                    {
                                        Object.keys(JSON.parse(e.selectedPlan)).length && JSON.parse(e.selectedPlan).period ? 
                                        <>
                                            {capitalizeFirstLetter(JSON.parse(e.selectedPlan).period)}
                                        </>
                                         : '-'
                                    }
                                </td>
                                <td>
                                    {(e.dateCreated && getMomentDateForUIReadOnly({ date: e.dateCreated, format: CONSTANTS.TABLE_DATE_FORMAT }))}
                                </td>
                            </tr>
                        })
                        }
                    </tbody>
                </table>
            </div>
            {/* <Pagination
                size="small"
                total={unpaidInvoiceCount}
                showTotal={showTotal}
                defaultPageSize={100} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                showQuickJumper
                onChange={(pageNumber, pageSize) => {
                    loadPageData(pageNumber, pageSize);
                    updateState({ pageSize: pageSize, pageNumber: pageNumber })
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                    handlePageSizeChange(pageNumber, pageSize, props)
                }}
            /> */}
        </>
    );
};

export default injectIntl(CustomerRegistrationComponent);