import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AgedCreditorComponent from '../../../../../components/modal/modalBody/reports/AgedCreditors';
import { fetchAgedCreditors } from './action';
import { fetchDataIfNeeded } from '../../../../../utils';

class AgedCreditors extends Component {
    constructor(props) {
        super();
        this.state = {
        }
    }
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, companyInfo: this.props.companyInfo };
        fetchDataIfNeeded('fetchAgedCreditors', 'agedCreditorsList', this.props, payload, true);

    }

    render() {
        return <div>
            <AgedCreditorComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        userInfo: state.common.userInfo,
        agedCreditorsList: state.reports.agedCreditorsList,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAgedCreditors
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AgedCreditors);