import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, INVENTORY_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createUpdateWarehouse = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/warehouseMaster/createWarehouseLocation`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(payload.warehouseMasterId){
          dispatch({ type: INVENTORY_ACTION_LIST.WAREHOUSE_LIST_UPDATION, data: res.data });
        }else{
          dispatch({ type: INVENTORY_ACTION_LIST.WAREHOUSE_LIST_ADDITION, data: res.data });
        }
        const payload2 = {
          relationshipId: props.companyInfo.relationshipId,
        }
        props.fetchWarehouseNames(payload2)
        showToasterMessage({ messageType: 'success', description: 'Warehouse saved successfully' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else {
          err.actionToCall = INVENTORY_ACTION_LIST.WAREHOUSE_LIST_ADDITION;
        }
      })
  }
}