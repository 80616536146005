import { Col, Drawer, Row } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { CustomButton } from "../../../../general/CustomButton";
import { TextBox } from "../../../../general/TextBox";
import { ErrorMsg } from "../../../../general/ErrorMessage";
import { Dropdown } from "../../../../general/Dropdown";

const BranchDrawer = (props) => {

  const [branchInfo, setBranchInfo] = useState(props.branchInfo || {});

  const closeDrawer = () => {
    props.updateState({
      branchDrawer: false,
    });
  };

  const createBranch = () =>{
    const {streetAddress1, cityName, countryName, stateName, zipCode} = branchInfo?.location || {};

    if(!branchInfo.warehouseName || (branchInfo.isPrimary === undefined) || !streetAddress1 || !cityName || !countryName || !stateName || !zipCode){
      setBranchInfo({...branchInfo, submitted: true});
      return;
    }
    branchInfo.relationshipId = props.companyInfo?.relationshipId;
    branchInfo.location = {
      ...(branchInfo.location || {}),
      relationshipId: props.companyInfo?.relationshipId,
      locationType: "BranchAddress",
      isDefault: branchInfo?.location.warehouseMasterId ? branchInfo?.location?.isDefault : 1
    }

    branchInfo.locationList = [branchInfo.location];

    props.showModal({
      modalBody: `Do you want to ${branchInfo.warehouseMasterId ? 'update' : 'create'} branch?`,
      handleSubmit: () => {
        props.createBranch(branchInfo, props);
      },
    });

  }

  return (
    <Drawer
      title="Create Branch"
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.branchDrawer}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {
              createBranch();
            }}
            key={1}
          />
        </>
      }
    >
      <Row gutter={[16,16]}>
        <Col span={12}>
          <div className="i-label">
            <FormattedMessage id='employeeMeta.branch.label.branchName'/>
            <span className="required">*</span>
          </div>
          <TextBox
            placeholder="Branch Name"
            type="text"
            value={branchInfo.warehouseName}
            onChange={(e) => {
              setBranchInfo({...branchInfo, warehouseName: e.target.value})
            }}
          />
          <ErrorMsg
            validator={() => { return !branchInfo.submitted || branchInfo.warehouseName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">         
            <FormattedMessage id='employeeMeta.branch.label.primaryBranch'/><span className="required">*</span>
          </div>
          <Dropdown
            items={[{label: 'Yes', value: 1}, {label: 'No', value: 0}]}
            value={branchInfo.isPrimary ? branchInfo.isPrimary : 0}
            valueKeyName='label'
            optionKeyName='value'
            placeholder="Select"
            onSelect={(optionValue, option) => {
              setBranchInfo({...branchInfo, isPrimary: optionValue});
            }}
          />
          <ErrorMsg
            validator={() => { return !branchInfo.submitted || !(branchInfo.isPrimary === undefined) }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Address 1<span className="required">*</span></div>
          <TextBox
            placeholder="Address 1"
            type="text"
            value={branchInfo.location?.streetAddress1}
            onChange={(e) => {
              setBranchInfo({...branchInfo, 
                location: {
                  ...(branchInfo?.location || {}),
                  streetAddress1: e.target.value
                }});
            }}
          />
          <ErrorMsg
            validator={() => { return !branchInfo.submitted || branchInfo.location?.streetAddress1 }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />

        </Col>
        <Col span={12}>
          <div className="i-label">Address 2</div>
          <TextBox
            placeholder="Address 2"
            type="text"
            value={branchInfo.location?.streetAddress2}
            onChange={(e) => {
              setBranchInfo({
                ...branchInfo, 
                location: {
                  ...(branchInfo?.location || {}),
                  streetAddress2: e.target.value
                }
              });
            }}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">City<span className="required">*</span></div>
          <TextBox
            placeholder="City Name"
            type="text"
            maxLength={20}
            value={branchInfo.location?.cityName}
            onChange={(e) => {
              setBranchInfo({
                ...branchInfo, 
                location: {
                  ...(branchInfo?.location || {}),
                  cityName: e.target.value
                }
              });
            }}
          />
          <ErrorMsg
            validator={() => { return !branchInfo.submitted || branchInfo.location?.cityName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">ZIP/Postal Code<span className="required">*</span></div>
          <TextBox
            placeholder="ZIP/Postal Code"
            type="text"
            maxLength={20}
            countStyle={{top: '40px'}}
            value={branchInfo.location?.zipCode}
            onChange={(e) => {
              setBranchInfo({
                ...branchInfo, 
                location: {
                  ...(branchInfo?.location || {}),
                  zipCode: e.target.value
                }
              });
            }}
          />
          <ErrorMsg
            validator={() => { return !branchInfo.submitted || branchInfo.location?.zipCode }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Country<span className="required">*</span></div>
          <Dropdown
            items={props.countries}
            value={branchInfo.location?.countryName}
            valueKeyName='countryName'
            optionKeyName='countryId'
            placeholder="Select Country"
            onSelect={(optionValue, option) => {
              setBranchInfo({...branchInfo, location: {...branchInfo.location, countryId: option.value, countryName: option.name, stateId: undefined, stateName: undefined }});
              if(option.name === "United States (USA)" || option.name === 'India'){
                props.fetchStates(option.value);
              }
            }}
          />
          <ErrorMsg
            validator={() => { return !branchInfo.submitted || branchInfo.location?.countryName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">State/Province/Region<span className="required">*</span></div>
          {branchInfo.location?.countryName === "United States (USA)" || branchInfo.location?.countryName === 'India' ? 
            <Dropdown
              items={props.states}
              value={branchInfo.location?.stateName}
              valueKeyName='stateName'
              optionKeyName='stateId'
              placeholder="Select State"
              onSelect={(optionValue, option) => {
                setBranchInfo({...branchInfo, location: {...branchInfo.location, stateId: option.value, stateName: option.name}});
              }}
            />
            :
            <TextBox
              placeholder="State Name"
              type="text"
              value={branchInfo.location?.stateName}
              onChange={(e) => {
                setBranchInfo({...branchInfo, location: {...branchInfo.location, stateName: e.target.value}});
              }}
            />
          }
          <ErrorMsg
            validator={() => { return !branchInfo.submitted || branchInfo.location?.stateName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

      </Row>
    </Drawer>
  );
};

export default BranchDrawer;
