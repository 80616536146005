import { INBOUND_DELIVERY_ACTION_LIST } from '../static/constants';
const initialState = {
  inboundDeliveryList: {
    1: []
  },
  inboundDeliveryCount: 0,
  inboundDeliveryData: {},
  idPoData: {},
  poList: [],
  inboundDeliveryDraftList: {
    1: []
  },
  inboundDeliveryDraftCount: 0,
}

const InboundDeliveryReducer = (state = initialState, action) => {
  switch (action.type) {
    case INBOUND_DELIVERY_ACTION_LIST.INBOUND_DELIVERY_LIST:
      return {
        ...state,
        inboundDeliveryList: {
          ...state.inboundDeliveryList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case INBOUND_DELIVERY_ACTION_LIST.INBOUND_DELIVERY_COUNT:
      return { ...state, inboundDeliveryCount: action.data };
    case INBOUND_DELIVERY_ACTION_LIST.INBOUND_DELIVERY_PO_LIST:
      return { ...state, poList: action.data };
    case INBOUND_DELIVERY_ACTION_LIST.INBOUND_DELIVERY_LIST_PAGE_RESET:
      return { ...state, inboundDeliveryList: initialState.inboundDeliveryList };
    case INBOUND_DELIVERY_ACTION_LIST.NEXT_ID_NUMBER:
      return { ...state, nextTxNumber: action.data };
    case INBOUND_DELIVERY_ACTION_LIST.NEXT_ID_NUMBER_RESET:
      return { ...state, nextTxNumber: '' };
    case INBOUND_DELIVERY_ACTION_LIST.INBOUND_DELIVERY_DATA:
      return { ...state, inboundDeliveryData: action.data };
    case INBOUND_DELIVERY_ACTION_LIST.RESET_INBOUND_DELIVERY_DATA:
      return { ...state, inboundDeliveryData: initialState.inboundDeliveryData };
    case INBOUND_DELIVERY_ACTION_LIST.ID_PO_DATA:
      return { ...state, idPoData: action.data };
    case INBOUND_DELIVERY_ACTION_LIST.RESET_ID_PO_DATA:
      return { ...state, idPoData: initialState.idPoData };
    case INBOUND_DELIVERY_ACTION_LIST.INBOUND_DELIVERY_DRAFT_LIST:
      return {
        ...state,
        inboundDeliveryDraftList: {
          ...state.inboundDeliveryDraftList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case INBOUND_DELIVERY_ACTION_LIST.INBOUND_DELIVERY_DRAFT_COUNT:
      return { ...state, inboundDeliveryDraftCount: action.data };
    case INBOUND_DELIVERY_ACTION_LIST.INBOUND_DELIVERY_DRAFT_LIST_PAGE_RESET:
      return { ...state, inboundDeliveryDraftList: initialState.inboundDeliveryDraftList };
    default:
      return state;
  }
};

export default InboundDeliveryReducer;