import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {  showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import { fetchCategory } from "../../drawer/inventory/action"; 
import { DndProvider } from 'react-dnd'


import { HTML5Backend } from 'react-dnd-html5-backend';
import Themes from "../../../components/eStore/themes";

class ThemesComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
    };
  }
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 100
    };
    this.props.fetchCategory(payload);
  }

  render() {
    return (
      <DndProvider backend={HTML5Backend}>
      <Themes
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></Themes>
      	</DndProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    schoolList: state.school.schoolList,
    schoolCount: state.school.schoolCount,
    productCategories: state.inventory.productCategories,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  fetchCategory,
  hideModal,

  pushModalToStack,
  popModalFromStack,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ThemesComp);
