import { Button, Checkbox, Input } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";


const { Search } = Input;

const SelectedProductModal = (props) => {
    const [selectedProduct, setSelectProduct] = useState(props.returnSet ? (props.returnSetting.notReturnableInventoryItemRefs || []) :props.replaceSet ? (props.replaceSetting.notReturnableInventoryItemRefs || []) : (props.cancelSetting.notReturnableInventoryItemRefs || []));
    const [searchText, setSearchText] = useState("");

    const handleSelectedProduct = () => {

        if (props.returnSet) {
            props.updateState({
                returnSetting: {
                    ...props.returnSetting,
                    notReturnableInventoryItemRefs: selectedProduct,
               

                },
                init: true,
            })
        }
        else if (props.replaceSet) {
            props.updateState({
                replaceSetting: {
                    ...props.replaceSetting,
                    notReturnableInventoryItemRefs: selectedProduct,
                  
                },
                init: true,
            });
        }

        else if (props.cancelSet) {
            props.updateState({
                cancelSetting: {
                    ...props.replaceSetting,
                    notReturnableInventoryItemRefs: selectedProduct,
                  
                },
                init: true,
            });
        }
        props.popModalFromStack();
    };

    const renderContent = (content) => {
        return (searchText ? content.filter((product) => {
            return product.itemName?.toLowerCase().includes(searchText?.toLowerCase());
        }) : content || [])
            .filter((rowData) => rowData.itemId)
            .map((val) => (
                <div key={val.itemId} className="radio-button-Wrapper">
                    <Checkbox key={val.itemId} value={val.itemId}>
                        <div className="parent-category-sub-wrapper">
                            <div className="parent-category-details">
                                <h2>{val.itemName}</h2>
                            </div>
                        </div>
                    </Checkbox>
                </div>
            ));
    };
    return (
        <>
            <div className="cat-search-wrapper">
                <Search
                    placeholder="Search products"
                    value={props.searchProducts}
                    onChange={(e) => {
                        setSearchText(e.target.value);
                    }}

                />
            </div>
            <div className="parent-category-list">
                <Checkbox.Group
                    onChange={(e) => setSelectProduct(e)}
                    value={selectedProduct || []}
                    width="100%"
                >
                    {props.activeTabKey === "1" ? renderContent(props.returnProductData.content) : renderContent(props.replaceProductData.content)}

                </Checkbox.Group>
                <div className="custom-footer-parent-category">
                    <Button type="primary"
                        onClick={() => {
                            handleSelectedProduct();
                        }}  >
                        <FormattedMessage id="button.save.label" defaultMessage="" />
                    </Button>
                </div>
            </div>
        </>
    );
}

export default SelectedProductModal