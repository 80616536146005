import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { FilePdfOutlined, MailOutlined, PrinterOutlined, EditOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import ReactHtmlParser from 'react-html-parser';
import { CONSTANTS, TRANSACTION_TYPES, ACCOUNTING_ENTRY_TYPE } from '../../../../../static/constants';
// import config from '../../../../../config/environmentConfig';
import { fixedDecimalNumber, getMomentDateForUIReadOnly } from '../../../../../utils';
// import { LogoComponent } from '../../../../general/LogoComponent';
import PayExpense from '../../../../../containers/finance/BusinessExpense/PayExpense';

const BusinessExpenseDetails = (props) => {
    const { businessExpenseData, companyInfo } = props;
    const { relationshipExpenseDetails } = businessExpenseData;
    const allRelationshipBillingAddress = filter(companyInfo.boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
    const relationshipAddress = find(allRelationshipBillingAddress, { isDefault: 1 }) || {};

    const getPayloadForPdf = () => {
        return {
            fileName: (businessExpenseData.expenseNumber || 'Expense') + '.pdf',
            transactionName: 'businessExpense',
            businessExpenseData: businessExpenseData,
            relationshipAddress: relationshipAddress,
            companyInfo: companyInfo,
            // bucketName: config.BUCKET_NAME.BO_RELATIONSHIP,
            // stampAwsKey: soDetail.stampAwsKey
        }
    }

    const generatePDF = () => {
        let payload = getPayloadForPdf();
        props.generatePDF(payload);
    }

    const printPdf = () => {
        let payload = getPayloadForPdf();
        props.printPDF(payload);
    }

    const openEmailDrawer = (props) => {
        let payload = getPayloadForPdf();
        payload.fileDataReqeired = true;
        payload.expenseMasterId = businessExpenseData.expenseMasterId;

        props.updateState({
            emailDrawerVisible: true,
            txData: payload,
            type: TRANSACTION_TYPES.BUSINESS_EXPENSE,
        });
    };

    return (
        <div className={"custom-modal show"}>
            <i className="close-icon">
                <svg width="17" height="17" onClick={() => { props.popModalFromStack(); }}>
                    <title><FormattedMessage id='button.close.label' defaultMessage='' /></title>
                    <path fill="#67757d" fillRule="evenodd"
                        d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z" />
                </svg>
            </i>
            <div className="modal-dialog">
                <div className="modal-content full-width">
                    <div className="modal-header">
                        <FormattedMessage id='sidebar.menuItem.businessExpense' defaultMessage='' />
                    </div>
                    <div id="so-readonly" className="inner-content">
                        <Row>
                            <Col span={7}>
                                <div className="d-inline-flex w-100">
                                    {/* <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()} /> */}
                                    {/* <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3+'/'+ config.BUCKET_NAME.BO_RELATIONSHIP +'/'+(props.companyInfo || {}).logoAwsObjectKey + "?"+Date.now()}/> */}
                                    <div className="read-only-content pl10">
                                        <div className="title lh-adjust pb5">
                                            {props.companyInfo.storeName}
                                        </div>
                                        <div>
                                            {getAddressInfoFragment(relationshipAddress, companyInfo)}
                                        </div>

                                    </div>
                                </div>
                            </Col>


                            <Col span={6} offset={11}>
                                <div className="read-only-content">
                                    {
                                        businessExpenseData.expenseNumber &&
                                        <Row>
                                            <Col span={10} className="title text-right">
                                                <FormattedMessage id='supplier.businessExpense.listing.header.expenseNo' defaultMessage='' />
                                            </Col>
                                            <Col span={14} className="pl10">
                                                <abbr>{businessExpenseData.expenseNumber}</abbr>
                                            </Col>
                                        </Row>
                                    }
                                    {
                                        businessExpenseData.expenseDate &&
                                        <Row>
                                            <Col span={10} className="title text-right">
                                                <FormattedMessage id='supplier.businessExpense.listing.header.expenseDate' defaultMessage='' />
                                            </Col>
                                            <Col span={14} className="pl10">
                                                {(businessExpenseData.expenseDate && getMomentDateForUIReadOnly({ date: businessExpenseData.expenseDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                            </Col>
                                        </Row>
                                    }

                                    {
                                        businessExpenseData.costCenterName &&
                                        <Row>
                                            <Col span={10} className="title text-right">
                                                <FormattedMessage id='costCenter.text' defaultMessage='' />
                                            </Col>
                                            <Col span={14} className="pl10">
                                                <abbr>{businessExpenseData.costCenterName}</abbr>
                                            </Col>
                                        </Row>
                                    }
                                    {
                                        businessExpenseData.refNumber &&
                                        <Row>
                                            <Col span={10} className="title text-right">
                                                <FormattedMessage id='supplier.businessExpense.listing.header.refNumber' defaultMessage='' />
                                            </Col>
                                            <Col span={14} className="pl10">
                                                <abbr>{businessExpenseData.refNumber}</abbr>
                                            </Col>
                                        </Row>
                                    }
                                    {
                                        businessExpenseData.projectNumber &&
                                        <Row>
                                            <Col span={10} className="title text-right">
                                                <FormattedMessage id='project.label' />
                                            </Col>
                                            <Col span={14} className="pl10">
                                                <abbr>{businessExpenseData.projectNumber}({businessExpenseData.projectName})</abbr>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <div className="modal-table-container mb15">
                            <table className="transaction-details-table">
                                <thead>
                                    <tr>
                                        <th className="cell-size-50">#</th>
                                        <th className="text-center"><FormattedMessage id='expenseType.text' defaultMessage='' /></th>
                                        <th className="text-center"><FormattedMessage id='modal.common.project.description' defaultMessage='' /></th>
                                        <th className="text-center"><FormattedMessage id='ledger.text' defaultMessage='' /></th>
                                        
                                        <th className="text-right"><FormattedMessage id='supplier.businessExpense.listing.header.expenseAmount' defaultMessage='' /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(relationshipExpenseDetails || []).map((item, i) => {
                                        const relationshipExp = find(item.relationshipCOATxExpense, { accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT })
                                        const credLedger = find((props.allLedgerAccounts || []), { chartOfAccountDetailsId: relationshipExp.ledgerAccountId }) || {};
                                        return (
                                            <tr key={i}>
                                                <td className="cell-size-50">{i + 1}</td>
                                                <td className="text-center">{item.expenseTypeName || '-'}</td>
                                                <td>
                                                    <div className="description-cell">
                                                        {ReactHtmlParser(item.description || '')}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {credLedger.ledgerAccountName || '-'}
                                                    </div>
                                                </td>
                                                <td className="text-right">{fixedDecimalNumber(item.expenseAmount || 0) || '-'}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <Row>
                            <Col span={9} className="read-only-content">

                                {businessExpenseData.remarksInternal &&
                                    <Row>
                                        <Col span={7} className="title">
                                            <FormattedMessage id='customer.salesQuote.listing.header.remarksCustomer' defaultMessage='' />
                                        </Col>
                                        <Col span={17}>
                                            {ReactHtmlParser(businessExpenseData.remarksInternal)}
                                        </Col>
                                    </Row>
                                }
                            </Col>

                            <Col offset={8} span={7}>
                                <div className="bill-summary mt5">
                                    <Row>
                                        <Col span={14}>
                                            <div className="right-txt">
                                                <FormattedMessage id='supplier.debitMemo.form.totalAmount.label' defaultMessage='' />
                                            </div>
                                        </Col>

                                        <Col span={10} className="text-right pr50">
                                            <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</span>
                                            {fixedDecimalNumber(businessExpenseData.subTotal || 0)}
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>

                    </div>
                    <div className=" button-group-action header-control">
                        <div className="small-btn-group">
                            <Button style={{height:'38px'}} title={props.intl.formatMessage({ id: 'modal.txDetails.common.edit', defaultMessage: '' })}
                                icon={<EditOutlined />}
                                onClick={() => {
                                    props.popModalFromStack();
                                    props.history.push({
                                        pathname: '/admin/business-expense-update',
                                        state: {
                                            expenseMasterId: businessExpenseData.expenseMasterId,
                                            relationshipId: businessExpenseData.relationshipId,
                                            update: true
                                        }
                                    })
                                }}
                            >
                            </Button>
                            <Button
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.clone', defaultMessage: '' })}
                                icon={<CopyOutlined />}
                                onClick={() => {
                                    props.popModalFromStack();
                                    props.history.push({
                                        pathname: '/admin/business-expense-create',
                                        state: {
                                            expenseMasterId: businessExpenseData.expenseMasterId,
                                            relationshipId: businessExpenseData.relationshipId,
                                            clone: true
                                        }
                                    })
                                }}
                            >
                            </Button>
                            <Button
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.delete', defaultMessage: '' })}
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    props.showModal({
                                        modalBody: "Are you sure you want to delete this transaction?",
                                        handleSubmit: () => {
                                            props.popModalFromStack();
                                            props.deleteRelationshipExpense({
                                                expenseMasterId: businessExpenseData.expenseMasterId,
                                                relationshipId: businessExpenseData.relationshipId,
                                            }, props)
                                        }
                                    })
                                }}
                            >
                            </Button>
                        </div>
                        <div className="small-btn-group">

                            <Button
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.email', defaultMessage: '' })}
                                icon={<MailOutlined />}
                                onClick={() => {
                                    openEmailDrawer(props);

                                }}
                            >
                            </Button>

                            <Button
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.downloadPDF', defaultMessage: '' })}
                                icon={<FilePdfOutlined />}
                                onClick={() => {
                                    generatePDF();
                                }}
                            >
                            </Button>
                            <Button
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.print', defaultMessage: '' })}
                                icon={<PrinterOutlined />}
                                onClick={() => {
                                    printPdf();
                                }}
                            >
                            </Button>
                        </div>
                        {(businessExpenseData.status !== "Paid" && businessExpenseData.status !== 'paid') ?
                            <Button
                                type="primary"
                                onClick={() => {
                                    const modalData = {
                                        title: <FormattedMessage id='expensePayment.text' defaultMessage='' />,
                                        modalBody: <PayExpense {...props} businessExpenseData={businessExpenseData} />,
                                        width: 600,
                                        hideFooter: true,
                                        wrapClassName: 'supplier-payment-modal'
                                    };
                                    props.showModal(modalData);
                                }}
                            > <FormattedMessage id='payExpense.label' defaultMessage='' />
                            </Button> : ''
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}

const getAddressInfoFragment = (obj, additionInfo) => {

    const fragmentObj = <Fragment>
        {
            obj.streetAddress1
                ? <div className='billing-address'>{obj.streetAddress1}
                </div>
                : ''
        }
        {
            obj.streetAddress2
                ? <div className='billing-address'>{obj.streetAddress2}
                </div>
                : ''
        }
        {
            obj.cityName
                ? <div className='billing-address'>
                    {
                        `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
                    }
                </div>
                : ''
        }
        {/* {
            additionInfo.firstName
                ? <div className='billing-address'>
                    {contactName}
                </div> :
                ''
        } */}
        {
            additionInfo.workPhone
                ? <div className='billing-address'>
                    <FormattedMessage id='phone' defaultMessage='' /> - {additionInfo.workPhone}
                </div> :
                ''
        }
        {
            additionInfo.email
                ? <div className='billing-address'>
                    <FormattedMessage id='email' defaultMessage='' /> - {additionInfo.email}
                </div> :
                ''
        }
        {/* {
            additionInfo.gstNumber
                ? <div className='billing-address'>
                     <FormattedMessage id='gstIn' defaultMessage='' /> - {additionInfo.gstNumber}
                </div> :
                ''
        }
        {
            additionInfo.panNumber
                ? <div className='billing-address'>
                     <FormattedMessage id='pan' defaultMessage='' /> - {additionInfo.panNumber}
                </div> :
                ''
        } */}

    </Fragment>

    return fragmentObj;
}


export default BusinessExpenseDetails;
