import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Tabs } from 'antd';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './index.scss';
import Tile from '../../../tiles/tiles';
import TotalStudents from '../../../../containers/modal/modalBody/customer/TotalStudents';
import TotalParents from '../../../../containers/modal/modalBody/customer/TotalParents';
import { STUDENT_REGISTRATION_LISTING_PAGE} from '../../../../static/constants';
import RegistrationListingComponent from './RegistrationListing';
import StudentListingComponent from './StudentListing';
import { fetchPaginationDataIfNeeded } from '../../../../utils';

const { TabPane } = Tabs;


const StudentRegistrationListingComp = (props) => {
    const { companyInfo } = props;
    function callback(key, props) {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: 25, formId: props.selectedFormId };
        if (key === '1') {
            props.updateState({ activeTab: 'Registrations', activeTabKey: "1", searchedText: '', pageSize: 25, pageNumber: 1});
        } else if (key === '2') {
            props.updateState({ activeTab: 'Students', activeTabKey: "2", searchedText: '', pageSize: 25, pageNumber: 1 });
            props.resetRegisterParentsData();
            fetchPaginationDataIfNeeded('getRegisterParentsData', 'registerParentsData', props, payload);
        }
    }
    // const breadCrumbList = [
    //     // {
    //     //     name: 'Dashboard',
    //     //     link: 'dashboard'
    //     // },
    //     // {
    //     //     name: 'Customer',
    //     //     link: 'customers'
    //     // },
    //     {
    //         name: 'Sales',
    //     },
    //     {
    //         name: 'Registration',
    //     }
    // ];
    const tilesData = [{
        footer: <FormattedMessage id='totalParents.text' />,
        count: props.totalParentsCount,
        type: STUDENT_REGISTRATION_LISTING_PAGE.TOTAL_PARENTS,
        icon: 'fa fa-user',
        color: 'darkGray-tile'
    },
    {
        footer: <FormattedMessage id='totalStudent.text'/>,
        count: props.totalStudentCount,
        icon: 'fa fa-user',
        type: STUDENT_REGISTRATION_LISTING_PAGE.TOTAL_STUDENT,
        color: 'green-tile'
    },
    {
        footer: <FormattedMessage id='feesDue.text' />,
        count: 0,
        icon: companyInfo.currencyIcon,
        type: STUDENT_REGISTRATION_LISTING_PAGE.FEES_DUE,
        color: 'blue-tile'
    },
    {
        footer: <FormattedMessage id='feesPaid.text' />,
        count: 0,
        icon: companyInfo.currencyIcon,
        type: STUDENT_REGISTRATION_LISTING_PAGE.FEES_PAID,
        color: 'magenta-tile'
    }];

    const openModalOfSelectedTile = (data) => {
        switch (data.type) {
            case STUDENT_REGISTRATION_LISTING_PAGE.TOTAL_PARENTS:
                if (props.selectedFormId) {
                    props.showModal(
                        {
                            title: <Fragment>
                                <div className='ant-modal-title'><FormattedMessage id='totalParents.text' /></div>
                            </Fragment>,
                            modalBody: <TotalParents {...props} registrationFormSettingId={props.selectedFormId} />,
                            width: 1100,
                            hideFooter: true,
                            wrapClassName: 'tiles-modal'
                        }
                    )
                }
                break;
            case STUDENT_REGISTRATION_LISTING_PAGE.TOTAL_STUDENT:
                if (props.selectedFormId) {
                    props.showModal(
                        {
                            title: <Fragment>
                                <div className='ant-modal-title'><FormattedMessage id='totalStudent.text' /></div>
                            </Fragment>,
                            modalBody: <TotalStudents {...props} registrationFormSettingId={props.selectedFormId} />,
                            width: 600,
                            hideFooter: true,
                            wrapClassName: 'tiles-modal'
                        }
                    )
                }
                break;
            case STUDENT_REGISTRATION_LISTING_PAGE.FEES_DUE:
               
                break;
            case STUDENT_REGISTRATION_LISTING_PAGE.FEES_PAID:
              
                break;
            default:
                break;
        }
    }

    return (
        <div className="customer-details-container">
            {/* <div className="invoice-header">
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div> */}
            <Tile tilesData={tilesData} {...props} getSelectedTile={(data) => {
                openModalOfSelectedTile(data);
            }} />
             <div className="customer-details">
                    <Tabs activeKey={props.activeTabKey || "1"} onChange={(key) => { callback(key, props); }} type="card">
                        <TabPane tab="Registrations" key="1">
                            <RegistrationListingComponent {...props} />
                        </TabPane>
                        <TabPane tab="Students" key="2">
                            <StudentListingComponent {...props} />
                        </TabPane>
                    </Tabs>
                </div>
           
        </div>
    );
};

export default injectIntl(StudentRegistrationListingComp);
