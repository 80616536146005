import { PROJECT_ACTION_LIST } from '../static/constants';

const initialState = {
  projects: {
    1: []
  },
  projectsCount: 0,
}

const ProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_ACTION_LIST.PROJECT_LIST:
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case PROJECT_ACTION_LIST.PROJECT_COUNT:
      return { ...state, projectsCount: action.data };
    case PROJECT_ACTION_LIST.RESET_PROJECT_LIST:
      return { ...state, projects: initialState.projects };
    default:
      return state;
  }
};

export default ProjectReducer;