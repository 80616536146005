import React, { Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
// import { AgGridReact } from "ag-grid-react";
import { Pagination, Skeleton } from "antd";
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly, sortTable } from "../../../utils";
import PageBreadcrumb from "../../PageBreadcrumb";
// import adminAccess from '../../../assets/images/school/admin-access.png';
import { CONSTANTS } from '../../../static/constants';
import { SortAscendingOutlined, SortDescendingOutlined} from '@ant-design/icons';
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const RecurringPayment = (props) => {
  const { subuscriptionList, updateState, companyInfo, subuscriptionCount, pageNumber, pageSize, dir=0 } =
    props;

  // const agGridStyle = {
  //   height: "100%",
  //   width: "100%",
  // };

  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItem.schoolManagement' defaultMessage='' />
    },
    {
      name: <FormattedMessage id='recurringPayment.text' defaultMessage='' />,
    }
  ];
  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded("getSubscriptionPayments", "subuscriptionList", props, payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.getSubscriptionPayments(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  // const accessPortal = (e) => {
  //   props.getAccessToken({
  //     relationshipId: companyInfo.relationshipId,
  //     relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
  //     clientOnlineUserAccountId: e.clientOnlineUserAccountId
  //   }, props);
  // };

  const sortColumn = (e) => {
    sortTable(document.getElementById('recurring-payment-table'), e.target.cellIndex, 1 - dir);
    updateState({ dir: 1 - dir })
  }

  return (
    <Fragment>
      {/* <div className="page-heading">
          <FormattedMessage id='heading.schoolManagement.fees.recurringPayments' defaultMessage='' />
      </div>
      <div className="pb10">
        <PageBreadcrumb breadCrumbList={breadCrumbList} />
      </div> */}
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id='heading.schoolManagement.fees.recurringPayments' defaultMessage='' />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
        </div>
      </div>
      <div className="view-container">
        <Skeleton loading={props.isLoading}>
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage id='schoolManagement.recurringPayment.table.heading' defaultMessage='' />
              </div>
              {
                pageSize ? <>
                  <div className="vertical-sep" />
                  <div>{pageSize}</div>
                </>:''
              }
            </div>
            <div className="right-actions">
            </div>
          </div>
          <div className="table-container">
            <table id='recurring-payment-table'>
              <thead>
                <tr>
                  <th width='10%' onClick={sortColumn}># {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th width='20%' onClick={sortColumn}>Subscription Name {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th width='20%' onClick={sortColumn}>Name {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th width='10%' onClick={sortColumn}>Status {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th width='10%' onClick={sortColumn}>Frequency {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th width='10%' onClick={sortColumn}>Start Date {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th width='10%' onClick={sortColumn}>End Date {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th width='10%' onClick={sortColumn}><FormattedMessage id="common.dateCreated"/> {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  {/* <th><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th> */}
                </tr>
              </thead>
              <tbody>
                {
                  (subuscriptionList[pageNumber] || []).map((e, i) => {
                    return <tr key={'usr-' + i}>
                      <td>{i + 1}</td>
                      {/* <td><img src={adminAccess} style={{ 'height': '25px', 'width': '25px' }} className="cursor-pointer" alt="Access Account" onClick={() => { accessPortal(e); }} /></td> */}
                      <td> <div>{`${e.subscriptionName || ''}`}</div></td>
                      <td> <div>{e.firstName + ` ` + e.lastName}</div></td>
                      <td> <div>{e.status}</div></td>
                      <td><div>{e.period}</div></td>
                      <td>{e.startDate ? getMomentDateForUIReadOnly({ date: new Date(e.startDate), format }) : ''}</td>
                      <td>{e.endDate ? getMomentDateForUIReadOnly({ date: new Date(e.endDate), format }) : ''}</td>
                      <td>{e.dateCreated ? getMomentDateForUIReadOnly({ date: new Date(e.dateCreated), format }) : ''}</td>
                      {/* <td>
                        <span className="cursor-pointer" style={{ marginLeft: '10px' }} onClick={() => { props.sendResetMail(e.email, props.history); }}>Resend Password</span>
                      </td> */}
                    </tr>
                  })
                }
              </tbody>
            </table>
          </div>
        </Skeleton>
        <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={subuscriptionCount}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
        </div>
        {/* <div className='team-and-free'>
          <div className="right-align pb10">
            <div className="action-dropdown-btn">
              <button onClick={() => {
                newCourse();
              }}>
                <FormattedMessage id="button.create.label" defaultMessage="" />
              </button>
            </div>
          </div>
          <div className="agGridWrapper">
            <div className='new-table-camp'>
            </div>
          </div>
        </div> */}
      </div>
    </Fragment>
  );
};

export default injectIntl(RecurringPayment);
