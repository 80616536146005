import React, { useState } from "react";
import './index.scss';
import { Button, Col, Divider, Result, Row, Skeleton, Tabs } from "antd";
import FeesIcon from "../../../assets/images/fees.png";
import RazorpayPaymentsComp from "../PaymentGateways/razorpay";
import StripePaymentsComp from "../PaymentGateways/Stripe";
import CardPointe from "../PaymentGateways/cardPointe";
import { getCurrencySymbol } from "../../../utils";

const {TabPane} = Tabs;


const ProformaPayment = (props) =>{
    const {proformaDetails, customerDetail, paymentGateways=[]} = props;

    // const [iFrameLoading, setIframeLoader] = useState(true);
    // const student = studentList.length ? studentList[0] : {};
    // const feeStructList = (classFees.find(obj=>obj.classId = student.classId || 26) || {}).feeStructList || [];
    
    if (!props.isListenerAdded) {
        window.addEventListener('message', function (event) {
            if (!event.data) return '';
            if (event.data && typeof event.data === 'string') {
                var token = JSON.parse(event.data);
                var mytoken = document.getElementById('mytoken') || {};
                mytoken.value = token?.message;
                props.updateState({
                    account: token.message,
                    expiry: token.expiry,
                    validationError: token.validationError,
                })
            }
        }, false);
        props.updateState({isListenerAdded: true});
    }


    // const submitPay = () =>{
    //     let arr = (props.primaryContactName || '').split(" ");
    //     const nameObj = {
    //       firstName: arr[0],
    //       lastName: (arr || []).length > 0 ? arr[1] : ''
    //     }
        
    //     let payload = {
    //         relationshipId: props.relationshipId,
    //         customerId: props.customerId,
    //         amount: student.amount || (feeStructList || []).reduce((amt, feeObj) => amt + feeObj.amount, 0),
    //         customerPreInvoiceMasterId: responseData.preInvoiceMasterId,
    //         preInvoiceId: responseData.preInvoiceMasterId,
    //         invoiceId: {}.invoiceMasterId,
    //         invoiceMasterId: {}.invoiceMasterId,
    //         formId: props.registrationFormSettingId,
    //         account: props.account,
    //         expiry: props.expiry,
    //         paymentSource: 'Online',
    //         alpidePaymentGatewayId: props.formSetting?.paymentGatewayId,
    //         firstName: nameObj.firstName, 
    //         lastName: nameObj.lastName, 
    //         email: props.primaryEmail,
    //     }

    //     props.updateState({ paymentBtnClicked: true });
    //     props.processCCPayment(props, payload);
    // }

    const handleTabChange = (activeTab) =>{
        const payload = {
          relationshipId: props.relationshipId,
          pageNumber: 1,
          pageSize: 25,
        }
        props.updateState({
            activeTab: activeTab
        })
        switch(activeTab){
            case 'RazorPay':
                console.log("razorpayTab action")
                break;
            case 'Stripe':
                console.log("stripeTab action")
                break;
            case 'CardPointe':
                console.log("card_pointeTab action")
                break;
            case 'AuthorizeNet':
                console.log("authorize_netTab action")
                break;
            case 'Cash':
                console.log("cashTab action")
                break;
            default:
                console.log("no tab action");
        }
    }

    const postPaymentProcess = (resObj) =>{
        const paymentResponse = {
            razorpayPaymentId: resObj.razorpay_payment_id,
            razorpayOrderId: resObj.razorpay_order_id,
            razorpaySignature: resObj.razorpay_signature
        }

        // const payload = {
        //     paymentResponse,
        //     relationshipId: props.relationshipId,
        //     proformaId: props.proformaDetails?.proformaId || 0,
        // }
        props.processRazorpayPayment(paymentResponse, props);

    }

    return <>
        {
            props.saveLoading ? <div className='open_loader'>
                <div className="payment-loader">
                    <div className="pad">
                    <div className="chip"></div>
                    <div className="line line1 mb10"></div>
                    <div className="line line2"></div>
                </div>
                <div className="loader-text">
                    Please wait while we processing your request securely...
                </div>
                </div>

                {/* <Spin size="large" spinning={props.saveLoading || true} tip={'Please Wait...'} /> */}
            </div>
            :
            ''
        }
        <div className="pay_container"> 
            <>
                <div className="pay_head">
                    {/* <i className={ICONS.LEFT_ARROW_P} onClick={()=>{
                        props.showModal({
                            modalBody: "Are you sure you want to cancel transaction?",
                            handleSubmit: () => {
                                // props.updateState({startPayment: false});
                                // window.location.reload();
                            }})
                    }} />  */}
                    Review and Pay
                </div>
                <div className="pay_details_m">
                    {proformaDetails?.isInvoiceConverted ?  
                        <Result
                            status="success"
                            title="Payment has already done for this invoice"
                        />
                        :
                        <div className="pay_details_l">
                            <Skeleton loading={props.listLoading} paragraph={{ rows: 10}} active>
                                <div className="pay_info">
                                    <div className="pay_info_head">
                                        <div className="b-text">Payment Gateways</div>
                                        <div className="light-txt">Choose how you'd like to pay</div>
                                    </div>

                                    {/* <Divider className="mt10 mb0" /> */}

                                    <div className="pay-tabs">
                                        {paymentGateways?.length > 1 ? 
                                            <Tabs
                                                animated={{ inkBar: true, tabPane: false }}
                                                tabBarGutter={16}
                                                onChange={handleTabChange}
                                                activeKey={props.activeTab}
                                                // tabPosition="left"
                                            >
                                                {paymentGateways.includes("Razorpay") ? <TabPane tab="Razorpay" key="razorpay">
                                                    <RazorpayPaymentsComp {...props} postPaymentProcess={postPaymentProcess} />
                                                </TabPane> 
                                                : ''}

                                                {paymentGateways.includes("Stripe") ? <TabPane tab="Stripe" key="stripe">
                                                    <StripePaymentsComp {...props} />
                                                </TabPane>
                                                :''}

                                                {paymentGateways.includes("CardPointe") ? <TabPane tab={"Card Pointe"} key='card_pointe'>
                                                    {/* {iFrameLoading ? <div className="loader"><Spin size="large" spinning={iFrameLoading || true} tip={'Please Wait...'} /></div> : ''}
                                                    <form name="tokenform" id="tokenform" method="POST" target="tokenframe">
                                                        <iframe 
                                                            title=" " 
                                                            onLoad={()=>setIframeLoader(false)}
                                                            id="tokenframe" 
                                                            name="tokenframe" 
                                                            src={`${config.CC_TOKENIZER_URL}?useexpiry=true&usecvv=true&cardlabel=Card Number*&expirylabel=Expiration Date*&cvvlabel=CVV*
                                                                &placeholder=${encodeURIComponent("16 Digit Card Number")}&placeholdermonth=MM&placeholderyear=YYYY&placeholdercvv=${encodeURIComponent("3 Digit CVV Code")}
                                                                &cardnumbernumericonly=true&cardinputmaxlength=16&cvvnumericonly=true&cvvnumbermaxlength=3&fullmobilekeyboard=true
                                                                &usemonthnames=true
                                                                &invalidcreditcardevent=true&invalidcvvevent=true&tokenizewheninactive=true
                                                                &formatinput=true
                                                                &autofocus=true
                                                                &css=${encodeURIComponent(`.error{color:%20red;}label{font-family:SegoeUI,DroidSans,Tahoma,Arial,sans-serif;font-size:12px;float:left;display:flex;text-align:left;margin-top:5px;}input{border:1pxsolid#e7e7e7;height:38px;display:inline-block;color:rgba(0,0,0,0.65);}input:focus,input:active{border:1pxsolid#0075FF;border-color:#0075FF;}select{height:38px;margin:5px0px5px;border:solid1px#e7e7e7;border-radius:2px;background-color:transparent;color:rgba(0,0,0,0.65);}select:focus,select:active{border:1pxsolid#0075FF;}#cccardlabel,#ccexpirylabel,#cccvvlabel{color:rgba(0,0,0,0.65);}#ccnumfield{width:100%;padding:8px;margin:5px05px0;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}#ccexpirymonth{width:30%;}#ccexpiryyear{width:30%;}#cccvvfield{width:120px;padding:8px;margin:5px05px0;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}#tokenformbr{display:none;}`)}`}
                                                            scrolling="no"
                                                            width="100%" 
                                                            height="260px" 
                                                            frameBorder="0"
                                                        />
                                                        <input type="hidden" name="mytoken" id="mytoken" />
                                                    </form> */}
                                                    <CardPointe {...props} />
                                                </TabPane>
                                                :''
                                                }

                                                {paymentGateways.includes("AuthorizeNet") ? <TabPane tab="Authorize.Net" key="authorize_net" disabled>
                                                    Authorise.Net Payments
                                                </TabPane>
                                                :''
                                                }

                                                {paymentGateways.includes("Cash") ? 
                                                    <TabPane tab="Cash" key="cash" disabled>
                                                        By Cash
                                                    </TabPane>
                                                    :
                                                    ''
                                                }
                                            </Tabs>
                                            :
                                            <>
                                                {paymentGateways.includes("Razorpay") ? 
                                                    <RazorpayPaymentsComp {...props} postPaymentProcess={postPaymentProcess} />
                                                : ''}

                                                {paymentGateways.includes("Stripe") ?
                                                    <StripePaymentsComp {...props} />
                                                :''}

                                                {paymentGateways.includes("CardPointe") ?
                                                    <CardPointe {...props} />
                                                :''
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </Skeleton>
                        </div>
                    }
                    <div className="pay_details_r">
                        <div className="pay_details_inner">
                            <div className="inner_head">
                                <img src={FeesIcon} alt="fees" height="50px" width="50px" /> <span className="b-text" style={{fontSize: '16px'}}>Payment Details</span>
                            </div>
                            <div className="inner_details">
                                <Skeleton loading={props.listLoading} paragraph={{ rows: 8}} active>
                                    <Row gutter={[8, 8]}>
                                        <Col span={12}>
                                            <div className="b-text">Customer Name</div>
                                            <div className="light-txt">{props.customerDetail.customerName || ''}</div>
                                        </Col>

                                        <Col span={12}>
                                            <div className="b-text">Contact Number</div>
                                            <div className="light-txt">{props.customerDetail.cellPhone || '-'}</div>
                                        </Col>
                                    </Row>
                                    
                                    <Divider />

                                    <Row gutter={[8, 8]}>
                                        <Col span={19}>
                                            Total Amount
                                        </Col>
                                        <Col span={5}>
                                            {proformaDetails?.invoiceData?.invoiceTotalAmount ? getCurrencySymbol(customerDetail.currencyCode) + proformaDetails?.invoiceData?.invoiceTotalAmount?.toFixed(2) : ''}
                                        </Col>
                                    </Row>
                                </Skeleton>
                            </div>
                        </div>
                    </div>
                </div>
            </> 
        </div>
    </>
}

export default ProformaPayment;