import React, { Fragment } from "react";
import { Row, Col, Button, Checkbox, Input, Tooltip, Select,Divider } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import ReactHtmlParser from 'react-html-parser';
import { MultipleSelectDropdown } from "../../../../general/MultipleSelectDropdown";
import { Dropdown } from "../../../../general/Dropdown";
import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import { AG_GRID_CONSTANTS, GRID_PREFERENCE_CONSTANTS } from '../../../../../static/agGridConstants';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../../static/cssClassConstants';
import { showToasterMessage, fixedDecimalAndFormateNumber } from '../../../../../utils';
import {
    MODAL_TYPE
} from '../../../../../static/constants'
import { getLabelName } from '../../../../modal/modalBody/customerInfo';
import { CustomTableUpload } from "../../../../general/UploadTable";
import CKEditor from "../../../../general/CustomCKEditor/ckeditor";
import { CUSTOM_CONFIGURATION } from "../../../../../static/ckEditorConfigConstants";
import cardlogo from '../../../../../assets/images/card-logo.jpg';
const { Option } = Select;
class CustomerProductDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sectionVisible: {
                discount: false,
                tax: false,
                expense: false
            },
            total: 0,
            subtotal: 0,
            subtotalWithoutDiscount: 0,
            totalDiscount: 0,
            totalTax: 0,
            itemLevelTaxes: [],
            rootDiscountPercent: 0,
            rootDiscountAmount: 0,
        }
    }

    getGrandTotal = () => {
        let grandTotal = 0;
        if (this.props.selectedSalesOrder.length) {
            this.props.selectedSalesOrder.forEach((soData) => {
                grandTotal = grandTotal + Number(this.state["total" + soData.salesOrderMasterId] || 0)
            });
            return Number(grandTotal || 0).toFixed(2)
        } else return 0.00;
    }

    getTotalDiscount = () => {
        let totalDiscount = 0;
        if (this.props.selectedSalesOrder.length) {
            this.props.selectedSalesOrder.forEach((soData) => {
                totalDiscount = totalDiscount + Number(this.state["totalDiscount" + soData.salesOrderMasterId] || 0)
            });
            return Number(totalDiscount || 0).toFixed(2)
        } else return 0.00;
    }

    getGrandSubTotal = () => {
        let grandSubTotal = 0;
        if (this.props.selectedSalesOrder.length) {
            this.props.selectedSalesOrder.forEach((soData) => {
                grandSubTotal = grandSubTotal + Number(this.state["subtotalWithoutDiscount" + soData.salesOrderMasterId] || 0)
            });
            return Number(grandSubTotal || 0).toFixed(2)
        } else return 0.00;
    }

    getItemLevelTaxes = () => {
        let itemLevelTaxes = [];
        this.props.selectedSalesOrder.forEach((soData) => {
            if (this.state["itemLevelTaxes" + soData.salesOrderMasterId]) {
                itemLevelTaxes = itemLevelTaxes.concat(this.state["itemLevelTaxes" + soData.salesOrderMasterId]);
            }
        });
        return itemLevelTaxes;
    }

    calculateSubtotal = () => {
        let total = 0;
        let subtotal = 0;
        let totalDiscount = 0;
        let totalTax = 0;
        let itemLevelTaxes = [];
        let subtotalWithoutDiscount = 0;
        let rootDiscountAmount = 0;
        let self = this;

        if (!this.state.rootDiscountPercent && this.props.rootDiscountPercent) {
            this.setState({
                rootDiscountPercent: this.props.rootDiscountPercent,
                rootDiscountAmount: this.props.rootDiscountAmount,
                expenseAmount: this.props.expenseAmount
            });
        }

        this.gridApi && this.gridApi.forEachNode(function (rowNode, index) {
            let stringifiedRowData = JSON.stringify(rowNode.data);
            let totalAmountOfColumn = 0;
            const rowdata = JSON.parse(stringifiedRowData);
            if ( rowdata.rate) {
                let taxObj = {
                    subtotal: 0,
                    taxPercent: 0,
                    taxAmount: 0
                }
                totalAmountOfColumn = 1 * Number(rowdata.rate);
                subtotalWithoutDiscount = Number(totalAmountOfColumn) + Number(subtotalWithoutDiscount);
                if (rowdata.discount) {
                    let discountAmount = ((Number(rowdata.discount || 0) * 0.01) * totalAmountOfColumn);
                    totalAmountOfColumn = totalAmountOfColumn - discountAmount;
                    totalDiscount = totalDiscount + discountAmount;
                }


                if (self.state.rootDiscountPercent || self.props.rootDiscountPercent) {
                    const rootDiscountPercent = self.state.rootDiscountPercent || self.props.rootDiscountPercent;
                    let itemLevelDiscountAmount = (Number(rootDiscountPercent) * 0.01) * totalAmountOfColumn;
                    totalAmountOfColumn = totalAmountOfColumn - itemLevelDiscountAmount;
                    totalDiscount = totalDiscount + itemLevelDiscountAmount
                    rootDiscountAmount = rootDiscountAmount + itemLevelDiscountAmount;
                }

                if (rowdata.tax) {
                    taxObj.taxPercent = Number(rowdata.tax || 0).toFixed(2);
                    taxObj.taxName = rowdata.taxName;
                    let taxAmount = ((Number(rowdata.tax || 0) * 0.01) * totalAmountOfColumn);
                    totalTax = totalTax + taxAmount;
                }
                if (taxObj.taxPercent) {
                    taxObj.subtotal = Number(totalAmountOfColumn || 0).toFixed(2);
                    const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
                    if (availableTaxObj) {
                        itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                            if (ele.taxPercent === taxObj.taxPercent) {
                                ele.subtotal = (Number(ele.subtotal || 0) + Number(taxObj.subtotal || 0)).toFixed(2);
                                ele.taxAmount = (Number(ele.taxPercent || 0) * 0.01) * Number(ele.subtotal || 0);
                            }
                            return true;
                        });
                    } else {
                        taxObj.taxAmount = ((Number(taxObj.taxPercent || 0) * 0.01) * Number(taxObj.subtotal || 0)).toFixed(2);
                        itemLevelTaxes.push(taxObj);
                    }
                }
                subtotal = Number(subtotal) + totalAmountOfColumn;
            }
        });


        total = Number(subtotal || 0) + totalTax + Number(this.state.expenseAmount || this.props.expenseAmount || 0);

        this.setState({
            total: Number(total || 0).toFixed(2),
            subtotal: Number(subtotal || 0).toFixed(2),
            totalDiscount: Number(totalDiscount || 0).toFixed(2),
            totalTax: Number(totalTax || 0).toFixed(2),
            subtotalWithoutDiscount: Number(subtotalWithoutDiscount || 0).toFixed(2),
            itemLevelTaxes,
            rootDiscountAmount: Number(rootDiscountAmount || 0).toFixed(2)
        });

        this.props.updateState({
            rootDiscountPercent: 0,
            rootDiscountAmount: 0,
            expenseAmount: 0
        })

        // else {
        //     this.props.selectedSalesOrder.forEach((soData) => {
        //         let total = 0;
        //         let subtotal = 0;
        //         let totalDiscount = 0;
        //         let totalTax = 0;
        //         let itemLevelTaxes = [];
        //         let subtotalWithoutDiscount = 0;
        //         let rootDiscountAmount = 0;
        //         let self = this;

        //         if (!this.state["rootDiscountPercent" + soData.salesOrderMasterId] && this.props["rootDiscountPercent" + soData.salesOrderMasterId]) {
        //             const payload = {};
        //             payload["rootDiscountPercent" + soData.salesOrderMasterId] = this.props["rootDiscountPercent" + soData.salesOrderMasterId];
        //             payload["rootDiscountAmount" + soData.salesOrderMasterId] = this.props["rootDiscountAmount" + soData.salesOrderMasterId];
        //             this.setState(payload);
        //         }

        //         if (!this.state["expenseAmount" + soData.salesOrderMasterId] && this.props["expenseAmount" + soData.salesOrderMasterId]) {
        //             const payload = {};
        //             payload["expenseAmount" + soData.salesOrderMasterId] = this.props["expenseAmount" + soData.salesOrderMasterId];
        //             this.setState(payload);
        //         }

        //         this["gridApi-" + soData.salesOrderMasterId] && this["gridApi-" + soData.salesOrderMasterId].forEachNode(function (rowNode, index) {
        //             let stringifiedRowData = JSON.stringify(rowNode.data);
        //             let totalAmountOfColumn = 0;
        //             const rowdata = JSON.parse(stringifiedRowData);
        //             if (rowdata.qty && rowdata.rate) {
        //                 let taxObj = {
        //                     subtotal: 0,
        //                     taxPercent: 0,
        //                     taxAmount: 0
        //                 }
        //                 totalAmountOfColumn = Number(rowdata.qty) * Number(rowdata.rate);
        //                 subtotalWithoutDiscount = Number(totalAmountOfColumn) + Number(subtotalWithoutDiscount);
        //                 if (rowdata.discount) {
        //                     let discountAmount = ((Number(rowdata.discount || 0) * 0.01) * totalAmountOfColumn);
        //                     totalAmountOfColumn = totalAmountOfColumn - discountAmount;
        //                     totalDiscount = totalDiscount + discountAmount;
        //                 }


        //                 if (self.state["rootDiscountPercent" + soData.salesOrderMasterId] || self.props["rootDiscountPercent" + soData.salesOrderMasterId]) {
        //                     const rootDiscountPercent = self.state["rootDiscountPercent" + soData.salesOrderMasterId] || self.props["rootDiscountPercent" + soData.salesOrderMasterId];
        //                     let itemLevelDiscountAmount = (Number(rootDiscountPercent) * 0.01) * totalAmountOfColumn;
        //                     totalAmountOfColumn = totalAmountOfColumn - itemLevelDiscountAmount;
        //                     totalDiscount = totalDiscount + itemLevelDiscountAmount
        //                     rootDiscountAmount = rootDiscountAmount + itemLevelDiscountAmount;
        //                 }

        //                 if (rowdata.tax) {
        //                     taxObj.taxPercent = Number(rowdata.tax || 0).toFixed(2);
        //                     taxObj.taxName = rowdata.taxName;
        //                     let taxAmount = ((Number(rowdata.tax || 0) * 0.01) * totalAmountOfColumn);
        //                     totalTax = totalTax + taxAmount;
        //                 }
        //                 if (taxObj.taxPercent) {
        //                     taxObj.subtotal = Number(totalAmountOfColumn || 0).toFixed(2);
        //                     const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
        //                     if (availableTaxObj) {
        //                         itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
        //                             if (ele.taxPercent === taxObj.taxPercent) {
        //                                 ele.subtotal = (Number(ele.subtotal || 0) + Number(taxObj.subtotal || 0)).toFixed(2);
        //                                 ele.taxAmount = (Number(ele.taxPercent || 0) * 0.01) * Number(ele.subtotal || 0);
        //                             }
        //                             return true;
        //                         });
        //                     } else {
        //                         taxObj.taxAmount = ((Number(taxObj.taxPercent || 0) * 0.01) * Number(taxObj.subtotal || 0)).toFixed(2);
        //                         itemLevelTaxes.push(taxObj);
        //                     }
        //                 }
        //                 subtotal = Number(subtotal) + totalAmountOfColumn;
        //             }
        //         });


        //         total = Number(subtotal || 0) + totalTax + Number(this.state["expenseAmount" + soData.salesOrderMasterId] || this.props["expenseAmount" + soData.salesOrderMasterId] || 0);

        //         const stateToUpdate = {};
        //         stateToUpdate["total" + soData.salesOrderMasterId] = Number(total || 0).toFixed(2);
        //         stateToUpdate["subtotal" + soData.salesOrderMasterId] = Number(subtotal || 0).toFixed(2)
        //         stateToUpdate["totalDiscount" + soData.salesOrderMasterId] = Number(totalDiscount || 0).toFixed(2);
        //         stateToUpdate["subtotalWithoutDiscount" + soData.salesOrderMasterId] = Number(subtotalWithoutDiscount || 0).toFixed(2);
        //         stateToUpdate["itemLevelTaxes" + soData.salesOrderMasterId] = itemLevelTaxes;
        //         stateToUpdate["totalTax" + soData.salesOrderMasterId] = Number(totalTax || 0).toFixed(2);
        //         stateToUpdate["rootDiscountAmount" + soData.salesOrderMasterId] = Number(rootDiscountAmount || 0).toFixed(2);

        //         this.setState(stateToUpdate);

        //         const payloadReset = {};
        //         payloadReset["rootDiscountPercent" + soData.salesOrderMasterId] = 0;
        //         payloadReset["rootDiscountAmount" + soData.salesOrderMasterId] = 0;
        //         payloadReset["expenseAmount" + soData.salesOrderMasterId] = 0;
        //         this.props.updateState(payloadReset);
        //     })
        // }
    }

    handleNewTaxAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.newTax' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.NEW_TAX,
                data: payload,

            },
            wrapClassName: 'added-item-dropdown-modal',

            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                showModal(data);
            }
        };
        showModal(data);
    }

    addItemConfirmationModal = (props, text, modalType) => {
        const { showModal, companyInfo } = props;
        const labelName = getLabelName(modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage=''
            />
            {labelName} ?
        </span>;

        let addFunc;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                addFunc = props.addUOM;
                break;
            }
            case MODAL_TYPE.EXPENSE_TYPE: {
                addFunc = props.addExpenseTypes;
                break;
            }
            default: {
                addFunc = () => {
                }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                addFunc({ text: text, relationshipId: companyInfo.relationshipId });
                this.gridApi && this.gridApi.stopEditing();
                props.hideModal();
            },
        };
        showModal(modalData);
    }

    getModalTitle = (newTextObj, modalType) => {
        let title;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                title = <FormattedMessage id='addItem.text.uom' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.EXPENSE_TYPE: {
                title = <FormattedMessage id='addItem.text.expenseType' defaultMessage='' />;
                break;
            }
            default:
                title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
        }
        return title;
    }

    handleNewItemAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
            const data = {
                title: this.getModalTitle(payload, modalType),
                formData,
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
                    } else {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                }
            };
            showModal(data);
        }
    }

    deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage=''
                values={{ value: payload.text }}
            />;

        let deleteFunc;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                deleteFunc = props.deleteUOM;
                break;
            }
            case MODAL_TYPE.NEW_TAX: {
                deleteFunc = props.deleteTax;
                break;
            }
            case MODAL_TYPE.EXPENSE_TYPE: {
                deleteFunc = props.deleteExpenseTypes;
                break;
            }
            default: {
                deleteFunc = () => {
                }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId })
            },
        };
        showModal(modalData);
    }

    render() {
        // const { txColumnSetting } = this.props;
      // const self = this;
        if (this.gridApi && this.props.priceTypeToApply && this.props.isToPriceTypeToApply) {
            let self = this;
            this.gridApi.forEachNode(function (rowNode, index) {
                if (rowNode.data.product) {
                    let rate = self.props.priceTypeToApply === AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0] ? Number(rowNode.data.variant.retailPrice || 0).toFixed(2) : Number(rowNode.data.variant.wholesalePrice || 0).toFixed(2);
                    rowNode.setData({ ...rowNode.data, rate, baseRate: rate });
                }
            });
            this.props.updateState({
                isToPriceTypeToApply: false
            })
        }

        const { sectionVisible, itemLevelTaxes } = this.state;
        const isPlaceOfSupplySameAsRelationship = (this.props.relationshipBillingAddress || {}).stateName === this.props.placeOfSupply;
        const onGridReady = (params) => {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            params.api.sizeColumnsToFit();
            // let txColumnSetting = this.props.txColumnSetting && this.props.txColumnSetting.length ? this.props.txColumnSetting : params.columnApi.getColumnState();
            // let actionIndex = 0;
            // let amountIndex = 0;
            // if (Object.keys(txColumnSetting).length) {
            //     const columnToBeSet = [];
            //     const indexObjArr = TRANSACTION_COLUMN_INDEX.SALES_INVOICE_FORM

            //     indexObjArr.forEach((e) => {
            //         let colObj = find(txColumnSetting, { colId: e + '_1' });
            //         if (colObj) {
            //             columnToBeSet.push(colObj);
            //         }
            //     });

            //     txColumnSetting = txColumnSetting.filter((ele) => {
            //         let columnName = ele.colId.split("_")[0];
            //         return indexObjArr.indexOf(columnName) === -1;
            //     })
            //     txColumnSetting = columnToBeSet.concat(txColumnSetting);
            //     txColumnSetting.forEach((e, index) => {
            //         let columnName = e.colId.split("_")[0];
            //         if (columnName !== 'action' && columnName !== 'amount') {
            //             if (columnName === 'isChecked' || columnName === 'sno') {
            //                 e.pinned = 'left';
            //                 e.hide = false;
            //             } else {
            //                 e.hide = _isColumnToHide(columnName);
            //             }
            //         }
            //         // if (columnName === 'salesOrderNumber') {
            //         //     e.hide = (!this.props.selectedSalesOrder.length || _isColumnToHide('salesOrderNumber')) || this.props.isShipment;
            //         // }

            //         // if (columnName === 'shipmentNumber') {
            //         //     e.hide = !this.props.selectedSalesOrder.length || _isColumnToHide('shipmentNumber') || !this.props.isShipment;
            //         // }

            //         if (columnName === 'action') {
            //             e.width = 52;
            //             actionIndex = index;
            //             e.hide = false;
            //             e.pinned = 'right';
            //         }
            //         if (columnName === 'amount') {
            //             e.hide = false;
            //             e.lockPosition = true;
            //             amountIndex = index;
            //             e.pinned = 'right';
            //         }

            //     });
            //     const actionObj = txColumnSetting[actionIndex];
            //     const amountObj = txColumnSetting[amountIndex];
            //     txColumnSetting = txColumnSetting.filter((e) => {
            //         let columnName = e.colId.split("_")[0];
            //         return columnName !== 'action' && columnName !== 'amount';
            //     });
            //     amountObj.lockPosition = true;
            //     actionObj.lockPosition = true;
            //     txColumnSetting.push(amountObj);
            //     txColumnSetting.push(actionObj);
            //     this.gridColumnApi.setColumnState(txColumnSetting);
            //     setTimeout(() => {
            //         if (this.gridColumnApi && this.gridColumnApi.columnController.bodyWidth < this.gridColumnApi.columnController.scrollWidth) {
            //             params.api.sizeColumnsToFit();
            //         }
            //     }, 500);
            // }
        }

        if (this.gridApi && this.props.isColumnFitTable) {
            this.gridApi.sizeColumnsToFit();
        }

        const onModelUpdated = (params) => {
            if (this.props.tableDataReRendered) {
                setTimeout(() => {
                    this.calculateSubtotal();
                }, 1500)
                //params.api.resetRowHeights();
                this.props.updateState({
                    tableDataReRendered: false
                })
                // let actionIndex = 0;
                // let amountIndex = 0;
                // let txColumnSetting = this.props.txColumnSetting && this.props.txColumnSetting.length ? this.props.txColumnSetting : params.columnApi.getColumnState();
                // const columnToBeSet = [];
                // const indexObjArr = TRANSACTION_COLUMN_INDEX.SALES_INVOICE_FORM

                // indexObjArr.forEach((e) => {
                //     let colObj = find(txColumnSetting, { colId: e + '_1' });
                //     if (colObj) {
                //         columnToBeSet.push(colObj);
                //     }
                // });

                // txColumnSetting = txColumnSetting.filter((ele) => {
                //     let columnName = ele.colId.split("_")[0];
                //     return indexObjArr.indexOf(columnName) === -1;
                // })
                // txColumnSetting = columnToBeSet.concat(txColumnSetting);
                // txColumnSetting.forEach((e, index) => {
                //     let columnName = e.colId.split("_")[0];
                //     if (columnName !== 'action' && columnName !== 'amount') {
                //         if (columnName === 'isChecked' || columnName === 'sno') {
                //             e.pinned = 'left';
                //             e.lockPosition = true;
                //             e.hide = false;
                //         } else {
                //             e.hide = _isColumnToHide(columnName);
                //         }
                //     }
                //     if (columnName === 'amount') {
                //         e.hide = false;
                //         amountIndex = index;
                //         e.lockPosition = true;
                //         e.pinned = 'right';
                //     }

                //     if (columnName === 'salesOrderNumber') {
                //         e.hide = (_isColumnToHide('salesOrderNumber'));
                //     }

                //     if (columnName === 'shipmentNumber') {
                //         e.hide = (_isColumnToHide('shipmentNumber'));
                //     }
                //     if (columnName === 'action') {
                //         actionIndex = index;
                //         e.width = 52;
                //         e.hide = false;
                //         e.pinned = 'right';
                //     }
                // });
                // const actionObj = txColumnSetting[actionIndex];
                // const amountObj = txColumnSetting[amountIndex];
                // txColumnSetting = txColumnSetting.filter((e) => {
                //     let columnName = e.colId.split("_")[0];
                //     return columnName !== 'action' && columnName !== 'amount';
                // });
                // amountObj.lockPosition = true;
                // actionObj.lockPosition = true;
                // txColumnSetting.push(amountObj);
                // txColumnSetting.push(actionObj);
                // params.columnApi.setColumnState(txColumnSetting);
                setTimeout(() => {
                    if (this.gridColumnApi && this.gridColumnApi.columnController.bodyWidth < this.gridColumnApi.columnController.scrollWidth) {
                        params.api.sizeColumnsToFit();
                    }
                }, 500);
            }
        }


        this.gridApis = [];
        // const content = <AddAndRemoveColumn {...this.props} />

        // const _isColumnToHide = (columnName) => {
        //     // if (this.props.forceHideTxColumn && this.props.forceHideTxColumn.indexOf(columnName) > -1) {
        //     //     return true;
        //     // }
        //     return (this.props.txColumns.indexOf(columnName) < 0 && this.props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
        // }


        const columnDefs = [
            {
                headerComponentFramework: () => {
                    return "#";
                },
                headerClass: "pointer-none",
                cellRendererFramework: (params) => {
                    return params.node.rowIndex + 1
                },
                field: "sno",
                rowDrag: true,
                pinned: true,
                suppressMovable: true,
                lockPosition: true,
                colId: "sno_1",
                hide: false,
                minWidth: 70,
                maxWidth: 70,
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='student.text' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.STUDENT,
                colId: AG_GRID_CONSTANTS.COMMON.STUDENT + "_1",
                resizable: true,
                minWidth: 100,
                maxWidth: 250,
                suppressMovable: true,
                sortable: true,
                editable: true,
                //hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.STUDENT),
                cellEditor: 'customDropDownEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellRendererFramework: (params) => {
                    return <span>{params.node.data.studentName } { params.node.data.studentGrade ? '(Grade '+params.node.data.studentGrade +')': ''}</span>
                },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        items: this.props.studentList,
                        optionKeyName: 'schoolStudentId',
                        valueKeyName: 'firstName',
                        lastNameKey: 'lastName',
                        gradeKeyName: 'grade',
                        onSelect: (selectedValue, option) => {
                            let selectedStudent = find(this.props.studentList, { schoolStudentId: option.value }) || {};
                            //obj.node.setDataValue(obj.colDef.field, selectedValue);
                            let isDuplicateItem = false;
                          
                            obj.api && obj.api.forEachNode((rowNode, index) => {
                                const { data } = rowNode;
                                if (data.schoolStudentId === selectedStudent.schoolStudentId && index !== obj.node.rowIndex) {
                                    isDuplicateItem = true;
                                }
                            });
                            if (isDuplicateItem) {
                                return showToasterMessage({
                                    messageType: 'error', description: 'Duplicate student is selected'
                                });
                            }

                            obj.node.data.isDuplicateItem = isDuplicateItem;
                            obj.node.data.studentGrade = selectedStudent.grade;
                            obj.node.data.schoolStudentId = selectedStudent.schoolStudentId;
                            obj.node.data.baseRate = selectedStudent.courseFee;
                            obj.node.data.rate = selectedStudent.courseFee;
                            obj.node.data.description = selectedStudent.subcourseName;
                            obj.node.data.studentName = selectedStudent.firstName + (selectedStudent.lastName ? ' '+selectedStudent.lastName : '' );
                           // obj.stopEditing();
                           
                            obj.api.refreshCells();

                        },
                        allowClear: true,
                        onClear: () => {
                            //obj.node.setDataValue(obj.colDef.field, undefined);
                        },
                        onClick: () => {
                            if(!this.props.registrationFormSettingId){
                                obj.stopEditing();
                                return showToasterMessage({
                                    messageType: 'error', description: this.props.intl.formatMessage({id: 'programName.notSelected.validation' })
                                });
                            }
                        },
                    }
                },
            },
            
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.description'
                        defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD,
                editable: true,
                minWidth: 200,
                suppressMovable: true,
                sortable: true,
                resizable: true,
                cellClass: " ag-cell-description",
              //  hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.DESCRIPTION),
                colId: GRID_PREFERENCE_CONSTANTS.DESCRIPTION + "_1",
                cellEditor: 'CKEditorGrid',
                cellEditorParams: (params) => {
                    return {
                        value: params.node.data.description || "",
                        isEditorLoaded: this.props.isEditorLoaded,
                        onChange: (value) => {
                            params.node.data[params.colDef.field] = value;
                            params.node.data.isEditorLoaded = true;
                            this.props.updateState({
                                isEditorLoaded: true
                            })
                        }
                    }
                },
                cellRendererFramework: (params) => {
                    setTimeout(() => {
                        if (params.reactContainer && params.reactContainer.scrollHeight > 30 && params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0)) {
                            params.eParentOfValue.style.height = 'inherit';
                            params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
                            params.node.data.maxHeight = params.reactContainer.scrollHeight;
                            params.node.setRowHeight(params.reactContainer.scrollHeight);
                            params.api && params.api.onRowHeightChanged();
                        }
                    }, 200)
                    return ReactHtmlParser(params.data.description)
                },
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.event.target.value && params.editing && keyCode === KEY_ENTER;
                    params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
                    if (params.event.target.scrollHeight > (params.data.minHeight || 30)) {
                        if (params.event.path && params.event.path[2]) {
                            params.event.path[2].style.height = 'inherit';
                            params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
                        }
                        params.node.setRowHeight(params.event.target.scrollHeight);
                        params.api && params.api.onRowHeightChanged();
                    } else {
                        if (params.event.path && params.event.path[2]) {
                            params.event.path[2].style.height = 'inherit';
                            params.event.path[2].style.height = `${(params.node.data.minHeight || 30)}px`;
                        }
                        params.node.setRowHeight((params.node.data.minHeight || 30));
                        params.api && params.api.onRowHeightChanged();
                    }
                    return gridShouldDoNothing;
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='fee.text' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.RATE_FIELD,
                colId: GRID_PREFERENCE_CONSTANTS.RATE + "_1",
                resizable: true,
                maxWidth: 130,
                minWidth: 100,
                suppressMovable: true,
                editable: true,
                sortable: true,
                cellEditor: 'customNumberEditor',
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.data.baseRate = value;
                            obj.node.data.rate = value;
                            obj.api.refreshCells();

                        }
                    }
                },
                valueGetter: (params) => {
                    if (params.data.specialDiscount && params.data.rate && Number(params.data.rate) !== 0) {
                        params.data.rate = params.data.baseRate - ((params.data.specialDiscount * .01) * params.data.baseRate);
                    }
                    if (!params.data.specialDiscount && params.data.baseRate) {
                        params.data.rate = params.data.baseRate;
                    }
                    if (this.props.priceListId && params.data.rate) {
                        params.data.rate = params.data.baseRate - (this.props.priceListType.toLowerCase() === 'markdown' ? (((params.data.specialDiscount || 0) * .01) * params.data.baseRate) + (this.props.priceListPercentage * .01) * params.data.baseRate : (((params.data.specialDiscount || 0) * .01) * params.data.baseRate) - (this.props.priceListPercentage * .01) * params.data.baseRate);
                    }
                    return params.data.rate && Number(params.data.rate || 0).toFixed(2);
                },
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            // {
            //     headerComponentFramework: () => {
            //         return <FormattedMessage id='customer.salesInvoice.form.gridHeader.specialDiscount'
            //             defaultMessage='' />;
            //     },
            //     field: AG_GRID_CONSTANTS.COMMON.SPL_DISCOUNT_FIELD,
            //     colId: AG_GRID_CONSTANTS.COMMON.SPL_DISCOUNT_FIELD+"_1",
            //     editable: true,
            //     sortable: true,
            //     minWidth: 100,
            //     maxWidth: 120,
            //     suppressMovable: true,
            //     hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT),
            //     cellEditor: 'customNumberEditor',
            //     cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            // },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.discount'
                        defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.DISCOUNT_FIELD,
                editable: true,
                suppressMovable: true,
                sortable: true,
                minWidth: 120,
                maxWidth: 120,
               // hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.DISCOUNT),
                colId: GRID_PREFERENCE_CONSTANTS.DISCOUNT + "_1",
                resizable: true,
                cellEditor: 'customNumberEditor',
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
                cellEditorParams: (obj) => {
                    return { maxValue: 100,
                        decimalDigitsLength: (this.props.companyInfo || {}).decimalDigitsLengthPrice,
                        onChange: (value) => {
                            obj.node.data.discount = value;
                            obj.api.refreshCells();
                        } }
                  }
                  
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='discountType.text' defaultMessage='' />;
                },
                field: 'discountTypeCol',
                editable: true,
                sortable: true,
                width: 100,
                minWidth: 100,
                suppressMovable: true,
                //hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.TAX),
                colId:   "discountTypeCol_1",
                resizable: true,
                cellEditor: 'customDropDownEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        //items: this.props.invoiceDiscountRule || [],
                        items: [{"discountType":"regular","priority":3,"discount":12,"discountUnit":"Percent","discountAmount":0,"discountPercent":12,"discountTypeName":"Regular"},{"discountType":"financialAssistance","priority":5,"discount":10,"discountUnit":"Amount","discountAmount":10,"discountPercent":0,"discountTypeName":"Financial Assistance"},{"discountType":"membership","priority":1,"discount":6,"discountUnit":"Percent","discountAmount":0,"discountPercent":6,"discountTypeName":"Membership"},{"discountType":"teacher","priority":7,"discount":3,"discountUnit":"Percent","discountAmount":0,"discountPercent":3,"discountTypeName":"Teacher"},{"discountType":"volunteer","priority":7,"discount":4,"discountUnit":"Percent","discountAmount":0,"discountPercent":4,"discountTypeName":"Volunteer"},{"discountType":"boardMember","priority":8,"discount":5,"discountUnit":"Percent","discountAmount":0,"discountPercent":5,"discountTypeName":"Board Member"},{"discountType":"sibling1","priority":10,"discount":6,"discountUnit":"Percent","discountAmount":0,"discountPercent":6,"discountTypeName":"Sibling-1"},{"discountType":"sibling2","priority":10,"discount":6,"discountUnit":"Percent","discountAmount":0,"discountPercent":6,"discountTypeName":"Sibling-2"},{"discountType":"sibling3","priority":8,"discount":6,"discountUnit":"Percent","discountAmount":0,"discountPercent":6,"discountTypeName":"Sibling-3"},{"discountType":"sibling4","priority":10,"discount":6,"discountUnit":"Percent","discountAmount":0,"discountPercent":6,"discountTypeName":"Sibling-4"},{"discountType":"sibling5","priority":11,"discount":6,"discountUnit":"Percent","discountAmount":0,"discountPercent":6,"discountTypeName":"Sibling-5"}],
                        optionKeyName: 'discountType',
                        valueKeyName: 'discountTypeName',
                        onSelect: (selectedValue, option) => {
                            let type = option.name;
                            obj.node.data.discountType = type;
                           // obj.node.data[obj.colDef.field] = type;
                            obj.api.refreshCells();
                        },
                        allowClear: true,
                        onClear: () => {
                            obj.node.data.discountType = null;
                        },
                    }
                },
                cellRendererFramework: (params) => {
                    return params.node.data.discountType || ''
                },
            },
            // {
            //     headerComponentFramework: () => {
            //         return <FormattedMessage id='customer.salesQuote.form.gridHeader.tax' defaultMessage='' />;
            //     },
            //     field: 'taxApplied',
            //     editable: true,
            //     sortable: true,
            //     width: 100,
            //     minWidth: 100,
            //     suppressMovable: true,
            //     //hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.TAX),
            //     colId: GRID_PREFERENCE_CONSTANTS.TAX + "_1",
            //     resizable: true,
            //     cellEditor: 'customDropDownEditor',
            //     suppressKeyboardEvent: (params) => {
            //         const KEY_ENTER = 13;
            //         const keyCode = params.event.keyCode;
            //         const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
            //         return gridShouldDoNothing;
            //     },
            //     cellEditorParams: (obj) => {
            //         return {
            //             lastColumnCell: true,
            //             items: this.props.taxes,
            //             optionKeyName: 'taxSingleRateId',
            //             canAddNew: true,
            //             canDelete: true,
            //             permissions: this.props.permissions,
            //             moduleName: MODULE_CODED_VALUES.TAXES,
            //             operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            //             deleteClickHandler: (payload) => {
            //                 this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX, payload);
            //             },
            //             onAddNewClick: (payload) => {
            //                 payload.formData = {
            //                     taxName: payload.textEntered,
            //                     submittedOnce: false
            //                 }
            //                 this.handleNewTaxAddition(this.props, payload, MODAL_TYPE.NEW_TAX)
            //             },
            //             valueKeyName: 'taxNameDisplay',
            //             onSelect: (selectedValue, option) => {
            //                 const selectedTax = find(this.props.taxes, { taxSingleRateId: Number(option.key) });
            //                 obj.node.data.tax = selectedTax.taxPercent;
            //                 obj.node.data.taxName = selectedTax.taxName;
            //                 //obj.node.setDataValue(obj.colDef.field, selectedValue);
            //                 obj.node.data.taxId = selectedTax.taxSingleRateId;
            //             },
            //             allowClear: true,
            //             onClear: () => {
            //                 obj.node.data.tax = null;
            //                 obj.node.data.taxId = null;
            //                 obj.node.data.taxApplied = null;
            //                 obj.node.data.taxName = null;
            //                 this.calculateSubtotal();
            //             },
            //         }
            //     }
            // },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.amount'
                        defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD,
                pinned: 'right',
                headerClass: "pointer-none",
                colId: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD + "_1",
                resizable: true,
                minWidth: 150,
                maxWidth: 250,
                lockPosition: true,
                suppressMovable: true,
                suppressNavigable: true,
                valueGetter: (params) => {
                    let totalAmountOfColumn = 0
                    if ((params.data.qty || 1) && params.data.rate) {
                        totalAmountOfColumn = (params.data.qty || 1) * params.data.rate;
                        if (params.data.discount) {
                            let discountAmount = ((params.data.discount * 0.01) * totalAmountOfColumn);
                            totalAmountOfColumn = totalAmountOfColumn - discountAmount;
                        }
                        if (params.data.tax) {
                            totalAmountOfColumn = ((params.data.tax * 0.01) * totalAmountOfColumn) + totalAmountOfColumn;
                        }
                        setTimeout(() => {
                            this.calculateSubtotal();
                        }, 100)
                    }
                    return totalAmountOfColumn ? fixedDecimalAndFormateNumber(totalAmountOfColumn) : "";
                },
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN

            },
            {
                headerComponentFramework: () => {
                    return <div className="cursor-pointer">
                        {/* <Popover
                            content={content}
                            title={<div className="text-center"> <FormattedMessage id='common.addRemove' defaultMessage='' /> </div>}
                            placement="left"
                            trigger="click"
                        >
                            <i className='fa fa-bars' />
                        </Popover> */}
                    </div>;
                },
                field: 'action',
                colId: "action_1",
                pinned: 'right',
                cellRenderer: this.props.isShipmentConversion ? 'ActionLock' : 'customActionEditor',
                minWidth: 50,
                maxWidth: 50,
                suppressMovable: true,
                lockPosition: true,
                suppressNavigable: true,
                cellRendererParams: (params) => {
                    return {
                        onClick: () => {
                            if (params.api.rowRenderer.lastRenderedRow > 0) {
                                // const sodata = params.api.getDisplayedRowAtIndex(params.node.rowIndex).data
                                // const selectedSalesOrder = this.props.selectedSalesOrder;
                                params.api.updateRowData({ remove: [params.api.getDisplayedRowAtIndex(params.node.rowIndex).data] });
                                // selectedSalesOrder.forEach((soDetailData) => {
                                //     if (sodata.salesOrderMasterId === soDetailData.salesOrderMasterId) {
                                //         const currentDetailData = [];
                                //         this["gridApi-" + sodata.salesOrderMasterId].forEachNode((rowNode) => {
                                //             currentDetailData.push(rowNode.data);
                                //         });
                                //         soDetailData.customerSalesOrderDetailsList = currentDetailData;
                                //     }
                                // });
                                // this.props.updateState({ selectedSalesOrder })
                            }
                            this.calculateSubtotal();
                        },
                    }
                }
            }
        ];

        const createInvoice = (isDraft) => {
            //let isOutOfStock = false;
            let totalItem = 0;
            let isRequiredFiledMissing = false;
            let isDiscountTypeMissing= false;
            let self = this;
            if(this.props.isbankReco && this.props.bankRecoBalance && this.props.bankRecoBalance !== Number(this.state.total) ){
                return showToasterMessage({
                    messageType: 'error', description: this.props.intl.formatMessage({id: 'bankReco.createInvoice.validation', defaultMessage: '' })
                });
            }

            if(!this.props.registrationFormSettingId){
                return showToasterMessage({
                    messageType: 'error', description: this.props.intl.formatMessage({id: 'programName.required.validation' })
                });
            }
           
            if (this.props.isAllowPayOnline && (!this.props.paymentGatewayName || !this.props.paymentGatewayId)) {
                return showToasterMessage({
                    messageType: 'error', description: this.props.intl.formatMessage({ id: 'paymentGateway.notAvailable.message', defaultMessage: '' })
                });
            }
            this.gridApi.forEachNode((rowNode, index) => {
                totalItem++;
                const { data } = rowNode;
                delete data.minHeight;
                if (!data.product) {
                    delete data.product;
                }
                if ((Object.keys(data || {}).length || index === 0) && ((!data.studentName && !data.description) || !(Number(data.rate || 0)) )) {
                    isRequiredFiledMissing = true;
                }
                if ((Object.keys(data || {}).length || index === 0) && ((data.discount && !data.discountType)  )) {
                    isDiscountTypeMissing = true;
                }
            })
            
          
            if (!totalItem || (isRequiredFiledMissing && !isDraft) || (!this.props.isCashSales && !(this.props.customer && this.props.customer.customerId))) {
                return showToasterMessage({
                    messageType: 'error', description: 'Please select Father, Student/Description and Fee'
                });
            }
            if (isDiscountTypeMissing && !isDraft) {
                return showToasterMessage({
                    messageType: 'error', description: 'Please select discount type'
                });
            }
            const modalData = {
                modalBody: <FormattedMessage
                    id='customer.salesInvoice.form.confirmation'
                    defaultMessage=''
                />,
                handleSubmit: () => {
                    this.props.hideModal();
                    const payload = {
                        props: this.props,
                        gridApis: this.gridApis,
                        isPlaceOfSupplySameAsRelationship: isPlaceOfSupplySameAsRelationship,
                        state: this.state,
                        gridApi: this.gridApi,
                        gridObject: this.gridApi,
                        grandTotal: this.getGrandTotal(),
                        isDraft: isDraft
                    }
                    if (isDraft) {
                        this.props.createSalesInvoiceDraft(payload);
                    } else {
                        if (self.props.customerPaymentTerm && (self.props.customerPaymentTerm.termName === "Cash on Delivery" || self.props.customerPaymentTerm.termName === "Payment in Advance")) {
                            const modalData2 = {
                                modalBody: <FormattedMessage
                                    id='invoice.markAsPaid.confirmation'
                                    defaultMessage=''
                                />,
                                footer: {cancel: { intlId: 'no' },submit: { intlId: 'yes' }},
                                isStack: true,
                                handleSubmit: () => {
                                    self.props.createSalesInvoice(payload, true);
                                },
                                onCancel: () => {
                                    self.props.createSalesInvoice(payload);
                                    self.props.popModalFromStack(modalData2);
                                }
                            };
                            self.props.pushModalToStack(modalData2);
                        }else{
                            self.props.createSalesInvoice(payload);
                        }
                    }
                }
            };
            this.props.showModal(modalData);


        }



        if (this.props.toUpdatePriceList) {
            this.gridApi.refreshCells({ force: true });
            this.props.updateState({ toUpdatePriceList: false })
        }



        const getRowHeight = (params) => {
            const rowHeight = params.node.data.scrollHeight ? (Number(params.node.data.scrollHeight.split("px")[0]) - 30) : 30;
            return rowHeight < 30 ? 30 : rowHeight;
        }

        // const saveColumnIndex = (columnState) => {
        //     let data = this.props.txPreferences || {};
        //     let gridPreference = {};
        //     data.relationshipId = (this.props.companyInfo || {}).relationshipId;
        //     if (data.tablePreference) {
        //         gridPreference = JSON.parse(data.tablePreference);
        //     }
        //     gridPreference.columnSetting = columnState;
        //     gridPreference.columnList = this.props.txColumns;
        //     data.gridPreference = JSON.stringify(gridPreference);

        //     this.props.saveUserPrefrences(data);
        // }

        // const saveColumnWidth = (columnState, width) => {
        //     let data = this.props.txPreferences || {};
        //     let gridPreference = {};
        //     data.relationshipId = (this.props.companyInfo || {}).relationshipId;
        //     data.clientUserAccountId = (this.props.userInfo || {}).userId;
        //     if (data.gridPreference) {
        //         gridPreference = JSON.parse(data.gridPreference);
        //     }
        //     gridPreference.columnSetting = columnState;
        //     gridPreference.columnList = this.props.txColumns;
        //     data.gridPreference = JSON.stringify(gridPreference);
        //     data.preferenceId = this.props.preferenceId;
        //     data.tablePreference = this.props.tablePreference;
        //     this.props.saveUserPrefrences(data, this.props);
        // }

        // let moveEvent = {};
        // const onColumnMoved = (event, isDragStopped) => {
        //     if (event.column) {
        //         moveEvent = event;
        //     }
        //     if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
        //         saveColumnIndex(moveEvent.columnApi.getColumnState());
        //     }
        // }

        // const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
        //     const gridColumnApi = tableGridColumnApi || this.gridColumnApi;
        //     event.api.resetRowHeights();
        //     //const gridApi = tablegridApi || this.gridApi;
        //     if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
        //         if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
        //             this.gridApi.sizeColumnsToFit();
        //             // const columnArray = event.columnApi.getColumnState();
        //             // for (let i = (columnArray.length - 1); i >= 0; i--) {
        //             //     if (columnArray[i] && !columnArray[i].hide && columnArray[i].colId !== 'action_1') {
        //             //         columnArray[i].width = columnArray[i].width + (gridColumnApi.columnController.scrollWidth - gridColumnApi.columnController.bodyWidth);
        //             //         break;
        //             //     }
        //             // }
        //             // saveColumnWidth(columnArray);
        //             setTimeout(() => {
        //                 const columnArray = event.columnApi.getColumnState();
        //                 saveColumnWidth(columnArray);
        //             }, 1000);
        //         } else {
        //             saveColumnWidth(event.columnApi.getColumnState());
        //         }
        //     }
        // }

        return (
            <Fragment>
                <div className="transaction-table">
                    
                        <CustomAgGrid
                            getRowHeight={getRowHeight}
                            // onColumnResized={onColumnResized}
                            // onDragStopped={() => {
                            //     onColumnMoved({}, true);
                            // }}
                            ////onColumnMoved={onColumnMoved}
                            columnDefs={columnDefs}
                            onGridReady={onGridReady}
                            onModelUpdated={onModelUpdated}
                            rowData={this.props.customerSalesOrderDetailsList || [{}]}
                            gridStyle={{
                                width: '100%',
                                height: '100%',
                                marginBottom: '10px'
                            }} /> 
                </div>
                            <div style={{display:'flex',gap:'10px'}} >
                                {(!this.props.isSoConversionToInvoice && !this.props.isShipmentConversion) ?
                                    <Button className="grey-button" onClick={
                                        () => { this.gridApi.updateRowData({ add: [{}] }); }
                                    }><FormattedMessage id='add.student' />
                                    </Button>
                                    : ''
                                }
                                {(!this.props.isSoConversionToInvoice && !this.props.isShipmentConversion) ?
                                    <Button className="grey-button" onClick={
                                        () => {
                                            this.props.showModal({
                                                modalBody: "Are you sure you want to clear?",
                                                handleSubmit: () => {
                                                    let gridApi = this.gridApi;
                                                    let self = this;
                                                    this.gridApi.forEachNode(function (rowNode, index) {
                                                        gridApi.updateRowData({ remove: [rowNode.data] });
                                                        if (gridApi.rowRenderer.lastRenderedRow < 0) {
                                                            gridApi.updateRowData({ add: [{}] });
                                                        }
                                                        self.calculateSubtotal();
                                                    })
                                                }
                                            })
                                        }
                                    }><FormattedMessage id='customer.salesInvoice.form.button.clearAllLines'
                                        defaultMessage='' /></Button> : ''
                                }
                            </div>
                            <div className="upload-btn-so">

                                    <CustomTableUpload fileList={this.props.fileList} onChange={
                                        (fileList) => {
                                            this.props.updateState({
                                                fileList
                                            })
                                        }
                                    } />
                            </div>
                            <Divider  style={{margin: '10px 0px'}}/>
                                            <Row>
                                                <Col span={12}>


                                    <div className="remarks-title">
                                        <FormattedMessage id='customer.salesInvoice.form.bankDetails.label'
                                            defaultMessage='' />
                                    </div>

                                    <MultipleSelectDropdown
                                        style={{ width: '60%' }}
                                        items={(this.props.companyInfo || {}).bankDetails || []}
                                        placeholder="Bank Details"
                                        mode='multiple'
                                        onDeSelect={(value) => {
                                            let { selectedBankDetails } = this.props
                                            selectedBankDetails = filter(selectedBankDetails, (taxId) => {
                                                return taxId !== value;
                                            });
                                            this.props.updateState({ selectedBankDetails });
                                        }}
                                        valueKeyName='name'
                                        addressLineKey1='accountNumber'
                                        // addressLineKey2='ifscCode'
                                        selectedValue={this.props.selectedBankDetails || []}
                                        optionKeyName='bankDetailId'
                                        onSelect={(bankDetailId) => {
                                            let selectedBankDetails = this.props.selectedBankDetails || [];
                                            if (selectedBankDetails.indexOf(bankDetailId) === -1) {
                                                selectedBankDetails.push(bankDetailId);
                                            } else {
                                                selectedBankDetails = selectedBankDetails.filter((el) => {
                                                    return el !== selectedBankDetails
                                                });
                                            }
                                            this.props.updateState({
                                                selectedBankDetails
                                            });
                                        }}
                                    />
                                   
                                    <div>
                                        <div className="remarks-title">
                                            <div style={{ marginTop: '10px' }}>
                                            <Tooltip placement="right" title={this.props.intl.formatMessage({ id: 'tooltip.onlinePaymnet', defaultMessage: '' })}>
                                                <span className="tooltip-title">
                                                    <FormattedMessage id='payment.gateway' defaultMessage='' />
                                                </span>
                                            </Tooltip>
                                            <Checkbox style={{ marginLeft: '20px' }} checked={this.props.isAllowPayOnline} onChange={() => {
                                                this.props.updateState({ isAllowPayOnline: !this.props.isAllowPayOnline })
                                            }}> </Checkbox>
                                            </div>
                                             {this.props.isAllowPayOnline ? <span>
                                            <div className="remarks-title">
                                                    <FormattedMessage id='select.gateway' defaultMessage='' />
                                                <img src={cardlogo} alt="" style={{ marginLeft: '10px' }} />
                                            </div>

                                            <Select
                                                style={{ width: '60%' }}
                                                items={this.props.allPaymentGatewayConfig || []}
                                                value={this.props.paymentGatewayName}
                                                onSelect={(selectedId, obj) => {
                                                    this.props.updateState({
                                                        paymentGatewayId: obj.key,
                                                        paymentGatewayName: obj.value
                                                    })
                                                }}
                                                allowClear={true}
                                                onClear={() => {
                                                    this.props.updateState({
                                                        paymentGatewayId: undefined,
                                                        paymentGatewayName: undefined,
                                                    })
                                                }}
                                            >
                                                {(this.props.allPaymentGatewayConfig || []).map((gatway) => {
                                                    return (
                                                        <Option key={gatway.alpidePaymentGatewayId} value={gatway.paymentGatewayName}>
                                                            <FormattedMessage id={gatway.userDefinedGatewayName} />
                                                        </Option>
                                                    )
                                                })
                                                }
                                            </Select>
                                            
                                        <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'tooltip.onlinePaymnet.help', defaultMessage: '' })} trigger="click">
                                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                        </Tooltip>
                                        </span> : ''}
                                        </div>
                                       
                                    </div>

                                    <div>

                                        <div className="remarks-title">
                                            <FormattedMessage id='customer.salesInvoice.form.internalRemarks.label'
                                                defaultMessage='' />
                                        </div>
                                        <CKEditor
                                            type="inline"
                                            className="description-textarea"
                                            key={`${"internalRemarks"}`}
                                            data={this.props.internalRemarks}
                                            onInit={editor => {
                                                this.editor = editor;
                                            }}
                                            onChange={(event) => {
                                                const data = event.editor.getData();
                                                this.props.updateState({ internalRemarks: data });
                                            }}
                                            config={CUSTOM_CONFIGURATION}
                                        />

                                    </div>

                                    <div >
                                        <FormattedMessage id='parentRemarks.text'
                                            defaultMessage='' />
                                    </div>

                                    {<CKEditor
                                        type="inline"
                                        className="description-textarea"
                                        key={`${"customerRemarks"}`}
                                        data={this.props.customerRemarks}
                                        onInit={editor => {
                                            this.editor = editor;
                                        }}
                                        onChange={(event) => {
                                            const data = event.editor.getData();
                                            this.props.updateState({ customerRemarks: data });
                                        }}
                                        config={CUSTOM_CONFIGURATION}
                                    />}
                                                </Col>

                                                <Col span={7} offset={5} >
                                                    <Row gutter={16}>
                                                        <Col span={12}>
                                                            <Row gutter={[16,8]}>
                                                                <Col>
                                                                {<Checkbox checked={this.props.isRootDiscount} onChange={() => {
                                                            this.props.updateState({ isRootDiscount: !this.props.isRootDiscount })
                                                        }}> <FormattedMessage id='customer.salesInvoice.form.checkbox.discount'
                                                            defaultMessage='' /></Checkbox>}
                                                                </Col>
                                                                {
                                                                this.props.isRootDiscount
                                                                    ?
                                                                    <Fragment>
                                                                        <Col>
                                                                         <div className="input-label-control" >
                                                                                    <span className="mark">%</span>
                                                                                    <Input
                                                                                   
                                                                                        type='number'
                                                                                        placeholder="0.00"
                                                                                        value={this.state.rootDiscountPercent || ""}
                                                                                        onChange={(e) => {
                                                                                            this.setState({
                                                                                                rootDiscountPercent: e.target.value
                                                                                            });
                                                                                        }}
                                                                                        onBlur={(e) => {
                                                                                            if (this.state.subtotal) {
                                                                                                this.calculateSubtotal();
                                                                                            }
                                                                                        }
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                {/* {this.state.rootDiscountAmount ? <span style={{ width: '120px', paddingTop: '5px' }}>
                                                                                    {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}{this.state.rootDiscountAmount || ""}
                                                                                </span> : ''
                                                                                } */}
                                                                                {/* <div className="input-label-control">
                                                                                    <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                                                                                    <Input
                                                                                        disabled
                                                                                        placeholder="0.00"
                                                                                        type='number'
                                                                                        value={this.state.rootDiscountAmount || ""}
                                                                                        onChange={(e) => {
                                                                                            this.setState({
                                                                                                rootDiscountAmount: Number(e.target.value)
                                                                                            });
                                                                                        }}
                                                                                        onBlur={(e) => {
                                                                                            if (this.state.subtotal) {
                                                                                                this.calculateSubtotal();
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    </div> */}
                                                                                    {/* <Dropdown
                                                                                        style={{ width: '120px', paddingTop: '25px' }}
                                                                                        items={this.props.invoiceDiscountRule}
                                                                                        value={this.state.rootDiscountType}
                                                                                        onSelect={(selectedType) => {
                                                                                            this.setState({
                                                                                                rootDiscountType: selectedType
                                                                                            })
                                                                                        }}
                                                                                        valueKeyName='discountTypeName'
                                                                                        optionKeyName='discountType'
                                                                                        allowClear={true}
                                                                                        onClear={() => {
                                                                                            this.setState({
                                                                                                rootDiscountType: undefined,
                                                                                            });
                                                                                            // this.calculateSubtotal();
                                                                                        }}
                                                                                    /> */}
                                                                                
                                                                           
                                                                        </Col>
                                                                        <Col>
                                                                            <div >
                                                                            <div><FormattedMessage id='customer.salesOrder.form.discountValue.label' defaultMessage='' /></div>
                                                                            <div>{fixedDecimalAndFormateNumber(this.state.rootDiscountAmount) || '0.00'}</div>
                                                                            </div>
                                                                        </Col>
                                                                    </Fragment>
                                                                    : ''
                                                            }
                                                            </Row>

                                                        </Col>
                                                        <Col span={12}>
                                                        <Row gutter={[16, 8]}>
                                                        <Col span={24}>
                                                            <Checkbox checked={this.props.isRootExpense} onChange={() => {
                                                                this.props.updateState({ isRootExpense: !this.props.isRootExpense })
                                                            }}>
                                                                <FormattedMessage id='customer.salesInvoice.form.checkbox.expense' defaultMessage='' />
                                                            </Checkbox>
                                                        </Col>
                  {
                    this.props.isRootExpense
                      ? <>
                      <Col span={24}>
                        <Dropdown
                          style={{ width: '100%' }}
                          items={this.props.expenseTypes}
                          valueKeyName='expenseType'
                          value={this.props.expenseId}
                          canAddNew={true}
                          canDelete={true}
                          deleteClickHandler={(payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.EXPENSE_TYPE, payload);
                          }}
                          onAddNewClick={(payload) => {
                            payload.formData = {
                              textBoxValue: payload.textEntered,
                              submittedOnce: false
                            }
                            this.handleNewItemAddition(this.props, payload, MODAL_TYPE.EXPENSE_TYPE)
                          }}
                          onSelect={(expenseId) => {
                            const selectedExpense = find(this.props.expenseTypes, { lkExpenseTypeId: Number(expenseId) })
                            this.props.updateState({
                              expenseId,
                              expensName: selectedExpense.expenseType

                            })
                          }}
                          optionKeyName='lkExpenseTypeId'
                          allowClear={true}
                          onClear={() => {
                            this.props.updateState({
                              expenseType: undefined,
                              expenseId: undefined
                            })
                          }}
                        />
                      </Col>
                      <Col span={24}>
                        <div className="input-label-control">
                          <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                          <Input
                            placeholder="0.00"
                            type='number'
                            value={this.state.expenseAmount}
                            onChange={(e) => {
                              this.setState({
                                expenseAmount: Number(e.target.value)
                              }, () => {
                                if (this.state.subtotal) {
                                  this.calculateSubtotal();
                                }
                              });
                            }}
                          />
                        </div>
                      </Col>
                      </>
                      :''
                  }
                </Row>
                                                             </Col>

                                                    </Row>
                                                    <div className="checkbox-control-group">
                                                        
                                                        {/* {false && <Checkbox onChange={() => {
                                                            this.setState({ sectionVisible: { ...sectionVisible, tax: !sectionVisible.tax } })
                                                        }}><FormattedMessage id='customer.salesInvoice.form.checkbox.tax'
                                                            defaultMessage='' /></Checkbox>} */}
                                                        {/* <Checkbox checked={this.props.isRootExpense} onChange={() => {
                                                            this.props.updateState({ isRootExpense: !this.props.isRootExpense })
                                                        }}><FormattedMessage id='customer.salesInvoice.form.checkbox.expense'
                                                            defaultMessage='' /></Checkbox> */}
                                                    </div>

                                                    <div className="product-amount-value">
                                                    <Row>
                                                        <Col span={15}>
                                                        <FormattedMessage id='customer.salesOrder.form.subTotal.label' defaultMessage='' />
                                                        </Col>
                                                        <Col span={9}>
                                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                        {fixedDecimalAndFormateNumber(this.state.subtotalWithoutDiscount)}
                                                        </Col>
                                                    </Row>

                                                        {
                                                            sectionVisible.tax
                                                                ? <Fragment>
                                                                    <Row type="flex" justify="end">
                                                                        <Col span={8}>
                                                                            <FormattedMessage id='customer.salesInvoice.form.taxName.label'
                                                                                defaultMessage='' />
                                                                        </Col>

                                                                        <Col span={16}>
                                                                            <Dropdown
                                                                                items={this.props.taxes}
                                                                                value={this.props.taxName}
                                                                                onSelect={(selectedTax) => {
                                                                                    this.props.updateState({
                                                                                        taxName: selectedTax
                                                                                    })
                                                                                }}
                                                                                valueKeyName='description'
                                                                                optionKeyName='taxSingleRateId'
                                                                                allowClear={true}
                                                                                onClear={() => {
                                                                                    this.props.updateState({
                                                                                        taxName: undefined,
                                                                                    });
                                                                                    this.calculateSubtotal();
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                    </Row>

                                                                    <Row type="flex" justify="end">
                                                                        <Col span={8}>
                                                                            <span className="label"><FormattedMessage
                                                                                id='customer.salesInvoice.form.tax.label' defaultMessage='' /></span>
                                                                        </Col>

                                                                        <Col span={16}>
                                                                            <div className="input-group">
                                                                                <div className="input-label-control">
                                                                                    <span className="mark">%</span>
                                                                                    <Input placeholder="0.00" />
                                                                                </div>
                                                                                <div className="input-label-control">
                                                                                    <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                                                                                    <Input placeholder="0.00" />
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Fragment>
                                                                : ''
                                                        }

                                                        {/* {
                                                            this.props.isRootExpense
                                                                ? <Fragment>
                                                                    <Row type="flex" className='amount-summary mb20' justify="end">
                                                                        <Col span={8}>
                                                                            <FormattedMessage id='customer.salesInvoice.form.totalExpense.label'
                                                                                defaultMessage='' />
                                                                        </Col>

                                                                        <Col span={16}>
                                                                            <Dropdown
                                                                                style={{ width: '100%' }}
                                                                                items={this.props.expenseTypes}
                                                                                valueKeyName='expenseType'
                                                                                value={this.props.expenseId}
                                                                                canAddNew={true}
                                                                                canDelete={true}
                                                                                deleteClickHandler={(payload) => {
                                                                                    this.deleteClickHandler(this.props, MODAL_TYPE.EXPENSE_TYPE, payload);
                                                                                }}
                                                                                onAddNewClick={(payload) => {
                                                                                    payload.formData = {
                                                                                        textBoxValue: payload.textEntered,
                                                                                        submittedOnce: false
                                                                                    }
                                                                                    this.handleNewItemAddition(this.props, payload, MODAL_TYPE.EXPENSE_TYPE)
                                                                                }}
                                                                                onSelect={(expenseId) => {
                                                                                    const selectedExpense = find(this.props.expenseTypes, { lkExpenseTypeId: Number(expenseId) })
                                                                                    this.props.updateState({
                                                                                        expenseId,
                                                                                        expensName: selectedExpense.expenseType

                                                                                    })
                                                                                }}
                                                                                optionKeyName='lkExpenseTypeId'
                                                                                allowClear={true}
                                                                                onClear={() => {
                                                                                    this.props.updateState({
                                                                                        expenseId: undefined,
                                                                                        expensName: undefined
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                    </Row>

                                                                    <Row type="flex" justify="end" className="mb20">
                                                                        <Col span={8}>
                                                                            <span className="label"><FormattedMessage
                                                                                id='customer.salesInvoice.form.totalExpenseAmount.label'
                                                                                defaultMessage='' /></span>
                                                                        </Col>

                                                                        <Col span={16}>
                                                                            <div className="input-group">
                                                                                <div className="input-label-control">
                                                                                    <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                                                                                    <Input
                                                                                        placeholder="0.00"
                                                                                        type='number'
                                                                                        value={this.state.expenseAmount}
                                                                                        onChange={(e) => {
                                                                                            this.setState({
                                                                                                expenseAmount: Number(e.target.value)
                                                                                            }, () => {
                                                                                                if (this.state.subtotal) {
                                                                                                    this.calculateSubtotal();
                                                                                                }
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Fragment>
                                                                : ''
                                                        } */}

                                                        {/* <div className="amount-summary">
                                                            <Row>
                                                                <Col span={8}>
                                                                    <span className="title"><FormattedMessage
                                                                        id='customer.salesInvoice.form.subTotal.label' defaultMessage='' /></span>
                                                                </Col>
                                                                <Col span={16}>
                                                                    {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                    {fixedDecimalAndFormateNumber(this.state.subtotalWithoutDiscount)}
                                                                </Col>
                                                            </Row>
                                                        </div> */}

                                                        {this.state.totalDiscount === '0.00' || this.state.totalDiscount === 0 ? null :
                                                            <div className="amount-summary">
                                                                <Row>
                                                                    <Col span={8}>
                                                                        <span className="title"><FormattedMessage
                                                                            id='customer.salesInvoice.form.totalDiscount.label'
                                                                            defaultMessage='' /></span>
                                                                    </Col>
                                                                    <Col span={16}>
                                                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                        {fixedDecimalAndFormateNumber(this.state.totalDiscount)}
                                                                    </Col>
                                                                </Row>
                                                            </div>}

                                                        {
                                                            itemLevelTaxes.length === 0 ? null : itemLevelTaxes.map((taxObj, i) => {
                                                                if (isPlaceOfSupplySameAsRelationship) {

                                                                }
                                                                return (
                                                                    (this.props.companyInfo || {}).countryName === 'India' ? (isPlaceOfSupplySameAsRelationship ? <div key={i}>
                                                                        <div className="amount-summary">
                                                                            <Row>
                                                                                <Col span={8}>
                                                                                    <span
                                                                                        className="title">CGST @ {taxObj.taxPercent / 2}% on {taxObj.subtotal} </span>
                                                                                </Col>
                                                                                <Col span={16}>
                                                                                    {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                                    {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                                                                                </Col>
                                                                            </Row>
                                                                        </div>

                                                                        <div className="amount-summary">
                                                                            <Row>
                                                                                <Col span={8}>
                                                                                    <span
                                                                                        className="title">SGST @ {taxObj.taxPercent / 2}% on {taxObj.subtotal} </span>
                                                                                </Col>
                                                                                <Col span={16}>
                                                                                    {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                                    {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </div> : <div key={i}>
                                                                            <div className="amount-summary">
                                                                                <Row>
                                                                                    <Col span={8}>
                                                                                        <span
                                                                                            className="title">IGST @ {taxObj.taxPercent}% on {taxObj.subtotal} </span>
                                                                                    </Col>
                                                                                    <Col span={16}>
                                                                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                                        {(Number(taxObj.taxAmount || 0)).toFixed(2)}
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </div>) :
                                                                        <div key={i}>
                                                                            <div className="amount-summary">
                                                                                <Row>
                                                                                    <Col span={8}>
                                                                                        <span
                                                                                            className="title">{taxObj.taxName} @ {taxObj.taxPercent}% on {taxObj.subtotal} </span>
                                                                                    </Col>
                                                                                    <Col span={16}>
                                                                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                                        {(Number(taxObj.taxAmount || 0)).toFixed(2)}
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </div>
                                                                )
                                                            })
                                                        }
                                                         

                                                        
                                                            <Row>
                                                                <Col span={15}>
                                                                    <span className="title"><FormattedMessage
                                                                        id='customer.salesInvoice.form.totalAmount.label'
                                                                        defaultMessage='' /></span>
                                                                </Col>
                                                                <Col span={9}>
                                                                    {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                    {fixedDecimalAndFormateNumber(this.state.total)}
                                                                </Col>
                                                            </Row>
                                                        
                                                    </div>
                                                </Col>

                                            </Row>

                       
                    

               


              
                    {/* <TextArea maxLength={500} value={this.props.footer || ''} className="add-footer-textarea" onChange={(e) => {
                    this.props.updateState({
                        footer: e.target.value
                    });
                }}
                    rows={1} placeholder="Add Footer Here"></TextArea> */}
                    <CKEditor
                        type="inline"
                        className="add-footer-textarea"
                        key={`${"footer"}`}
                        data={this.props.footer}
                        onInit={editor => {
                            this.editor = editor;
                        }}
                        onChange={(event) => {
                            const data = event.editor.getData();
                            this.props.updateState({ footer: data });
                        }}
                        // 05-02-21 config={CUSTOM_CONFIGURATION}
                        config={{ ...CUSTOM_CONFIGURATION, placeholder: 'Add Footer Here' }}
                    />

                    <div className="footer">
                        <div className="left-fItems">
                        <Button className="grey-button" onClick={() => {
                                // this.props.history.goBack();
                                this.props.popModalFromStack();
                            }}><FormattedMessage id='button.cancel.label' defaultMessage='' /></Button>
                        </div>
                        {/* {!this.props.update &&
                            <Col span={2}>
                                <Button className="ant-btn-primary ml10"
                                    onClick={() => {
                                        createInvoice(true);
                                    }}
                                ><FormattedMessage id='button.draft.label' defaultMessage='' /></Button>
                            </Col>
                        } */}
                        <div className="right-fItems">
                        <Button className="ant-btn-primary mr10" onClick={() => {
                                createInvoice();
                            }}><FormattedMessage id='confirmation.submit' defaultMessage='' /></Button>
                        </div>
                    </div>
               
            </Fragment>
        )
    }
};

export default injectIntl(CustomerProductDetails);
