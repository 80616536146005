import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import StockSummaryListingComp from '../../../../../components/inventory/warehouse/stockSummary/Listing';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../../utils';
import { fetchInventoryItemsByWarehouse, getInventoryItemsByWarehouseCount, resetPaginatedData } from './action';
import { fetchWarehouseNames} from '../../../../drawer/inventory/action';
import { pushModalToStack } from '../../../../../actions/commonActions';


class StockSummaryListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warehouseMasterId : 0,
      pageSize: 25,
      pageNumber: 1,
      isDataRendered: false
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,
      warehouseMasterId : 0
    };
    fetchPaginationDataIfNeeded('fetchInventoryItemsByWarehouse', 'stockSummaryList', this.props, payload);
    fetchDataIfNeeded('getInventoryItemsByWarehouseCount', 'stockSummaryCount', this.props, payload);
    fetchDataIfNeeded('fetchWarehouseNames', 'allWarehouses', this.props, payload);
  }

  render() {
    return (
      <StockSummaryListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    stockSummaryList: state.stockSummary.stockSummaryList,
    stockSummaryCount: state.stockSummary.stockSummaryCount,
    allWarehouses: state.inventory.allWarehouses,
    listLoading: state.common.listLoading,
    headerLoading:state.common.headerLoading,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchInventoryItemsByWarehouse, 
  getInventoryItemsByWarehouseCount,
  resetPaginatedData,
  fetchWarehouseNames,
  pushModalToStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StockSummaryListing);
