import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CategoryComponent from '../../../../../components/modal/modalBody/finance/LedgerAccounts/CategoryTab';
import { fetchAllLedgerAccountGroup, fetchAllCategories, fetchAllCategoryGroups, fetchAllLedgerAccounts } from '../../../../../containers/finance/LedgerAccounts/action';
import { fetchDataIfNeeded } from  '../../../../../utils';
import { fetchNextAccountNo, createLedgerAccount, createCategory, createLedgerAccountGroup,isDuplicateCategory,isDuplicateLedgerAccountGroupName,isDuplicateLedgerAccountName } from  './action';
import {getAllGstRates, getAllTaxByName} from '../../../../drawer/inventory/action';

class LedgerAccounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
      }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId
        }
        fetchDataIfNeeded('fetchAllLedgerAccountGroup', 'allLedgerAccountGroups', this.props, payload);
        fetchDataIfNeeded('fetchAllCategories', 'allCategories', this.props, payload);
        fetchDataIfNeeded('fetchAllCategoryGroups', 'allCategoryGroup', this.props, payload);
        if (companyInfo.countryName === "India") {
            fetchDataIfNeeded('getAllGstRates', 'allGstRates', this.props, { ...payload, type: 'GOODS', pageNumber: 0, pageSize: 50 });
            fetchDataIfNeeded('getAllTaxByName', 'cgstTaxes', this.props, { ...payload, type: 'CGST', actionName: 'cgst_taxes' });
            fetchDataIfNeeded('getAllTaxByName', 'sgstTaxes', this.props, { ...payload, type: 'SGST', actionName: 'sgst_taxes' });
            fetchDataIfNeeded('getAllTaxByName', 'igstTaxes', this.props, { ...payload, type: 'IGST', actionName: 'igst_taxes' });
          } else {
            fetchDataIfNeeded('getAllTaxByName', 'VAT', this.props, { ...payload, type: 'VAT', actionName: 'globle_taxes' });
          }
        
    }

    componentWillReceiveProps(props){
        this.setState({
            ledgerAccountNumberBySystem: props.nextLedgerAccountNo,
            ledgerAccountNumber: !props.ledgerAccountNumber ?  props.nextLedgerAccountNo: props.ledgerAccountNumber
        })
    }

    render() {
        return <CategoryComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        isLoading: state.common.isLoading,
        allLedgerAccountGroups: state.ledger.allLedgerAccountGroups,
        allCategories: state.ledger.allCategories,
        allCategoryGroup: state.ledger.allCategoryGroup,
        nextLedgerAccountNo: state.ledger.nextLedgerAccountNo,
        allGstRates: state.inventory.allGstRates,
        cgstTaxes: state.inventory.cgstTaxes,
        sgstTaxes: state.inventory.sgstTaxes,
        igstTaxes: state.inventory.igstTaxes,
        globleTaxes: state.inventory.globleTaxes,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllLedgerAccountGroup,
    fetchAllCategories, 
    fetchAllCategoryGroups,
    fetchNextAccountNo,
    createLedgerAccount,
    createCategory, 
    createLedgerAccountGroup,
    fetchAllLedgerAccounts,
    getAllGstRates, 
    getAllTaxByName,isDuplicateCategory,isDuplicateLedgerAccountGroupName,isDuplicateLedgerAccountName
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LedgerAccounts);