import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SponsorshipComp from "../../../components/fundraising/sponsorship";
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../../utils";
import { getSponsorships, sponsorshipCount, deleteSponsorship, resetPaginatedData } from "./action";
import { showModal, hideModal } from './../../../actions/commonActions';

class Sponsorships extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,
    };
    fetchPaginationDataIfNeeded("getSponsorships", "sponsorshipList", this.props, payload);
    fetchDataIfNeeded("sponsorshipCount", "sponsorshipListCount", this.props, payload);
  }

  render() {
    return (
      <SponsorshipComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></SponsorshipComp>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    sponsorshipList: state.fundraisingReducer.sponsorshipList,
    sponsorshipListCount: state.fundraisingReducer.sponsorshipListCount,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSponsorships, 
      sponsorshipCount, 
      deleteSponsorship,
      resetPaginatedData,
      showModal,
      hideModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sponsorships);
