import React from "react";
import { Component } from "react";
import { pushModalToStack, popModalFromStack } from "../../actions/commonActions";
import PricingSetup from "../../components/PricingSetup";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { completeInitialSetup, getAlpideProducts } from "./action";


class PricingSetupContainer extends Component{

  constructor(props) {
    super(props);
    
    this.state = {
      plansLoaded: false,
      loginData: props?.location?.state?.loginData || {},
      permissionData: props?.location?.state?.permissionData || {},
      clientUserAccountId: props?.location?.state?.clientUserAccountId || 0,
    };

    // in case login data is not present redirect to login page
    if(!props?.location?.state?.clientUserAccountId && !Object.keys(props?.location?.state?.loginData || {})?.length){
      props.history.push("/");
    }
  }

  componentDidMount(){
    // this.props.getAllPlanIndustries();
    this.props.getAlpideProducts();
  }

  componentWillReceiveProps(props){

    // if(!this.state.plansLoaded && (props.planIndustries || []).length){

    //   const uniqueIndustryTypesSet = new Set(props.planIndustries.map(item => item.industryType));

    //   const uniqueObjects = Array.from(uniqueIndustryTypesSet).map(industryType => {
    //     return props.planIndustries.find(item => item.industryType === industryType);
    //   });

    //   this.setState({
    //     planIndustries: uniqueObjects,
    //     plansLoaded: true
    //   })

    // }
  }

  render() {
    return <PricingSetup {...this.props} {...this.state}/>;
  }
    
}
const mapStateToProps = (state) => {
  return {
    sessionType: state.auth.sessionType,
    planLoading: state.common.planLoading,
    planIndustries: state.common.planIndustries,
    alpideProducts: state.common.alpideProducts,

  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushModalToStack,
      popModalFromStack,
      // getAllPlanIndustries,
      completeInitialSetup,
      getAlpideProducts,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(PricingSetupContainer);
