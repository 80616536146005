import React, { useState } from "react";
import { Button, Col, TimePicker, Row } from "antd";
import {
    getCurrentDateForBackend,
    getLocalDateFromUtcStr
} from "../../../utils";
import { Dropdown } from "../../general/Dropdown";
import { TextBox } from "../../general/TextBox";
import moment from "moment-timezone";
import { CONSTANTS } from "../../../static/constants";

const EditAttendanceComponent = (props) => {

    const { studentData, attendanceStatus, setEditPop } = props;

    const [editedAt, setEditedAt] = useState({
        attendanceDate: moment(props.selectedDate || '').format(CONSTANTS.BACKEND_FORMAT),
        atStatus: attendanceStatus,
    });


    const handleSubmit = () => {
      let attendanceList = [{
        studentId: studentData.studentId,
        studentFirstName: studentData.studentName,
        studentLastName: '',
        attendanceStatus: editedAt.atStatus || "Present",
      }];
  
      const payload = {
        relationshipId: props.relationshipId,
        attendanceList: attendanceList,
        classId: props.classId,
        classSectionId: props.classSectionId || 0,
        subjectId: props.subjectId || 0,
        // attendanceDate: getCurrentDateForBackend(
        //   editedAt.attendanceDate || new Date()
        // ),
        attendanceDate: moment(
          (editedAt.attendanceDate || new Date())).format("YYYY-MM-DD hh:mm:ss") 
        ,
        createdByEmpId: (props.userInfo || {}).relationshipEmployeeId,
        formId: 0,
      };
      props.saveAttendance(payload, props);
       
    };
  return (
    <div style={{ padding: '10px 10px 10px 0'}}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <div className="i-label">Student Name</div>
          <TextBox disabled= {true} value={studentData.studentName || ''} />
        </Col>

        <Col span={12}>
          <div className="i-label">Date</div>
          <TextBox disabled= {true} value={getLocalDateFromUtcStr({ date: editedAt.attendanceDate })} />
        </Col>

        <Col span={12}>
          <div className="i-label">Class Name</div>
          <TextBox disabled= {true} value={props.className || ''} />
        </Col>

        {props.sectionName ? <Col span={12}>
          <div className="i-label">Section Name</div>
          <TextBox disabled= {true} value={props.sectionName || ''} />
        </Col>:''}

        <Col span={24}>
          <div className="i-label">Status</div>
          <Dropdown
            showSearch={false}
            items={[
              "Present",
              "Absent",
              "Tardy",
              "Leave",
              "Holiday",
            ]}
            value={editedAt.atStatus}
            onSelect={(selectedValue) => {
              setEditedAt({ ...editedAt, atStatus: selectedValue });
            }}
          />
        </Col>
      </Row>

      <div className="pop_footer">

        <Button
            onClick={() => {
                setEditPop(false);
            }}
        >
            Cancel
        </Button>
        <Button
            type="primary"
            onClick={() => {
              handleSubmit();
              setEditPop(false);
            }}
        >
            Submit
        </Button>

      </div>

    </div>
  );
};

export default EditAttendanceComponent;