import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Skeleton, Pagination, Popover, Tooltip,Empty } from 'antd';
import { fetchPaginationDataIfNeeded, fixedDecimalAndFormateNumber, getMomentDateForUIReadOnly, sortTable } from '../../../../utils'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './index.scss';
import CreditMemoDetail from '../../../../containers/modal/modalBody/customer/CreditMemoDetail';
import { CONSTANTS } from '../../../../static/constants';
import { AddAndRemoveCustomerListingColumn } from "../../../general/AddAndRemoveCustomerListingColumn";
import { InsertRowRightOutlined } from '@ant-design/icons';
import StandardCreditMemo from '../../../../containers/customer/creditMemo/StandardCreditMemo';
import CustomerProfile from '../../../../containers/modal/modalBody/customer/Profile'
import PageHeader from '../../../Common/pageHeader';

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const CreditMemoListingComp = (props) => {
    const { creditMemoList, updateState, companyInfo, creditMemoCount, pageNumber, pageSize, dir = 0 } = props;

    // const getActionMenuPermissionData = () => {
    //     const primaryPerm = permissions.primary || [];
    //     const permittedModules = {
    //         firstSection: {
    //             updateCreditMemo: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.CREDIT_MEMO, PERMISSION_VALUES.UPDATE),
    //             cloneCreditMemo: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.CREDIT_MEMO, PERMISSION_VALUES.CREATE)
    //         }
    //     };
    //     Object.keys(permittedModules.firstSection).forEach((moduleName) => {
    //         if (permittedModules.firstSection[moduleName] === false) {
    //             delete permittedModules.firstSection[moduleName];
    //         }
    //     })
    //     return permittedModules;
    // }

    // const actionMenu = (param, permittedModules) => {
    //     return (
    //         <Menu className="cell-action-dropdown">
    //             {
    //                 permittedModules.firstSection.updateCreditMemo
    //                     ? (
    //                         <Menu.Item key="0">
    //                             <i className="fa fa-pencil" />
    //                             <Link
    //                                 to={
    //                                     {
    //                                         pathname: '/admin/update-credit-memo',
    //                                         state: {
    //                                             customerId: param.data.customerId,
    //                                             creditMemoMasterId: param.data.creditMemoMasterId,
    //                                             update: true
    //                                         }
    //                                     }}
    //                             ><FormattedMessage id='updateCM.text' defaultMessage='' /> </Link>
    //                         </Menu.Item>
    //                     )
    //                     : <Fragment />
    //             }

    //             {
    //                 permittedModules.firstSection.updateCreditMemo
    //                     ? (
    //                         <Menu.Item key="0">
    //                             <i className="fa fa-trash" />
    //                             <Link onClick={() => {
    //                                 props.showModal({
    //                                     modalBody: <FormattedMessage id='common.delete.confirmation' defaultMessage='' />,
    //                                     handleSubmit: () => { 
    //                                         props.deleteCreditMemo({
    //                                             customerId: param.data.customerId,
    //                                             creditMemoMasterId: param.data.creditMemoMasterId,
    //                                             relationshipId: param.data.relationshipId
    //                                         }, props)
    //                                     }
    //                                 })

    //                             }}><FormattedMessage id='common.delete.confirmation' defaultMessage='' /></Link>
    //                         </Menu.Item>
    //                     )
    //                     : <Fragment />
    //             }

    //         </Menu >
    //     );
    // }


    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        fetchPaginationDataIfNeeded('fetchCreditMemos', 'creditMemo', props, payload);
    }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetPaginatedData();
        props.fetchCreditMemos(payload);
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
    }

    // const getRowHeight = (params) => {
    //     let height = 28;
    //     return height;
    // }

    const openCreditMemoDetails = (data) => {
        const creditMemoPayload = {
            customerId: data.customerId,
            creditMemoMasterId: data.creditMemoMasterId
        }
        props.pushModalToStack({
            modalBody: <CreditMemoDetail {...props} creditMemoPayload={creditMemoPayload} />,
            width: '100%',
            hideFooter: true, keyboard:false,
            wrapClassName: 'modal-custom-detail',
            
        })
    }

    // const saveColumnIndex = (columnState) => {
    //     let data = props.txPreferences || {};
    //     let tablePreference = {};
    //     data.relationshipId = (props.companyInfo || {}).relationshipId;
    //     data.clientUserAccountId = (props.userInfo || {}).userId;
    //     if (data.tablePreference) {
    //         tablePreference = JSON.parse(data.tablePreference);
    //     }
    //     tablePreference.columnSetting = columnState;
    //     tablePreference.columnList = props.txColumns;
    //     data.tablePreference = JSON.stringify(tablePreference);
    //     data.preferenceId = props.preferenceId;
    //     data.gridPreference = props.gridPreference;
    //     props.saveUserPrefrences(data);
    // }

    // const saveColumnWidth = (columnState, width) => {
    //     let data = props.txPreferences || {};
    //     let tablePreference = {};
    //     data.relationshipId = (props.companyInfo || {}).relationshipId;
    //     data.clientUserAccountId = (props.userInfo || {}).userId;
    //     if (data.tablePreference) {
    //         tablePreference = JSON.parse(data.tablePreference);
    //     }
    //     tablePreference.columnSetting = columnState;
    //     tablePreference.columnList = props.txColumns;
    //     data.tablePreference = JSON.stringify(tablePreference);
    //     data.preferenceId = props.preferenceId;
    //     data.gridPreference = props.gridPreference;
    //     props.saveUserPrefrences(data, props);
    // }

    // let moveEvent = {};
    // const onColumnMoved = (event, isDragStopped) => {
    //     if (event.column) {
    //         moveEvent = event;
    //     }
    //     if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
    //         saveColumnIndex(moveEvent.columnApi.getColumnState());
    //     }
    // }

    // const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
    //     const gridColumnApi = tableGridColumnApi || props.gridColumnApi;
    //     event.api.resetRowHeights();
    //     if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
    //         event.api.resetRowHeights();
    //         if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
    //             props.gridApi.sizeColumnsToFit();
    //             setTimeout(() => {
    //                 const columnArray = event.columnApi.getColumnState();
    //                 saveColumnWidth(columnArray);
    //             }, 1000);
    //         } else {
    //             saveColumnWidth(event.columnApi.getColumnState());
    //         }
    //     }
    // }

    const _isColumnToHide = (columnName) => {
        return (props.txColumns.indexOf(columnName) < 0 && props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
    }

    //const permittedModules = getActionMenuPermissionData();

    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.creditMemo.listing.header.creditMemoNo' defaultMessage='' />;
    //         },
    //         field: "creditMemoNumber",
    //         hide: _isColumnToHide('creditMemoNumber'),
    //         colId: "creditMemoNumber_1",
    //         minWidth: 150,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         rowDrag: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
    //             openCreditMemoDetails(link.data)
    //         }}>{link.value}</div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.creditMemo.listing.header.customer' defaultMessage='' />;
    //         },
    //         field: "customerName",
    //         hide: _isColumnToHide('customerName'),
    //         colId: "customerName_1",
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div className="cursor-pointer" >
    //         <div onClick={() => {
    //             props.history.push("customers/details", {
    //                 customerId: link.data.customerId,
    //                 customerName: link.data.customerName,
    //             })
    //         }}>{link.data.customerName}</div>
    //     </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.creditMemo.listing.header.transactionDate' defaultMessage='' />;
    //         },
    //         field: "creditMemoDate",
    //         hide: _isColumnToHide('creditMemoDate'),
    //         colId: "creditMemoDate_1",
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div>
    //              <Tooltip placement="topLeft" title={(link.data.creditMemoDate && getMomentDateForUIReadOnly({date: link.data.creditMemoDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
    //             {getMomentDateForUIReadOnly({date: new Date(link.data.creditMemoDate), format})}
    //             </Tooltip>
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='customer.creditMemo.listing.header.amount' defaultMessage='' />  </div>;
    //         },
    //         field: "creditMemoTotalAmount",
    //         colId: "creditMemoTotalAmount_1",
    //         hide: _isColumnToHide('creditMemoTotalAmount'),
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         cellRendererFramework: (link) => {
    //             return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{link.value} </span> : "-"
    //         },
    //         valueGetter: (link) => {
    //             // return link.data.creditMemoTotalAmount ? Number(link.data.creditMemoTotalAmount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
    //             return link.data.creditMemoTotalAmount ? fixedDecimalAndFormateNumber(Number(link.data.creditMemoTotalAmount)) : "-";
    //         },
    //         resizable: true,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.creditMemo.listing.header.systemStatus' defaultMessage='' />;
    //         },
    //         field: "status",
    //         hide: _isColumnToHide('status'),
    //         colId: "status_1",
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         cellRendererFramework: (params) => {
    //             return <div> <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage={params.value} /></span> </div>
    //         },
    //         resizable: true,
    //         cellClass: 'status-cell'
    //     },
    //     // {
    //     //     headerComponentFramework: () => {
    //     //         return <div className="cursor-pointer">
    //     //             <Popover
    //     //                 content={<AddAndRemoveCustomerListingColumn {...props} />}
    //     //                 title={<div className="text-center">
    //     //                     <FormattedMessage id='common.addRemove' defaultMessage='' /> </div>}
    //     //                 placement="left"
    //     //                 trigger="click"
    //     //             >
    //     //                 <i className='fa fa-bars' />
    //     //             </Popover>
    //     //         </div>;
    //     //     },
    //     //     cellRendererFramework: (param) => {
    //     //         return <Dropdown overlay={actionMenu(param, permittedModules)} trigger={['click']}>
    //     //             <Button className="action-btn small">
    //     //                 <i className="fa fa-ellipsis-v" />
    //     //             </Button>
    //     //         </Dropdown>
    //     //     },
    //     //     field: "action",
    //     //     colId: "action_1",
    //     //     pinned: 'right',
    //     //     minWidth: 52,
    //     //     lockPosition: true,
    //     //     maxWidth: 52,
    //     //     suppressMovable: true,
    //     // }
    // ];

    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    const breadCrumbList = [
        {
            name: <FormattedMessage id='common.sales' defaultMessage='' />,
        },
        {
            name: <FormattedMessage id='common.creditMemo' defaultMessage='' />,
        }
    ];

    // const onGridReady = (params) => {
    //     props.updateState({
    //         gridApi: params.api,
    //         gridColumnApi: params.columnApi
    //     });
    //     if (props.isColumnFitTable && params.api) {
    //         params.api.sizeColumnsToFit();
    //         props.updateState({
    //             isColumnFitTable: false
    //         })
    //     }
    //     onDataRendered(params);
    // }

    // const onModelUpdated = (params) => {
    //     if((creditMemoList[pageNumber] || []).length &&  !props.isRowHeightSet){
    //         setTimeout(()=>{
    //             params.api.resetRowHeights();
    //         }, 1000)
    //         props.updateState({
    //             isRowHeightSet: true
    //         })
    //     }
    //     if (props.tableDataReRendered) {
    //         //params.api.resetRowHeights();
    //         props.updateState({
    //             tableDataReRendered: false
    //         })
    //         let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
    //         const columnToBeSet = [];
    //         const indexObjArr = TRANSACTION_COLUMN_INDEX.CREDIT_MEMO_LISTING;

    //         indexObjArr.forEach((e) => {
    //             let colObj = find(txColumnSetting, { colId: e + '_1' });
    //             if (colObj) {
    //                 columnToBeSet.push(colObj);
    //             }
    //         });

    //         txColumnSetting = txColumnSetting.filter((ele) => {
    //             let columnName = ele.colId.split("_")[0];
    //             return indexObjArr.indexOf(columnName) === -1;
    //         })
    //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
    //         txColumnSetting.forEach((e) => {
    //             let columnName = e.colId.split("_")[0];
    //             if (columnName !== 'action') {
    //                 if (columnName === 'isChecked') {
    //                     e.hide = false;
    //                 } else {
    //                     e.hide = _isColumnToHide(columnName);
    //                 }
    //             } if (columnName === 'action') {
    //                 e.hide = false;
    //                 e.pinned = 'right';
    //             }
    //         });
    //         params.columnApi.setColumnState(txColumnSetting);
    //         setTimeout(() => {
    //             if (props.gridColumnApi && props.gridColumnApi.columnController.bodyWidth < props.gridColumnApi.columnController.scrollWidth) {
    //                 params.api.sizeColumnsToFit();
    //             }
    //         }, 500);
    //     }
    // }

    // const onDataRendered = (params) => {
    //     let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length? props.txColumnSetting: params.columnApi.getColumnState();
    //     if (Object.keys(txColumnSetting).length) {
    //         const columnToBeSet = [];
    //         const indexObjArr = TRANSACTION_COLUMN_INDEX.CREDIT_MEMO_LISTING;

    //         indexObjArr.forEach((e) => {
    //             let colObj = find(txColumnSetting, { colId: e + '_1' });
    //             if (colObj) {
    //                 columnToBeSet.push(colObj);
    //             }
    //         });

    //         txColumnSetting = txColumnSetting.filter((ele) => {
    //             let columnName = ele.colId.split("_")[0];
    //             return indexObjArr.indexOf(columnName) === -1;
    //         })
    //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
    //         txColumnSetting.forEach((e) => {
    //             let columnName = e.colId.split("_")[0];
    //             if (columnName !== 'action') {
    //                 if (columnName === 'isChecked') {
    //                     e.hide = false;
    //                 } else {
    //                     e.hide = _isColumnToHide(columnName);
    //                 }
    //             } if (columnName === 'action') {
    //                 e.hide = false;
    //                 e.width = 52;
    //                 e.pinned = 'right';
    //             }
    //         });
    //         params.columnApi.setColumnState(txColumnSetting);
    //         setTimeout(() => {
    //             if (params.columnApi && params.columnApi.columnController.bodyWidth < params.columnApi.columnController.scrollWidth) {
    //                 params.api.sizeColumnsToFit();
    //             }
    //         }, 500);
    //     } else {
    //         params.api.sizeColumnsToFit();
    //     }
    // }

    const sortColumn = (e) => {
        sortTable(document.getElementById('creditMemo-table'), e.target.cellIndex, 1 - dir);
        updateState({ dir: 1 - dir })
    }
    const openNewCm = () => {
        props.pushModalToStack({
            modalBody: <StandardCreditMemo {...props} />,
            width: '100%',
            hideTitle: true,
            hideFooter: true,
            wrapClassName: 'new-transaction-wrapper',
            onCancel: () => {
                props.showModal({
                  modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                  footer:{submit:{intlId:"button.close.label"}},
                  handleSubmit: () => {
                    props.popModalFromStack();
                  }
                })
              }
        })
    }


    return (
        <Fragment>
           
        <PageHeader {...props}
        pageName="heading.sales.creditMemo"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={openNewCm}
      />
            <div className='view-container'>

                    <div className="view-container-actions">
            <Skeleton loading={props.headerLoading} paragraph={false} active>
                        <div className="left-actions">
                            <div className="table-heading">
                                <FormattedMessage id='sales.creditMemo.table.heading' defaultMessage='' />
                            </div>


                            <div className="vertical-sep" />
                            {/* <div>{creditMemoList[pageNumber] ? creditMemoList[pageNumber].length : ""}</div> */}
                             {creditMemoCount}

                        </div>
                        <div className="right-actions">
                                <Popover
                                    content={<AddAndRemoveCustomerListingColumn {...props} />}
                                    title={<div className="text-center">Add/Remove</div>}
                                    placement="leftTop"
                                    trigger="click"
                                    arrow="right"
                                    visible={props.showColPop}
                                >
                                    <button onClick={() => { props.updateState({ showColPop: !props.showColPop }) }}>
                                        <InsertRowRightOutlined className='mr5'/> Columns
                                    </button>
                                </Popover>
                        </div>
                        </Skeleton>
                    </div>
                    {/* {props.txColumns.length ? <div>
                        <div className="cursor-pointer">
                        <span className="top-button">
                            <Popover
                                content={<AddAndRemoveCustomerListingColumn {...props} />}
                                title={<div className="text-center">
                                    <FormattedMessage id='common.addRemove' defaultMessage='' /> </div>}
                                placement="left"
                                trigger="click"
                            >
                                <i className='fa fa-bars' />
                            </Popover>
                            </span>
                            <div className="clearfix"></div>
                        </div>
                        <AgGridReact
                            onGridReady={onGridReady}
                            columnDefs={columnDefs}
                            rowData={creditMemoList[pageNumber] || []}
                            rowDragManaged={true}
                            domLayout={"autoHeight"}
                            animateRows={true}
                            defaultColDef={{
                                flex: 1,
                                autoHeight: true,
                                wrapText: true,
                            }}
                            // getRowHeight={getRowHeight}
                            onModelUpdated={onModelUpdated}
                            onColumnResized={onColumnResized}
                            onDragStopped={() => {
                                onColumnMoved({}, true);
                            }}
                            //onColumnMoved={onColumnMoved}
                        >
                        </AgGridReact>
                    </div> : null} */}
                    <Skeleton loading={props.listLoading} active paragraph={{ rows: 16 }}>

                    <div className='table-container'>
                        <table id='creditMemo-table'>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    {!_isColumnToHide('creditMemoNumber') ? <th onClick={sortColumn}><FormattedMessage id='customer.creditMemo.listing.header.creditMemoNo' defaultMessage='' /></th> : ''}
                                    {!_isColumnToHide('customerName') ? <th onClick={sortColumn}><FormattedMessage id='customer.creditMemo.listing.header.customer' defaultMessage='' /></th> : ''}
                                    {!_isColumnToHide('creditMemoDate') ? <th onClick={sortColumn}><FormattedMessage id='customer.creditMemo.listing.header.transactionDate' defaultMessage='' /></th> : ''}
                                    {!_isColumnToHide('status') ? <th onClick={sortColumn}><FormattedMessage id='customer.creditMemo.listing.header.systemStatus' defaultMessage='' /></th> : ''}
                                    {!_isColumnToHide('creditMemoTotalAmount') ? <th onClick={sortColumn}><FormattedMessage id='customer.creditMemo.listing.header.amount' defaultMessage='' /></th> : ''}
                                </tr>
                            </thead>
                            <tbody>
                                {creditMemoList && creditMemoList[pageNumber] ?
                                    creditMemoList[pageNumber].map((rowData, index) => (
                                        <tr key={index}>
                                            <td>{Number(((pageNumber - 1) * pageSize) + index + 1)}</td>
                                            {!_isColumnToHide('creditMemoNumber') ? <td>
                                                <div className="cursor-pointer" onClick={() => {
                                                    openCreditMemoDetails(rowData)
                                                }}>{rowData.creditMemoNumber}</div>
                                            </td> : ''}
                                            {!_isColumnToHide('customerName') ? <td>
                                                <div className="cursor-pointer" >
                                                    <div 
                                                    // onClick={() => {
                                                    //     props.history.push("customers/details", {
                                                    //         customerId: rowData.customerId,
                                                    //         customerName: rowData.customerName,
                                                    //     })
                                                    onClick={() => {
                                                        props.pushModalToStack({  
                                                  
                                                          modalBody: <CustomerProfile {...props}
                                                          customerId= {rowData.customerId}
                                                        //   customerName={rowData.customerName}
                                                        //   customerDetails={rowData}
                                                      />,
                                                      width: '100%',
                                                      hideTitle: true,  
                                                      hideFooter: true,
                                                      wrapClassName: "new-transaction-wrapper",
                                                      })
                                                    }}>{rowData.customerName}</div>
                                                </div>
                                            </td> : ''}
                                            {!_isColumnToHide('creditMemoDate') ? <td>
                                                <div>
                                                    <Tooltip placement="topLeft" title={(rowData.creditMemoDate && getMomentDateForUIReadOnly({ date: rowData.creditMemoDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                                                        {getMomentDateForUIReadOnly({ date: new Date(rowData.creditMemoDate), format })}
                                                    </Tooltip>
                                                </div>
                                            </td> : ''}
                                            {!_isColumnToHide('status') ? <td>
                                                {<span className={["status-code", rowData.status.toLowerCase()].join(' ')}><FormattedMessage id={rowData.status} defaultMessage={rowData.status} /></span>}
                                            </td> : ''}
                                            {!_isColumnToHide('creditMemoTotalAmount') ? <td>
                                                {rowData.creditMemoTotalAmount ? fixedDecimalAndFormateNumber(Number(rowData.creditMemoTotalAmount)) : "-"}
                                            </td> : ''}
                                        </tr>
                                    )) :  <tr key="empty-data-box">
                                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                        <Empty />
                                    </td>
                                </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </Skeleton>

                <div className="footer">
                   <Skeleton loading={props.headerLoading} paragraph={false} active>
                    <div className="f-left"></div>
                    <div className="f-right">
                        <Pagination
                            size="small"
                            total={creditMemoCount}
                            showTotal={showTotal}
                            defaultPageSize={props.pageSize}
                            showSizeChanger
                            pageSizeOptions={[10, 25, 50, 100, 200]}
                            // showQuickJumper
                            onChange={(pageNumber, pageSize) => {
                                loadPageData(pageNumber, pageSize);
                                updateState({ pageSize: pageSize, pageNumber: pageNumber })
                            }}
                            onShowSizeChange={(pageNumber, pageSize) => {
                                handlePageSizeChange(pageNumber || 1, pageSize, props)
                            }}
                        />
                    </div>
                    </Skeleton>
                </div>
            </div>
        </Fragment>
    );
};

export default injectIntl(CreditMemoListingComp);
